import { gql } from "@apollo/client";

export const FORCE_URLS_REFRESH = gql`
  mutation ForceUrlsRefresh(
    $websiteId: ID!
    $urls: String!
    $forceRefreshToken: String!
    $options: ForceRefreshOptions
  ) {
    forceUrlsRefresh(
      websiteId: $websiteId
      urls: $urls
      forceRefreshToken: $forceRefreshToken
      options: $options
    ) {
      errors {
        message
      }

      ok
    }
  }
`;
