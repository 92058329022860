import { useQuery } from "@apollo/client";
import { useState } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { Pie } from "../../Charts";
import {
  BarChart,
  convertToNivoPie,
  reducebyDimensions,
  StackedBarChart,
} from "../../common/charts";
import { DataHandler } from "../../components/DataHandler";
import { DEVICES_COLORS, HTTP_CODES_COLORS } from "../../constants";
import {
  NotDeliverableReason,
  QueryIndexedPagesResult,
} from "../../gql/types.generated";
import {
  GET_WEBSITE_INDEXATION_MONITORING,
  WebsiteIndexationMonitoringQuery,
  WebsiteIndexationMonitoringQueryVariables,
} from "../../gql/websites/monitoring";
import { Link, useParams } from "react-router-dom";
import { ConfigIdParams, WebsiteIdParams } from "../types/routeParams";

interface ChartCardProps {
  data: NonNullable<
    WebsiteIndexationMonitoringQuery["website"]
  >["queryIndexedPages"];
}

function StatusCodeCard({ data }: ChartCardProps) {
  return (
    <Card>
      <Card.Header>HTTP Status Codes</Card.Header>
      <Card.Body>
        <Row className="websiteHomeChart">
          <Col sm={3}>
            <Pie
              data={convertToNivoPie(
                reducebyDimensions(data, ["httpCode"], "count"),
                "httpCode",
                "count",
                HTTP_CODES_COLORS
              )}
            />
          </Col>
          <Col sm={9}>
            <StackedBarChart
              data={data}
              dimension="date"
              splitBy={["httpCode"]}
              metric="count"
              colors={HTTP_CODES_COLORS}
              xTitle="Date"
              yTitle="Number of Pages Indexed"
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

function DevicesCard({ data }: ChartCardProps) {
  return (
    <Card>
      <Card.Header>Devices</Card.Header>
      <Card.Body>
        <Row className="websiteHomeChart">
          <Col sm={3}>
            <Pie
              data={convertToNivoPie(
                reducebyDimensions(data, ["device"], "count"),
                "device",
                "count",
                DEVICES_COLORS
              )}
            />
          </Col>
          <Col sm={9}>
            <StackedBarChart
              data={data}
              dimension="date"
              splitBy={["device"]}
              metric="count"
              colors={{}}
              xTitle="Date"
              yTitle="Number of Indexed Pages"
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

function SectionsCard({ data }: ChartCardProps) {
  return (
    <Card>
      <Card.Header>Sections</Card.Header>
      <Card.Body>
        <Row className="websiteHomeChart">
          <Col sm={3}>
            <Pie
              data={convertToNivoPie(
                reducebyDimensions(data, ["sectionName"], "count"),
                "sectionName",
                "count"
              )}
            />
          </Col>
          <Col sm={9}>
            <StackedBarChart
              data={data}
              dimension="date"
              splitBy={["sectionName"]}
              metric="count"
              colors={{}}
              xTitle="Date"
              yTitle="Number of Pages Indexed"
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

function TtlRangeCard({ data }: ChartCardProps) {
  return (
    <Card>
      <Card.Header>TTL Refresh time ranges</Card.Header>
      <Card.Body>
        <Row className="websiteHomeChart">
          <Col sm={3}>
            <Pie
              data={convertToNivoPie(
                reducebyDimensions(data, ["ttlRange"], "count"),
                "ttlRange",
                "count"
              )}
            />
          </Col>
          <Col sm={9}>
            <StackedBarChart
              data={data}
              dimension="date"
              splitBy={["ttlRange"]}
              metric="count"
              colors={{}}
              xTitle="Date"
              yTitle="Number of Pages Indexed"
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

function NotDeliverableReasonsCard({ data }: ChartCardProps) {
  const filtered = data.filter(
    (d) => d.notDeliverableReason !== NotDeliverableReason.Cached
  );
  return (
    <Card>
      <Card.Header>Not Deliverable Reasons</Card.Header>
      <Card.Body>
        <Row className="websiteHomeChart">
          <Col sm={3}>
            <Pie
              data={convertToNivoPie(
                reducebyDimensions(filtered, ["notDeliverableReason"], "count"),
                "notDeliverableReason",
                "count"
              )}
            />
          </Col>
          <Col sm={9}>
            <StackedBarChart
              data={filtered}
              dimension="date"
              splitBy={["notDeliverableReason"]}
              metric="count"
              colors={{}}
              xTitle="Date"
              yTitle="Number of Non Deliverable Pages"
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
}

function NonIndexedPagesCard({ data }: ChartCardProps) {
  const filtered = data.filter((d) => !d.indexed);
  return (
    <Card>
      <Card.Header>Non Indexed Pages</Card.Header>
      <Card.Body className="websiteHomeChart">
        <BarChart
          data={filtered}
          dimension="date"
          metric="count"
          metricLegend="Non Indexed Pages"
          color="orange"
          xTitle="Date"
          yTitle="Number of Pages"
        />
      </Card.Body>
    </Card>
  );
}

const coeff = 1000 * 60;
const coeff30days = 1000 * 60 * 60 * 24 * 30;

function WebsiteIndexationHome() {
  const { websiteId } = useParams<
    WebsiteIdParams & ConfigIdParams
  >() as WebsiteIdParams & ConfigIdParams;
  const now = new Date();

  // Round to last minute
  const end = new Date(Math.round(now.getTime() / coeff) * coeff);
  const tEnd = Math.floor(end.getTime() / 1000);
  const start = new Date(Math.round(end.getTime() - coeff30days));
  start.setMinutes(0);
  const tStart = Math.floor(start.getTime() / 1000);

  const [selectedSection, setSelectedSection] = useState("");

  const { error, loading, data } = useQuery<
    WebsiteIndexationMonitoringQuery,
    WebsiteIndexationMonitoringQueryVariables
  >(GET_WEBSITE_INDEXATION_MONITORING, {
    variables: { id: websiteId, timestampStart: tStart, timestampEnd: tEnd },
  });

  const website = data?.website;

  if (error || loading || !website) {
    return (
      <DataHandler error={error} loading={loading} data={website} expectData />
    );
  }

  const stats = selectedSection
    ? website.queryIndexedPages.filter((i) => i.sectionName === selectedSection)
    : website.queryIndexedPages;

  const uniqueSections = Array.from(
    new Set(website.queryIndexedPages.map((res) => res.sectionName))
  );
  const sections = uniqueSections.filter(
    (s): s is NonNullable<QueryIndexedPagesResult["sectionName"]> => !!s
  );

  return (
    <div>
      <h1>
        <Link to={`/website/${website.id}`}>{website.name}</Link>
        {" > "}
        Indexed Pages Dashboard
      </h1>

      {stats ? (
        <div>
          {sections && (
            <Form.Group>
              <Form.Control
                as="select"
                onChange={(e) => setSelectedSection(e.target.value)}
              >
                <option value="">All Behaviors</option>
                {sections.map((section) => (
                  <option key={section} value={section}>
                    {section}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          )}

          <StatusCodeCard data={stats} />
          <DevicesCard data={stats} />
          <SectionsCard data={stats} />
          <TtlRangeCard data={stats} />
          <NonIndexedPagesCard data={stats} />
          <NotDeliverableReasonsCard data={stats} />
        </div>
      ) : (
        "Loading"
      )}
    </div>
  );
}

export { WebsiteIndexationHome };
