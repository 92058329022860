// React form
// https://rjsf-team.github.io/react-jsonschema-form/
// https://react-jsonschema-form.readthedocs.io/en/latest/

const CustomJsUiSchema = {
  code: {
    "ui:widget": "AceWidget",
  },
};

export enum ModuleType {
  CUSTOM_JS = "CustomJs",
  PAGE_EDITOR = "PageEditor",
  REMOVE_LINKS = "RemoveLinks",
  REDIRECT = "Redirect",
  NO_FOLLOW_LINKS = "NoFollowLinks",
}

export const MODULE_UISCHEMAS: Partial<Record<ModuleType, unknown>> = {
  [ModuleType.CUSTOM_JS]: CustomJsUiSchema,
};

export const DATA_SUMMARY_COLUMNS: Record<ModuleType, string[]> = {
  [ModuleType.REDIRECT]: ["url", "destinationUrl"],
  [ModuleType.REMOVE_LINKS]: ["url"],
  [ModuleType.NO_FOLLOW_LINKS]: ["url"],
  [ModuleType.PAGE_EDITOR]: ["url"],
  [ModuleType.CUSTOM_JS]: [],
};
