export const DEPLOY_TESTS = [
    ["KindAlwaysSuccess", "AlwaysSuccess"],
    ["KindCacheHit", "CacheHit"],
    ["KindCacheMiss", "CacheMiss"],
    ["KindNoCache", "NoCdnCache"],
    ["KindNoCacheHeader", "NoCdnCacheHeaders"],
    ["KindNoFallbackCache", "NoFallbackCache"],
    ["KindTimeout", "Timeout"],
    ["KindTimeoutSearchBots", "TimeoutUA"],
    ["KindRedirect", "ForceRedirect"],
    ["KindForwardParameters", "ForwardParameters"],
    ["KindRobotsTxt", "RobotsTxt"],
    ["KindResources", "Resources"],
    ["KindBotifyBot", "BotifyBots"],
    ["KindUser", "UserRequest"],
    ["KindHttp", "Http"],
    ["KindNotModified", "NotModified"],
    ["KindWebsocket", "Websocket"],
    ["KindPost", "PostRequest"],
  ] as const;

export const DEPLOY_TESTS_IDS = DEPLOY_TESTS.map((deployTest) => deployTest[0]);

export const DEFAULT_ASSETS_LISTS = [
  "7z",
  "ai",
  "avi",
  "bmp",
  "bz2",
  "css",
  "csv",
  "dat",
  "dmg",
  "doc",
  "docx",
  "eot",
  "exe",
  "flac",
  "flv",
  "gif",
  "gz",
  "ico",
  "ief",
  "iso",
  "jpe",
  "jpeg",
  "jpg",
  "js",
  "json",
  "less",
  "m1v",
  "m4a",
  "m4v",
  "map",
  "mka",
  "mkv",
  "mov",
  "mp",
  "mp2",
  "mp3",
  "mp4",
  "mpa",
  "mpe",
  "mpeg",
  "mpg",
  "odt",
  "ogg",
  "ogm",
  "ogv",
  "opus",
  "otf",
  "ott",
  "ovg",
  "pbm",
  "pdf",
  "pgm",
  "png",
  "pnm",
  "ppm",
  "pps",
  "ppt",
  "pptx",
  "ps",
  "psd",
  "qt",
  "rar",
  "ras",
  "rdf",
  "rgb",
  "rss",
  "svg",
  "swf",
  "tif",
  "tiff",
  "torrent",
  "tsv",
  "ttf",
  "txt",
  "vcf",
  "wav",
  "webm",
  "webp",
  "wmv",
  "woff",
  "woff2",
  "woff2",
  "xbm",
  "xlm",
  "xls",
  "xml",
  "xpdl",
  "xpm",
  "xwd",
  "zip",
];
export const DEFAULT_ASSETS_REGEX = `["\\\\.(${DEFAULT_ASSETS_LISTS.join("|")})$"]`

export const DEFAULT_FAILOVER_USER_AGENT = "Mozilla/5.0 (compatible; botify; http://botify.com) fallback"