import { useQuery } from "@apollo/client";
import { useState } from "react";

import { DataHandler } from "../../components/DataHandler";
import { ExplorerTable, SectionComponent } from "../../common/explorer";
import { IFilter } from "../../common/typing/filter";
import { WebsiteType } from "../../gql/types.generated";
import {
  GET_WEBSITE,
  WebsiteQuery,
  WebsiteQueryVariables,
} from "../../gql/websites/website";
import { DateComponent } from "../components/date";
import { Header } from "../components/header";
import {
  DEFAULT_START_OFFSET,
  INDEXED_PAGES_DEFINITION,
  PRESETS,
} from "./Settings";
import { getDates } from "../../common/time";
import { PresetParams, WebsiteIdParams } from "../types/routeParams";
import { IPreset } from "../../common/typing/website";
import { Link, useParams } from "react-router-dom";

function WebsiteIndexationExplorer() {
  const { websiteId, preset } = useParams<
    WebsiteIdParams & PresetParams
  >() as WebsiteIdParams & PresetParams;
  const startOffsetInPreset = (PRESETS as Record<string, IPreset>)?.[preset]
    ?.startOffset;
  const { start, end } = getDates(
    websiteId,
    startOffsetInPreset || DEFAULT_START_OFFSET
  );
  const { loading, error, data } = useQuery<
    WebsiteQuery,
    WebsiteQueryVariables
  >(GET_WEBSITE, {
    variables: { id: websiteId },
  });
  const {
    startDate,
    endDate,
    component: dateComponent,
  } = DateComponent(websiteId, start, end, !!startOffsetInPreset);

  const [section, setSection] = useState("");

  const additionalFilters: IFilter[] = section
    ? [{ field: "section_name", predicate: "eq", value: section }]
    : [];

  const website = data?.website;

  if (error || loading || !website) {
    return (
      <DataHandler error={error} loading={loading} data={website} expectData />
    );
  }

  return (
    <div>
      <Header>
        <Header.Title websiteId={websiteId} name="Cached Pages" />
        <Header.Selectors
          selectorComponents={[
            <SectionComponent
              sections={website.productionVersion?.sections ?? []}
              setSection={setSection}
            />,
            dateComponent,
          ]}
        />
      </Header>

      <div>
        <Link to={`/website/${websiteId}/indexation/explorer/last`}>
          Last Cached Pages
        </Link>{" "}
        {" | "}
        <Link to={`/website/${websiteId}/indexation/explorer/last24`}>
          Last Cached Pages (24h)
        </Link>{" "}
        {" | "}
        <Link to={`/website/${websiteId}/indexation/explorer/last_desktop`}>
          Last Cached Desktop Pages
        </Link>{" "}
        {" | "}
        <Link to={`/website/${websiteId}/indexation/explorer/last_mobile`}>
          Last Cached Mobile Pages
        </Link>{" "}
        {" | "}
        <Link to={`/website/${websiteId}/indexation/explorer/top`}>
          Top Cached Pages
        </Link>
        {" | "}
        <Link to={`/website/${websiteId}/indexation/explorer/2xx`}>
          Last Success Pages
        </Link>
        {" | "}
        <Link to={`/website/${websiteId}/indexation/explorer/3xx`}>
          Last 3xx Pages
        </Link>
        {" | "}
        <Link to={`/website/${websiteId}/indexation/explorer/4xx`}>
          Last 4xx Pages
        </Link>
        {" | "}
        <Link to={`/website/${websiteId}/indexation/explorer/5xx`}>
          Last 5xx Pages
        </Link>
        {" | "}
        <Link to={`/website/${websiteId}/indexation/explorer/topBadCanonical`}>
          Top Bad Canonical
        </Link>
        {" | "}
      </div>

      <ExplorerTable
        website={data.website as WebsiteType}
        startDate={startDate}
        endDate={endDate}
        currentPreset={preset}
        presets={PRESETS}
        definitions={INDEXED_PAGES_DEFINITION}
        additionalFilters={additionalFilters}
      />
    </div>
  );
}

export { WebsiteIndexationExplorer };
