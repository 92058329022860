import { gql } from "@apollo/client";

export const GET_ALL_CLUSTERS = gql`
  query AllClusters {
    allClusters {
      id
      name
    }
    allPageworkersClusters {
      ...PageWorkersCluster
    }
  }

  fragment PageWorkersCluster on PageworkersClusterType {
    id
    name
    comments
    isDefault
  }
`;
