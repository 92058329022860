import { useQuery } from "@apollo/client";
import moment from "moment";
import { useState } from "react";
import { Table } from "react-bootstrap";
import Card from "react-bootstrap/Card";

import { Button } from "../../components/Button/Button";
import { DataHandler } from "../../components/DataHandler";
import {
  InterceptorCheckerConfigType,
  InterceptorCheckerJobType,
} from "../../gql/types.generated";
import {
  WebsiteInterceptorCheckerConfigsQuery,
  WebsiteInterceptorCheckerConfigsQueryVariables,
  WEBSITE_INTERCEPTOR_CHECKER_CONFIGS,
} from "../../gql/websites/website";
import { Header } from "../components/header";
import { IRule, transformRuleToString } from "./Utils";
import { useParams } from "react-router-dom";
import { WebsiteIdParams } from "../types/routeParams";

enum ConfigFieldsLists {
  SCOPE_ROUTE = "scopeRoute",
  URL_CACHE_HIT = "urlCacheHit",
  URL_OUT_OF_SCOPE = "urlOutOfScope",
  URL_AMP = "urlAmp",
  IGNORED_PARAMS = "ignoredParams",
  INTERCEPTOR_TIMEOUT = "interceptorTimeout",
  USER_AGENT_TEST_BOT = "userAgentTestBot",
  USER_AGENT_BROWSER = "userAgentBrowser",
  MAX_PARALLEL = "maxParallel",
  MAX_SPEED = "maxSpeed",
  SWITCH_TO_AKAMAI_STAGIN = "switchToAkamaiStaging",
  CHECKS_TO_PERFORM = "checksToPerform",
  EXTRA_HEADERS = "extraHeaders",
}

/** Deserialization of `InterceptorCheckerJobType["result"]` */
interface IJobResult {
  setupTest: {
    id: string;
    description: string;
    rules: IRule;
  };
  succeed: boolean;
  responses: IResponse[];
}

/** Deserialization of `IJobResult["responses"][number]` */
interface IResponse {
  url: string;
  verb: string;
  durationMs: number;
  timestampMs: number;
  statusCode: number;
  succeed: boolean;
  responseHeaders: IHeader[];
  requestHeaders: IHeader[];
  bodyHeader: string;

  httpCheckRes: unknown;
  error: unknown;
}

interface IHeader {
  key: string;
  value: string;
}

type IInterceptorType = Pick<
  InterceptorCheckerConfigType,
  "name" | ConfigFieldsLists
> & {
  lastJob?: IInterceptorJobType | null;
};

function Interceptor({ interceptor }: { interceptor: IInterceptorType }) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <Card>
      <Card.Body>
        <h3>{interceptor.name}</h3>

        <Button onClick={toggle} variant="link">
          {isOpen ? "Hide Config" : "Show config"}
        </Button>

        {isOpen && <ParamsTable interceptor={interceptor} />}

        {interceptor.lastJob && (
          <>
            <h5>Last Job</h5>
            <InterceptorJob job={interceptor.lastJob} />
          </>
        )}
      </Card.Body>
    </Card>
  );
}

type IInterceptorJobType = Pick<
  InterceptorCheckerJobType,
  "errorMessage" | "dateCreated" | "status" | "result"
>;

function InterceptorJob({ job }: { job: IInterceptorJobType }) {
  if (job.status === "FAILED") {
    return (
      <>
        <p>Job failed unexpectedly, for reason:</p>
        <pre>{job.errorMessage}</pre>
      </>
    );
  }

  const results: { results: IJobResult[] } | null = job.result
    ? JSON.parse(job.result)
    : null;

  return (
    <div>
      Created {moment(job.dateCreated).format("MMMM Do, h:mm:ss a")} (
      {moment(job.dateCreated).fromNow()})
      <Table striped bordered>
        <thead>
          <tr>
            <td>Name</td>
            <td>Description</td>
            <td>Result</td>
          </tr>
        </thead>
        {results?.results.map((result) => (
          <InterceptorSingleTest result={result} />
        ))}
      </Table>
    </div>
  );
}

function InterceptorSingleTest({ result }: { result: IJobResult }) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <>
      <tr>
        <td>
          {result.setupTest.id}{" "}
          <Button onClick={toggle} variant="link">
            {isOpen ? "Hide Config" : "Show config"}
          </Button>
        </td>
        <td>{result.setupTest.description}</td>
        <td>{result.succeed ? "✅" : "❌"}</td>
      </tr>
      {isOpen && (
        <tr>
          <td colSpan={3}>
            <Table variant="dark">
              <thead>
                <tr>
                  <td>URL</td>
                  <td>Verb</td>
                  <td>Duration</td>
                  <td>Status</td>
                  <td>Result</td>
                </tr>
              </thead>
              {result.responses.map((response) => {
                return <UrlEntry response={response} />;
              })}
            </Table>

            <b>Rules</b>
            <br />
            {transformRuleToString({ rule: result.setupTest.rules })}
          </td>
        </tr>
      )}
    </>
  );
}

function UrlEntry({ response }: { response: IResponse }) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  return (
    <>
      <tr>
        <td>
          {response.url}{" "}
          <Button variant="link" style={{ padding: 0 }} onClick={toggle}>
            {isOpen ? "Hide" : "Show details"}
          </Button>
        </td>
        <td>{response.verb}</td>
        <td>{response.durationMs}ms</td>
        <td>{response.statusCode}</td>
        <td>{response.succeed ? "✅" : "❌"}</td>
      </tr>
      {isOpen && (
        <tr>
          <td colSpan={5}>
            <UrlDetail response={response} />
          </td>
        </tr>
      )}
    </>
  );
}

function UrlDetail({ response }: { response: IResponse }) {
  const FIELDS = [
    "succeed",
    "verb",
    "statusCode",
    "timestampMs",
    "durationMs",
    "error",
    "requestHeaders",
    "responseHeaders",
    "bodyHeader",
    "httpCheckRes",
  ] as const;

  const renderSwitch = (field: keyof IResponse, response: IResponse) => {
    switch (field) {
      case "succeed":
        return response[field] === true ? <>✅</> : <>❌</>;
      case "requestHeaders":
      case "responseHeaders":
        return response[field].map((header) => (
          <>
            <b>{header.key}</b>: {header.value}
            <br />
          </>
        ));
      case "timestampMs":
        return (
          <>
            {moment.unix(response[field] / 1000).format("MMMM Do, h:mm:ss a")}
          </>
        );
      case "bodyHeader":
        return <>{atob(response[field])}</>;
      default:
        return <>{response[field]}</>;
    }
  };

  return (
    <Table bordered variant="dark">
      {FIELDS.map((field) => (
        <tr>
          <td>{field}</td>
          <td>{renderSwitch(field, response)}</td>
        </tr>
      ))}
    </Table>
  );
}

function ParamsTable({ interceptor }: { interceptor: IInterceptorType }) {
  const FIELD_LABELS = {
    scopeRoute: "Scope Route",
    urlCacheHit: "URL Cache Hit",
    urlOutOfScope: "Url Out Of Scope",
    urlAmp: "URL AMP",
    ignoredParams: "Ignored Params",
  } as const;

  const renderSwitch = (field: keyof IInterceptorType) => {
    switch (field) {
      case "switchToAkamaiStaging":
        return interceptor[field] === true ? <>Yes</> : <>No</>;
      default:
        return <>{interceptor[field]}</>;
    }
  };

  return (
    <Table bordered>
      {Object.values(ConfigFieldsLists).map((field) => (
        <tr>
          <td>{FIELD_LABELS[field as keyof typeof FIELD_LABELS] ?? field}</td>
          <td>{renderSwitch(field)}</td>
        </tr>
      ))}
    </Table>
  );
}

export function WebsiteInterceptors() {
  const { websiteId } = useParams<WebsiteIdParams>() as WebsiteIdParams;
  const { loading, error, data } = useQuery<
    WebsiteInterceptorCheckerConfigsQuery,
    WebsiteInterceptorCheckerConfigsQueryVariables
  >(WEBSITE_INTERCEPTOR_CHECKER_CONFIGS, {
    variables: { id: websiteId },
  });

  const website = data?.website;

  if (error || loading || !website) {
    return (
      <DataHandler error={error} loading={loading} data={website} expectData />
    );
  }

  return (
    <div>
      <Header.Title websiteId={websiteId} name="Interceptors" />

      {website.interceptorCheckerConfigs?.map((interceptor) => (
        <Interceptor interceptor={interceptor} />
      ))}
    </div>
  );
}
