import { Button } from "react-bootstrap";

import { useCreateDraft } from "../hooks";

interface IResetDraftButtonProps {
  websiteId: string;
  productionVersionId: string;
}

export function ResetDraftButton({
  websiteId,
  productionVersionId,
}: IResetDraftButtonProps) {
  const [createDraft] = useCreateDraft();

  async function resetDraft() {
    if (
      !window.confirm(
        "Are you sure you want to reset current draft to production?"
      )
    ) {
      return;
    }

    await createDraft({
      variables: {
        website: websiteId,
        baseVersion: productionVersionId,
      },
    });
  }

  return (
    <Button variant="link" onClick={() => resetDraft()}>
      Reset Draft to Production
    </Button>
  );
}
