import { gql } from "@apollo/client";

export const SECTION_FIELDS_FRAGMENT = gql`
  fragment SectionFields on SectionType {
    id
    stableId
    name
    mode
    fallbackSection
    sectionPriority
    refreshFrequency
    refreshPriority
    maxStale
    rules
    htmlContentTypes
    jsRedirectStatusCode
    overrideRenderConfigs
    liveMode
    liveScope
    ignoreParameters
    ignoreParametersStrategy
    ignoreParametersReplaceLinks
    ignoreParametersCascading
    customRefreshRules {
      filters {
        source
        type
        input
        name
        value
        predicate
      }
      priority
      maxStale
      refreshFrequency
      preventIndexation
    }
    desktopConfig {
      id
      name
      action
    }
    mobileConfig {
      id
      name
      action
    }
    modeNotFoundStatusCode
    modeNotFoundHtml
    modeRedirectStatusCode
    modeRedirectStrategy
    modeRedirectJs
    modeRedirectLocation
    modeCustomHtmlStatusCode
    modeCustomHtmlHtml
    renderingOnSetupBeforeRenderCode
    renderingOnInitCode
    renderingDomContentLoadedCode
    renderingOnloadCode
    renderingOnActionCode
    renderingOnWaitForCode
    renderingOndoneCode
    preBeamResponseCode
    jsRedirectStatusCode
    maxSpeed
    liveMaxSpeed
  }
`;
