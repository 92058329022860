import React, { useState } from "react";
import type { JsCodeType } from "../../../../gql/types.generated";
import { filterSearchedScripts, orderScripts } from "../../core/scripts";
import { ScriptsTableColumn, SCRIPTS_TABLE_COLUMNS } from "./columns";
import { makeScriptsTableContext } from "./ScriptsTableContext";
import { useScriptsTableMutations } from "./useScriptsTableMutations";

type UseScriptsTableParams = {
  scripts: JsCodeType[];
  websiteVersionId: string;
};

export type UseScriptsTableValues = {
  /** Scripts ordered by position, filtered by search */
  shownScripts: JsCodeType[];
  columns: ScriptsTableColumn[];
  search: string;
  setSearch: (value: string) => void;
  loading: boolean;
  ScriptsTableContext: ({ children }: React.PropsWithChildren) => JSX.Element;
};

export const useScriptsTable = ({
  scripts,
  websiteVersionId,
}: UseScriptsTableParams): UseScriptsTableValues => {
  const initialScriptsOrder = orderScripts(scripts);
  const [orderedScripts, setOrderedScripts] = useState(initialScriptsOrder);
  const [search, setSearch] = useState("");
  const shownScripts = filterSearchedScripts(orderedScripts, search);
  const columns = SCRIPTS_TABLE_COLUMNS;

  const { activateScript, pauseScript, deleteScript, reorderScripts, loading } =
    useScriptsTableMutations({
      initialScriptsOrder,
      setOrderedScripts,
      websiteVersionId,
    });

  const ScriptsTableContext = makeScriptsTableContext({
    columns,
    orderedScripts,
    shownScripts,
    activateScript,
    pauseScript,
    deleteScript,
    onReorder: reorderScripts,
  });

  return {
    search,
    setSearch,
    loading,
    shownScripts,
    columns,
    ScriptsTableContext,
  };
};
