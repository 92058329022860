import { WidgetProps } from "@rjsf/utils";
import AceEditor from "react-ace";

export const AceWidget = ({ label, value, onChange }: WidgetProps) => {
  return (
    <>
      <label className="form-label">{label}</label>
      <AceEditor
        mode="javascript"
        onChange={(newCode) => onChange(newCode)}
        value={value}
        width="auto"
        height="400px"
        style={{ marginTop: 16, marginBottom: 16 }}
      />
    </>
  );
};
