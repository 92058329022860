import { useQuery } from "@apollo/client";
import moment from "moment";
import { Button } from "react-bootstrap";

import { goToPreview } from "../../../common/preview";
import { shortUrl } from "../../../common/str";
import { IMetricCard, IPagesDefinition } from "../../../common/typing/website";
import { DataHandler } from "../../../components/DataHandler";
import {
  SitemapDataType,
  ModeChoices,
  WebsiteType,
} from "../../../gql/types.generated";
import {
  GET_WEBSITE,
  WebsiteQuery,
  WebsiteQueryVariables,
} from "../../../gql/websites/website";
import { Header } from "../../components/header";
import { InputIdParams, WebsiteIdParams } from "../../types/routeParams";
import { UrlsTable } from "../Urls";
import { findByStableId } from "../Utils";
import { useParams } from "react-router-dom";

export const CARDS: Record<string, IMetricCard<SitemapDataType>> = {
  url: {
    name: "URL",
    fields: ["url"],
    display: (item, data) => {
      return (
        <>
          {shortUrl(item.url, 100)} |{" "}
          <a href={item.url} target="_blank" rel="noreferrer">
            Original Page
          </a>
          {" | "}
          <Button
            variant="link"
            style={{ padding: 0 }}
            onClick={() => goToPreview(data, item.url, "desktop")}
          >
            Preview Desktop
          </Button>
          {" | "}
          <Button
            variant="link"
            style={{ padding: 0 }}
            onClick={() => goToPreview(data, item.url, "mobile")}
          >
            Preview Mobile
          </Button>
        </>
      );
    },
  },
  dateFirstSeen: {
    name: "Date First Seen",
    display: (item, data) => {
      return (
        <span style={{ whiteSpace: "nowrap" }}>
          {moment(item.dateFirstSeen).format("YYYY-MM-DD")}
        </span>
      );
    },
  },
  dateLastSeen: {
    name: "Date Last Seen",
    display: (item, data) => {
      return (
        <span style={{ whiteSpace: "nowrap" }}>
          {moment(item.dateLastSeen).format("YYYY-MM-DD")}
        </span>
      );
    },
  },
};

export const PRESET_MAIN = {
  id: "main",
  cards: ["url", "dateFirstSeen", "dateLastSeen"],
  orderBy: "-dateLastSeen",
};

export const PRESETS = {
  main: PRESET_MAIN,
};

const getDatamodel = (
  mode: ModeChoices
): IPagesDefinition<SitemapDataType> => {
  const dimensions = ["url"];
  if (mode === ModeChoices.Incremental) {
    dimensions.push(...["dateFirstSeen", "dateLastSeen"]);
  }
  return {
    fieldsVerbose: {},
    gqlFunction: "inputSitemap",
    dimensions,
    cards: CARDS,
  };
};

export function SitemapUrls() {
  const { websiteId, inputId } = useParams<
    WebsiteIdParams & InputIdParams
  >() as WebsiteIdParams & InputIdParams;
  const { loading, error, data } = useQuery<
    WebsiteQuery,
    WebsiteQueryVariables
  >(GET_WEBSITE, {
    variables: { id: websiteId },
  });

  const input = findByStableId(
    inputId,
    data?.website?.draftVersion?.sitemapInputs ?? []
  );

  if (error || loading || !input) {
    return (
      <DataHandler
        error={error}
        loading={loading}
        data={input}
        noDataMessage={
          <>
            Input not found: <code>{inputId}</code>
          </>
        }
        expectData
      />
    );
  }

  return (
    <div>
      <Header.Title websiteId={websiteId} name={`${input.name} > URLs`} />

      <div>
        URL : {input.url} / Mode : {input.mode}
      </div>

      <UrlsTable
        inputId={input.id}
        website={data?.website as WebsiteType}
        definitions={getDatamodel(input.mode)}
        presets={PRESETS}
        currentPreset="main"
      />
    </div>
  );
}
