import { gql } from "@apollo/client";

export const GET_WEBSITE_MONITORING = gql`
  query WebsiteMonitoring(
    $id: String!
    $timestampStart: Int!
    $timestampEnd: Int!
  ) {
    website(id: $id) {
      id
      servedByHour: queryServedPages(
        dimensions: ["dateHour"]
        timestampStart: $timestampStart
        timestampEnd: $timestampEnd
      ) {
        dateHour
        countUrls
        countHits
      }
      indexedByHour: queryIndexedPages(
        dimensions: ["dateHour"]
        timestampStart: $timestampStart
        timestampEnd: $timestampEnd
      ) {
        dateHour
        countUrls
      }
    }
  }
`;
