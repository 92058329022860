import React, { useState } from "react";

import { gql, useMutation, ApolloQueryResult } from "@apollo/client";
import { Button, Card, Table } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import moment from "moment";
import { ModuleType } from "../Settings";
import {
  Exact,
  ModuleType as ModuleTypeGQL,
} from "../../../gql/types.generated";
import { GetModuleQuery } from "../../../gql/websites/website";
import { useNavigate } from "react-router-dom";

const PUSH_VERSION_MUTATION = gql`
  mutation PushVersion($input: PushModuleToProductionMutationInput!) {
    pushModuleToProduction(input: $input) {
      status
    }
  }
`;

export function Versioning({
  moduleType,
  websiteId,
  moduleDraftId,
  moduleStableId,
  hasDatasets,
  refreshMainQuery,
  allVersions,
}: {
  moduleType: ModuleType;
  websiteId: string;
  moduleDraftId?: string;
  moduleStableId?: string;
  hasDatasets: boolean;
  refreshMainQuery: (
    variables?:
      | Exact<{
          websiteId: string;
          stableId: string;
        }>
      | undefined
  ) => Promise<ApolloQueryResult<GetModuleQuery>>;
  allVersions?: Array<Partial<ModuleTypeGQL | null>> | null;
}) {
  const navigate = useNavigate();
  const [comment, setComment] = useState("");
  const [createVersion, { loading }] = useMutation(PUSH_VERSION_MUTATION, {
    onCompleted: (data) => {
      window.scrollTo(0, 0);
      refreshMainQuery();
      navigate(
        `/website/${websiteId}/modules/${moduleType}/${moduleStableId}/${
          hasDatasets ? "data" : "config"
        }`,
        {
          state: {
            message: {
              value: "Version pushed into production",
              status: "success",
            },
          },
        }
      );
    },
  });

  const submitForm = () => {
    createVersion({
      variables: {
        input: {
          comment,
          websiteId: websiteId,
          moduleId: moduleDraftId,
        },
      },
    });
  };

  return (
    <>
      {" "}
      <Card>
        <Card.Header>Push New Version To Production</Card.Header>
        <Card.Body>
          <Form.Group>
            <Form.Label>Comment</Form.Label>
            <Form.Control
              name="comment"
              type="text"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Enter Comment"
            />
            <Form.Text>
              Comment about version being pushed to production
            </Form.Text>
          </Form.Group>

          <Button
            disabled={loading}
            className="float-right"
            onClick={submitForm}
          >
            {loading
              ? "Please wait while new version is being published "
              : "Confirm Push Draft Version To Production"}
          </Button>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>All Versions</Card.Header>
        <Card.Body className="card-with-table">
          <Table striped borderless hover border-left-0 border-right-0>
            <thead>
              <tr>
                <td>Version Status</td>
                <td>Comment</td>
                <td>Version Num</td>
                <td>Published</td>
              </tr>
            </thead>
            <tbody>
              {allVersions &&
                allVersions.map((version) => {
                  return (
                    <tr>
                      <td>{version?.versionStatus}</td>
                      <td>{version?.comment}</td>
                      <td>{version?.versionNum}</td>
                      <td>
                        {version?.versionDatePublished &&
                          moment(version.versionDatePublished).fromNow()}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
}
