import { copyStrToClipboard } from "../../common/str";
import { Button } from "../Button/Button";

interface ICopyProps {
  textToCopy: string;
}

/**
 * Provides a copy icon with feedback that copy to the clipboard.
 * @param textToCopy
 * @example
 * <Copy textToCopy={text} />
 */
export const Copy = ({ textToCopy }: ICopyProps) => {
  const handleCopy = async () => await copyStrToClipboard(textToCopy);

  return (
    <Button variant="none" onClick={handleCopy}>
      <i className="bi bi-copy"></i>
    </Button>
  );
};
