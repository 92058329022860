import { useQuery } from "@apollo/client";
import { Button, Card, Form } from "react-bootstrap";

import { useLocalStorageSynchronizedState } from "../../../common/hooks";
import { DataHandler } from "../../../components/DataHandler";
import { ModulesListType } from "../../../gql/types.generated";
import {
  GetModuleQuery,
  GetModuleQueryVariables,
  GET_WEBSITE_MODULE,
  UPDATE_WEBSITE_MODULE,
} from "../../../gql/websites/website";
import { Header } from "../../components/header";
import { Messager } from "../../components/messager";
import {
  ModuleTypeParams,
  StableIdParams,
  WebsiteIdParams,
} from "../../types/routeParams";
import { HelpForModuleType } from "../help/Base";
import { getPreviewUrl, ModuleForm, PreviewOptionsForm } from "./Base";
import { Versioning } from "./Versioning";
import { ReportingHome } from "../reporting/Home";
import {
  Route,
  useNavigate,
  Routes,
  useParams,
  Link,
  Navigate,
} from "react-router-dom";

export function Update() {
  const { websiteId, stableId, moduleType } = useParams<
    WebsiteIdParams & StableIdParams & ModuleTypeParams
  >() as WebsiteIdParams & StableIdParams & ModuleTypeParams;

  const navigate = useNavigate();

  const { loading, error, data, refetch } = useQuery<
    GetModuleQuery,
    GetModuleQueryVariables
  >(GET_WEBSITE_MODULE, {
    variables: {
      websiteId: websiteId,
      stableId: stableId,
    },
    fetchPolicy: "network-only",
  });

  const [previewURL, setPreviewURL] = useLocalStorageSynchronizedState({
    key: "previewURL:" + stableId,
    defaultValue: "",
  });
  const { component: PreviewOptionsFormComponent, previewOptions } =
    PreviewOptionsForm(stableId);

  const onCompleted = () => {
    window.scrollTo(0, 0);
    navigate(
      {
        pathname:
          "/website/" + websiteId + "/modules/" + moduleType + "/" + stableId,
      },
      {
        state: {
          message: {
            value: "Module Saved",
            status: "success",
          },
        },
      }
    );
  };

  if (error || loading) return <DataHandler error={error} loading={loading} />;

  const modulesIdx: Record<ModulesListType["id"], ModulesListType> = {};
  (data?.website?.modulesTypes || []).forEach((module) => {
    modulesIdx[module.id] = module;
  });

  const formData = {
    name: data?.website?.moduleConfig?.name,
    ...JSON.parse(data?.website?.moduleConfig?.config || ""),
  };

  if (data?.website?.moduleConfig?.description) {
    formData.description = data?.website?.moduleConfig?.description;
  }

  return (
    <div>
      <Header.Title
        websiteId={websiteId}
        name={
          <>
            <Link to={`/website/${websiteId}/modules`}>Modules</Link> {"> "}
            <Link
              to={`/website/${websiteId}/modules/${moduleType}/${data?.website?.moduleConfig?.stableId}/`}
            >
              {data?.website?.moduleConfig?.name}
            </Link>
          </>
        }
      />

      <Messager />

      <div style={{ display: "flex", justifyContent: "space-around" }}>
        <Link
          to={`/website/${websiteId}/modules/${moduleType}/${data?.website?.moduleConfig?.stableId}/config`}
        >
          Configuration
        </Link>
        <Link
          to={`/website/${websiteId}/modules/${moduleType}/${data?.website?.moduleConfig?.stableId}/versioning`}
        >
          Versioning
        </Link>
        <Link
          to={`/website/${websiteId}/modules/${moduleType}/${data?.website?.moduleConfig?.stableId}/reporting`}
        >
          Reporting
        </Link>
        <Link
          to={`/website/${websiteId}/modules/${moduleType}/${data?.website?.moduleConfig?.stableId}/help`}
        >
          Help
        </Link>
      </div>
      <div style={{ padding: "7px" }}></div>

      <Routes>
        <Route
          path="config"
          element={
            <>
              <ModuleForm
                gql={UPDATE_WEBSITE_MODULE}
                moduleType={moduleType}
                jsonschema={JSON.parse(modulesIdx[moduleType].jsonschema)}
                initialFormData={formData}
                onCompleted={onCompleted}
                extraFormData={{ id: data?.website?.moduleConfig?.id }}
              />
              <Card id="preview">
                <Card.Header>Page Preview</Card.Header>
                <Card.Body>
                  <div className="form-group">
                    <Form.Control
                      type="text"
                      placeholder="Enter an URL"
                      onChange={(e) => setPreviewURL(e.target.value)}
                      value={previewURL}
                    />

                    <Form.Group>
                      <PreviewOptionsFormComponent />
                    </Form.Group>
                  </div>
                  <Button
                    onClick={(e) => {
                      const newUrl = getPreviewUrl(
                        previewURL,
                        stableId,
                        previewOptions
                      );
                      window.open(newUrl, "_blank");
                    }}
                  >
                    Preview
                  </Button>
                </Card.Body>
              </Card>
            </>
          }
        ></Route>
        <Route
          index
          element={
            <Navigate
              to={
                Number(data?.website?.moduleConfig?.datasets?.length) > 0
                  ? `/website/${websiteId}/modules/${moduleType}/${stableId}/data`
                  : `/website/${websiteId}/modules/${moduleType}/${stableId}/config`
              }
              replace
            />
          }
        />
        <Route
          path="versioning"
          element={
            <Versioning
              moduleType={moduleType}
              websiteId={websiteId}
              moduleDraftId={data?.website?.moduleConfig?.id}
              moduleStableId={stableId}
              hasDatasets={
                Number(data?.website?.moduleConfig?.datasets?.length) > 0
              }
              refreshMainQuery={refetch}
              allVersions={data?.website?.allVersions}
            />
          }
        ></Route>
        <Route
          path="reporting"
          element={
            <ReportingHome
              websiteId={websiteId}
              moduleId={stableId}
              versions={data?.website?.allVersions}
            />
          }
        ></Route>
        <Route
          path="help"
          element={<HelpForModuleType moduleType={moduleType} />}
        ></Route>
      </Routes>
    </div>
  );
}
