import { gql } from "@apollo/client";

export const GET_INPUT_TEXT_LIST = gql`
  query WebsiteInputTextList($id: String!) {
    inputTextList(id: $id) {
      id
      name
      nbUrls
      urls
      website {
        id
        name
      }
    }
  }
`;
