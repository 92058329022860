import { gql } from "@apollo/client";

export const PREVIEW_CPAP = gql`
  mutation PreviewCpap($input: PreviewCpapMutationInput!) {
    previewCpap(input: $input) {
      html
      stats
      status
      headers
      logs
      jsError
      screenshot
      errors {
        field
        messages
      }
    }
  }
`;
