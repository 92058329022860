import React, { useRef } from "react";
import { useMutation } from "@apollo/client";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { Optionalize } from "../../common/typing/utils";
import { SectionInput } from "../../gql/types.generated";
import {
  SECTION_TESTER,
  UrlTesterMutation,
  UrlTesterMutationVariables,
} from "../../gql/websites/section";
import "./urlTester.css";
import { UrlTesterResult } from "./urlTesterResult";

interface ISectionInputUnsanitized
  extends Optionalize<
    SectionInput,
    "fallbackSection" | "sectionPriority" | "rules"
  > {}

function extractOverloadedSection(
  section: ISectionInputUnsanitized
): SectionInput {
  return (({ id, name, mode, sectionPriority, fallbackSection, rules }) => ({
    id,
    name,
    mode,
    sectionPriority: sectionPriority || 998,
    fallbackSection: !!fallbackSection,
    rules: rules ?? "{}",
  }))(section);
}

interface IUrlTesterProps {
  websiteId: string;
  section?: ISectionInputUnsanitized;
}

export function UrlTester({ websiteId, section }: IUrlTesterProps) {
  const overloadedSection = section ? extractOverloadedSection(section) : null;
  const initialPayload = {
    website: websiteId,
    urls: [],
    overloadedSection,
  };
  const urlsRef = useRef<HTMLTextAreaElement | null>(null);
  const [urlTesterMutation, { data, loading, error }] = useMutation<
    UrlTesterMutation,
    UrlTesterMutationVariables
  >(SECTION_TESTER);
  const results = data?.urlTester?.results;
  const nameIsRequired = overloadedSection && !overloadedSection.name;

  const handleClick = async (e: React.FormEvent<HTMLElement>) => {
    e.stopPropagation();
    e.preventDefault();

    const urls = urlsRef.current?.value.split("\n").filter((v) => v) ?? [];

    if (urls.length === 0) {
      return;
    }

    const payload = {
      ...initialPayload,
      urls,
    };

    try {
      await urlTesterMutation({ variables: payload });
    } catch (e) {}
  };

  return (
    <>
      {error && (
        <Alert variant="danger">
          Something went wrong.
          <br />
          <pre>{JSON.stringify(error, null, 2)}</pre>
        </Alert>
      )}

      {nameIsRequired && (
        <Alert variant="danger">Behavior name is required.</Alert>
      )}
      <Form>
        <Form.Group>
          <Form.Label>Urls</Form.Label>
          <Form.Control name="urls" as="textarea" rows={5} ref={urlsRef} />
        </Form.Group>
        <Button
          type="button"
          className="float-right submit-btn"
          disabled={loading || !!nameIsRequired}
          onClick={handleClick}
        >
          {loading ? "Loading..." : "Test it !"}
        </Button>
      </Form>
      {results?.map((result, idx) => (
        <UrlTesterResult key={idx} result={result} />
      ))}
    </>
  );
}
