import { Link } from "react-router-dom";

interface IWebsiteLinkProps {
  websiteId: string;
  path?: string;
  children: React.ReactNode;
}

/**
 * Renders a link to a website given its id.
 * The optionnally provided path is suffixed to the url.
 */
export const WebsiteLink = ({
  websiteId,
  path = "",
  children,
}: IWebsiteLinkProps) => (
  <Link to={`/website/${websiteId}${path}`}>{children}</Link>
);
