import Form from "react-bootstrap/Form";
import {
  FilterInputValue,
  FilterValue,
  getFilterValue,
  getInputValue,
  WebsitesFilterHandler,
} from "./helpers";

interface IFeatureFilterProps {
  name: string;
  label: string;
  value: FilterValue;
  onChange: WebsitesFilterHandler;
}

export function FeatureFilter({
  onChange,
  label,
  name,
  value,
}: IFeatureFilterProps) {
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const targetValue = event.target.value as FilterInputValue;

    onChange(name, getFilterValue(targetValue));
  };

  const currentValue = getInputValue(value);

  return (
    <Form.Group>
      <Form.Label htmlFor={name}>{label}</Form.Label>
      <Form.Select
        id={name}
        onChange={handleChange}
        className="form-control"
        value={currentValue}
      >
        <option value="both">--</option>
        <option value="enabled">Enabled</option>
        <option value="disabled">Disabled</option>
      </Form.Select>
    </Form.Group>
  );
}
