import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { isNil } from "lodash";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import {
  BrowserRouter as Router,
  Link,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./App.css";
import { useAdvancedMode, useGetUserInfo } from "./common/hooks";
import { DataHandler } from "./components/DataHandler";
import { Error404Page } from "./components/Error404Page/Error404Page";
import { ModeSwitcher } from "./components/ModeSwitcher";
import { AUTH_TOKEN } from "./constants";
import { AdvancedModeContextProvider } from "./context";
import { Home } from "./home";
import { LightweightCdn } from "./LightweightCdn";
import { Login, Logout } from "./Login";
import "./Login.css";
import { MonitoringRoutes } from "./monitoring/Routes";
import { CreateWebsite } from "./website/create";
import { WebsiteRouting } from "./website/home";

function InternalRouting() {
  const token = localStorage.getItem(AUTH_TOKEN);
  const { isAdvancedMode } = useAdvancedMode();
  const { loading, error, isSuperuser } = useGetUserInfo();
  const navigate = useNavigate();
  if (!token) {
    navigate("/login", { replace: true });
  }

  if (loading || error || isNil(isSuperuser)) {
    return (
      <DataHandler
        error={error}
        loading={loading}
        data={isSuperuser}
        expectData
        noDataMessage="A problem occurred when retrieving user info."
      />
    );
  }

  return (
    <>
      <ToastContainer />
      <div>
        <div className="header header1">
          <Container>
            <Col>⚡Botify Activation Console</Col>
          </Container>
        </div>
        <div className="header header2">
          <Container>
            <Row>
              <Col xs={6}>
                <ul className="nav">
                  <li className="nav-item">
                    <Link to="/">Home</Link>
                  </li>

                  <li className="nav-item">
                    <Link to="monitoring">Monitoring</Link>
                  </li>
                  <li className="nav-item">
                    <Link to="monitoring/health">Health</Link>
                  </li>
                  {isAdvancedMode ? (
                    <li className="nav-item">
                      <Link to="lightweight-cdn">ADN</Link>
                    </li>
                  ) : null}
                </ul>
              </Col>
              <Col>
                <ModeSwitcher />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="hero h-100">
          <Routes>
            <Route path="website/create" element={<CreateWebsite />} />
            <Route path="website/:websiteId/*" element={<WebsiteRouting />} />
            <Route path="monitoring/*" element={<MonitoringRoutes />} />
            {isAdvancedMode && (
              <Route path="lightweight-cdn" element={<LightweightCdn />} />
            )}
            <Route index element={<Home />} />
            <Route path="*" element={<Error404Page />} />
          </Routes>
        </div>
      </div>
    </>
  );
}

function AppRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/logout" element={<Logout />}></Route>
        <Route
          path="/*"
          element={
            <AdvancedModeContextProvider>
              <InternalRouting />
            </AdvancedModeContextProvider>
          }
        ></Route>
      </Routes>
    </Router>
  );
}

export default AppRouter;
