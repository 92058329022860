import { useQuery } from "@apollo/client";
import { useState } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import { BarChart, StackedBarChart } from "../common/charts";
import { capitalize } from "../common/str";
import { DataHandler } from "../components/DataHandler";
import type {
  QueryIndexedPagesResult,
  QueryQueryIndexedPagesArgs,
  QueryQueryServedPagesArgs,
  QueryServedPagesResult,
} from "../gql/types.generated";
import { isDatabaseKey, isMetricKey, isPeriodKey } from "./guards";
import { getQuery } from "./Helpers";
import { METRICS_MAPPING } from "./Home/Constants";
import { getDateSettings, getOptions } from "./Settings";
import { DatabaseKey, MetricKey, PeriodKey } from "./types";
import { Link, useNavigate, useParams } from "react-router-dom";

const defaultMetricKey: MetricKey = "countHits";
const defaultDbKey: DatabaseKey = "servedPages";
const defaultPeriodKey: PeriodKey = "24h";

type IWebsitesMetricProps = {
  metric: string;
  db: string;
  period: string;
};

export function WebsitesMetric() {
  const params = useParams<IWebsitesMetricProps>();
  // Default when search params are invalid
  const metric = isMetricKey(params.metric) ? params.metric : defaultMetricKey;
  const db = isDatabaseKey(params.db) ? params.db : defaultDbKey;
  const period = isPeriodKey(params.period) ? params.period : defaultPeriodKey;
  const navigate = useNavigate();
  const options = getOptions();

  const { dateField, dateVerbose, timestampStart, timestampEnd } =
    getDateSettings(period, options);

  const query = getQuery({
    name: db + capitalize(metric) + "Query",
    metrics: ["websiteId", "websiteName", dateField, metric],
    dimensions: [dateField, "websiteId", "websiteName"],
    orderBy: [dateField, "websiteId"],
    db,
  });

  const { loading, error, data } = useQuery<
    | { queryIndexedPages: QueryIndexedPagesResult[] }
    | {
        queryServedPages: QueryServedPagesResult[];
      },
    QueryQueryIndexedPagesArgs | QueryQueryServedPagesArgs
  >(query, {
    variables: {
      timestampStart: timestampStart,
      timestampEnd: timestampEnd,
    },
  });

  const [filterProdWebsites, setFilterProdWebsites] = useState(true);

  // Redirect when search params are invalid
  if (
    !isMetricKey(params.metric) ||
    !isDatabaseKey(params.db) ||
    !isPeriodKey(params.period)
  ) {
    navigate(`/monitoring/websites/${db}/${period}/${metric}`, {
      replace: true,
    });
  }

  if (error || loading || !data) {
    return (
      <DataHandler error={error} loading={loading} data={data} expectData />
    );
  }

  const dataByWebsite: Record<
    string,
    {
      entries: Array<QueryIndexedPagesResult | QueryServedPagesResult>;
      maxEvents: number;
    }
  > = {};
  const queryKey = "query" + capitalize(db);
  const queriedData = (() => {
    if ("queryIndexedPages" in data) {
      return data.queryIndexedPages;
    }
    if ("queryServedPages" in data) {
      return data.queryServedPages;
    }
    throw new Error(`Invalid query key: "${queryKey}"`);
  })();

  queriedData.forEach((entry) => {
    if (!entry.websiteName) return;

    if (!dataByWebsite[entry.websiteName]) {
      dataByWebsite[entry.websiteName] = {
        entries: [],
        maxEvents: 0,
      };
    }
    dataByWebsite[entry.websiteName].entries.push(entry);
    const entryMetric = entry[metric as keyof typeof entry];

    if (typeof entryMetric !== "number" || isNaN(entryMetric)) {
      return;
    }

    if (entryMetric > dataByWebsite[entry.websiteName].maxEvents) {
      dataByWebsite[entry.websiteName].maxEvents = entryMetric;
    }
  });

  const mapping = METRICS_MAPPING[db];
  // @ts-ignore-next-line
  const unsafeMetric = mapping[metric];
  const verboseMetric: string = unsafeMetric?.verbose ?? "";

  return (
    <Container fluid>
      <h1>
        {verboseMetric} {dateVerbose}
      </h1>

      <Card>
        <Card.Header>All websites</Card.Header>
        <Card.Body>
          <StackedBarChart
            data={queriedData}
            dimension={dateField}
            metric={metric}
            splitBy="websiteName"
            xTitle="Date"
            yTitle={"Number of " + verboseMetric}
            height="450px"
          />
        </Card.Body>
      </Card>
      <hr />

      <h2>
        {verboseMetric} by Website {dateVerbose}
      </h2>

      <Row>
        <Col style={{ textAlign: "right" }}>
          <Form.Check
            type="checkbox"
            label="Only show websites with days over 1,000 events"
            onClick={(e) => setFilterProdWebsites(!filterProdWebsites)}
            checked={filterProdWebsites === true}
          />
        </Col>
      </Row>

      {[...Object.keys(dataByWebsite)].sort().map((websiteName) => {
        if (
          filterProdWebsites === true &&
          dataByWebsite[websiteName].maxEvents < 1000
        ) {
          return null;
        }

        return (
          <Card>
            <Card.Header>{websiteName}</Card.Header>
            <Card.Body>
              <BarChart
                data={dataByWebsite[websiteName].entries}
                dimension={dateField}
                metric={metric}
                color="#1966e3"
                xTitle="Date"
                yTitle={"Number of " + verboseMetric}
                height="300px"
              />
              <Card.Text>
                <Link
                  to={
                    "/website/" +
                    dataByWebsite[websiteName].entries[0]["websiteId"]
                  }
                >
                  Go to Website
                </Link>{" "}
                |&nbsp;
                <Link
                  to={
                    "/website/" +
                    dataByWebsite[websiteName].entries[0]["websiteId"] +
                    "/beam/explorer/last"
                  }
                >
                  Go to Website Beam Explorer
                </Link>
              </Card.Text>
            </Card.Body>
          </Card>
        );
      })}
    </Container>
  );
}
