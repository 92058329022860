import { gql } from "@apollo/client";

export const GET_JOB = gql`
  query Job($id: String!, $websiteId: String!) {
    importJob(id: $id, websiteId: $websiteId) {
      id
      importType
      status
      result
    }
  }
`;
