import { useState } from "react";

/**
 * Provides an immutable Set while (mostly) keeping its API.
 * The returned Set can be safely manipulated in a stateful
 * context.
 */
export const useSet = <T>(
  initialValue: Iterable<T> | null | undefined
): {
  has: (value: T) => boolean;
  add: (value: T) => void;
  delete: (value: T) => void;
  toggle: (value: T) => void;
  clear: () => void;
  values: () => IterableIterator<T>;
  readonly size: number;
} => {
  const [set, setSet] = useState(new Set(initialValue));

  const has = (value: T) => set.has(value);

  const add = (value: T) => setSet((prev) => new Set(prev).add(value));

  // 'delete' is a reserved word, hence leading _
  const _delete = (value: T) =>
    setSet((prev) => {
      const newSet = new Set(prev);
      newSet.delete(value);
      return newSet;
    });

  const toggle = (value: T) => (set.has(value) ? _delete(value) : add(value));

  const clear = () => setSet(new Set());

  const values = () => set.values();

  return {
    ...set,
    has,
    add,
    delete: _delete,
    toggle,
    clear,
    values,
    get size() {
      return set.size;
    },
  };
};
