import { Card, Dropdown, Nav } from "react-bootstrap";

import { NavLink, useLocation } from "react-router-dom";

import { ReactNode } from "react";
import { useAdvancedMode } from "../../common/hooks";
import { WebsiteType } from "../../gql/types.generated";

interface IWebsiteNavProps {
  website: Pick<WebsiteType, "id" | "name">;
}

export const WebsiteNav = ({ website }: IWebsiteNavProps) => {
  const { isAdvancedMode } = useAdvancedMode();
  const location = useLocation();
  const websiteId = website.id;
  return (
    <Card className="leftNav position-sticky" style={{ top: "16px" }}>
      <Card.Body>
        <Nav className="flex-column">
          <h2>{website.name}</h2>

          <h3>SpeedWorkers</h3>
          <Nav.Item>
            <NavLink end to={`/website/${websiteId}`}>
              Home
            </NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink end to={`/website/${websiteId}/settings`}>
              Settings
            </NavLink>
          </Nav.Item>
          {isAdvancedMode && (
            <>
              <Nav.Item>
                <NavLink end to={`/website/${websiteId}/scripts`}>
                  Script Management
                </NavLink>
              </Nav.Item>
              <Nav.Item>
                <NavLink end to={`/website/${websiteId}/adn`}>
                  Lightweight CDN
                </NavLink>
              </Nav.Item>
            </>
          )}
          <Dropdown.Divider />
          <SubNav title="Reporting">
            <Nav.Item>
              <NavLink end to={`/website/${websiteId}/inventory`}>
                Inventory History
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink end to={`/website/${websiteId}/inventory/live`}>
                Inventory Live
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink end to={`/website/${websiteId}/searchEngineIndex`}>
                Search Engine Index
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink end to={`/website/${websiteId}/indexation`}>
                Indexed Pages Dashboard
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink
                to={`/website/${websiteId}/indexation/explorer/last`}
                className={() => {
                  return location.pathname.indexOf("/indexation/explorer/") > 0
                    ? "active"
                    : "";
                }}
              >
                Indexed Pages Explorer
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink to={`/website/${websiteId}/indexation/history`}>
                Indexed Pages History
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink end to={`/website/${websiteId}/beam`}>
                Served Pages Dashboard
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink
                to={`/website/${websiteId}/beam/explorer/last`}
                className={() => {
                  return location.pathname.indexOf("/beam/explorer/") > 0
                    ? "active"
                    : "";
                }}
              >
                Served Pages Explorer
              </NavLink>
            </Nav.Item>
          </SubNav>

          <SubNav title="Bing Content Submission">
            <Nav.Item>
              <NavLink end to={`/website/${websiteId}/bingContentSubmission`}>
                Dashboard
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink
                to={`/website/${websiteId}/bingContentSubmission/explorer/last`}
                className={() => {
                  return location.pathname.indexOf(
                    "/bingContentSubmission/explorer/"
                  ) > 0
                    ? "active"
                    : "";
                }}
              >
                Explorer
              </NavLink>
            </Nav.Item>
          </SubNav>
          <Dropdown.Divider />
          <SubNav title="Tools">
            <Nav.Item>
              <NavLink to={`/website/${websiteId}/url-tester`}>
                URL Tester
              </NavLink>
            </Nav.Item>
            {isAdvancedMode ? (
              <>
                <Nav.Item>
                  <NavLink to={`/website/${websiteId}/debug`}>Debug</NavLink>
                </Nav.Item>
                <Nav.Item>
                  <NavLink end to={`/website/${websiteId}/forceRefresh`}>
                    Force refresh
                  </NavLink>
                </Nav.Item>
              </>
            ) : null}
          </SubNav>
        </Nav>
        {isAdvancedMode ? (
          <Nav className="flex-column" style={{ paddingTop: "10px" }}>
            <h3>PageWorkers</h3>
            <Nav.Item>
              <NavLink end to={`/website/${websiteId}/pw-settings`}>
                Settings
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink end to={`/website/${websiteId}/modules`}>
                Modules
              </NavLink>
            </Nav.Item>
          </Nav>
        ) : null}
      </Card.Body>
    </Card>
  );
};

const SubNav = ({
  children,
  title,
}: {
  title: string;
  children: ReactNode;
}) => {
  return (
    <Nav className="flex-column">
      <div>{title}</div>
      <div style={{ marginLeft: "1em" }}>{children}</div>
    </Nav>
  );
};
