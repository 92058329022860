import Form from "react-bootstrap/Form";

import "./Dropdown.css";

interface IOption {
  id: string;
  name: string;
  default: boolean
}

interface IDropdown {
  label: string;
  name: string;
  required?: boolean;
  placeholder?: string;
  options: Array<IOption>;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  feedback?: {
    message: string;
    isInvalid?: boolean;
    isValid?: boolean;
    type: "valid" | "invalid";
  };
}

export const Dropdown = ({
  name,
  label,
  required,
  placeholder,
  options,
  onChange,
  feedback,
}: IDropdown) => {
  return (
    <Form.Group controlId={name}>
      <Form.Label>
        {label} {required && <span className="field-required">*</span>}
      </Form.Label>
      <Form.Control
        as="select"
        onChange={onChange}
        required={required}
        isInvalid={feedback?.isInvalid}
        isValid={feedback?.isValid}
      >
        <option value="" disabled={required} selected>
          {placeholder || "-- None --"}
        </option>
        {options.map((option, i) => (
          <option value={option.id} key={i} selected={option.default} >
            {option.name}
          </option>
        ))}
      </Form.Control>
      {feedback && (
        <Form.Control.Feedback type={feedback.type}>
          {feedback.message}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

export default Dropdown;
