import { ADNConfigsList } from "./ADNConfigsList";
import { ADNConfigCreation } from "./ADNConfigCreation";
import { ADNConfig } from "./ADNConfig";
import { Error404Page } from "../../components/Error404Page/Error404Page";
import { Route, Routes } from "react-router-dom";

export function ADNRoutingSystem() {
  return (
    <Routes>
      <Route index element={<ADNConfigsList />}></Route>
      <Route path="create" element={<ADNConfigCreation />}></Route>
      <Route path="edit/:configId" element={<ADNConfig />}></Route>
      <Route path="*" element={<Error404Page />}></Route>
    </Routes>
  );
}
