import { UrlTesterResult as UrlTesterResultType } from "../../gql/types.generated";
import "./urlTesterResult.css";

interface IUrlTesterResultProps {
  result: Pick<UrlTesterResultType, "url" | "matchingSection">;
}

export function UrlTesterResult({ result }: IUrlTesterResultProps) {
  return (
    <div className="result">
      {result.matchingSection && (
        <>
          <strong>{result.url}</strong> matched{" "}
          <strong>{result.matchingSection.name}</strong> mode{" "}
          <strong>{result.matchingSection.mode}</strong>
          {result.matchingSection.matchingRules?.length && (
            <>
              <span>
                {" "}
                with:
                <br />
              </span>
              <ul>
                {result.matchingSection.matchingRules.map(
                  ({ field, value, group, operator }, idx) => (
                    <li key={idx}>
                      {field} {operator} {value}{" "}
                      {group ? `(matched with: ${group})` : undefined}
                    </li>
                  )
                )}
              </ul>
            </>
          )}
        </>
      )}
      {!result.matchingSection && (
        <>
          <strong>{result.url}</strong> didn't match
        </>
      )}
    </div>
  );
}
