import React from "react";
import cx from "classnames";

import "./Button.css";

interface IButtonProps extends React.ComponentPropsWithRef<"button"> {
  // Add more variants as needed
  variant: "link" | "none";
}

export const Button = ({
  variant,
  className,
  children,
  ...rest
}: IButtonProps) => {
  return (
    <button className={cx("Button", `Button--${variant}`, className)} {...rest}>
      {children}
    </button>
  );
};
