import { gql } from "@apollo/client";
import { SECTION_FIELDS_FRAGMENT } from "./sectionFieldsFragment.gql";

export const UPDATE_SECTION = gql`
  ${SECTION_FIELDS_FRAGMENT}
  mutation UpdateSection($input: InternalUpdateSectionMutationInput!) {
    internalUpdateSection(input: $input) {
      section {
        ...SectionFields
      }
      errors {
        field
        messages
      }
    }
  }
`;
