import "bootstrap/dist/css/bootstrap.min.css";
import AceEditor from "react-ace";
import { Accordion, Button, Card, Spinner } from "react-bootstrap";

// /!\ DO NOT SORT: it crashes if imported before react-ace
import "ace-builds/webpack-resolver";

import { JsCodeStep, JsCodeType } from "../../../gql/types.generated";
import { scriptsByPosition } from "../../scripts/core/scripts";
import {
  getCodeForStep,
  getStepLabel,
  ORDERED_STEPS,
} from "../../scripts/core/steps";
import { useGoToScriptEdition } from "../../scripts/hooks/useGoToScriptEdition";
import { StatusCell } from "../../scripts/pages/ScriptsManagementPage/cells/StatusCell";

type BehaviorScriptsProps = {
  scripts: JsCodeType[];
  loading: boolean;
};

export const BehaviorScripts = ({ scripts, loading }: BehaviorScriptsProps) => (
  <Card>
    <Card.Header>Scripts</Card.Header>
    <Card.Body>
      {loading ? (
        <Spinner animation="border" />
      ) : (
        <BehaviorScriptsList scripts={scripts} />
      )}
    </Card.Body>
  </Card>
);

const BehaviorScriptsList = ({ scripts }: { scripts: JsCodeType[] }) => (
  <Accordion>
    {ORDERED_STEPS.map((step) => (
      <StepScriptsList
        step={step}
        scripts={scripts
          .filter((script) => script.steps?.includes(step))
          .sort(scriptsByPosition)}
      />
    ))}
  </Accordion>
);

const StepScriptsList = ({
  scripts,
  step,
}: {
  scripts: JsCodeType[];
  step: JsCodeStep;
}) => {
  const goToScriptEdition = useGoToScriptEdition();

  return (
    <div>
      <h3
        style={{ fontSize: "18px", fontWeight: "bold", marginBottom: "16px" }}
      >
        {getStepLabel(step)}
      </h3>

      {scripts.length === 0 && <p>Not set</p>}

      {scripts.map((script) => (
        <Accordion.Item
          key={script.id}
          eventKey={`${step}-${script.id}`}
          className="mb-4"
        >
          <Accordion.Header>
            <div className="w-100 d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <div style={{ minWidth: "72px", marginRight: "16px" }}>
                  <StatusCell active={script.active} />
                </div>
                <div>{script.name}</div>
              </div>

              <Button variant="" onClick={() => goToScriptEdition(script.id)}>
                <i className="bi bi-pencil-square" />
              </Button>
            </div>
          </Accordion.Header>

          <Accordion.Body>
            <AceEditor
              readOnly
              mode="javascript"
              width="100%"
              maxLines={50}
              value={getCodeForStep(script, step)}
            />
          </Accordion.Body>
        </Accordion.Item>
      ))}
    </div>
  );
};
