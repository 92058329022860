import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

import {
	InputDescriptor,
	RefreshRule as IRefreshRule,
	RefreshRuleFilter as IRefreshRuleFilter,
} from "../gql/types.generated";
import { DurationShortcuts } from "./durationShortcuts";
import { useRefreshRulesDebug } from "./hooks";
import { capitalize } from "./str";
import { KeyOf, ValueOf } from "./typings";
import "./customRules.css";
import { swapNeighbors } from "./array/utils";
import { regExIsValid } from "../website/helpers";

const PREDICATES = [
	"equals",
	"starts_with",
	"ends_with",
	"contains",
	"regex",
	"not_equals",
	"not_starts_with",
	"not_ends_with",
	"not_contains",
	"not_regexp",
	"higher_than",
	"higher_than_or_equal",
	"lower_than",
	"lower_than_or_equals",
] as const;

const NUMBER_PREDICATES = [
	"equals",
	"not_equals",
	"higher_than",
	"higher_than_or_equals",
	"lower_than",
	"lower_than_or_equals",
] as const;

function getReadablePredicate(predicate: string) {
	return predicate.replace("_", " ").replace(/\b\w/g, (l) => l.toUpperCase());
}

// TODO: This is not foolproof but it avoid heavy refacto
const EMPTY_FILTER = {} as IRefreshRuleFilter;

interface ICustomRefreshRulesComponentProps {
	data: IRefreshRule[];
	setData: (data: IRefreshRule[]) => void;
	defaults: IRefreshRule;
	inputs: InputDescriptor[];
}

interface ICustomRefreshRulesComponentHandlers {
	updateFilterField: <K extends KeyOf<IRefreshRuleFilter>>(
		idx: number,
		filterIdx: number,
		field: K,
		value?: IRefreshRuleFilter[K]
	) => void;
	updateInputId: (idx: number, filterIdx: number, inputId: string) => void;
	changeType: (idx: number, filterIdx: number, newType: string) => void;
	changeSource: (idx: number, filterIdx: number, newSource: string) => void;
	moveUpRule: (idx: number) => void;
	moveDownRule: (idx: number) => void;
	deleteRule: (idx: number) => void;
	addFilter: (idx: number) => void;
	deleteFilter: (idx: number, filterIdx: number) => void;
	addRule: () => void;
	toggleAttribute: (
		idx: number,
		attr: KeyOf<IRefreshRule>,
		toggle: boolean
	) => void;
	changeAttributeValue: (
		idx: number,
		attr: KeyOf<IRefreshRule>,
		value: ValueOf<IRefreshRule>
	) => void;
}

export const CustomRefreshRulesComponent = ({
	data,
	setData,
	defaults,
	inputs,
}: ICustomRefreshRulesComponentProps) => {
	const [isDebug, setDebug] = useRefreshRulesDebug();

	const updateFilterField = <K extends KeyOf<IRefreshRuleFilter>>(
		idx: number,
		filterIdx: number,
		field: K,
		value?: IRefreshRuleFilter[K]
	) => {
		const newData = [...data];
		const filter = assertAndReturnFilter(newData, idx, filterIdx);

		filter[field] = value;
		setData(newData);
	};

	const updateInputId = (idx: number, filterIdx: number, inputId: string) => {
		const newData = [...data];
		const filter = assertAndReturnFilter(newData, idx, filterIdx);

		filter.input = inputId;
		setData(newData);
	};

	const changeType = (idx: number, filterIdx: number, newType: string) => {
		const newData = [...data];
		const filter = assertAndReturnFilter(newData, idx, filterIdx);

		filter.type = newType;
		filter.name = null;
		filter.predicate = "equals";
		filter.value = null;
		setData(newData);
	};

	const changeSource = (
		idx: number,
		filterIdx: number,
		newSource: string
	) => {
		const newData = [...data];
		const filter = assertAndReturnFilter(newData, idx, filterIdx);

		filter.source = newSource;
		filter.type = null;
		filter.input = null;
		filter.name = null;
		filter.predicate = "equals";
		filter.value = null;
		setData(newData);
	};

	const moveUpRule = (idx: number) => {
		setData(swapNeighbors(data, idx, "left"));
	};

	const moveDownRule = (idx: number) => {
		setData(swapNeighbors(data, idx, "right"));
	};

	const addFilter = (idx: number) => {
		const newData = [...data];
		const filters = assertAndReturnFilters(newData, idx);

		filters.push(EMPTY_FILTER);
		setData(newData);
	};

	const deleteFilter = (idx: number, filterIdx: number) => {
		const newData = [...data];
		const filters = assertAndReturnFilters(newData, idx);

		filters.splice(filterIdx, 1);
		setData(newData);
	};

	const deleteRule = (idx: number) => {
		const newData = [...data];
		newData.splice(idx, 1);
		setData(newData);
	};

	const toggleAttribute = (
		idx: number,
		attr: KeyOf<IRefreshRule>,
		toggle: boolean
	) => {
		const newData = [...data];
		const rule = assertAndReturnRule(newData, idx);

		rule[attr] = (toggle ? defaults[attr] : null) as ValueOf<
			(typeof rule)[typeof attr]
		>;

		setData(newData);
	};
	const changeAttributeValue = <K extends KeyOf<IRefreshRule>>(
		idx: number,
		attr: K,
		value: IRefreshRule[K]
	) => {
		const newData = [...data];
		const rule = assertAndReturnRule(newData, idx);

		rule[attr] = value;
		setData(newData);
	};
	const addRule = () => {
		setData([
			...data,
			{
				filters: [EMPTY_FILTER],
				refreshFrequency: null,
				maxStale: null,
				priority: null,
				preventIndexation: false,
			},
		]);
	};
	const handlers = {
		updateFilterField,
		updateInputId,
		changeType,
		changeSource,
		moveUpRule,
		moveDownRule,
		deleteRule,
		addFilter,
		deleteFilter,
		addRule,
		toggleAttribute,
		changeAttributeValue,
	};

	return (
		<div>
			{data.map((_, idx) => (
				<RefreshRule
					data={data}
					idx={idx}
					handlers={handlers}
					nbRules={data.length}
					defaults={defaults}
					inputs={inputs}
				/>
			))}

			<div>
				<Button variant="link" onClick={() => handlers.addRule()}>
					Add new Rule
				</Button>
			</div>

			{isDebug && (
				<>
					<pre className="debugRule">
						{JSON.stringify(data, null, 2)}
					</pre>
					<br />
					<Button variant="link" onClick={() => setDebug(false)}>
						Hide debug
					</Button>
				</>
			)}
			{!isDebug && (
				<Button variant="link" onClick={() => setDebug(true)}>
					Show debug
				</Button>
			)}
		</div>
	);
};

interface IRefreshRuleProps {
	data: IRefreshRule[];
	idx: number;
	handlers: ICustomRefreshRulesComponentHandlers;
	nbRules: number;
	defaults: IRefreshRule;
	inputs: InputDescriptor[];
}

const RefreshRule = ({
	data,
	idx,
	handlers,
	nbRules,
	defaults,
	inputs,
}: IRefreshRuleProps) => {
	const actions = [];
	if (idx > 0) {
		actions.push(
			<Button variant="link" onClick={() => handlers.moveUpRule(idx)}>
				Move Up
			</Button>
		);
	}
	if (idx < nbRules - 1) {
		actions.push(
			<Button variant="link" onClick={() => handlers.moveDownRule(idx)}>
				Move Down
			</Button>
		);
	}
	actions.push(
		<Button variant="link" onClick={() => handlers.deleteRule(idx)}>
			Delete Rule
		</Button>
	);

	return (
		<div className="rule">
			<div>If URL matches</div>
			<RefreshRuleFilters
				data={data}
				idx={idx}
				handlers={handlers}
				inputs={inputs}
			/>
			<div>Then</div>

			<div className="form-row">
				<div className="col-md-3">
					<RefreshRuleAttribute
						data={data}
						idx={idx}
						attribute="refreshFrequency"
						handlers={handlers}
						defaults={defaults}
					/>
				</div>
				<div className="col-md-3">
					<RefreshRuleAttribute
						data={data}
						idx={idx}
						attribute="maxStale"
						handlers={handlers}
						defaults={defaults}
					/>
				</div>
				<div className="col-md-3">
					<RefreshRuleAttribute
						data={data}
						idx={idx}
						attribute="priority"
						handlers={handlers}
						defaults={defaults}
					/>
				</div>
				<div className="col-md-3">
					<RefreshRuleAttribute
						data={data}
						idx={idx}
						attribute="preventIndexation"
						handlers={handlers}
						defaults={defaults}
					/>
				</div>
			</div>

			<div className="mt-4">
				{actions.reduce((prev, curr) => (
					<>
						{prev} | {curr}
					</>
				))}
			</div>
		</div>
	);
};

interface IRefreshRuleAttributeProps {
	data: IRefreshRule[];
	idx: number;
	attribute: Exclude<KeyOf<IRefreshRule>, "__typename" | "id" | "filters">;
	handlers: ICustomRefreshRulesComponentHandlers;
	defaults: IRefreshRule;
}

const RefreshRuleAttribute = ({
	data,
	idx,
	attribute,
	handlers,
	defaults,
}: IRefreshRuleAttributeProps) => {
	const rule = assertAndReturnRule(data, idx);
	const isOpen = rule[attribute] !== null;
	const updateEntry = (value: number) => {
		handlers.changeAttributeValue(idx, attribute, value);
	};

	const getValue = <K extends keyof typeof rule>(
		attribute: K
	): (typeof rule)[K] => (isOpen ? rule[attribute] : defaults[attribute]);

	const Input = () => {
		if (attribute === "priority") {
			return (
				<Form.Control
					name={attribute}
					as="select"
					readOnly={!isOpen}
					value={getValue("priority") ?? undefined}
					onChange={(e) =>
						handlers.changeAttributeValue(
							idx,
							attribute,
							e.target.value
						)
					}
				>
					<option value={3} selected={rule[attribute] === 3}>
						Low
					</option>
					<option value={2} selected={rule[attribute] === 2}>
						Medium
					</option>
					<option value={1} selected={rule[attribute] === 1}>
						High
					</option>
				</Form.Control>
			);
		}

		if (attribute === "preventIndexation") {
			const value = getValue("preventIndexation");
			return (
				<Form.Control
					name={attribute}
					as="select"
					readOnly={!isOpen}
					value={typeof value === "boolean" ? `${value}` : "false"}
					onChange={(e) =>
						handlers.changeAttributeValue(
							idx,
							attribute,
							e.target.value === "true"
						)
					}
				>
					<option value="false" selected={rule[attribute] === false}>
						No
					</option>
					<option value="true" selected={rule[attribute] === true}>
						Yes
					</option>
				</Form.Control>
			);
		}

		return (
			<Form.Control
				name={attribute}
				type="text"
				placeholder={capitalize(attribute)}
				readOnly={!isOpen}
				value={getValue(attribute) ?? undefined}
				onChange={(e) =>
					handlers.changeAttributeValue(
						idx,
						attribute,
						e.target.value
					)
				}
			/>
		);
	};

	return (
		<>
			<Form.Label>{capitalize(attribute)}</Form.Label>
			{/* TODO: Replace the following input with src/components/InputLockable */}
			<InputGroup>
				{attribute !== "preventIndexation" && (
						<Button
							variant={isOpen ? "primary" : "secondary"}
							onClick={() =>
								handlers.toggleAttribute(
									idx,
									attribute,
									!isOpen
								)
							}
						>
							<i
								className={!isOpen ? "bi-lock" : "bi-unlock"}
							></i>
						</Button>
				)}
				<Input />
			</InputGroup>
			{!isOpen && (
				<Form.Text>
					Value is inherited from behaviors {attribute}
				</Form.Text>
			)}
			{isOpen &&
				attribute !== "priority" &&
				attribute !== "preventIndexation" && (
					<DurationShortcuts
						onClick={(durationInMinutes) =>
							updateEntry(durationInMinutes)
						}
					>
						<>
							{rule[attribute]} minutes in days equal to{" "}
							{((rule[attribute] ?? 0) / 60 / 24).toFixed(1)} days
						</>
					</DurationShortcuts>
				)}
		</>
	);
};

interface IRefreshRuleFiltersProps {
	data: IRefreshRule[];
	idx: number;
	handlers: ICustomRefreshRulesComponentHandlers;
	inputs: InputDescriptor[];
}

const RefreshRuleFilters = ({
	data,
	idx,
	handlers,
	inputs,
}: IRefreshRuleFiltersProps) => {
	const filters = assertAndReturnFilters(data, idx);

	return (
		<>
			{filters.map((_: unknown, filterIdx: number) => (
				<div>
					<RefreshRuleFilter
						data={data}
						idx={idx}
						filterIdx={filterIdx}
						handlers={handlers}
						inputs={inputs}
					/>
					{filterIdx < filters.length - 1 && (
						<div className="separator">AND</div>
					)}
				</div>
			))}
			<div>
				<Button variant="link" onClick={() => handlers.addFilter(idx)}>
					Add filter
				</Button>
			</div>
		</>
	);
};

interface IRefreshRuleFilterProps {
	data: IRefreshRule[];
	idx: number;
	filterIdx: number;
	handlers: ICustomRefreshRulesComponentHandlers;
	inputs: InputDescriptor[];
}

const RefreshRuleFilter = ({
	data,
	idx,
	filterIdx,
	handlers,
	inputs,
}: IRefreshRuleFilterProps) => {
	const filter = assertAndReturnFilter(data, idx, filterIdx);

	return (
		<div className="row">
			<div className="col-md-11">
				<Form.Group>
					<Form.Control
						name=""
						as="select"
						required
						onChange={(e) =>
							handlers.changeSource(
								idx,
								filterIdx,
								e.target.value
							)
						}
					>
						<option value="" selected={!filter.source}>
							Please select a source
						</option>
						<option
							value="input"
							selected={filter.source === "input"}
						>
							Source
						</option>
						<option
							value="page"
							selected={filter.source === "page"}
						>
							Page
						</option>
					</Form.Control>
				</Form.Group>
				{filter.source === "page" && (
					<RefreshRuleFilterPage
						data={data}
						idx={idx}
						filterIdx={filterIdx}
						handlers={handlers}
					/>
				)}
				{filter.source === "input" && (
					<RefreshRuleFilterInput
						data={data}
						idx={idx}
						filterIdx={filterIdx}
						handlers={handlers}
						inputs={inputs}
					/>
				)}
			</div>
			<div className="col-md-1">
				<Button
					variant="link"
					onClick={() => handlers.deleteFilter(idx, filterIdx)}
				>
					X
				</Button>
			</div>
		</div>
	);
};

interface IRefreshRuleFilterPageProps {
	data: IRefreshRule[];
	idx: number;
	filterIdx: number;
	handlers: ICustomRefreshRulesComponentHandlers;
}

const RefreshRuleFilterPage = ({
	data,
	idx,
	filterIdx,
	handlers,
}: IRefreshRuleFilterPageProps) => {
	const filter = assertAndReturnFilter(data, idx, filterIdx);

	return (
		<>
			<Form.Group>
				<Form.Control
					name=""
					as="select"
					onChange={(e) =>
						handlers.changeType(idx, filterIdx, e.target.value)
					}
				>
					<option value="" selected={!filter.type}>
						Please select
					</option>
					<option
						value="status_code"
						selected={filter.type === "status_code"}
					>
						Status Code
					</option>
					<option
						value="status_code_previous"
						selected={filter.type === "status_code_previous"}
					>
						Previous Changing Status Code
					</option>
					<option
						value="status_code_consecutive"
						selected={filter.type === "status_code_consecutive"}
					>
						Number of consecutive renderings with same status code
					</option>
					<option
						value="status_code_duration"
						selected={filter.type === "status_code_duration"}
					>
						Number of minutes with same Status Code
					</option>
					<option
						value="property"
						selected={filter.type === "property"}
					>
						Property
					</option>
				</Form.Control>
			</Form.Group>

			{(filter.type === "status_code" ||
				filter.type === "status_code_previous") && (
				<RefreshRuleFilterStatusCode
					data={data}
					idx={idx}
					filterIdx={filterIdx}
					handlers={handlers}
					placeholder="Enter Status Code"
				/>
			)}
			{filter.type === "status_code_consecutive" && (
				<RefreshRuleFilterStatusCode
					data={data}
					idx={idx}
					filterIdx={filterIdx}
					handlers={handlers}
					placeholder="Enter Number of Consecutive Renderings"
				/>
			)}
			{filter.type === "status_code_duration" && (
				<RefreshRuleFilterStatusCode
					data={data}
					idx={idx}
					filterIdx={filterIdx}
					handlers={handlers}
					placeholder="Enter Number of Minutes with same Status Code"
				/>
			)}
			{filter.type === "property" && (
				<RefreshRuleFilterProperty
					data={data}
					idx={idx}
					filterIdx={filterIdx}
					handlers={handlers}
				/>
			)}
		</>
	);
};

interface IRefreshRuleFilterInputProps {
	data: IRefreshRule[];
	idx: number;
	filterIdx: number;
	handlers: ICustomRefreshRulesComponentHandlers;
	inputs: InputDescriptor[];
}

const RefreshRuleFilterInput = ({
	data,
	idx,
	filterIdx,
	handlers,
	inputs,
}: IRefreshRuleFilterInputProps) => {
	const filter = assertAndReturnFilter(data, idx, filterIdx);

	return (
		<>
			<Form.Group>
				<Form.Control
					name=""
					as="select"
					required
					onChange={(e) =>
						handlers.updateInputId(idx, filterIdx, e.target.value)
					}
				>
					<option value="">Please Select Source</option>
					{inputs.map((input) => (
						<option
							value={input.stableId ?? undefined}
							selected={filter.input === input.stableId}
						>
							{input.inputType} / {input.name}
						</option>
					))}
				</Form.Control>
			</Form.Group>

			<Form.Group>
				<Form.Control
					name=""
					as="select"
					required
					onChange={(e) =>
						handlers.changeType(idx, filterIdx, e.target.value)
					}
				>
					<option value="" selected={!filter.type}>
						Please Select Condition
					</option>
					<option value="exists" selected={filter.type === "exists"}>
						Exists
					</option>
					<option
						value="property"
						selected={filter.type === "property"}
					>
						Property
					</option>
				</Form.Control>
			</Form.Group>

			{filter.type === "property" && (
				<RefreshRuleFilterProperty
					data={data}
					idx={idx}
					filterIdx={filterIdx}
					handlers={handlers}
				/>
			)}
		</>
	);
};

interface IRefreshRuleFilterPropertyProps {
	data: IRefreshRule[];
	idx: number;
	filterIdx: number;
	handlers: ICustomRefreshRulesComponentHandlers;
}

const RefreshRuleFilterProperty = ({
	data,
	idx,
	filterIdx,
	handlers,
}: IRefreshRuleFilterPropertyProps) => {
	const filter = assertAndReturnFilter(data, idx, filterIdx);

	const isInvalidRule = ((input) => {
		const isRegExpRule =
			input.predicate &&
			["regex", "not_regexp"].includes(input.predicate);

		if (!isRegExpRule) {
			return false;
		}

		if (!input.value) {
			return true;
		}

		if (input.value.length > 300) {
			return true;
		}
		let isRegExpValid = false;
		regExIsValid(input.value, () => (isRegExpValid = true));

		return isRegExpValid;
	})(filter);

	return (
		<div className="form-row">
			<div className="col-md-4">
				<Form.Group>
					<Form.Label>Property Name</Form.Label>
					<Form.Control
						name="name"
						type="text"
						placeholder="Enter Property Name"
						value={filter.name ?? undefined}
						required
						onChange={(e) =>
							handlers.updateFilterField(
								idx,
								filterIdx,
								"name",
								e.target.value
							)
						}
					></Form.Control>
				</Form.Group>
			</div>
			<div className="col-md-4">
				<Form.Group>
					<Form.Label>Predicate</Form.Label>
					<Form.Control
						name="predicate"
						as="select"
						onChange={(e) =>
							handlers.updateFilterField(
								idx,
								filterIdx,
								"predicate",
								e.target.value
							)
						}
					>
						{PREDICATES.map((currentPredicate) => {
							return (
								<option
									selected={
										filter.predicate === currentPredicate
									}
									value={currentPredicate}
								>
									{getReadablePredicate(currentPredicate)}
								</option>
							);
						})}
					</Form.Control>
				</Form.Group>
			</div>
			<div className="col-md-4">
				<Form.Group>
					<Form.Label>Property Value</Form.Label>
					<InputGroup className="mb-2">
						<Form.Control
							name="value"
							type="text"
							placeholder="Enter Value"
							value={filter.value ?? undefined}
							onChange={(e) =>
								handlers.updateFilterField(
									idx,
									filterIdx,
									"value",
									e.target.value
								)
							}
							isInvalid={isInvalidRule}
						></Form.Control>
						<Form.Control.Feedback type="invalid">
							The regex should be shorten than 300 characters and
							valid.
						</Form.Control.Feedback>
					</InputGroup>
				</Form.Group>
			</div>
		</div>
	);
};

interface IRefreshRuleFilterStatusCodeProps {
	data: IRefreshRule[];
	idx: number;
	filterIdx: number;
	handlers: ICustomRefreshRulesComponentHandlers;
	placeholder: string;
}

const RefreshRuleFilterStatusCode = ({
	data,
	idx,
	filterIdx,
	handlers,
	placeholder,
}: IRefreshRuleFilterStatusCodeProps) => {
	const filter = assertAndReturnFilter(data, idx, filterIdx);
	const updateEntry = (value: number) => {
		handlers.updateFilterField(idx, filterIdx, "value", `${value}`);
	};

	return (
		<>
			<div className="form-row">
				<div className="col-md-6">
					<Form.Group>
						<Form.Label>Predicate</Form.Label>
						<Form.Control
							name="predicate"
							as="select"
							onChange={(e) =>
								handlers.updateFilterField(
									idx,
									filterIdx,
									"predicate",
									e.target.value
								)
							}
						>
							{NUMBER_PREDICATES.map((currentPredicate) => {
								return (
									<option
										selected={
											filter.predicate ===
											currentPredicate
										}
										value={currentPredicate}
									>
										{getReadablePredicate(currentPredicate)}
									</option>
								);
							})}
						</Form.Control>
					</Form.Group>
				</div>

				<div className="col-md-6">
					<Form.Group>
						<Form.Label>Value</Form.Label>
						<Form.Control
							name="statusCode"
							type="text"
							placeholder={placeholder}
							value={filter.value ?? undefined}
							required
							onChange={(e) =>
								handlers.updateFilterField(
									idx,
									filterIdx,
									"value",
									e.target.value
								)
							}
						></Form.Control>
					</Form.Group>
					{filter.type === "status_code_duration" && (
						<DurationShortcuts
							onClick={(durationInMinutes) =>
								updateEntry(durationInMinutes)
							}
						>
							<>
								{filter.value} minutes in days equal to{" "}
								{(
									Number.parseInt(filter.value ?? "") /
									60 /
									24
								).toFixed(1)}{" "}
								days
							</>
						</DurationShortcuts>
					)}
				</div>
			</div>
		</>
	);
};

const assertAndReturnRule = (data: IRefreshRule[], idx: number) => {
	const rule = data[idx];

	if (!rule) {
		throw new Error("No rule to update");
	}

	return rule;
};

const assertAndReturnFilters = (data: IRefreshRule[], idx: number) => {
	const filters = assertAndReturnRule(data, idx).filters;

	if (!filters) {
		throw new Error("No filters to update");
	}

	return filters;
};

const assertAndReturnFilter = (
	data: IRefreshRule[],
	idx: number,
	filterIdx: number
) => {
	const filter = assertAndReturnFilters(data, idx)?.[filterIdx];

	if (!filter) {
		throw new Error("No filter to update.");
	}

	return filter;
};
