import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";

import {
  FilterInputValue,
  getFilterValue,
  getInputValue,
  WebsitesFilterHandler,
} from "./helpers";

interface IClusterFilterProps {
  onChange: WebsitesFilterHandler;
  filterKey: string;
  filterValue: boolean;
}

export function ClusterFilter({
  onChange,
  filterKey,
  filterValue,
}: IClusterFilterProps) {
  const handleChange = (targetValue: FilterInputValue) => {
    onChange(filterKey, getFilterValue(targetValue));
  };

  // Must do that because of react bootstrap doesn't work :sad:
  const currentValue = getInputValue(filterValue);

  return (
    <div style={{ paddingTop: "10px" }}>
      <ToggleButtonGroup
        className="mb-2"
        type="radio"
        name="radio"
        onChange={handleChange}
        value={currentValue}
      >
        <ToggleButton id="toggle-both" variant="secondary" value="both">
          All
        </ToggleButton>
        <ToggleButton id="toggle-enabled" variant="secondary" value="enabled">
          Demo
        </ToggleButton>
        <ToggleButton id="toggle-disabled" variant="secondary" value="disabled">
          Production
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}
