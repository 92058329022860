import { WebsiteType } from "../gql/types.generated";

export interface IWebsitePreviewURL
  extends Pick<WebsiteType, "beamDomain" | "previewToken" | "beamWebsiteId"> {}

export const getPreviewURL = (
  website: IWebsitePreviewURL,
  url: string,
  device: string
) => {
  return `${
    website.beamDomain
  }page?overlay=true&navigable=true&remove_scripts=true&resources_absolute_uris=true&token=${
    website.previewToken
  }&website_id=${website.beamWebsiteId}${
    device ? "&device=" + device : ""
  }&uri=${encodeURIComponent(url)}`;
};

export const goToPreview = (
  website: IWebsitePreviewURL,
  url: string,
  device: string
) => {
  window.open(getPreviewURL(website, url, device), "_blank");
};
