import { gql } from "@apollo/client";

export const UPDATE_BA_CONFIG_CODE = gql`
  mutation UpdateBaConfigCode($input: UpdateBaConfigCodeMutationInput!) {
    updateBaConfig(input: $input) {
      website {
        id
        name
        baDesktopCode
        baDesktopOnLoadCode
        baDesktopJsRules
        baDesktopJsConfig
        baMobileCode
        baMobileOnLoadCode
        baMobileJsRules
        baMobileJsConfig
      }
    }
  }
`;
