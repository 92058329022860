import { gql } from "@apollo/client";

export const GET_PAGE_EDIT = gql`
  query PageEdit($websiteId: String!, $id: String!) {
    pageEdit(id: $id, websiteId: $websiteId) {
      id
      device
      url
      title
      description
      h1
      permanentRedirect
      temporaryRedirect
      htmlBlocks {
        id
        name
        cssSelector
        insertStrategy
        innerHtml
      }
    }
  }
`;
