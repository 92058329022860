import Alert from "react-bootstrap/Alert";
import { ForceRefreshResultErrors } from "./forceRefreshResultErrors";
import { useForceRefresh } from "./useForceRefresh";

interface IForceUrlsRefreshProps {
  forceRefresh: NonNullable<
    ReturnType<typeof useForceRefresh>["forceRefreshData"]
  >;
}

export function ForceUrlsRefreshResult({
  forceRefresh,
}: IForceUrlsRefreshProps) {
  if (forceRefresh.errors) {
    return <ForceRefreshResultErrors errors={forceRefresh.errors} />;
  }

  return (
    <Alert variant="success">
      Force refresh job has been successfully created.
    </Alert>
  );
}
