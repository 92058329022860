import { useQuery } from "@apollo/client";

import { BarChart } from "../../../common/charts";
import { DataHandler } from "../../../components/DataHandler";
import type {
  QueryQueryServedPagesArgs,
  QueryServedPagesResult,
} from "../../../gql/types.generated";
import { getQuery } from "../../Helpers";
import type { IMonitoringOptions } from "../../types";
import { METRICS_MAPPING } from "../Constants";
import { Link } from "react-router-dom";

type HitsByHourCardMetric = keyof typeof METRICS_MAPPING.servedPages;

interface IHitsByHourCardProps {
  metric?: keyof typeof METRICS_MAPPING.servedPages;
  options: IMonitoringOptions;
}

export function HitsByHourCard({
  options,
  metric = "countHits",
}: IHitsByHourCardProps) {
  const { loading, error, data } = useQuery<
    {
      queryServedPages: Pick<
        QueryServedPagesResult,
        "date" | HitsByHourCardMetric
      >[];
    },
    QueryQueryServedPagesArgs
  >(
    getQuery({
      name: "HitsQuery",
      metrics: ["dateHour", metric],
      dimensions: ["dateHour"],
      orderBy: ["dateHour"],
    }),
    {
      variables: {
        timestampStart: options.timestampStartLast24h,
        timestampEnd: options.timestampEnd,
      },
    }
  );

  if (error || loading || !data) {
    return (
      <DataHandler error={error} loading={loading} data={data} expectData />
    );
  }

  return (
    <>
      <BarChart
        data={data.queryServedPages}
        dimension="dateHour"
        metric={metric}
        color={METRICS_MAPPING.servedPages[metric].color}
        xTitle="Hour"
        yTitle="Number of Hits"
        height="250px"
      />
      <Link to={"websites/servedPages/24h/" + metric}>
        Get Detail by Website
      </Link>
    </>
  );
}
