import { gql } from "@apollo/client";

export const ANALYZE_URLS_REFRESH_IMPACT = gql`
  mutation AnalyzeUrlsRefreshImpact($websiteId: ID!, $urls: String!) {
    analyzeUrlsRefreshImpact(websiteId: $websiteId, urls: $urls) {
      errors {
        message
      }

      totalInventoryUrls
      nbOfUrlsToRefresh
      maxAllowedUrlsToRefresh

      impactLevel

      forceRefreshToken
    }
  }
`;
