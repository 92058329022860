import { Alert } from "react-bootstrap";
import { REGEX_MAX_LENGTH } from "../../helpers";

export interface IErrorRegExAlert {
  hasLongRegEx: boolean;
  hasInvalidRegEx: boolean;
}

export function ErrorRegExAlert({
  hasInvalidRegEx,
  hasLongRegEx,
}: IErrorRegExAlert) {
  if (!hasInvalidRegEx && !hasLongRegEx) {
    return null;
  }

  return (
    <Alert variant="danger">
      <ul>
        {hasInvalidRegEx ? <li>At least one regex is invalid</li> : null}
        {hasLongRegEx ? (
          <li>"The regex must be less than {REGEX_MAX_LENGTH} characters"</li>
        ) : null}
      </ul>
    </Alert>
  );
}
