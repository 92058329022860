import { gql } from "@apollo/client";

export const UPDATE_ADN_CONFIG = gql`
  mutation UpdateAdnConfig($input: UpdateADNConfigMutationInput!) {
    updateAdnConfig(input: $input) {
      errors {
        field
        messages
      }
      adnConfig {
        id
      }
    }
  }
`;
