import { useQuery, gql } from "@apollo/client";
import Table from "react-bootstrap/Table";

import { WebsiteType } from "../gql/types.generated";
import { capitalize } from "./str";
import { IFilter } from "./typing/filter";
import { IMetricCardItem, IPagesDefinition, IPreset } from "./typing/website";

export const getDimensions = (dimensions: string[], fields: string[]) => {
  return fields.filter((e) => dimensions.includes(e));
};

const getFields = (
  cards: string[],
  cardsDefinitions: Record<string, { fields?: string[] }>
) => cards.flatMap((card) => cardsDefinitions[card]?.fields ?? [card]);

const gqlDef = (
  presetId: string,
  func: string,
  dimensions: string[],
  fields: string[],
  orderBy: string
) => {
  return gql`
    query Website${capitalize(presetId)} (
      $id: String!
      $timestampStart: Int!
      $timestampEnd: Int!
      $filters: JSONString
    ) {
      website(id: $id) {
        id
        ${func} (
          timestampStart: $timestampStart
          timestampEnd: $timestampEnd
          page: 1
          size: 1000
          dimensions: [${dimensions
            .map((dimension) => `"${dimension}"`)
            .join(",")}]
          filters: $filters
          orderBy: ["${orderBy}"]
        ) {
          ${fields.join("\n")}
        }
      }
    }
  `;
};

interface IUseGqlQueryOptions {
  websiteId: string;
  tStart: number;
  tEnd: number;
  datamodel: IPagesDefinition;
  preset: IPreset;
  additionalFilters?: IFilter[];
}

export const useGqlQuery = ({
  websiteId,
  tStart,
  tEnd,
  datamodel,
  preset,
  additionalFilters = [],
}: IUseGqlQueryOptions) => {
  const fields = getFields(preset.cards, datamodel.cards);
  const dimensions = getDimensions(datamodel.dimensions, fields);
  const filters = [...(preset.filters ?? []), ...additionalFilters];

  return useQuery(
    gqlDef(
      preset.id,
      datamodel.gqlFunction,
      dimensions,
      fields,
      preset.orderBy ?? ""
    ),
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        id: websiteId,
        timestampStart: tStart,
        timestampEnd: tEnd,
        preset: preset.id,
        filters: JSON.stringify(filters),
      },
    }
  );
};

export const generateTable = (
  items: IMetricCardItem[],
  datamodel: IPagesDefinition,
  preset: IPreset,
  website: WebsiteType
) => {
  const { cards } = datamodel;
  const cardsNames = preset.cards.map(
    (c) => cards[c]?.name ?? datamodel.fieldsVerbose[c] ?? c
  );
  const displayCard = (
    item: IMetricCardItem,
    card: string,
    website: WebsiteType
  ) => {
    if (cards[card]) {
      const textAlign = cards[card].align;
      const style = textAlign ? { textAlign } : {};
      return (
        <td key={card} style={style}>
          {cards[card].display(item, website)}
        </td>
      );
    }
    return <td key={card}>{item[card as keyof IMetricCardItem]}</td>;
  };

  return (
    <Table striped bordered hover className="white" size="sm">
      <thead>
        <tr>
          {cardsNames.map((name, idx) => (
            <td key={`${idx}-${name}`}>{name}</td>
          ))}
        </tr>
      </thead>
      <tbody>
        {items.map((item, i) => {
          return (
            <tr key={i}>
              {preset.cards.map((c) => displayCard(item, c, website))}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
