import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { useMutation, useQuery, gql } from "@apollo/client";
import { Button, Alert } from "react-bootstrap";
import {
  useFormInput,
  useFormInputSynchronizedWithLS,
} from "../../../common/hooks";
import { Header } from "../../components/header";
import {
  GET_WEBSITE,
  WebsiteQuery,
  WebsiteQueryVariables,
} from "../../../gql/websites/website";
import { WebsiteIdParams } from "../../types/routeParams";
import { CreateBotifyAnalyticsImportMutation } from "../../../gql/types.generated";
import { DataHandler } from "../../../components/DataHandler";
import { useParams } from "react-router-dom";

const GQL_CREATE_JOB = gql`
  mutation Automation($input: CreateBotifyAnalyticsImportMutationInput!) {
    createBotifyAnalyticsImport(input: $input) {
      jobId
      success
    }
  }
`;

export function ImportCrawlData() {
  const { websiteId } = useParams<WebsiteIdParams>() as WebsiteIdParams;
  const { loading, error } = useQuery<WebsiteQuery, WebsiteQueryVariables>(
    GET_WEBSITE,
    { variables: { id: websiteId } }
  );

  const [ownerSlug, setOwnerSlug] = useFormInputSynchronizedWithLS({
    key: "owner" + websiteId,
    defaultValue: "",
  });
  const [projectSlug, setProjectSlug] = useFormInputSynchronizedWithLS({
    key: "project" + websiteId,
    defaultValue: "",
  });
  const [analysisSlug, setAnalysisSlug] = useFormInputSynchronizedWithLS({
    key: "analysis" + websiteId,
    defaultValue: "",
  });
  const [device, setDevice] = useFormInput("");

  const [createdJob, setCreatedJob] =
    useState<CreateBotifyAnalyticsImportMutation | null>(null);
  const [validated, setValidated] = useState(false);

  const [submitImport] = useMutation(GQL_CREATE_JOB, {
    update(cache, { data: { createBotifyAnalyticsImport } }) {
      setCreatedJob(createBotifyAnalyticsImport);
    },
  });

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    setValidated(true);
    await submitImport({
      variables: {
        input: {
          website: websiteId,
          device: device,
          project: projectSlug,
          username: ownerSlug,
          crawl: analysisSlug,
        },
      },
    });
  }

  if (error || loading) {
    return <DataHandler error={error && "Failed"} loading={loading} />;
  }

  const jobUrl = createdJob
    ? `https://app.botify.com/admin/crawlblocks_export/crawlblocksexportjob/${createdJob.jobId}/change/`
    : "";

  return (
    <React.Fragment>
      <Header.Title
        websiteId={websiteId}
        name="Sources > Botify Analytics > Import"
      />
      <Card>
        <Card.Header>Main Information</Card.Header>
        <Card.Body>
          {createdJob && Number(createdJob.jobId) > 0 && (
            <Alert variant="primary">
              Crawl data import job will start in a few minutes. Please check
              status on Slack (#ftl-index-jobs).
              <br />
              <a href={jobUrl}>
                Click here to get more informations on job #{createdJob.jobId}
              </a>
            </Alert>
          )}
          {createdJob && !createdJob.jobId && (
            <Alert variant="danger">
              Job can not be created. Check your parameters and credentials.
            </Alert>
          )}

          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Project Owner</Form.Label>
              <Form.Control
                value={ownerSlug}
                onChange={setOwnerSlug}
                required
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Project Slug</Form.Label>
              <Form.Control
                value={projectSlug}
                onChange={setProjectSlug}
                required
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Analysis Slug</Form.Label>
              <Form.Control
                value={analysisSlug}
                onChange={setAnalysisSlug}
                required
              />
              <Form.Text>
                How to find Project Owner, Project Slug and Analysis Slug?
                <br />
                Go to a Botify Site Crawler analysis report and take its URL (ex
                :
                https://app.botify.com/paul-mccartney/thebeatles.com/?analysisSlug=20200101)
                <br />
                Project Owner is <b>paul-mccartney</b>, Project Slug is{" "}
                <b>thebeatles.com</b> and Analysis Slug is <b>20200101</b>
              </Form.Text>
            </Form.Group>

            <Form.Group>
              <Form.Label>Device</Form.Label>
              <Form.Control
                as="select"
                value={device}
                onChange={setDevice}
                required
              >
                <option value="">Select a source...</option>
                {[
                  ["desktop", "Desktop"],
                  ["mobile", "Mobile"],
                  ["na", "Adaptive or Responsive for any device"],
                ].map((entry) => (
                  <option value={entry[0]} key={entry[0]}>
                    {entry[1]}
                  </option>
                ))}
              </Form.Control>
              <Form.Text>
                Select "Desktop" if you want index pages only for desktop device
                <br />
                Select "Mobile" if you want index pages only for mobile device
                <br />
                Select "Adaptive or Responsive" if you want to index pages that
                are compatible with all devices
              </Form.Text>
            </Form.Group>

            <Button type="submit" className="float-right">
              Import Crawl Data
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
