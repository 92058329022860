import { Maybe } from "graphql/jsutils/Maybe";
import { RuleGroupType } from "react-querybuilder";

import { WebsiteType } from "../gql/types.generated";
import { Status } from "./typing";

export const getPreviewLink = (
  website: Pick<WebsiteType, "beamDomain" | "beamWebsiteId" | "previewToken">,
  url: string,
  device: Maybe<string>
) => {
  const newPageUrl =
    website.beamDomain +
    "page?overlay=true&navigable=true&remove_scripts=true&resources_absolute_uris=true&token=" +
    website.previewToken +
    "&website_id=" +
    website.beamWebsiteId +
    (device ? "&device=" + device : "") +
    "&uri=" +
    encodeURIComponent(url);
  return newPageUrl;
};

/**
 * Reduces the input `results` to the most severe Status found.
 */
export const getGlobalStatus = (results: { status: Status }[]): Status =>
  results.reduce<Status>(
    (globalStatus, { status }) => getMostSevereStatus(globalStatus, status),
    "SUCCESS"
  );

const getMostSevereStatus = (a: Status, b: Status) =>
  STATUSES_SEVERITY[a] > STATUSES_SEVERITY[b] ? a : b;

const STATUSES_SEVERITY: Record<Status, number> = {
  SUCCESS: 0,
  WARNING: 1,
  FAIL: 2,
};

export const REGEX_MAX_LENGTH = 300;

export function validateRegExpInRules(ruleGroup: RuleGroupType) {
  const { rules } = ruleGroup;
  const result = { hasLongRegEx: false, hasInvalidRegEx: false };

  rules.forEach((rule) => {
    if (!("operator" in rule)) {
      return;
    }

    if (!["regex", "!regex"].includes(rule.operator)) {
      return;
    }

    if (!result.hasLongRegEx && rule.value.length > REGEX_MAX_LENGTH) {
      result.hasLongRegEx = true;
    }

    if (!result.hasInvalidRegEx) {
      regExIsValid(rule.value, () => (result.hasInvalidRegEx = true));
    }
  });

  return result;
}

export function regExIsValid(regEx: string, callback: () => void) {
  try {
    //  nosemgrep: javascript.lang.security.audit.detect-non-literal-regexp.detect-non-literal-regexp
    new RegExp(regEx);
    return;
  } catch (error) {
    callback();
  }
}
