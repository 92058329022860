import { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";

import Dropdown from "../../components/Dropdown/Dropdown";
import {
  AllClustersQuery,
  AllClustersQueryVariables,
  GET_ALL_CLUSTERS,
  PageWorkersClusterFragment,
} from "../../gql/websites/clusters";
import {
  CreateWebsiteMutation,
  CreateWebsiteMutationVariables,
  CREATE_WEBSITE,
  GET_ALL_WEBSITES,
  GetAllWebsitesQuery,
} from "../../gql/websites/website";
import { DataHandler } from "../../components/DataHandler/DataHandler";
import { InputGroup } from "react-bootstrap";
import { useAdvancedMode } from "../../common/hooks";
import {
  getPwClustersDropdownOptions,
  getSwClustersDropdownOptions,
} from "./utils";
import { isBetaEnv } from "../../common/env";
import { useNavigate } from "react-router-dom";

function CreateWebsite() {
  const [submitted, setSubmitted] = useState(false);

  const [name, setName] = useState("");
  const [hosts, setHosts] = useState("");

  const { isAdvancedMode } = useAdvancedMode();
  const [swCluster, setSwCluster] = useState("");
  const [pwCluster, setPwCluster] = useState("");
  const envIsBeta = isBetaEnv();
  const isStandardModeInBetaEnv = envIsBeta && !isAdvancedMode;

  const { loading, error, data } = useQuery<
    AllClustersQuery,
    AllClustersQueryVariables
  >(GET_ALL_CLUSTERS);
  const [
    createWebsite,
    { loading: creatingWebsite, error: createdWebsiteError },
  ] = useCreateWebsite();

  useEffect(() => {
    // When we get the list of clusters, we set the default as selected
    if (data?.allPageworkersClusters) {
      // We only have at most one
      const defaultPWClusters = data.allPageworkersClusters.filter(
          (pwCluster: PageWorkersClusterFragment) => pwCluster.isDefault
      );
      const defaultPWCluster = defaultPWClusters[0];
      setPwCluster(defaultPWCluster?.id ?? '');
    }
  }, [data?.allPageworkersClusters]);

  const creatingError = createdWebsiteError?.message;

  const swClusters = data?.allClusters;
  const pwClusters = data?.allPageworkersClusters;

  if (error || loading || !swClusters) {
    return (
      <DataHandler
        error={error}
        loading={loading}
        data={swClusters}
        expectData
      />
    );
  }

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    setSubmitted(true);
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      return;
    }

    createWebsite({
      variables: {
        name,
        hosts,
        cluster: swCluster,
        pageworkersCluster: pwCluster,
      },
    });
  };

  return (
    <div>
      <Card>
        <Card.Header>
          <b>Create New Website</b>
        </Card.Header>
        <Card.Body>
          {creatingError && <Alert variant="danger">{creatingError}</Alert>}
          <Form noValidate onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>
                Name <span className="field-required">*</span>
              </Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  id="name"
                  type="text"
                  onChange={(e) => setName(e.target.value)}
                  required
                  isInvalid={name === "" && submitted}
                />
                <Form.Control.Feedback type="invalid">
                  Please set name
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Domain <span className="field-required">*</span>
              </Form.Label>
              <Form.Control
                id="hosts"
                type="text"
                onChange={(e) => setHosts(e.target.value)}
                required
                isInvalid={hosts === "" && submitted}
              />
              <Form.Control.Feedback type="invalid">
                Please set domains
              </Form.Control.Feedback>
              <Form.Text>
                You can declare multiple domains by separating them with a
                comma. declaring "site.com" will also handle subdomains like
                "xxx.site.com"
              </Form.Text>
            </Form.Group>
            <Form.Group>
              <Form.Label>SW Cluster</Form.Label>
              <Dropdown
                name="sw-cluster"
                label="Choose a SpeedWorkers cluster"
                placeholder="Select a cluster"
                required
                options={getSwClustersDropdownOptions(
                  isStandardModeInBetaEnv,
                  swClusters
                )}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSwCluster(e.target.value)
                }
                feedback={{
                  type: "invalid",
                  isInvalid: swCluster === "" && submitted,
                  message: "Please set a SpeedWorkers cluster",
                }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>PW Cluster</Form.Label>
              <Dropdown
                name="pw-cluster"
                label="Choose a PageWorkers cluster (optional)"
                options={getPwClustersDropdownOptions(
                  isStandardModeInBetaEnv,
                  pwClusters
                )}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setPwCluster(e.target.value)
                }
              />
              <Form.Text>
                Automatically, we select a demo cluster referenced as "(default)".<br/>
                For production customers, you should target a production cluster, otherwise keep default selection.<br/>
                If the website shouldn't have access to PageWorkers, set this to "-- None --".
              </Form.Text>
            </Form.Group>

            <Button disabled={loading || creatingWebsite} type="submit">
              {loading
                ? "Website is being created, please wait a few seconds"
                : "Submit"}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}

function useCreateWebsite() {
  const navigate = useNavigate();

  return useMutation<CreateWebsiteMutation, CreateWebsiteMutationVariables>(
    CREATE_WEBSITE,
    {
      onCompleted: (data) => {
        const website = data?.createWebsite?.website;
        if (!website) return;

        navigate(
          {
            pathname: "/website/" + website.id,
          },
          {
            state: {
              message: {
                value: `Website ${website.name} is created`,
                status: "success",
              },
            },
          }
        );
      },
      update: (store, { data: dataCreate }) => {
        const createdWebsite = dataCreate?.createWebsite?.website;
        const dataStore = store.readQuery<GetAllWebsitesQuery>({
          query: GET_ALL_WEBSITES,
        });
        if (!createdWebsite || !dataStore) return;

        store.writeQuery({
          query: GET_ALL_WEBSITES,
          data: [createdWebsite, ...dataStore.allWebsites],
        });
      },
    }
  );
}

export default CreateWebsite;
