export const METRICS_MAPPING = {
  servedPages: {
    countHitsDelivered: {
      verbose: "Delivered Hits",
      color: "green",
    },
    countHitsNotDelivered: {
      verbose: "Not Delivered Hits",
      color: "orange",
    },
    countHits: {
      verbose: "Hits",
      color: "blue",
    },
  },
  indexedPages: {
    count: {
      verbose: "Indexed Pages",
      color: "blue",
    },
  },
};
