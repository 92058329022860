import { gql } from "@apollo/client";

export const CREATE_INPUT_TEXT_LIST = gql`
  mutation CreateInputTextList($input: CreateInputTextListMutationInput!) {
    createInputTextList(input: $input) {
      textListInput {
        id
        name
        nbUrls
        urls
      }
      errors {
        field
        messages
      }
    }
  }
`;
