import { Route, Routes } from "react-router-dom";
import { useAdvancedMode } from "../../common/hooks";
import { Error404Page } from "../../components/Error404Page/Error404Page";
import { ScriptCreationPage } from "./pages/ScriptCreationPage";
import { ScriptEditionPage } from "./pages/ScriptEditionPage";
import { ScriptManagementPage } from "./pages/ScriptsManagementPage";

export const ScriptsRouting = () => {
  const { isAdvancedMode } = useAdvancedMode();

  // Scripts management is not available in standard mode
  if (!isAdvancedMode) {
    return (
      <Routes>
        <Route path="*" element={<Error404Page />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/create" element={<ScriptCreationPage />} />
      <Route path="/edit/:scriptId" element={<ScriptEditionPage />} />
      <Route path="/" element={<ScriptManagementPage />} />
      <Route path="*" element={<Error404Page />} />
    </Routes>
  );
};
