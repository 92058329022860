import { gql, useQuery } from "@apollo/client";
import { Alert, Table } from "react-bootstrap";

import { capitalize } from "../../common/str";
import { getDimensions } from "../../common/table";
import { IFilter } from "../../common/typing/filter";
import {
  IMetricCard,
  IMetricCardItem,
  IPagesDefinition,
  IPreset,
} from "../../common/typing/website";
import { DataHandler } from "../../components/DataHandler";
import { WebsiteType } from "../../gql/types.generated";

const getFields = (
  cards: string[],
  cardsDefinitions: Record<string, IMetricCard>
) => cards.flatMap((card) => cardsDefinitions?.[card]?.fields ?? [card]);

interface IGenerateGqlQueryOptions {
  preset: string;
  func: string;
  dimensions: string[];
  fields: string[];
  orderBy?: string;
}

const buildGqlQuery = ({
  preset,
  func,
  dimensions,
  fields,
  orderBy,
}: IGenerateGqlQueryOptions) => gql`
    query Query${capitalize(preset)} (
      $id: String!
      $filters: JSONString
    ) {
      ${func}(id: $id) {
        id
        queryData(
          page: 1
          size: 1000
          dimensions: [${dimensions
            .map((dimension) => `"${dimension}"`)
            .join(",")}]
          filters: $filters
          ${orderBy ? `orderBy: ["${orderBy}"]` : ""}
        ) {
          ${fields.join("\n")}
        }
      }
    }
  `;

interface IUseGqlQueryOptions {
  inputId: string;
  datamodel: IPagesDefinition;
  preset: IPreset;
  additionalFilters?: IFilter[];
}

const useGqlQuery = ({
  inputId,
  datamodel,
  preset,
  additionalFilters = [],
}: IUseGqlQueryOptions) => {
  const fields = getFields(preset.cards, datamodel.cards);
  const dimensions = getDimensions(datamodel.dimensions, fields);
  const filters = [...(preset.filters ?? []), ...additionalFilters];

  return useQuery(
    buildGqlQuery({
      preset: preset.id,
      func: datamodel.gqlFunction,
      dimensions,
      fields,
      orderBy: preset.orderBy,
    }),
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        id: inputId,
        preset: preset.id,
        filters: JSON.stringify(filters),
      },
    }
  );
};

interface IGenerateTableOptions {
  items: IMetricCardItem[];
  datamodel: IPagesDefinition;
  preset: IPreset;
  website: WebsiteType;
}

export const generateTable = ({
  items,
  datamodel,
  preset,
  website,
}: IGenerateTableOptions) => {
  const cards = datamodel.cards;
  const cardsNames = preset.cards.map(
    (c) => datamodel.cards[c]?.name ?? datamodel.fieldsVerbose[c] ?? c
  );

  return (
    <Table striped bordered hover className="white" size="sm">
      <thead>
        <tr>
          {cardsNames.map((c) => (
            <td>{c}</td>
          ))}
        </tr>
      </thead>
      <tbody>
        {items.map((item) => (
          <tr>
            {preset.cards.map((c) => (
              <td>
                {cards[c]?.display(item, website) ??
                  item[c as keyof typeof item]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

interface IUrlsTableProps {
  inputId: string;
  website: WebsiteType;
  definitions: IPagesDefinition<unknown>;
  presets: Record<string, IPreset>;
  currentPreset: string;
}

export function UrlsTable({
  inputId,
  website,
  definitions,
  presets,
  currentPreset,
}: IUrlsTableProps) {
  const {
    loading,
    error,
    data: monitoringData,
    networkStatus,
  } = useGqlQuery({
    inputId,
    datamodel: definitions,
    preset: presets[currentPreset],
  });

  if (error || loading)
    return (
      <>
        {!loading && error && (
          <Alert variant="warning">
            If the error message explicitly states that the BigQuery table is
            not found, there is a high chance that neither version of that
            source currently exists in production, and the table has not been
            created yet.
          </Alert>
        )}

        <DataHandler error={error} loading={loading} />
      </>
    );

  return (
    <>
      {networkStatus !== 1 &&
      monitoringData &&
      monitoringData[definitions.gqlFunction]
        ? generateTable({
            items: monitoringData[definitions.gqlFunction].queryData,
            datamodel: definitions,
            preset: presets[currentPreset],
            website,
          })
        : "Loading"}
    </>
  );
}
