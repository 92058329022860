export function QuickAccessNav() {
  return (
    <div>
      <p>
        Quick access to <a href="#sources">Sources</a>,{" "}
        <a href="#behaviors">Behaviors</a>,{" "}
        <a href="#optimized-behaviors">Optimized Behaviors</a>,{" "}
        <a href="#advanced-options">Advanced Options</a>,{" "}
        <a href="#preview">Cache Preview</a>
      </p>
    </div>
  );
}
