import { gql } from "@apollo/client";
import { WEBSITE_SCRIPT_FRAGMENT } from "./websiteScriptFragment.gql";

export const CREATE_WEBSITE_SCRIPT = gql`
  ${WEBSITE_SCRIPT_FRAGMENT}
  mutation CreateJSCode($script: CreateJSCodeMutationInput!) {
    createJsCode(input: $script) {
      jsCode {
        ...WebsiteScriptFragment
      }
    }
  }
`;
