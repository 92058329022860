import { gql } from "@apollo/client";
import { SECTION_FIELDS_FRAGMENT } from "../section/sectionFieldsFragment.gql";
import { WEBSITE_VERSION_FRAGMENT } from "./websiteVersionFragment.gql";

export const GET_WEBSITE = gql`
  ${SECTION_FIELDS_FRAGMENT}
  ${WEBSITE_VERSION_FRAGMENT}
  query Website($id: String!) {
    website(id: $id) {
      id
      name
      beamDomain
      beamWebsiteId
      previewToken
      linksToReport
      linksToDoc
      apiKeys {
        id
        active
        scope
      }
      botifyAnalyticsInputs {
        id
        description
        device
        storageUri
        inputType
        automationUsername
        automationProject
        automationId
      }
      annotations {
        id
        date
        message
      }
      productionVersion {
        id
        ...WebsiteVersionMainFieldFragment
        sections {
          id
          name
        }
      }
      draftVersion {
        id
        ...WebsiteVersionMainFieldFragment
        inputs {
          id
          stableId
          inputType
          name
        }
        textListInputs {
          id
          stableId
          name
          nbUrls
        }
        linksDiscoveryInputs {
          id
          stableId
          name
          nbDays
        }
        sitemapInputs {
          id
          stableId
          name
          url
          mode
          nbDays
        }
        csvInputs {
          id
          stableId
          name
          url
          mode
          nbDays
        }
        botsDiscoveryInputs {
          id
          stableId
          name
          nbDays
        }
        sections {
          ...SectionFields
        }
        configs {
          id
          name
          action
          renderingRules
          extraHeaders
          emulationDevice
          userAgent
          timeout
          maxSpeed
        }
      }
      botifyProjectUser
      botifyProjectSlug
    }
  }
`;
