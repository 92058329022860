import { useQuery } from "@apollo/client";
import moment from "moment";
import { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";

import { DataHandler } from "../components/DataHandler";
import {
  ALL_WEBSITES_HEALTH,
  HealthQuery,
  HealthQueryVariables,
} from "../gql/websites/website";
import { StatusIcon } from "../website/components/StatusIcon";
import { Status } from "../website/typing";
import { Link } from "react-router-dom";

const HealthTable = () => {
  // Refresh component every 60s
  const { loading, error, data } = useQuery<HealthQuery, HealthQueryVariables>(
    ALL_WEBSITES_HEALTH,
    {
      pollInterval: 60000,
    }
  );

  if (error || loading || !data) {
    return (
      <DataHandler error={error} loading={loading} data={data} expectData />
    );
  }
  return (
    <Table striped bordered hover className="white" size="sm">
      <thead>
        <tr>
          <td>Website Name</td>
          <td>Interceptors Status</td>
          <td>Settings Status</td>
        </tr>
      </thead>
      <tbody>
        {data.allWebsites?.map((website) => {
          // Only display websites with at least interceptors or settings status
          if (
            website?.healthInterceptorStatus ||
            website?.healthSettingsStatus
          ) {
            return (
              <tr>
                <td>
                  <Link to={`/website/${website.id}`}>{website.name}</Link>
                </td>
                <td>
                  {website.healthInterceptorStatus ? (
                    <Link to={`/website/${website.id}/interceptors`}>
                      <StatusDisplay
                        status={website.healthInterceptorStatus}
                        from={website.healthInterceptorLastChecked}
                      />
                    </Link>
                  ) : null}
                </td>
                <td>
                  {website.healthSettingsStatus ? (
                    <Link to={`/website/${website.id}/settingsCheckerJob/last`}>
                      <StatusDisplay
                        status={website.healthSettingsStatus}
                        from={website.healthSettingsLastChecked}
                      />
                    </Link>
                  ) : null}
                </td>
              </tr>
            );
          }
          return null;
        })}
      </tbody>
    </Table>
  );
};

interface IStatusDisplayProps {
  status: Status;
  from?: string | null;
}

const StatusDisplay = ({ status, from }: IStatusDisplayProps) => (
  <>
    <StatusIcon status={status} />
    {moment(from).fromNow()}
  </>
);

export function Health() {
  useEffect(() => {
    document.title = "Health | Botify Activation";
  }, []);

  return (
    <Container id="monitoring">
      <h1>Websites Health</h1>
      <HealthTable />
    </Container>
  );
}
