import { gql } from "@apollo/client";

export const GET_WEBSITE_BA_CONFIG = gql`
  query WebsiteBaConfig($id: String!) {
    website(id: $id) {
      id
      name
      baDesktopCode
      baDesktopOnLoadCode
      baDesktopJsRules
      baDesktopJsConfig
      baMobileCode
      baMobileOnLoadCode
      baMobileJsRules
      baMobileJsConfig
    }
  }
`;
