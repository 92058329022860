import React from "react";

import QueryBuilder, { RuleGroupType } from "react-querybuilder";
import {
  ErrorRegExAlert,
  IErrorRegExAlert,
} from "../../components/feedback/ErrorRegExAlert";
import { validateRegExpInRules } from "../../helpers";
import { WidgetProps } from "@rjsf/utils";

export const ScopeWidget = ({
  label,
  value,
  onChange,
  setRegExRuleErrors,
  regExRuleErrors,
}: WidgetProps & {
  regExRuleErrors: IErrorRegExAlert;
  setRegExRuleErrors: React.Dispatch<React.SetStateAction<IErrorRegExAlert>>;
}) => {
  const FIELDS = [
    { name: "url", label: "URL" },
    { name: "protocol", label: "Protocol" },
    { name: "host", label: "Host" },
    { name: "path", label: "Path" },
    { name: "query_string", label: "Query String" },
    { name: "status_code", label: "HTTP Status Code" },
  ];

  const OPERATORS_MAIN = [
    { name: "equals", label: "Equals" },
    { name: "!equals", label: "Not Equals" },
  ];
  const OPERATORS_STR = [
    { name: "startsWith", label: "Starts With" },
    { name: "endsWith", label: "Ends With" },
    { name: "contains", label: "Contains" },
    { name: "!startsWith", label: "Doesn't Starts With" },
    { name: "!endsWith", label: "Doesn't Ends With" },
    { name: "!contains", label: "Doesn't Contains" },
    { name: "regex", label: "Matches Regexp" },
    { name: "!regex", label: "Doesn't Matches Regexp" },
  ];
  const OPERATORS_INT = [
    { name: "greaterThan", label: "Greater Than" },
    { name: "lowerThan", label: "Lower Than" },
    { name: "greaterThanOrEqual", label: "Greater Than Or Equal" },
    { name: "lowerThanOrEqual", label: "Lower Than Or Equal" },
  ];
  const updateScope = (scope: RuleGroupType) => {
    //const finalScope = scope ? scope: {combinator:"and", rules: []};
    setRegExRuleErrors(validateRegExpInRules(scope));

    onChange(JSON.stringify(scope));
  };
  const scope = value ? JSON.parse(value) : undefined;
  const getOperators = (field: string) => {
    if (field === "status_code") {
      return [...OPERATORS_MAIN, ...OPERATORS_INT];
    } else {
      return [...OPERATORS_MAIN, ...OPERATORS_STR];
    }
  };

  return (
    <>
      <label className="form-label">{label}</label>
      <ErrorRegExAlert {...regExRuleErrors} />
      <QueryBuilder
        fields={FIELDS}
        onQueryChange={updateScope}
        query={scope}
        controlElements={{
          // Prevent adding nested groups. Only 1 root group is supported.
          addGroupAction: () => null,
        }}
        getOperators={getOperators}
      />
    </>
  );
};
