import { gql } from "@apollo/client";

export const GET_WEBSITE_SETTINGS_CHECKER_LAST_JOB = gql`
  query WebsiteSettingsCheckerLastJob($id: String!) {
    website(id: $id) {
      id
      beamDomain
      beamWebsiteId
      previewToken
      lastJob: settingsCheckerJobs(size: 1, filterRunning: false) {
        id
        dateCreated
        status
        result
      }
    }
  }
`;
