import {
  MetricKey,
  allMetricKeys,
  DatabaseKey,
  allDatabaseKeys,
  PeriodKey,
  allPeriodKeys,
} from "./types";

export const isMetricKey = (maybeMetric: unknown): maybeMetric is MetricKey => {
  return (
    typeof maybeMetric === "string" &&
    allMetricKeys.some((key) => maybeMetric === key)
  );
};

export const isDatabaseKey = (
  maybeDatabase: unknown
): maybeDatabase is DatabaseKey => {
  return (
    typeof maybeDatabase === "string" &&
    allDatabaseKeys.some((key) => maybeDatabase === key)
  );
};

export const isPeriodKey = (maybePeriod: unknown): maybePeriod is PeriodKey => {
  return (
    typeof maybePeriod === "string" &&
    allPeriodKeys.some((key) => maybePeriod === key)
  );
};
