import { ApolloError } from "@apollo/client/errors";
import React, { useState, useRef } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import { ErrorBanner } from "../components/feedback";
import { UrlsListFormGroup } from "../components/form";
import { ForceUrlsRefreshResult } from "./forceUrlsRefreshResult";
import { ImpactAnalysisResult } from "./impactAnalysisResult";
import { type ForceRefreshStrategy } from "./types";
import { SectionSelect } from "./SectionSelect";
import { useForceRefresh } from "./useForceRefresh";
import {
  type ForceRefreshOptions,
  Mode,
  Device,
} from "../../gql/types.generated";

import "./forceRefresh.css";

interface IForceRefreshProps {
  websiteId: string;
}

export function ForceRefresh({ websiteId }: IForceRefreshProps) {
  const [strategy, setStrategy] = useState<ForceRefreshStrategy>("rawlist");
  const [urls, setUrls] = useState("");
  const [sectionId, setSectionId] = useState("");
  const priorityRef = useRef<HTMLInputElement>(null);
  const modeRef = useRef<HTMLInputElement>(null);
  const deviceRef = useRef<HTMLSelectElement>(null);
  const {
    analyzeImpact,
    isImpactAnalysisLoading,
    impactAnalysisData,
    impactAnalysisError,
    forceRefresh,
    isForceRefreshLoading,
    forceRefreshData,
    forceRefreshError,
  } = useForceRefresh(strategy);

  const forceRefreshToken = impactAnalysisData?.forceRefreshToken;

  const isImpactAnalysisDisabled = isImpactAnalysisLoading;
  const isForceRefreshDisabled = !forceRefreshToken || isForceRefreshLoading;

  const handleSubmitImpactAnalysis = async () => {
    try {
      await analyzeImpact({
        variables: {
          websiteId,
          urls, // rawlist only
          sectionId, // section only
        },
      });
    } catch (e) {}
  };

  const handleSubmitForceRefresh = async () => {
    if (!forceRefreshToken) {
      return;
    }

    const options: ForceRefreshOptions = {
      priority: priorityRef.current?.checked ? 0 : undefined,
      mode: modeRef.current?.checked ? Mode.PurgeRefresh : undefined,
      device: (deviceRef.current?.value as Device) ?? Device.Auto,
    };

    try {
      await forceRefresh({
        variables: {
          websiteId,
          urls, // rawlist only
          sectionId, // section only
          forceRefreshToken,
          options,
        },
      });
    } catch (e) {}
  };

  return (
    <div>
      <div className="lead">
        <p>
          Manually refresh cache for some URLs when some pages are outdated and
          you can't wait for them to be normally refreshed according to their
          behavior configuration.
        </p>
        <p>
          N.B. The result of the impact analysis may display that you are about
          to refresh less urls than you provided. It is normal and the result of
          some optimizations such as deduplication.
        </p>
      </div>

      <ForceRefreshErrors
        errorImpactAnalysis={impactAnalysisError}
        errorForceUrlsRefresh={forceRefreshError}
      />

      {impactAnalysisData ? (
        <ImpactAnalysisResult analysis={impactAnalysisData} />
      ) : null}
      {forceRefreshData ? (
        <ForceUrlsRefreshResult forceRefresh={forceRefreshData} />
      ) : null}

      <Form>
        <Form.Group>
          <Form.Label>What to refresh</Form.Label>
          <Form.Control
            as="select"
            value={strategy}
            onChange={(e) =>
              setStrategy(e.target.value as ForceRefreshStrategy)
            }
          >
            <option value="rawlist">A raw list of urls</option>
            <option value="section">All urls matching a behavior</option>
          </Form.Control>
        </Form.Group>

        {strategy === "rawlist" ? (
          <UrlsListFormGroup
            id="urls"
            label="Urls to refresh"
            value={urls}
            onChange={(event) => setUrls(event.target.value)}
            required
          />
        ) : null}

        {strategy === "section" ? (
          <SectionSelect
            websiteId={websiteId}
            value={sectionId}
            onChange={setSectionId}
          />
        ) : null}

        <Form.Group controlId="checkbox-priority">
          <Form.Check
            type="checkbox"
            label="Refresh these pages first (e.g. emergency)"
            ref={priorityRef}
          />
        </Form.Group>
        <Form.Group controlId="checkbox-mode">
          <Form.Check
            type="checkbox"
            label="Don't deliver these pages until they are refreshed"
            ref={modeRef}
          />
        </Form.Group>
        <Form.Group
          controlId="device-select"
          className="device-select-container"
        >
          <Form.Label>Device to refresh</Form.Label>
          <Form.Control as="select" required ref={deviceRef}>
            <option value="auto">auto</option>
            <option value="mobile">mobile only</option>
            <option value="desktop">desktop only</option>
          </Form.Control>
        </Form.Group>

        <div className="button-container">
          <Button
            className="submit-btn"
            disabled={isImpactAnalysisDisabled}
            onClick={handleSubmitImpactAnalysis}
          >
            {isImpactAnalysisLoading ? "Analysing..." : "Analyse impacts"}
          </Button>
          <ButtonTooltip
            tooltipMessage={
              isForceRefreshDisabled ? "You must analyse impact first" : ""
            }
            tooltipId="tooltip-force-refresh"
            forceShow={!isForceRefreshDisabled ? false : undefined}
          >
            <Button
              className="submit-btn"
              disabled={isForceRefreshDisabled}
              onClick={handleSubmitForceRefresh}
            >
              {isForceRefreshLoading ? "Loading..." : "Force refresh"}
            </Button>
          </ButtonTooltip>
        </div>
      </Form>
    </div>
  );
}

interface IForceRefreshErrorsProps {
  errorImpactAnalysis?: ApolloError;
  errorForceUrlsRefresh?: ApolloError;
}

function ForceRefreshErrors({
  errorImpactAnalysis,
  errorForceUrlsRefresh,
}: IForceRefreshErrorsProps) {
  if (errorImpactAnalysis) {
    return (
      <ErrorBanner
        message="Error during impact analysis"
        error={errorImpactAnalysis}
      />
    );
  }

  if (errorForceUrlsRefresh) {
    return (
      <ErrorBanner
        message="Error during force refresh"
        error={errorForceUrlsRefresh}
      />
    );
  }

  return null;
}

interface ITooltipButtonProps {
  children: React.ReactElement;
  tooltipMessage: string;
  tooltipId: string;
  forceShow?: boolean;
}

function ButtonTooltip({
  children,
  tooltipMessage,
  tooltipId,
  forceShow,
}: ITooltipButtonProps) {
  return (
    <OverlayTrigger
      placement="auto"
      overlay={<Tooltip id={tooltipId}>{tooltipMessage}</Tooltip>}
      show={forceShow}
    >
      {children}
    </OverlayTrigger>
  );
}
