import { useState } from "react";
import { Form } from "react-bootstrap";

interface IUrlsListError {
  value: string;
  line: number;
}

interface IUrlsListFormGroup {
  id: string;
  label: string;
  value: string;
  onChange: (
    event: React.ChangeEvent<HTMLTextAreaElement>,
    errors: IUrlsListError[]
  ) => void;
  required?: boolean;
}

export const UrlsListFormGroup = ({
  id,
  label,
  value,
  onChange,
  required,
}: IUrlsListFormGroup) => {
  const parseInput = (value: string) =>
    parseUrlsInput(value, { allowEmpty: !required });

  const [{ urls, errors }, setParsedUrls] = useState(parseInput(value));
  const hasErrors = errors.length !== 0;
  const helpId = `${id}Help`;

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const parsed = parseInput(event.target.value);
    setParsedUrls(parsed);
    onChange(event, parsed.errors);
  };

  return (
    <Form.Group controlId={id}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        aria-describedby={helpId}
        as="textarea"
        rows={10}
        value={value}
        onChange={handleChange}
        isInvalid={hasErrors}
        required={required}
      />
      <Form.Text id={helpId} muted>
        One URL per line {!hasErrors && <span>- {urls.length} URL(s)</span>}
      </Form.Text>

      {hasErrors && (
        <Form.Control.Feedback type="invalid">
          Invalid URL <code>{errors[0].value}</code> on line{" "}
          <code>{errors[0].line}</code>
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};

interface IParsedUrls {
  urls: string[];
  errors: IUrlsListError[];
}

const parseUrlsInput = (
  value: string,
  options: { allowEmpty: boolean }
): IParsedUrls => {
  const urls = value !== "" ? value.split("\n") : [];
  const errors = validateUrls(urls, options);
  return { urls, errors };
};

const validateUrls = (
  urls: string[],
  { allowEmpty }: { allowEmpty: boolean }
): IUrlsListError[] => {
  if (urls.length === 0 && !allowEmpty) {
    return [emptyListError()];
  }
  return urls
    .map((url, i) => ({ value: url, line: i + 1 })) // map first to get correct line
    .filter((url) => !isValidUrl(url.value));
};

const emptyListError = (): IUrlsListError => ({ value: "", line: 1 });

const isValidUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch {
    return false;
  }
};
