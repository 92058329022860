import { useQuery } from "@apollo/client";
import { pick, sumBy } from "lodash";
import moment from "moment";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";

import { reducebyDimensionsFn, StackedBarChart } from "../common/charts";
import { NumberKeyOf } from "../common/typing/utils";
import { DataHandler } from "../components/DataHandler";
import {
  QueryInventoryStatsResult,
  WebsiteInventoryStatsResultType,
} from "../gql/types.generated";
import {
  GET_WEBSITE_INVENTORY_HISTORY,
  WebsiteInventoryHistoryQuery,
  WebsiteInventoryHistoryQueryVariables,
} from "../gql/websites/website";
import { Header } from "./components/header";
import "./inventory.css";
import { WebsiteIdParams } from "./types/routeParams";
import { useParams } from "react-router-dom";

interface IAggregatedResults
  extends Pick<
    WebsiteInventoryStatsResultType,
    | "count"
    | "countInIndex"
    | "countInStale"
    | "countExpired"
    | "countNotIndexed"
  > {}

const aggregateResults = (
  result: IAggregatedResults,
  entries: IAggregatedResults[]
) => {
  result.count = sumBy(entries, "count");
  result.countInIndex = sumBy(entries, "countInIndex");
  result.countInStale = sumBy(entries, "countInStale");
  result.countExpired = sumBy(entries, "countExpired");
  result.countNotIndexed = sumBy(entries, "countNotIndexed");
};

export const flattenByMetrics = (
  results: QueryInventoryStatsResult[],
  dimensions: (keyof QueryInventoryStatsResult)[],
  metrics: NumberKeyOf<Required<QueryInventoryStatsResult>>[]
) =>
  results.flatMap((result) =>
    metrics.map((metric) => ({
      ...pick(result, dimensions),
      metric,
      count: result[metric],
    }))
  );

export function WebsiteInventoryHistory() {
  const { websiteId } = useParams<WebsiteIdParams>() as WebsiteIdParams;

  const dateEnd = moment().format("YYYY-MM-DD");
  const dateStart = moment().subtract(120, "days").format("YYYY-MM-DD");

  const { loading, error, data } = useQuery<
    WebsiteInventoryHistoryQuery,
    WebsiteInventoryHistoryQueryVariables
  >(GET_WEBSITE_INVENTORY_HISTORY, {
    variables: {
      id: websiteId,
      dateStart: dateStart,
      dateEnd: dateEnd,
    },
  });

  const queryInventoryStats = data?.website?.queryInventoryStats;

  if (error || loading || !queryInventoryStats) {
    return (
      <DataHandler
        error={error}
        loading={loading}
        data={queryInventoryStats}
        expectData
      />
    );
  }

  return (
    <div>
      <Header.Title websiteId={websiteId} name="Inventory" />
      <ChartCount queryInventoryStats={queryInventoryStats} />
      <ChartBySection queryInventoryStats={queryInventoryStats} />
    </div>
  );
}

interface IChartProps {
  queryInventoryStats: QueryInventoryStatsResult[];
}

function ChartCount({ queryInventoryStats }: IChartProps) {
  const newResults = flattenByMetrics(
    reducebyDimensionsFn({
      data: queryInventoryStats,
      dimensions: ["date"],
      fn: aggregateResults,
    }),
    ["date"],
    ["countInIndex", "countInStale", "countExpired", "countNotIndexed"]
  );

  return (
    <Card>
      <Card.Header>Inventory</Card.Header>
      <Card.Body>
        <Row className="websiteHomeChart">
          <StackedBarChart
            data={newResults}
            dimension="date"
            splitBy={["metric"]}
            metric="count"
            colors={{
              countInIndex: "green",
              countInStale: "orange",
              countExpired: "red",
              countNotIndexed: "gray",
            }}
            xTitle="Date"
            yTitle="Number of Pages"
          />
        </Row>
      </Card.Body>
    </Card>
  );
}

function ChartBySection({ queryInventoryStats }: IChartProps) {
  return (
    <Card>
      <Card.Header>Inventory By Behavior</Card.Header>
      <Card.Body>
        <Row className="websiteHomeChart">
          <StackedBarChart
            data={queryInventoryStats}
            dimension="date"
            splitBy={["sectionName"]}
            metric="count"
            colors={{ true: "#23c451", false: "#F84F30" }}
            xTitle="Date"
            yTitle="Number of Pages"
          />
        </Row>
      </Card.Body>
    </Card>
  );
}
