import { gql } from "@apollo/client";

export const WEBSITE_INTERCEPTOR_CHECKER_CONFIGS = gql`
  query WebsiteInterceptorCheckerConfigs($id: String!) {
    website(id: $id) {
      id
      interceptorCheckerConfigs {
        id
        name
        platform
        scopeRoute
        urlCacheHit
        urlOutOfScope
        urlAmp
        ignoredParams
        interceptorTimeout
        userAgentTestBot
        userAgentBrowser
        maxParallel
        maxSpeed
        switchToAkamaiStaging
        checksToPerform
        extraHeaders
        lastJob {
          id
          dateCreated
          status
          errorMessage
          result
          testStatus
        }
      }
    }
  }
`;
