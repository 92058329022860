import { Link } from "react-router-dom";
import { useAdvancedMode } from "../../common/hooks";
import { Button } from "react-bootstrap";

export function ModeSwitcher() {
  const { isAdvancedMode, setIsAdvancedMode, standardModeOnly } =
    useAdvancedMode();

  return (
    <ul className="nav">
      <li className="nav-item">
        <Link to="/website/create">Create a new Website</Link>
      </li>
      {standardModeOnly ? null : (
        <li className="nav-item">
          <Button
            variant="link"
            onClick={() => setIsAdvancedMode(!isAdvancedMode)}
          >
            Use {isAdvancedMode ? "Standard" : "Advanced"} Mode
          </Button>
        </li>
      )}
      <li className="nav-item">
        <Link to="/logout">Logout</Link>
      </li>
    </ul>
  );
}
