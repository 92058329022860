import { gql } from "@apollo/client";

export const GET_BING_CONTENT_SUBMISSION_MONITORING = gql`
  query BingContentSubmissionMonitoring(
    $id: String!
    $timestampStart: Int!
    $timestampEnd: Int!
  ) {
    website(id: $id) {
      id
      queryContentSubmitPages(
        timestampStart: $timestampStart
        timestampEnd: $timestampEnd
        orderBy: ["date"]
        dimensions: ["service", "device", "date", "succeed", "errorCode"]
      ) {
        service
        device
        date
        succeed
        errorCode
        countSubmissions
      }
    }
  }
`;
