import { gql } from "@apollo/client";

export const GET_WEBSITE_SETTINGS_CHECKER_JOBS = gql`
  query WebsiteSettingsCheckerJobs($id: String!) {
    website(id: $id) {
      id
      lastSettingsCheckerJob: settingsCheckerJobs(
        size: 1
        filterRunning: false
      ) {
        id
        dateCreated
        status
        result
      }
      runningSettingsCheckerJob: settingsCheckerJobs(
        size: 1
        filterDone: false
      ) {
        id
        dateCreated
        status
      }
      interceptorCheckerConfigs: interceptorCheckerConfigs {
        id
        lastJob {
          id
          dateCreated
          testStatus
        }
      }
    }
  }
`;
