import type {
  QueryServedPagesResult,
  QueryQueryServedPagesArgs,
} from "../../../gql/types.generated";
import type { IMonitoringOptions } from "../../types";
import { useQuery } from "@apollo/client";
import { Row, Col } from "react-bootstrap";
import { getQuery } from "../../Helpers";
import { DataHandler } from "../../../components/DataHandler";

interface IMainCardProps {
  options: IMonitoringOptions;
}

export function MainCard({ options }: IMainCardProps) {
  const { loading, error, data } = useQuery<
    {
      queryServedPages: Pick<
        QueryServedPagesResult,
        "countHits" | "countUrls"
      >[];
    },
    QueryQueryServedPagesArgs
  >(
    getQuery({ name: "MainMetricsQuery", metrics: ["countHits", "countUrls"] }),
    {
      variables: {
        timestampStart: options.timestampStartLast24h,
        timestampEnd: options.timestampEnd,
      },
    }
  );

  if (error || loading || !data) {
    return (
      <DataHandler error={error} loading={loading} data={data} expectData />
    );
  }

  return (
    <>
      <Row className="metric">
        <Col className="text-center metricName"># Hits</Col>
        <Col className="text-center metricName"># URLs</Col>
      </Row>
      <Row className="metric">
        <Col className="text-center metricValue">
          {data.queryServedPages[0].countHits}
        </Col>
        <Col className="text-center metricValue">
          {data.queryServedPages[0].countUrls}
        </Col>
      </Row>
    </>
  );
}
