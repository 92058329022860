import { useQuery } from "@apollo/client";
import { Table } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer-continued";
import JSONPretty from "react-json-pretty";
import "react-json-pretty/themes/monikai.css";

import { sortConfig } from "../../common/object";
import { getNonNullList } from "../../common/typings";
import { DataHandler } from "../../components/DataHandler";
import { JobType } from "../../gql/types.generated";
import {
  GET_WEBSITE_DEBUG_CONFIG,
  GET_WEBSITE_QUERY_JOBS,
  WebsiteDebugConfigQuery,
  WebsiteDebugConfigQueryVariables,
  WebsiteQueryJobsQuery,
  WebsiteQueryJobsQueryVariables,
} from "../../gql/websites/website";
import { Header } from "../components/header";
import { WebsiteIdParams } from "../types/routeParams";
import { useParams } from "react-router-dom";

var JSONPrettyMon = require("react-json-pretty/dist/monikai");

interface IBatchesCardProps {
  websiteId: string;
}

function BatchesCard({ websiteId }: IBatchesCardProps) {
  const queryFilters = JSON.stringify([
    { field: "unique_name", predicate: "startsWith", value: "botifybatch" },
  ]);
  const { loading, error, data } = useQuery<
    WebsiteQueryJobsQuery,
    WebsiteQueryJobsQueryVariables
  >(GET_WEBSITE_QUERY_JOBS, {
    variables: {
      id: websiteId,
      filters: queryFilters,
    },
  });

  const jobs = data?.website?.queryJobs
    ? getNonNullList<JobType>(data?.website?.queryJobs)
    : null;
  if (jobs) {
    jobs.sort(function (a, b) {
      if (!a.dateCreated || !b.dateCreated) {
        return 0;
      }
      if (a.dateCreated > b.dateCreated) {
        return -1;
      }
      if (b.dateCreated > a.dateCreated) {
        return 1;
      }
      return 0;
    });
  }

  return (
    <Card>
      <Card.Header>Batches</Card.Header>
      <Card.Body>
        {loading && "Loading"}
        {error && `Error! ${error}`}

        {!loading && !error && jobs && jobs.length > 0 && (
          <Table striped bordered hover className="white" size="sm">
            <thead>
              {" "}
              <tr>
                <td>Date Started</td>
                <td>Date Finished</td>
                <td># URLs</td>
                <td>Device</td>
                <td>Config ID</td>
                <td>Link to Botify Admin</td>
              </tr>
            </thead>
            {jobs.map((entry) => {
              const jobParams = JSON.parse(entry.lastJobParams || "{}");

              return (
                <tr>
                  <td>{entry.dateCreated}</td>
                  <td>{entry.dateFinished}</td>
                  <td>{jobParams.urlsCount}</td>
                  <td>{jobParams.device}</td>
                  <td>
                    <a
                      href={
                        "/website/" +
                        websiteId +
                        "/config/" +
                        jobParams.configID
                      }
                    >
                      {jobParams.configID}
                    </a>
                  </td>
                  <td>
                    <a
                      href={
                        "https://app.botify.com/admin/ftl/ftlbatch/" +
                        jobParams.botifyBatchID +
                        "/change/"
                      }
                    >
                      Link to Botify Admin
                    </a>
                  </td>
                </tr>
              );
            })}
          </Table>
        )}
      </Card.Body>
    </Card>
  );
}

export function WebsiteDebug() {
  const { websiteId } = useParams<WebsiteIdParams>() as WebsiteIdParams;
  const { loading, error, data } = useQuery<
    WebsiteDebugConfigQuery,
    WebsiteDebugConfigQueryVariables
  >(GET_WEBSITE_DEBUG_CONFIG, {
    variables: { id: websiteId },
  });

  const debugConfig = data?.website?.debugConfig;

  if (error || loading || !debugConfig) {
    return (
      <DataHandler
        error={error}
        loading={loading}
        data={debugConfig}
        expectData
      />
    );
  }

  return (
    <div>
      <Header.Title websiteId={websiteId} name="Debug" />
      <BatchesCard websiteId={websiteId} />
      <Card>
        <Card.Header>Production Main Config</Card.Header>
        <Card.Body>
          <JSONPretty data={debugConfig.mainConfig} theme={JSONPrettyMon} />
        </Card.Body>
      </Card>

      <Card>
        <Card.Header>Draft Main Config</Card.Header>
        <Card.Body>
          <JSONPretty
            data={debugConfig.draftMainConfig}
            theme={JSONPrettyMon}
          />
        </Card.Body>
      </Card>

      <Card>
        <Card.Header>Versions Diff</Card.Header>
        <Card.Body>
          {debugConfig.mainConfig && debugConfig.draftMainConfig ? (
            <ReactDiffViewer
              oldValue={JSON.stringify(
                sortConfig(JSON.parse(debugConfig.mainConfig)),
                null,
                2
              )}
              newValue={JSON.stringify(
                sortConfig(JSON.parse(debugConfig.draftMainConfig)),
                null,
                2
              )}
              compareMethod={DiffMethod.WORDS}
              splitView
            />
          ) : (
            <>Error !</>
          )}
        </Card.Body>
      </Card>

      <Card>
        <Card.Header>Inputs Config</Card.Header>
        <Card.Body>
          <JSONPretty data={debugConfig.inputsConfig} theme={JSONPrettyMon} />
        </Card.Body>
      </Card>

      <Card>
        <Card.Header>Rendering JS Config</Card.Header>
        <Card.Body>
          <JSONPretty
            data={debugConfig.renderingJsConfig}
            theme={JSONPrettyMon}
          />
        </Card.Body>
      </Card>

      <Card>
        <Card.Header>Delivery JS Config</Card.Header>
        <Card.Body>
          <JSONPretty
            data={debugConfig.deliveryJsConfig}
            theme={JSONPrettyMon}
          />
        </Card.Body>
      </Card>
    </div>
  );
}
