import { gql } from "@apollo/client";

export const GET_WEBSITE_INVENTORY_HISTORY = gql`
  query WebsiteInventoryHistory(
    $id: String!
    $dateStart: Date!
    $dateEnd: Date!
  ) {
    website(id: $id) {
      id
      beamDomain
      beamWebsiteId
      previewToken
      allTimeConfigs {
        stableId
        name
      }
      allTimeInputs {
        stableId
        name
        inputType
        lastVersion
      }
      queryInventoryStats(
        dimensions: ["date", "configId", "sectionName", "priority"]
        dateStart: $dateStart
        dateEnd: $dateEnd
        orderBy: ["date"]
      ) {
        date
        sectionName
        priority
        configId
        count
        countInIndex
        countInStale
        countExpired
        countNotIndexed
      }
    }
  }
`;
