import { useLocation, useNavigate } from "react-router-dom";

const PAGE_SIZE = 20;
const PAGE_PARAM_KEY = "page";

export function usePagination() {
  const navigate = useNavigate();
  const { search } = useLocation();

  const searchParams = new URLSearchParams(search);
  const pageParam = searchParams.get(PAGE_PARAM_KEY) ?? "1";
  const page = parseInt(pageParam, 10);

  const currentParams = Object.fromEntries(
    new URLSearchParams(search).entries()
  );

  return {
    page: Number.isInteger(page) ? page : 1,
    setPage: (newPage: number) => {
      navigate({
        search: new URLSearchParams({
          ...currentParams,
          [PAGE_PARAM_KEY]: `${newPage}`,
        }).toString(),
      });
    },
    pageSize: PAGE_SIZE
  }
}
