import { gql } from "@apollo/client";

export const GET_WEBSITE_INDEXATION_MONITORING = gql`
  query WebsiteIndexationMonitoring(
    $id: String!
    $timestampStart: Int!
    $timestampEnd: Int!
  ) {
    website(id: $id) {
      id
      name
      queryIndexedPages(
        timestampStart: $timestampStart
        timestampEnd: $timestampEnd
        orderBy: ["date"]
        dimensions: [
          "httpCode"
          "device"
          "sectionName"
          "date"
          "notDeliverableReason"
          "indexed"
        ]
      ) {
        httpCode
        device
        sectionName
        ttlRange
        date
        count
        indexed
        notDeliverableReason
      }
    }
  }
`;
