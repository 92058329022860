import { Container } from "react-bootstrap";

export function Error404Page() {
  return (
    <Container>
      <h2>Oops!</h2>
      <div> 404 Page not found</div>
    </Container>
  );
}
