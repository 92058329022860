import { JsCodeType } from "../../../../../gql/types.generated";
import { InlineList } from "./InlineList";

export const SectionsCell = ({
  sections,
  applyOnWebsite,
}: Pick<JsCodeType, "applyOnWebsite" | "sections">) =>
  applyOnWebsite ? (
    <>Whole website</>
  ) : (
    <InlineList entries={sections} render={({ name }) => name} />
  );
