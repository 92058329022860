import { gql } from "@apollo/client";

export const GET_WEBSITES_WITH_ADN = gql`
  query GetWebsitesWithADN {
    allWebsites {
      id
      name
      productionVersion {
        id
        hosts
      }
    }
    lastAdnConfigStatuses {
      id
      status
      environment
      createdDate
      adnConfig {
        id
        workerVersion
        website {
          id
        }
      }
    }
  }
`;
