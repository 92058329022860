import React from "react";
import Alert from "react-bootstrap/Alert";
import { type ForceRefreshErrorType } from "../../gql/types.generated";

interface IForceRefreshResultErrorsProps {
  errors: ForceRefreshErrorType[];
}

export function ForceRefreshResultErrors({
  errors,
}: IForceRefreshResultErrorsProps) {
  return (
    <Alert variant="danger">
      Errors:
      <br />
      <pre>
        {errors.map((error, idx) => (
          <React.Fragment key={`error-${idx}`}>
            {error.message}
            <br />
          </React.Fragment>
        ))}
      </pre>
    </Alert>
  );
}
