import { useMutation } from "@apollo/client";
import { useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useLocalStorageSynchronizedState } from "../../../../common/hooks";
import { PreviewerConfigDevice } from "../../../../gql/types.generated";
import {
  PreviewJsMutation,
  PreviewJsMutationVariables,
  PREVIEW_JS,
} from "../../../../gql/websites/website";
import { WebsiteIdParams } from "../../../types/routeParams";
import { CodeByStep } from "../ScriptEditor";
import { codeByStepToPreviewJsInput } from "./mappers";
import { addBaseTagToHtml, IPreviewState, PREVIEW_DEVICES } from "./preview";

type JsPreviewFormProps = {
  script: { codeByStep: CodeByStep; id?: string; name?: string };
  setPreview: (preview: IPreviewState | null) => void;
  disabled: boolean;
};

export const JsPreviewForm = ({
  script,
  setPreview,
  disabled,
}: JsPreviewFormProps) => {
  const websiteId = useParams<WebsiteIdParams>().websiteId!;
  const [previewDevice, setPreviewDevice] = useState(PREVIEW_DEVICES[0].value);
  const [previewUrl, setPreviewUrl] = useLocalStorageSynchronizedState({
    key: "htmlEditPreviewURL" + websiteId,
    defaultValue: "",
  });

  const [submitCompileJs, { loading }] = useMutation<
    PreviewJsMutation,
    PreviewJsMutationVariables
  >(PREVIEW_JS, {
    onCompleted({ previewJs }) {
      if (!!previewJs?.stats && typeof previewJs?.html === "string")
        setPreview({
          ...previewJs,
          url: previewUrl,
          stats: JSON.parse(previewJs.stats ?? "{}"),
          html: addBaseTagToHtml(previewJs.html ?? "", previewUrl),
        });
    },
  });

  const submitPreview = () => {
    setPreview(null);
    submitCompileJs({
      variables: {
        input: {
          website: websiteId,
          device: previewDevice,
          url: previewUrl,

          ...codeByStepToPreviewJsInput(script),
        },
      },
    });
  };

  return (
    <div style={{ width: "100%", display: "flex", gap: 8 }}>
      <Form.Control
        type="text"
        placeholder="Enter an URL"
        onChange={(e) => setPreviewUrl(e.target.value)}
        value={previewUrl}
      />
      <Form.Control
        as="select"
        value={previewDevice}
        onChange={(e) =>
          setPreviewDevice(e.currentTarget.value as PreviewerConfigDevice)
        }
      >
        {PREVIEW_DEVICES.map(({ value, label }) => (
          <option key={value} value={value}>
            {label}
          </option>
        ))}
      </Form.Control>
      <Button
        variant="outline-primary"
        onClick={submitPreview}
        disabled={disabled || loading}
      >
        {loading ? (
          <Spinner animation="border" size="sm" />
        ) : (
          <span className="text-nowrap">Preview configuration</span>
        )}
      </Button>
    </div>
  );
};
