import { useEffect } from "react";
import { useQuery } from "@apollo/client";
import Form from "react-bootstrap/Form";
import { DataHandler } from "../../components/DataHandler/DataHandler";
import {
  GET_PRODUCTION_SECTION_SUMMARIES,
  GetProductionSectionSummariesQueryVariables,
  GetProductionSectionSummariesQuery,
} from "../../gql/websites/section";
import { SectionMode } from "../../gql/types.generated";

interface ISectionSelectProps {
  websiteId: string;
  value: string;
  onChange: (value: string) => void;
}

export function SectionSelect({
  websiteId,
  value,
  onChange,
}: ISectionSelectProps) {
  const { loading, error, data } = useQuery<
    GetProductionSectionSummariesQuery,
    GetProductionSectionSummariesQueryVariables
  >(GET_PRODUCTION_SECTION_SUMMARIES, { variables: { websiteId } });

  const sections = data?.website?.productionVersion?.sections;
  const useableSections = sections?.filter(
    (section) => section.mode === SectionMode.Allow
  );
  const firstSectionId = useableSections?.[0].id;

  // Update selected option on load
  useEffect(() => {
    if (!value && firstSectionId) {
      onChange(firstSectionId);
    }
  }, [value, firstSectionId, onChange]);

  if (error || loading || !useableSections) {
    return (
      <DataHandler
        error={error}
        loading={loading}
        data={useableSections}
        noDataMessage="No behaviors found"
        expectData
      />
    );
  }

  return (
    <Form.Group>
      <Form.Label>Behavior to refresh</Form.Label>
      <Form.Control
        as="select"
        name="sectionId"
        value={value || firstSectionId} // value may be empty string
        onChange={(e) => onChange(e.target.value)}
      >
        {useableSections.map((section) => {
          return (
            <option key={section.id} value={section.id}>
              {section.name} ({section.id})
            </option>
          );
        })}
      </Form.Control>
    </Form.Group>
  );
}
