import { useQuery } from "@apollo/client";
import React, { ReactNode } from "react";

import { DataHandler } from "../../../components/DataHandler";
import { WebsiteType } from "../../../gql/types.generated";
import {
  GET_WEBSITE,
  WebsiteQuery,
  WebsiteQueryVariables,
} from "../../../gql/websites/website";
import { Link } from "react-router-dom";

interface IHeaderProps {
  websiteId: string;
  name: string | React.ReactElement;
}

export const Title = ({ websiteId, name }: IHeaderProps) => {
  const { loading, error, data } = useQuery<
    WebsiteQuery,
    WebsiteQueryVariables
  >(GET_WEBSITE, {
    variables: { id: websiteId },
  });

  const website = data?.website;

  if (error || loading || !website) {
    return (
      <DataHandler error={error} loading={loading} data={website} expectData />
    );
  }

  return <BreadcrumbTitle website={website} steps={[name]} />;
};

interface IBreadcrumbTitleProps {
  website: Pick<WebsiteType, "id" | "name">;
  steps: ReactNode[];
}

/**
 * Renders a breadcrumb for the given steps, starting with a link
 * to the given website.
 */
export const BreadcrumbTitle = ({ website, steps }: IBreadcrumbTitleProps) => (
  <h1>
    <Link to={`/website/${website.id}`}>{website.name}</Link>
    {steps.reduce<ReactNode[]>((acc, cur) => [...acc, " > ", cur], [])}
  </h1>
);
