import { useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import moment from "moment";
import { Card, Table } from "react-bootstrap";

import { capitalize, snakeToCamelCase } from "../../common/str";
import { DataHandler } from "../../components/DataHandler";
import {
  ModulesListType,
  ModuleType,
  WebsiteType,
} from "../../gql/types.generated";
import { Header } from "../components/header";
import { Messager } from "../components/messager";
import { WebsiteIdParams } from "../types/routeParams";
import { Link, useParams } from "react-router-dom";

const sortPriority = (a: ModuleType, b: ModuleType) => {
  if (a.priority < b.priority) {
    return -1;
  }
  return 1;
};

export function Home() {
  const { websiteId } = useParams<WebsiteIdParams>() as WebsiteIdParams;
  const query = gql`
    query Website($id: String!) {
      website(id: $id) {
        id
        modulesTypes {
          id
          name
          jsonschema
        }
        modules {
          id
          stableId
          priority
          name
          description
          moduleType
          versionNum
          dateLastEdited
          productionModule {
            id
            versionNum
            versionDatePublished
          }
        }
      }
    }
  `;

  const { loading, error, data } = useQuery<{ website: WebsiteType }>(query, {
    variables: { id: websiteId },
    fetchPolicy: "no-cache",
  });

  if (error || loading) return <DataHandler error={error} loading={loading} />;

  const ModulesTypesIdx: Record<ModulesListType["id"], ModulesListType> = {};
  data?.website?.modulesTypes?.map(
    (mt: ModulesListType) => (ModulesTypesIdx[mt.id] = mt)
  );

  return (
    <div>
      <Header.Title websiteId={websiteId} name="Modules" />
      <Messager />
      <Card id="behaviors">
        <Card.Header>All Installed Modules</Card.Header>
        <Card.Body className="card-with-table">
          <Table
            striped
            borderless
            hover
            border-left-0="true"
            border-right-0="true"
          >
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Priority</th>
                <th>Version</th>
              </tr>
            </thead>
            <tbody>
              {data?.website.modules.sort(sortPriority).map((module) => {
                const moduleTypeCamel = capitalize(
                  snakeToCamelCase(module.moduleType.toLowerCase())
                );

                return (
                  <tr key={module.id}>
                    <td>
                      <Link
                        to={`/website/${data?.website?.id}/modules/${moduleTypeCamel}/${module.stableId}`}
                      >
                        {module.name}
                      </Link>
                      {module.dateLastEdited && (
                        <>
                          <br />
                          <small>
                            Last modified :{" "}
                            {moment(module.dateLastEdited).fromNow()}
                          </small>
                        </>
                      )}
                    </td>

                    <td>{ModulesTypesIdx[moduleTypeCamel].name}</td>
                    <td>{module.priority}</td>
                    <td>
                      {module.productionModule?.versionNum}
                      {module.productionModule?.versionDatePublished && (
                        <>
                          <br />
                          <small>
                            Last published :{" "}
                            {moment(
                              module.productionModule.versionDatePublished
                            ).fromNow()}
                          </small>
                        </>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card.Body>
        <Card.Body>
          <Link
            role="button"
            to={`/website/${data?.website?.id}/modules/create`}
            className="btn btn-primary"
          >
            Create new Module
          </Link>
        </Card.Body>
      </Card>
      <SetupCard websiteId={websiteId} />
    </div>
  );
}

interface ISetupCardProps {
  websiteId: string;
}

const SetupCard = ({ websiteId }: ISetupCardProps) => {
  return (
    <Card>
      <Card.Header>Preview (Fake it until you install it)</Card.Header>
      <Card.Body>
        <p>
          You can preview all the modules you put on production by simply
          injecting PageWorkers JS Tag with a chrome plugin
        </p>

        <p>
          Step 1. Install{" "}
          <a
            href="https://chrome.google.com/webstore/detail/code-injector/edkcmfocepnifkbnbkmlcmegedeikdeb"
            target="_blank"
            rel="noreferrer"
          >
            Code Injector
          </a>{" "}
          Chrome-Plugin
        </p>

        <p>Step 2. A a new Rule</p>

        <p>
          <img src="/pw_setup/step1.png" alt="Add new rule" width="400px" />
        </p>

        <p>Step 3. Click on "Files" section</p>

        <p>
          <img
            src="/pw_setup/step2.png"
            alt="Click Files section"
            width="400px"
          />
        </p>

        <p>Step 4. On Host field, fill with your website's domain</p>

        <p>
          Step 5. On file field, paste the following URL:{" "}
          <kbd>https://tags.pw.adn.cloud/{websiteId}/activation.js</kbd>
        </p>

        <p>Step 6. Save and enjoy while navigating on your website!</p>
      </Card.Body>
    </Card>
  );
};
