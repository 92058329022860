import { Maybe } from "graphql/jsutils/Maybe";
import {
  AllClustersQuery,
} from "../../gql/websites/clusters";

const SW_DEMO_CLUSTER_ID = "4";
const PW_DEMO_CLUSTER_ID = "2";

export function getPwClustersDropdownOptions(
  isStandardModeInBetaEnv: boolean,
  pwClusters: Maybe<AllClustersQuery["allPageworkersClusters"]>
) {
  if (!pwClusters) {
    return [];
  }

  const clustersToDisplay = isStandardModeInBetaEnv
    ? pwClusters.filter((cluster) => cluster?.id === PW_DEMO_CLUSTER_ID)
    : pwClusters;

  return clustersToDisplay.map(cluster => ({
    id: cluster.id,
    name: cluster.isDefault
      ? cluster.comments
        ? `${cluster.name} — ${cluster.comments} (default)`
        : `${cluster.name} (default)`
      : cluster.comments
        ? `${cluster.name} — ${cluster.comments}`
        : cluster.name,
    default: cluster.isDefault
  }))
}

export function getSwClustersDropdownOptions(
  isStandardModeInBetaEnv: boolean,
  swClusters: Maybe<AllClustersQuery["allClusters"]>
) {
  if (!swClusters) {
    return [];
  }

  const clustersToDisplay = isStandardModeInBetaEnv
    ? swClusters.filter((cluster) => cluster?.id === SW_DEMO_CLUSTER_ID)
    : swClusters;

  return clustersToDisplay.map(cluster => ({
    id: cluster.id,
    name: cluster.name,
    default: false
  }))
}