import { gql } from "@apollo/client";

export const UPDATE_WEBSITE_SETTINGS = gql`
  mutation UpdateSettings($input: UpdateWebsiteSettingsMutationInput!) {
    updateWebsiteSettings(input: $input) {
      websiteVersion {
        id
        hosts
        enableSmartCacheRefresh
        noDeliveryStatusCode
        filterBadBots
        customClientIpHeader
        useRenderingFarm
        maxSpeed
        liveMaxSpeed
        enableIncrementalCache
        indexMinimumRefreshInterval
        pagesMinimumRefreshInterval
      }
      errors {
        field
        messages
      }
    }
  }
`;
