import { getPreviewURL } from "../../common/preview";
import { shortUrl, truncate } from "../../common/str";
import type { QueryServedPagesResult } from "../../gql/types.generated";
import {
  IMetricCard,
  IPagesDefinition,
  IPreset,
} from "../../common/typing/website";
import { Copy } from "../../components/Copy/Copy";

const HTML_OBJECTS_CHANGED = [
  "countLinksChanged",
  "countCanonicalChanged",
  "countTitleChanged",
  "countDescriptionChanged",
  "countH1Changed",
  "countHreflangChanged",
] as const;

const TRUE_HTML_VALUES = [
  "countCanonicalChanged",
  "countTitleChanged",
  "countDescriptionChanged",
  "countH1Changed",
] as const;

type HTMLObjectsChangedKey = typeof HTML_OBJECTS_CHANGED[number];

const HTML_OBJECTS_CHANGED_NAME_MAP: Record<HTMLObjectsChangedKey, string> = {
  countLinksChanged: "links",
  countCanonicalChanged: "canonical",
  countTitleChanged: "title",
  countDescriptionChanged: "description",
  countH1Changed: "h1",
  countHreflangChanged: "hreflang",
} as const;

const htmlHasChanged = (item: QueryServedPagesResult) => {
  return HTML_OBJECTS_CHANGED.some((key) => {
    const value = item[key];
    if (!value) return false;
    return value > 0;
  });
};

const getHtmlChanges = (item: QueryServedPagesResult) => {
  const filtered: string[] = [];
  HTML_OBJECTS_CHANGED.forEach((key) => {
    const value = item[key];
    if (value && value > 0) {
      if (TRUE_HTML_VALUES.some((v) => v === key)) {
        filtered.push(HTML_OBJECTS_CHANGED_NAME_MAP[key] + " changed");
      } else {
        filtered.push(
          value + " " + HTML_OBJECTS_CHANGED_NAME_MAP[key] + " changed"
        );
      }
    }
  });
  return filtered.join(", ");
};

export const FIELDS_VERBOSE = {
  url: "URL",
  searchEngine: "Search Engine",
  bot: "Bot",
  delivered: "Delivered",
  httpCodeBeam: "HTTP Code of Beam Response",
  httpCodeServedPage: "HTTP Code of Served Page",
  httpCodeIndexedPage: "HTTP Code of Indexed Page",
  cacheHit: "Cache Hit",
  datetime: "Datetime",
  deliveryTime: "Delivery Time (Ms)",
  speedGain: "Speed Gain",
  countLinksChanged: "Number of Links Changed",
  countHits: "# Hits",
  resolvedDevice: "Resolved Device",
  countUrls: "# URLs",
};

export const CARDS: Record<string, IMetricCard<QueryServedPagesResult>> = {
  delivery: {
    name: "Delivered",
    fields: ["delivered", "notDeliveredReason"],
    display: (item) => {
      return (
        <>
          {item.delivered ? "Yes" : "No"}
          {!item.delivered && (
            <>
              <br />
              {item.notDeliveredReason}
            </>
          )}
        </>
      );
    },
  },
  exampleUrl: {
    name: "URL Example",
    fields: ["exampleUrl"],
    display: (item) => {
      return (
        <>
          {shortUrl(item.exampleUrl, 100)} |{" "}
          <a href={item.exampleUrl} target="_blank" rel="noreferrer">
            Original Page
          </a>
        </>
      );
    },
  },
  exampleCacheUrl: {
    name: "Cache URL Example",
    fields: ["exampleCacheUrl"],
    display: (item) => {
      return (
        <>
          {shortUrl(item.exampleCacheUrl, 100)} |{" "}
          <a href={item.exampleCacheUrl} target="_blank" rel="noreferrer">
            Original Page
          </a>
        </>
      );
    },
  },
  urlsSample: {
    name: "Sample of URLs (max 10)",
    fields: ["urlsSample"],
    display: (item) => {
      return (
        <>
          {item.urlsSample.map((url) => (
            <>
              <a href={url!} target="_blank" rel="noreferrer">
                {shortUrl(url!, 100)}
              </a>
              <br />
            </>
          ))}
        </>
      );
    },
  },
  canonicalUrl: {
    name: "Canonical URL",
    fields: ["canonicalUrl"],
    display: (item) => {
      return (
        <a href={item.canonicalUrl} target="_blank" rel="noreferrer">
          {shortUrl(item.canonicalUrl, 100)}
        </a>
      );
    },
  },
  queryStringKeys: {
    name: "Query String Keys",
    fields: ["queryStringKeys"],
    display: (item) => {
      return <>{truncate(item.queryStringKeys, 100)}</>;
    },
  },
  cacheUrlQueryStringKeys: {
    name: "Cache URL's Query String Keys",
    fields: ["cacheUrlQueryStringKeys"],
    display: (item) => {
      return <>{truncate(item.cacheUrlQueryStringKeys, 100)}</>;
    },
  },
  urlEnriched: {
    name: "URL",
    fields: [
      "url",
      "resolvedDevice",
      "indexedDevicesFound",
      "qualityControlLastSeenStatusSucceeded",
      "qualityControlLastSeenListWarning",
      //"qualityControlLastSeenListFail"
    ] /*.concat(
      HTML_OBJECTS_CHANGED.map(e => "count" + capitalize(e) + "Changed")
    )*/,
    display: (item, data) => {
      return (
        <div>
          <Copy textToCopy={item.url} />
          {shortUrl(item.url, 100)}{" "}
          {item.indexedDevicesFound.map((device) => {
            return (
              <span>
                <a
                  href={getPreviewURL(
                    data,
                    item.url,
                    device === "na" ? "desktop" : device!
                  )}
                  target="_blank"
                  rel="noreferrer"
                >
                  Preview {device === "na" ? "responsive" : device}
                </a>
                &nbsp;
              </span>
            );
          })}
          |{" "}
          <a
            href={`https://webcache.googleusercontent.com/search?q=cache:${item.url}`}
            target="_blank"
            rel="noreferrer"
          >
            Google Cache
          </a>{" "}
          |{" "}
          <a href={item.url} target="_blank" rel="noreferrer">
            Original Page
          </a>
          {htmlHasChanged(item) ? (
            <div className="htmlChanged">{getHtmlChanges(item)}</div>
          ) : (
            ""
          )}
          {item.qualityControlLastSeenStatusSucceeded === false ? (
            <div>
              {item.qualityControlLastSeenListWarning.length > 0 && (
                <span>
                  <span className="badge badge-warning">Warning</span>{" "}
                  {item.qualityControlLastSeenListWarning.join(",")}{" "}
                </span>
              )}
              {item.qualityControlLastSeenListFail && (
                <span>
                  <span className="badge badge-danger">Fail</span>{" "}
                  {item.qualityControlLastSeenListFail}{" "}
                </span>
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      );
    },
  },
  delivered: {
    display: (item) => {
      return <>{item.delivered ? "Yes" : "No"}</>;
    },
  },
  cacheHit: {
    display: (item) => {
      return <>{item.cacheHit ? "Yes" : "No"}</>;
    },
  },
  deliveryTime: {
    align: "right",
    display: (item) => {
      return <>{Intl.NumberFormat().format(item.deliveryTime)}</>;
    },
  },
  countUrls: {
    align: "right",
    display: (item) => {
      return <>{Intl.NumberFormat().format(item.countUrls)}</>;
    },
  },
  countHits: {
    align: "right",
    display: (item) => {
      return <>{Intl.NumberFormat().format(item.countHits)}</>;
    },
  },
  httpCodeIndexedPage: {
    name: "HTTP Code of Cached Page",
    fields: ["cacheHit", "httpCodeIndexedPage"],
    display: (item) => {
      return <>{item.cacheHit ? item.httpCodeIndexedPage : "Not cached"}</>;
    },
  },
  speed: {
    name: "Speed",
    fields: ["deliveryTime", "speedGain", "cacheHit"],
    display: (item) => {
      return (
        <>
          {item.deliveryTime}ms{" "}
          {item.cacheHit ? <small>x{Math.round(item.speedGain)}</small> : ""}
        </>
      );
    },
  },
  datetime: {
    name: "Date (UTC)",
    display: (item) => {
      return (
        <span style={{ whiteSpace: "nowrap" }}>
          {item.datetime.replace("+00:00", "")}
        </span>
      );
    },
  },
  avgCacheFreshnessForDeliveredPages: {
    name: "Average Daily Freshness",
    display: (item) => (
      <>{Math.round(item.avgCacheFreshnessForDeliveredPages) + " hrs"}</>
    ),
  },
};

export const SERVED_PAGES_DEFINITION: IPagesDefinition = {
  fieldsVerbose: FIELDS_VERBOSE,
  gqlFunction: "queryServedPages",
  dimensions: [
    "url",
    "resolvedDevice",
    "datetime",
    "httpCodeIndexedPage",
    "bot",
    "delivered",
    "notDeliveredReason",
  ],
  cards: CARDS,
};

export const PRESET_RAW: IPreset = {
  id: "last",
  cards: [
    "urlEnriched",
    "deliveryTime",
    "bot",
    "speed",
    "httpCodeIndexedPage",
    "resolvedDevice",
    "datetime",
  ],
  orderBy: "-datetime",
};

export const PRESET_RAW_24: IPreset = {
  ...PRESET_RAW,
  id: "last24",
  startOffset: 1,
};

export const PRESET_TOP: IPreset = {
  id: "top",
  cards: ["urlEnriched", "httpCodeIndexedPage", "countHits"],
  orderBy: "-countHits",
};

export const PRESET_TOP_CACHE_HIT: IPreset = {
  id: "topCacheHit",
  cards: [
    "urlEnriched",
    "httpCodeIndexedPage",
    "countHits",
    "avgCacheFreshnessForDeliveredPages",
  ],
  orderBy: "-countHits",
  filters: [{ field: "cache_hit", predicate: "eq", value: true }],
};

export const PRESET_TOP_CACHE_MISS: IPreset = {
  id: "topCacheMiss",
  cards: ["urlEnriched", "countHits", "delivery", "lastDeliveryDate"],
  orderBy: "-countHits",
  filters: [
    { field: "cache_hit", predicate: "eq", value: false },
    { field: "cache_hit", predicate: "eq", value: false },
  ],
};

export const PRESET_TOP_LIVE_FETCH: IPreset = {
  id: "topCacheMiss",
  cards: ["urlEnriched", "countHits", "delivery", "lastDeliveryDate"],
  orderBy: "-countHits",
  filters: [
    { field: "cache_hit", predicate: "eq", value: false },
    { field: "delivered", predicate: "eq", value: true },
  ],
};

export const PRESET_TOP_NOT_CACHED: IPreset = {
  id: "topNotCached",
  cards: ["urlEnriched", "countHits", "lastDeliveryDate"],
  orderBy: "-countHits",
  filters: [
    { field: "not_delivered_reason", predicate: "eq", value: "not_cached" },
  ],
};

export const PRESET_TOP_BAD_CANONICAL: IPreset = {
  id: "top",
  cards: [
    "canonicalUrl",
    "resolvedDevice",
    "urlsSample",
    "countHits",
    "countUrls",
  ],
  orderBy: "-countUrls",
  filters: [{ field: "canonical_equal", predicate: "eq", value: false }],
};

export const PRESET_TOP_JS_ERROR: IPreset = {
  id: "topNotCached",
  cards: ["urlEnriched", "countHits", "lastDeliveryDate"],
  orderBy: "-countHits",
  filters: [
    { field: "not_delivered_reason", predicate: "eq", value: "js_code_error" },
  ],
};

export const PRESET_TOP_BLOCKED: IPreset = {
  id: "topNotCached",
  cards: ["urlEnriched", "countHits", "lastDeliveryDate"],
  orderBy: "-countHits",
  filters: [
    {
      field: "not_delivered_reason",
      predicate: "eq",
      value: "blocked_by_section",
    },
  ],
};

export const PRESET_TOP_NOT_CACHED_BY_DIRECTORY_N1: IPreset = {
  id: "topNotCached",
  cards: ["pathN1", "exampleUrl", "countUrls", "countHits"],
  orderBy: "-countHits",
  filters: [
    { field: "not_delivered_reason", predicate: "eq", value: "not_cached" },
  ],
};

export const PRESET_TOP_NOT_CACHED_BY_DIRECTORY_N2: IPreset = {
  id: "topNotCached",
  cards: ["pathN1", "pathN2", "exampleUrl", "countUrls", "countHits"],
  orderBy: "-countHits",
  filters: [
    { field: "not_delivered_reason", predicate: "eq", value: "not_cached" },
  ],
};

export const PRESET_TOP_NOT_CACHED_BY_QUERY_STRING: IPreset = {
  id: "topNotCached",
  cards: ["queryStringKeys", "exampleUrl", "countUrls", "countHits"],
  orderBy: "-countHits",
  filters: [
    { field: "not_delivered_reason", predicate: "eq", value: "not_cached" },
  ],
};

export const PRESET_TOP_NOT_CACHED_CACHE_URL_BY_QUERY_STRING: IPreset = {
  id: "topNotCached",
  cards: [
    "cacheUrlQueryStringKeys",
    "exampleCacheUrl",
    "countUrls",
    "countHits",
  ],
  orderBy: "-countHits",
  filters: [
    { field: "not_delivered_reason", predicate: "eq", value: "not_cached" },
  ],
};

export const PRESETS = {
  last: PRESET_RAW,
  last24: PRESET_RAW_24,
  top: PRESET_TOP,
  topCacheHit: PRESET_TOP_CACHE_HIT,
  topCacheMiss: PRESET_TOP_CACHE_MISS,
  topLiveFetch: PRESET_TOP_LIVE_FETCH,
  topNotCached: PRESET_TOP_NOT_CACHED,
  topJsError: PRESET_TOP_JS_ERROR,
  topBlocked: PRESET_TOP_BLOCKED,
  topBadCanonical: PRESET_TOP_BAD_CANONICAL,
  topNotCachedByDirectoryN1: PRESET_TOP_NOT_CACHED_BY_DIRECTORY_N1,
  topNotCachedByDirectoryN2: PRESET_TOP_NOT_CACHED_BY_DIRECTORY_N2,
  topNotCachedByQueryString: PRESET_TOP_NOT_CACHED_BY_QUERY_STRING,
  topNotCachedCacheUrlByQueryString:
    PRESET_TOP_NOT_CACHED_CACHE_URL_BY_QUERY_STRING,
} as const;

export const DEFAULT_START_OFFSET = 30;
