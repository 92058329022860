import moment from "moment";

const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss"

export function formatDate(timestamp: number) {
  if (!timestamp) {
    return "";
  }

  return moment(timestamp * 1000).format(DATE_FORMAT);
}

export function formatDatetime(datetime: string) {
  if (!datetime) {
    return "";
  }

  return moment(datetime).format(DATE_FORMAT);
}

export function formatLargeNumber(number: number) {
  if (!number) {
    return "";
  }

  if (typeof number === "string") {
    number = parseInt(number, 10);
  }

  return number
    .toLocaleString("fr-FR", { useGrouping: true })
    .replace(/\u202F/g, " ");
}
