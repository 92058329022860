import { gql } from "@apollo/client";

export const ROLLBACK_ALL_ADN_CONFIGS = gql`
  mutation RollbackAllAdnConfigs(
    $environment: AdnEnvironment!
    $datetimeStr: String
  ) {
    rollbackAllAdnConfigs(
      environment: $environment
      datetimeStr: $datetimeStr
    ) {
      messages {
        websiteId
        message
      }
    }
  }
`;
