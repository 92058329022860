import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { CSSProperties } from "react";
import { Button, Card, Form, Spinner, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { DataHandler } from "../../../../components/DataHandler";
import { JsCodeType } from "../../../../gql/types.generated";
import { BreadcrumbTitle } from "../../../components/header/Title";
import { useScriptsQuery } from "../../hooks/useScriptsQuery";
import { useScriptsTableContext } from "./ScriptsTableContext";
import { useScriptsTable } from "./useScriptsTable";

export const ScriptManagementPage = () => {
  const { scripts, website, error, loading } = useScriptsQuery();

  if (loading || error || !website?.draftVersion?.id) {
    return (
      <DataHandler
        error={error}
        loading={loading}
        data={website?.draftVersion?.id}
        expectData
      />
    );
  }

  return (
    <div>
      <BreadcrumbTitle website={website} steps={["Script Management"]} />

      <Card className="p-3">
        <ScriptTable
          websiteVersionId={website.draftVersion.id}
          scripts={scripts as JsCodeType[]}
        />
      </Card>
    </div>
  );
};

type ScriptTableProps = { scripts: JsCodeType[]; websiteVersionId: string };

const ScriptTable = ({ scripts, websiteVersionId }: ScriptTableProps) => {
  const navigate = useNavigate();
  const {
    search,
    setSearch,
    loading,
    columns,
    shownScripts,
    ScriptsTableContext,
  } = useScriptsTable({ scripts, websiteVersionId });

  return (
    <div>
      <div className="d-flex justify-content-between mb-3">
        <div
          className="d-flex align-items-center"
          style={{ width: "400px", gap: "8px" }}
        >
          <Form.Control
            className="w-75"
            type="text"
            placeholder="Search a snippet"
            value={search}
            onChange={(e) => setSearch(e.currentTarget.value)}
          />
          {loading && <Spinner animation="border" size="sm" />}
        </div>
        <Button
          className="text-nowrap"
          variant="primary"
          onClick={() => navigate("./create")}
        >
          Create Script
        </Button>
      </div>

      <ScriptsTableContext>
        <Table className="align-middle">
          <thead>
            <tr>
              {columns.map(({ key, header }) => (
                <th key={key}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {shownScripts.map((script) => (
              <ScriptsTableRow key={script.id} script={script} />
            ))}
          </tbody>
        </Table>
      </ScriptsTableContext>
    </div>
  );
};

const ScriptsTableRow = ({ script }: { script: JsCodeType }) => {
  const { columns } = useScriptsTableContext();
  const { listeners, setNodeRef, transform, transition, isDragging } =
    useSortable({ id: script.id });

  const style: CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    boxShadow: isDragging ? "0 4px 4px #00000033" : "initial",
    zIndex: isDragging ? 1 : "initial",
    position: isDragging ? "relative" : "initial",
    opacity: isDragging ? 0.5 : "initial",
  };

  return (
    <tr ref={setNodeRef} style={style}>
      {columns.map(({ key, cell }) => (
        <td key={key}>{cell(script, { dragListeners: listeners })}</td>
      ))}
    </tr>
  );
};
