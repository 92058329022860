// @ts-nocheck
// TODO: should be checked fully
import { Chart } from "react-google-charts";
import _ from "lodash";

export const stringToColor = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let colour = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += value.toString(16).slice(-2).padStart(2, "0");
  }
  return colour;
};

export function removeTypenameFromData<
  T extends { __typename?: string | undefined }
>(data: Array<T>) {
  return data.map(({ __typename, ...rest }) => rest);
}

export const populateEmptyPoints = (periodRange, data, key, emptyState) => {
  const values_idx = {};
  const newData = [];
  data.forEach((value) => {
    values_idx[value[key]] = value;
  });
  periodRange.forEach((value) => {
    if (value in values_idx) {
      newData.push(values_idx[value]);
    } else {
      const emptyData = { ...emptyState };
      emptyData[key] = value;
      newData.push(emptyData);
    }
  });
  return newData;
};

export const convertToNivoPie = (
  data,
  dim,
  metric,
  colorMapping?: Record<string, string>
) => {
  return data.map((entry) => {
    const label = entry[dim] === null ? "null" : entry[dim].toString();
    return {
      id: label,
      label,
      value: entry[metric],
      color:
        colorMapping !== undefined && colorMapping[label] !== undefined
          ? colorMapping[label]
          : stringToColor(label),
    };
  });
};

export const reducebyDimensions = (
  data,
  dimensions,
  metric,
  fn?: any = _.sumBy
) => {
  const newData = _(data)
    .groupBy((entry) => {
      return _.map(dimensions, (v) => {
        return entry[v];
      });
    })
    .map((entries, id) => {
      const newEntry = {};
      _.forEach(dimensions, (d) => {
        newEntry[d] = entries[0][d];
      });
      newEntry[metric] = fn(entries, metric);
      return newEntry;
    })
    .value();

  return newData;
};

/*
data: Array of data
dimensions : array of dimensions
fn: function taking (result, entries) with result as an array with selected dimensions and entries
    as initial entries matching the dimensions
 */
export const reducebyDimensionsFn = <T extends {}>({
  data,
  dimensions,
  fn,
}): T[] => {
  const newData = _(data)
    .groupBy((entry) => {
      return _.map(dimensions, (v) => {
        return entry[v];
      });
    })
    .map((entries, id) => {
      const newEntry = {};
      _.forEach(dimensions, (d) => {
        newEntry[d] = entries[0][d];
      });
      fn(newEntry, entries);

      return newEntry;
    })
    .value();

  return newData;
};

export const cleanConsecutiveLabelsSameDay = (data, key, newKey) => {
  /*
    If consecutive labels share the same day, remove the date from the next ones
    Ex :
    >> cleanConsecutiveLabelsSameDay([
        {"label": "2010-01-01 10", "count": 1},
        {"label": "2010-01-01 11", "count": 1},
        {"label": "2010-01-02 10", "count": 1},
    ], "label", "cleanedLabel")
    [
        {"cleanedLabel": "2010-01-01 10", "label": "2010-01-01 10", "count": 1},
        {"cleanedLabel": "11", "label": "2010-01-01 11", "count": 1},
        {"cleanedLabel": "2010-01-02 10", "label": "2010-01-02 10", "count": 1},

    ]

    */
  data.forEach((value, index) => {
    if (index === 0) {
      value[newKey] = value[key] + ":00".replace(" ", "\n");
    } else {
      const current = value[key].slice(0, 10);
      const previous = data[index - 1][key].slice(0, 10);
      if (current === previous) {
        value[newKey] = value[key].slice(10) + ":00";
      } else {
        value[newKey] = value[key] + ":00".replace(" ", "\n");
      }
    }
  });
};

export const LineChart = ({
  data,
  dimension,
  metric,
  color,
  xTitle,
  yTitle,
}) => {
  const newData = [[dimension, metric]];
  data.forEach((entry) => {
    newData.push([entry[dimension], entry[metric]]);
  });

  return (
    <Chart
      width="100%"
      height="100%"
      chartType="LineChart"
      loader={<div>Loading Chart</div>}
      data={newData}
      options={{
        colors: [color],
        chartArea: { width: "80%" },
        hAxis: {
          minValue: 0,
          textStyle: {
            fontSize: 10,
            color: "gray",
          },
          title: xTitle,
        },
        vAxis: {
          minValue: 0,
          title: yTitle,
        },
      }}
    />
  );
};

export const MultiLineChart = ({
  data,
  dimension,
  metrics,
  colors,
  xTitle,
  yTitle,
  annotations,
}) => {
  const newData = [[dimension, ...metrics]];
  data.forEach((entry) => {
    newData.push([entry[dimension], ...metrics.map((m) => entry[m])]);
  });

  // Add annotations
  if (annotations) {
    newData.forEach((entry, index) => {
      annotations.forEach((annotation, idx) => {
        if (index === 0) {
          entry.splice(1 + idx, 0, { role: "annotation", type: "string" });
        } else {
          if (annotation.xValue === entry[0]) {
            entry.splice(1 + idx, 0, annotation.label);
          } else {
            entry.splice(1 + idx, 0, null);
          }
        }
      });
    });
  }

  console.log(newData);

  return (
    <Chart
      width="100%"
      height="100%"
      chartType="LineChart"
      loader={<div>Loading Chart</div>}
      data={newData}
      options={{
        colors: colors,
        chartArea: { width: "60%" },
        hAxis: {
          minValue: 0,
          textStyle: {
            fontSize: 10,
            color: "gray",
          },
          title: xTitle,
        },
        vAxis: {
          minValue: 0,
          title: yTitle,
        },
        annotations: {
          style: "line",
        },
      }}
    />
  );
};

export const BarChart = ({
  data,
  dimension,
  dimensionLegend = dimension,
  metric,
  metricLegend = metric,
  color,
  xTitle,
  yTitle,
  width = "100%",
  height = "100%",
  sortByX = false,
}) => {
  const newData = data.map((entry) => {
    return [entry[dimension], entry[metric]];
  });
  if (sortByX) {
    newData.sort((a, b) => {
      if (a[0] > b[0]) return 1;
      else if (a[0] < b[0]) return -1;
      return 0;
    });
  }
  newData.unshift([dimensionLegend, metricLegend]);

  return (
    <Chart
      width={width}
      height={height}
      chartType="ColumnChart"
      loader={<div>Loading Chart</div>}
      data={newData}
      options={{
        colors: [color],
        chartArea: { width: "80%" },
        hAxis: {
          minValue: 0,
          title: xTitle,
        },
        vAxis: {
          minValue: 0,
          title: yTitle,
        },
      }}
    />
  );
};

const toLabel = (value) => {
  if (value === null) {
    return "null";
  }
  return value.toString();
};

export const StackedBarChart = ({
  data,
  dimension,
  splitBy,
  metric,
  colors = {},
  xTitle,
  yTitle,
  width = "100%",
  height = "100%",
  sortByX = false,
}) => {
  // Reduce by dimension + splitBy
  const reducedData = reducebyDimensions(data, [dimension, splitBy], metric);

  const valuesSet = new Set([]);
  // newData.forEach(e => { values.add(newData});
  reducedData.forEach((entry) => {
    valuesSet.add(entry[splitBy]);
  });

  const values = Array.from(valuesSet);
  values.sort();

  const newData = _(reducedData)
    .groupBy((entry) => entry[dimension])
    .map((entries, id) => {
      const newEntry = [];
      const idx = {};
      newEntry.push(id);
      entries.forEach((e) => (idx[e[splitBy]] = e[metric]));
      values.forEach((e) => newEntry.push(idx[e] ?? null));
      return newEntry;
    })
    .value();

  if (sortByX) {
    newData.sort((a, b) => {
      if (a[0] > b[0]) return 1;
      else if (a[0] < b[0]) return -1;
      return 0;
    });
  }

  // Make the final data by dimension
  const chartData = [
    ...[["Date"].concat(values.map((e) => toLabel(e)))],
    ...newData,
  ];

  const finalColors = values.map(
    (e) => colors[e] || stringToColor(e || "null")
  );
  return (
    <Chart
      width={width}
      height={height}
      chartType="ColumnChart"
      loader={<div>Loading Chart</div>}
      data={chartData}
      options={{
        colors: finalColors,
        chartArea: { width: "60%" },
        isStacked: true,
        bar: { groupWidth: "70%" },
        hAxis: {
          minValue: 0,
          textStyle: {
            fontSize: 10,
            color: "gray",
          },
          title: xTitle,
        },
        vAxis: {
          minValue: 0,
          title: yTitle,
        },
      }}
    />
  );
};
