import { Detail } from "./detail";
import { Explore } from "./explore";
import { Import } from "./import";
import { JobDetail } from "./jobDetail";
import { Route, Routes } from "react-router-dom";

export function PageEdit() {
  return (
    <Routes>
      <Route index element={<Explore />} />
      <Route path="detail/:pageEditId" element={<Detail />} />
      <Route path="import" element={<Import />} />
      <Route path="job/:jobId" element={<JobDetail />} />
    </Routes>
  );
}
