import { gql } from "@apollo/client";
import { capitalize } from "../common/str";
import type { MetricKey, DimensionKey, DatabaseKey } from "./types";

interface IGetQueryOptions {
  name: string;
  metrics: MetricKey[];
  dimensions?: DimensionKey[];
  orderBy?: DimensionKey[];
  db?: DatabaseKey;
}

export function getQuery({
  name,
  metrics,
  dimensions = [],
  orderBy = [],
  db = "servedPages",
}: IGetQueryOptions) {
  return gql`
    query ${name} (
      $timestampStart: Int!
      $timestampEnd: Int!
    ) {
      query${capitalize(db)} (
        timestampStart: $timestampStart
        timestampEnd: $timestampEnd
        dimensions: ${JSON.stringify(dimensions)}
        orderBy: ${JSON.stringify(orderBy)}
      ) {
        ${metrics.join("\n")}
      }
    }
  `;
}
