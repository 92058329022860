import { useMutation } from "@apollo/client";
import { useCallback } from "react";
import { toast } from "react-toastify";
import {
  JsCodeType,
  UpdateJsCodeMutation,
} from "../../../../gql/types.generated";
import { DELETE_WEBSITE_SCRIPT } from "../../../../gql/websites/scripts/deleteWebsiteScript.gql";
import {
  DeleteJsCodeMutation,
  DeleteJsCodeMutationVariables,
} from "../../../../gql/websites/scripts/deleteWebsiteScript.gql.generated";
import { ORDER_WEBSITE_SCRIPTS } from "../../../../gql/websites/scripts/orderWebsiteScripts";
import {
  OrderJsCodeMutation,
  OrderJsCodeMutationVariables,
} from "../../../../gql/websites/scripts/orderWebsiteScripts.generated";
import { UPDATE_WEBSITE_SCRIPT } from "../../../../gql/websites/scripts/updateWebsiteScript";
import { UpdateJsCodeMutationVariables } from "../../../../gql/websites/scripts/updateWebsiteScript.generated";

type UseScriptsTableMutationsParams = {
  websiteVersionId: string;
  initialScriptsOrder: JsCodeType[];
  setOrderedScripts: React.Dispatch<React.SetStateAction<JsCodeType[]>>;
};

export const useScriptsTableMutations = ({
  websiteVersionId,
  initialScriptsOrder,
  setOrderedScripts,
}: UseScriptsTableMutationsParams) => {
  // Activate / pause mutations

  const [updateWebsiteScript, { loading: loadingToggle }] = useMutation<
    UpdateJsCodeMutation,
    UpdateJsCodeMutationVariables,
    { scriptName: string; activate: boolean }
  >(UPDATE_WEBSITE_SCRIPT, {
    onCompleted: (data) => {
      // FIXME: fix type generation
      // @ts-expect-error generated type is incorrect (missing updateJsCode field)
      const updatedScript = data?.updateJsCode?.jsCode;
      if (updatedScript) {
        setOrderedScripts((prev) =>
          prev.map((script) =>
            script.id === updatedScript.id ? updatedScript : script
          )
        );
      }
    },
    onError: (error, clientOptions) => {
      const { scriptName, activate } = clientOptions!.context!;
      toast(
        `Failed to ${activate ? "pause" : "activate"} script "${scriptName}": ${error}`,
        { type: "error" }
      );
    },
  });

  const toggleScript = (activate: boolean) => async (script: JsCodeType) => {
    updateWebsiteScript({
      variables: {
        script: {
          id: script.id,
          name: script.name,
          websiteVersion: script.websiteVersion.id,
          sections: script.sections.map((section) => section.id),
          active: activate,
        },
      },
      context: { activate, scriptName: script.name },
    });
  };

  const activateScript = toggleScript(true);
  const pauseScript = toggleScript(false);

  // Reorder mutation

  const [orderWebsiteScripts, { loading: loadingOrder }] = useMutation<
    OrderJsCodeMutation,
    OrderJsCodeMutationVariables
  >(ORDER_WEBSITE_SCRIPTS, {
    onError: (error) => {
      toast(`Failed to reorder scripts: ${error.message}`, { type: "error" });
      setOrderedScripts(initialScriptsOrder);
    },
  });

  const reorderScripts = useCallback(
    async (reorderedScripts: JsCodeType[]) => {
      setOrderedScripts(reorderedScripts);
      orderWebsiteScripts({
        variables: {
          websiteVersionId,
          scriptIds: reorderedScripts.map(({ id }) => id),
        },
      });
      toast("The new execution order for your scripts is successfully saved.", {
        type: "success",
      });
    },
    [websiteVersionId, orderWebsiteScripts, setOrderedScripts]
  );

  // Delete mutation

  const [deleteWebsiteScript, { loading: loadingDelete }] = useMutation<
    DeleteJsCodeMutation,
    DeleteJsCodeMutationVariables,
    { scriptName: JsCodeType["name"] }
  >(DELETE_WEBSITE_SCRIPT, {
    onError: (error, clientOptions) => {
      const { scriptName } = clientOptions!.context!;
      toast(`Failed to delete script ${scriptName}: ${error}`, {
        type: "error",
      });
    },
    onCompleted: (data, clientOptions) => {
      const { scriptName } = clientOptions!.context!;
      const deletedScriptId = data?.deleteJsCode?.id;
      if (deletedScriptId) {
        setOrderedScripts((prev) =>
          prev.filter((s) => s.id !== deletedScriptId)
        );
        toast(`Script "${scriptName}" successfully deleted.`);
      }
    },
  });

  const deleteScript = useCallback(
    async (script: JsCodeType) => {
      deleteWebsiteScript({
        variables: { scriptId: script.id, websiteVersionId },
        context: { scriptName: script.name },
      });
    },
    [websiteVersionId, deleteWebsiteScript]
  );

  const loading = loadingToggle || loadingOrder || loadingDelete;

  return {
    activateScript,
    pauseScript,
    deleteScript,
    reorderScripts,
    loading,
  };
};
