import { Ace } from "ace-builds";
import { useState } from "react";
import AceEditor from "react-ace";

import { Button, ButtonGroup } from "react-bootstrap";
import { JsCodeStep } from "../../../gql/types.generated";
import { getVisibleSteps, VisibleStep } from "../core/steps";

type ScriptEditorProps = {
  codeByStep: CodeByStep;
  onChange: (codeByStep: CodeByStep) => void;
  onValidate?: (step: VisibleStep, validations: CodeValidation[]) => void;
};

export type CodeValidation = {
  step: VisibleStep;
  line: number | undefined;
  text: string;
};

export type CodeByStep = Record<VisibleStep, string>;

export const ScriptEditor = ({
  codeByStep,
  onChange,
  onValidate,
}: ScriptEditorProps) => {
  const [activeStep, setActiveStep] = useState<VisibleStep>(JsCodeStep.Init);

  const stepCode = codeByStep[activeStep];

  const handleActiveStepCodeChange = (value: string) =>
    onChange({ ...codeByStep, [activeStep]: value });

  const handleActiveStepValidation = (annotations: Ace.Annotation[]) =>
    onValidate?.(
      activeStep,
      annotations
        .filter(({ type }) => type === "error")
        .map(({ row, text }) => ({ step: activeStep, line: row, text }))
    );

  return (
    <div>
      <StepSelectionTabs
        activeStep={activeStep}
        setActiveStep={setActiveStep}
      />
      <AceEditor
        mode="javascript"
        value={stepCode}
        onChange={handleActiveStepCodeChange}
        onValidate={handleActiveStepValidation}
        width="100%"
        minLines={20}
        maxLines={50}
      />
    </div>
  );
};

type StepSelectionTabsProps = {
  activeStep: VisibleStep;
  setActiveStep: (step: VisibleStep) => void;
};

const StepSelectionTabs = ({
  activeStep,
  setActiveStep,
}: StepSelectionTabsProps) => (
  <ButtonGroup className="mb-4">
    {getVisibleSteps().map(({ id, executionContext, label }) => (
      <Button
        key={id}
        variant={id === activeStep ? "primary" : "secondary"}
        onClick={() => setActiveStep(id)}
      >
        <span className="small">{executionContext}</span>
        <br />
        {label}
      </Button>
    ))}
  </ButtonGroup>
);
