import _ from "lodash";
import React from "react";

import {
  IPaginationProps,
  Pagination,
} from "../../common/Pagination/Pagination";
import { WebsiteType } from "../../gql/types.generated";
import { PaginatedWebsitesWithFiltersQuery } from "../../gql/websites/website/websitesList.gql.generated";
import { ClusterEntry, WebsiteEntry } from "./websiteFeatures";
import "./websitesList.css";

interface IWebsitesListProps extends IPaginationProps {
  websites: PaginatedWebsitesWithFiltersQuery["websitesList"]["websites"];
}

export function WebsitesList({
  websites,
  ...paginationProps
}: IWebsitesListProps) {
  const websitesGroupedByCluster = _.groupBy(websites, (i) => i.cluster.name);
  const clusterNames = Object.keys(websitesGroupedByCluster).sort((a, b) =>
    a.toLowerCase().localeCompare(b.toLowerCase())
  );

  return (
    <>
      {clusterNames.map((clusterName) => (
        <React.Fragment key={clusterName}>
          <ClusterEntry
            key={clusterName}
            cluster={websitesGroupedByCluster[clusterName][0].cluster}
          />
          <div>
            {websitesGroupedByCluster[clusterName].map((website) => (
              <WebsiteEntry key={website.id} website={website as WebsiteType} />
            ))}
          </div>
        </React.Fragment>
      ))}

      {websites.length > 0 ? <Pagination {...paginationProps} /> : undefined}
    </>
  );
}
