import { useQuery } from "@apollo/client";

import { BarChart } from "../../../common/charts";
import { DataHandler } from "../../../components/DataHandler";
import type {
  QueryIndexedPagesResult,
  QueryQueryIndexedPagesArgs,
} from "../../../gql/types.generated";
import { getQuery } from "../../Helpers";
import type { IMonitoringOptions } from "../../types";
import { Link } from "react-router-dom";

interface IIndexedCardProps {
  options: IMonitoringOptions;
}

export function IndexedCard({ options }: IIndexedCardProps) {
  const { loading, error, data } = useQuery<
    {
      queryIndexedPages: Pick<QueryIndexedPagesResult, "date" | "count">[];
    },
    QueryQueryIndexedPagesArgs
  >(
    getQuery({
      name: "IndxedQuery",
      metrics: ["date", "count"],
      dimensions: ["date"],
      orderBy: ["date"],
      db: "indexedPages",
    }),
    {
      variables: {
        timestampStart: options.timestampStart30dMidnight,
        timestampEnd: options.timestampEnd,
      },
    }
  );

  if (error || loading || !data) {
    return (
      <DataHandler error={error} loading={loading} data={data} expectData />
    );
  }

  return (
    <>
      <BarChart
        data={data.queryIndexedPages}
        dimension="date"
        metric="count"
        color="blue"
        xTitle="Date"
        yTitle="Number of Imported Pages"
        height="250px"
      />
      Get Detail by Website :{" "}
      <Link to="websites/indexedPages/30d/count">Last 30 days</Link>,{" "}
      <Link to="websites/indexedPages/24h/count">Last 24 Hours</Link>
    </>
  );
}
