import { Button, Card, Table } from "react-bootstrap";
import { formatDatetime, formatLargeNumber } from "./utils";

interface HistoryRecordsProps {
  records: Array<any>;
  onGetRawPage: (record: Record<string, unknown>) => void;
}

export function HistoryRecords({ records, onGetRawPage }: HistoryRecordsProps) {
  return (
    <Card style={{ height: "300px", overflow: "auto" }}>
      <Card.Header>History</Card.Header>
      <Card.Body>
        <Table striped bordered hover className="white" size="sm">
          <thead>
            <tr>
              <td>Date (UTC)</td>
              <td>Indexed</td>
              <td>Status code</td>
              <td>Page Device</td>
              <td>Page Size</td>
              <td>Action</td>
            </tr>
          </thead>
          <tbody>
            {records.map((historyRecord: Record<string, any>) => (
              <tr key={`record-${historyRecord["datetime"]}`}>
                <td>{formatDatetime(historyRecord["datetime"])}</td>
                <td>{String(historyRecord["indexed"])}</td>
                <td>{historyRecord["httpCode"]}</td>
                <td>{historyRecord["device"]}</td>
                <td>{formatLargeNumber(historyRecord["pageSize"])}</td>
                <td>
                  <Button onClick={() => onGetRawPage(historyRecord)}>
                    Get Rawpage !
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}
