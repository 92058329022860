export type IRule = {
  rules: IRule[];
  combinator?: "And" | "Or";
  field: "header" | "body" | "status" | "duration";
  key: string;
  operator:
    | "startsWith"
    | "notStartsWith"
    | "endsWith"
    | "notEndsWith"
    | "contains"
    | "notContains"
    | "equals"
    | "notEquals"
    | "regex"
    | "notRegex"
    | "allDifferent"
    | "allIdentical"
    | "higherThan"
    | "lowerThan";
  value?: string;
};

export function transformRuleToString({
  rule,
  level = 0,
}: {
  rule: IRule | null;
  level?: number;
}) {
  if (rule === null) {
    return "";
  }

  let ret = [" ".repeat(level)];

  // If it's a combinator rule
  if (rule.rules.length > 0) {
    switch (rule.combinator) {
      case "And":
        ret.push("And \n");
        break;
      case "Or":
        ret.push("Or \n");
        break;
      default:
        return "";
    }
    rule.rules.forEach((subRule) => {
      ret.push(...transformRuleToString({ rule: subRule, level: level + 1 }));
      ret.push("\n");
    });

    return ret;
  }

  // Classic rule, extract field, operator and value

  switch (rule.field) {
    case "header":
      ret.push("Header " + rule.key);
      break;
    case "body":
      ret.push("Body");
      break;
    case "status":
      ret.push("Status");
      break;
    case "duration":
      ret.push("Duration");
      break;
    default:
      return "";
  }

  ret.push(" ");

  switch (rule.operator) {
    case "startsWith":
      ret.push("starts with");
      break;
    case "notStartsWith":
      ret.push("doesn't start with");
      break;
    case "endsWith":
      ret.push("ends with");
      break;
    case "notEndsWith":
      ret.push("doesn't end with");
      break;
    case "contains":
      ret.push("contains");
      break;
    case "notContains":
      ret.push("doesn't contain");
      break;
    case "equals":
      ret.push("equals");
      break;
    case "notEquals":
      ret.push("doesn't equal");
      break;
    case "regex":
      ret.push("matches");
      break;
    case "notRegex":
      ret.push("doesn't match");
      break;
    case "allDifferent":
      ret.push("are all different");
      break;
    case "allIdentical":
      ret.push("are all identical");
      break;
    case "higherThan":
      ret.push("is higher than");
      break;
    case "lowerThan":
      ret.push("is lower than");
      break;
    default:
      return "";
  }

  if (rule.operator !== "allDifferent" && rule.operator !== "allIdentical") {
    ret.push(" " + rule.value);
  }

  return ret;
}
