import Alert from "react-bootstrap/Alert";
import { ForceRefreshResultErrors } from "./forceRefreshResultErrors";
import { ImpactLevel } from "../../gql/types.generated";
import { useForceRefresh } from "./useForceRefresh";
import { Duration } from "../../common/duration";

const ImpactLevelBannerVariant = {
  [ImpactLevel.Ok]: "success",
  [ImpactLevel.Warning]: "warning",
  [ImpactLevel.Danger]: "danger",
};

interface IImpactAnalysisResultsProps {
  analysis: NonNullable<
    ReturnType<typeof useForceRefresh>["impactAnalysisData"]
  >;
}

export function ImpactAnalysisResult({
  analysis,
}: IImpactAnalysisResultsProps) {
  if (analysis.errors) {
    return <ForceRefreshResultErrors errors={analysis.errors} />;
  }

  return (
    <Alert
      variant={
        analysis.impactLevel
          ? ImpactLevelBannerVariant[analysis.impactLevel]
          : "info" // Should not happen
      }
    >
      Analysis
      <pre>
        Total URLs in inventory: {analysis.totalInventoryUrls}
        <br />
        Total URLs valid url to be refreshed: {analysis.nbOfUrlsToRefresh}
        <br />
        Max URLs allowed to be refreshed: {analysis.maxAllowedUrlsToRefresh}
        {"estimatedRefreshDuration" in analysis ? (
          <>
            <br />
            Force refresh duration:{" "}
            <Duration ms={Number(analysis.estimatedRefreshDuration)} />
            <br />
            Max allowed force refresh duration:{" "}
            <Duration ms={Number(analysis.maxAllowedRefreshDuration)} />
            <br />
            Total URLs whose refresh would be delayed:{" "}
            {analysis.estimatedNbOfDelayedPages}
            <br />
            Time until the delayed URLs are refreshed:{" "}
            <Duration
              ms={
                Number(analysis.estimatedRefreshDuration) +
                Number(analysis.estimatedDelayRecoveryDuration)
              }
            />
          </>
        ) : null}
      </pre>
    </Alert>
  );
}


