import { gql } from "@apollo/client";

export const ORDER_WEBSITE_SCRIPTS = gql`
  mutation OrderJSCode($websiteVersionId: ID!, $scriptIds: [ID!]!) {
    orderJsCode(websiteVersion: $websiteVersionId, codeOrder: $scriptIds) {
      id
      name
    }
  }
`;
