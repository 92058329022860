import { Maybe } from "graphql/jsutils/Maybe";

export type ValueOf<T> = T[keyof T];
export type KeyOf<T> = keyof T;
export type NonNullableKeys<T> = { [P in keyof T]: NonNullable<T[P]> };

/** Just here to fix api response type because not using NonNull List in backend */
export function isNonNullList<T>(
  list?: Maybe<Array<Maybe<T>>>
): list is Array<T> {
  return !!list && !list.some((item) => item === null);
}

/** Just here to fix api response type because not using NonNull List in backend */
export function getNonNullList<T>(list?: Maybe<Array<T | null>>): Array<T> {
  return excludeNullItems(list) ?? [];
}

/** Filters out null items from input `list` if set, else it's no-op. */
export function excludeNullItems<T>(list: Maybe<(T | null)[]>): Maybe<T[]> {
  return list?.filter((item): item is T => item !== null);
}
