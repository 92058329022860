import { gql } from "@apollo/client";

export const GET_PAGE_EDITS = gql`
  query PageEdits($websiteId: String!, $page: Int!, $search: String!) {
    pageEdits(page: $page, websiteId: $websiteId, search: $search) {
      page
      pages
      hasNext
      hasPrev
      objects {
        id
        url
        device
      }
    }
  }
`;
