import { useState } from "react";
import { ModuleType, MODULE_UISCHEMAS } from "../Settings";
import { useMutation } from "@apollo/client";
import { AceWidget } from "../widgets/Ace";
import { ScopeWidget } from "../widgets/Scope";
import { Card, Form as BForm } from "react-bootstrap";
import { DocumentNode } from "graphql";
import Form, { IChangeEvent } from '@rjsf/core';

import { useFormInputSynchronizedWithLS } from "../../../common/hooks";
import { RJSFValidationError, WidgetProps } from "@rjsf/utils";
import validator from "@rjsf/validator-ajv8";

const removeRequiredOnBoolean = (subSchema: JSONSchema) => {
  // react-jsonschema-form is always asking to check boolean fields which are required
  // We remove required flag to make it work
  if (subSchema.properties) {
    Object.keys(subSchema.properties).forEach((prop) => {
      if (subSchema.properties[prop].type === "boolean") {
        subSchema.required.splice(subSchema.required.indexOf(prop), 1);
      } else if (subSchema.properties[prop].type === "object") {
        removeRequiredOnBoolean(subSchema.properties[prop]);
      } else if (subSchema.properties[prop].type === "array") {
        removeRequiredOnBoolean(subSchema.properties[prop].items);
      }
    });
  }
};

interface JSONSchema {
  properties: IModuleSchemaProperties;
  required: Array<string>;
}

interface IModuleSchemaProperties extends Record<string, any> {
  name: SchemaProps;
  description?: SchemaProps;
  scope?: SchemaProps;
  scopeType?: string;
}

interface SchemaProps {
  type: string;
  title: string;
}

interface IModuleForm {
  gql: DocumentNode;
  moduleType: ModuleType;
  jsonschema: {
    properties: IModuleSchemaProperties;
    required: Array<string>;
  };

  initialFormData: Partial<IFormData> | null;
  onCompleted: () => void;
  extraFormData: {};
}

interface IFormData {
  name: string;
  description?: string;
  scope?: string;
  scopeType: string;
}

export const ModuleForm = ({
  gql,
  moduleType,
  jsonschema,
  initialFormData,
  onCompleted,
  extraFormData = {},
}: IModuleForm) => {
  const [submitMutation] = useMutation(gql, {
    onCompleted,
  });

  const [regExRuleErrors, setRegExRuleErrors] = useState({
    hasLongRegEx: false,
    hasInvalidRegEx: false,
  });

  if (
    initialFormData &&
    initialFormData.scope &&
    typeof initialFormData.scope !== "string"
  ) {
    initialFormData.scope = JSON.stringify(initialFormData.scope);
  }

  const [formData, setFormData] = useState(initialFormData || {});

  const schema = { ...jsonschema };

  removeRequiredOnBoolean(schema);
  schema.properties.name = {
    type: "string",
    title: "Name",
  };
  schema.properties.description = {
    type: "string",
    title: "Description",
  };

  // Temporary transform scope to string instead of object
  // Display scope only if scopeType == pattern while waiting for fix with anyAll
  // https://github.com/rjsf-team/react-jsonschema-form/issues/2752
  if (schema.properties.scopeType) {
    if (formData.scopeType === "pattern") {
      schema.properties.scope = {
        type: "string",
        title: "Scope",
      };
    } else {
      delete schema.properties.scope;
    }
  } else if (schema.properties.scope) {
    schema.properties.scope = {
      type: "string",
      title: "Scope",
    };
  }

  if (schema.required?.indexOf("name") < 0) {
    schema.required.push("name");
  }

  const handleSubmit = ({ formData }: IChangeEvent<Partial<IFormData>>) => {
    if (regExRuleErrors.hasLongRegEx || regExRuleErrors.hasInvalidRegEx) {
      return;
    }

    const config = { ...formData };
    const name = config.name;
    const description = config.description;

    // Reinject scope as a real object
    config.scope = config.scope ? JSON.parse(config.scope) : null;

    // Temporary transform scope to string instead of object
    // While waiting this issue to be fixed
    // https://github.com/rjsf-team/react-jsonschema-form/issues/2752

    // Delete scope when scopeType is mapping
    if (config.scopeType !== "pattern" && config.scope) {
      delete config.scope;
    }
    const finalFormData = {
      ...{ config: JSON.stringify(config) },
      name: name,
      description: description,
      ...extraFormData,
    };

    submitMutation({ variables: { input: finalFormData } });
  };

  const widgets = {
    AceWidget: AceWidget,
    ScopeWidget: (props: WidgetProps) => (
      <ScopeWidget
        {...props}
        setRegExRuleErrors={setRegExRuleErrors}
        regExRuleErrors={regExRuleErrors}
      />
    ),
  };

  const uiSchema = {
    scope: {
      "ui:widget": "ScopeWidget",
    },
    ...(MODULE_UISCHEMAS[moduleType] || {}),
    "ui:order": ["name", "description", "scopeType", "scope", "*"],
  };

  return (
    <Card>
      <Card.Header>{moduleType} Configuration</Card.Header>
      <Card.Body>
        <Form
          schema={schema}
          widgets={widgets}
          uiSchema={uiSchema}
          formData={formData}
          onChange={(e: IChangeEvent<Partial<IFormData>>) => {
            let data = { ...e.formData };

            if (data.scopeType !== "pattern") {
              delete data.scope;
            }

            setFormData(data);
          } }
          onSubmit={handleSubmit}
          onError={(errors: RJSFValidationError[]) => console.log("Form is invalid: ", { formData, schema, errors })}
          validator={validator}        />
      </Card.Body>
    </Card>
  );
};

const usePreviewOptions = ({
  key,
  defaultValue,
}: {
  key: string;
  defaultValue: string;
}) => {
  const [value, setValue] = useFormInputSynchronizedWithLS({
    key,
    defaultValue,
  });
  const optionsSplit = value.split("+");
  return {
    value,
    previewOptions: { context: optionsSplit[0], device: optionsSplit[1] },
    setValue,
  };
};

export const getPreviewUrl = (
  url: string,
  moduleStableId: string,
  previewOptions: { device: string; context: string }
) => {
  return `${url}#activation:forceModuleDraft=${moduleStableId}&forceDevice=${previewOptions.device}&forceContext=${previewOptions.context}`;
};

export const PreviewOptionsForm = (moduleStableId: string) => {
  const {
    value: previewOptionsRaw,
    previewOptions,
    setValue: setPreviewOptions,
  } = usePreviewOptions({
    key: "previewOptions:" + moduleStableId,
    defaultValue: "bot+desktop",
  });

  const component = () => (
    <BForm.Control name="context" as="select" onChange={setPreviewOptions}>
      <option
        value="bot+desktop"
        selected={previewOptionsRaw === "bot+desktop"}
      >
        Preview as Bot on Desktop
      </option>
      <option value="bot+mobile" selected={previewOptionsRaw === "bot+mobile"}>
        Preview as Bot on Mobile
      </option>
      <option
        value="user+desktop"
        selected={previewOptionsRaw === "user+desktop"}
      >
        Preview as Human on Desktop
      </option>
      <option
        value="user+mobile"
        selected={previewOptionsRaw === "user+mobile"}
      >
        Preview as Human on Mobile
      </option>
    </BForm.Control>
  );

  return { component, previewOptions };
};
