import { Button, Card, Form, Table } from "react-bootstrap";
import { WebsiteQuery } from "../../gql/websites/website";
import { SectionType } from "../../gql/types.generated";
import { sortSections } from "./utils";
import { SectionEntry } from "./SectionEntry";
import { Link } from "react-router-dom";

interface IWebsiteHomeAdvancedSection {
  website: WebsiteQuery["website"];
  websiteId: string;
  previewURL: string;
  previewDevice: string;
  setPreviewURL: React.Dispatch<React.SetStateAction<string>>;
  setPreviewDevice: React.Dispatch<React.SetStateAction<string>>;
}

export function WebsiteHomeAdvancedSection({
  website,
  websiteId,
  previewURL,
  setPreviewURL,
  previewDevice,
  setPreviewDevice,
}: IWebsiteHomeAdvancedSection) {
  if (!website) {
    return null;
  }

  const sections = website?.draftVersion?.sections ?? [];

  const goToPreview = () => {
    const newPageUrl =
      website.beamDomain +
      "page?overlay=true&navigable=true&remove_scripts=true&resources_absolute_uris=true&token=" +
      website.previewToken +
      "&device=" +
      previewDevice +
      "&website_id=" +
      website.beamWebsiteId +
      "&uri=" +
      encodeURIComponent(previewURL);
    window.open(newPageUrl, "_blank");
  };

  return (
    <>
      <Card id="sources">
        <Card.Header>Sources</Card.Header>
        <Card.Body className="card-with-table">
          <Table striped borderless hover border-left-0 border-right-0>
            <thead>
              <tr>
                <td>Source Type</td>
                <th>Name</th>
                <th>Preview URLs</th>
              </tr>
            </thead>
            <tbody>
              {website.draftVersion?.textListInputs.map((entry) => (
                <tr>
                  <td>Text List</td>
                  <td>
                    <Link to={`/website/${websiteId}/inputs/lists/${entry.id}`}>
                      {entry.name} ({entry.nbUrls} URLs)
                    </Link>
                  </td>
                  <td>
                    <Link
                      to={`/website/${websiteId}/inputs/textList/${entry.stableId}/urls`}
                    >
                      Preview URLs
                    </Link>
                  </td>
                </tr>
              ))}

              {website.draftVersion?.csvInputs.map((entry) => (
                <tr>
                  <td>CSV</td>
                  <td>
                    {entry.name} ({entry.nbDays} days - {entry.mode})
                  </td>
                  <td>
                    <Link
                      to={`/website/${websiteId}/inputs/csv/${entry.stableId}/urls`}
                    >
                      Preview URLs
                    </Link>
                  </td>
                </tr>
              ))}

              {website.draftVersion?.linksDiscoveryInputs.map((entry) => (
                <tr>
                  <td>Links Discovery</td>
                  <td>
                    {entry.name} ({entry.nbDays} days)
                  </td>
                  <td>
                    <Link
                      to={`/website/${websiteId}/inputs/linksDiscovery/${entry.stableId}/urls`}
                    >
                      Preview URLs
                    </Link>
                  </td>
                </tr>
              ))}
              {website.draftVersion?.sitemapInputs.map((entry) => (
                <tr>
                  <td>Sitemap</td>
                  <td>
                    {entry.name} ({entry.nbDays} days - {entry.url} -{" "}
                    {entry.mode})
                  </td>
                  <td>
                    <Link
                      to={`/website/${websiteId}/inputs/sitemap/${entry.stableId}/urls`}
                    >
                      Preview URLs
                    </Link>
                  </td>
                </tr>
              ))}
              {website.draftVersion?.botsDiscoveryInputs.map((entry) => (
                <tr>
                  <td>Bots Discovery</td>
                  <td>
                    {entry.name} ({entry.nbDays} days)
                  </td>
                  <td />
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Body>
        <Card.Body>
          <Link
            role="button"
            to={`/website/${website.id}/inputs/lists/create`}
            className="btn btn-primary"
          >
            Create a new text list source
          </Link>
        </Card.Body>
      </Card>
      <Card id="behaviors">
        <Card.Header>Standard Behaviors</Card.Header>
        <Card.Body className="card-with-table">
          <Table striped borderless hover border-left-0 border-right-0>
            <thead>
              <tr>
                <th>Name</th>
                <th>Config</th>
                <th>Priority</th>
              </tr>
            </thead>

            <tbody>
              {sections
                .filter(({ mode }) => mode === "ALLOW")
                .sort(sortSections)
                .map((section, i) => (
                  <SectionEntry
                    key={section.id}
                    index={i}
                    section={section as SectionType}
                    sectionsCount={sections.length}
                    websiteId={websiteId}
                  />
                ))}
            </tbody>
          </Table>
        </Card.Body>

        <Card.Body>
          <Link
            role="button"
            to={`/website/${website.id}/section/create`}
            className="btn btn-primary"
          >
            Create new Behavior
          </Link>
        </Card.Body>
      </Card>
      <Card id="optimized-behaviors">
        <Card.Header>
          Optimized Behaviors (Blocking, 3xx, 4xx, Custom HTML)
        </Card.Header>

        <Card.Body className="card-with-table">
          <Table striped borderless hover border-left-0 border-right-0>
            <thead>
              <tr>
                <th>Name</th>
                <th>Config</th>
                <th>Priority</th>
              </tr>
            </thead>

            <tbody>
              {sections
                .filter((section) => section.mode !== "ALLOW")
                .sort(sortSections)
                .map((section, i) => {
                  return (
                    <SectionEntry
                      key={section.id}
                      index={i}
                      section={section as SectionType}
                      sectionsCount={sections.length}
                      websiteId={website.id}
                    />
                  );
                })}
            </tbody>
          </Table>
        </Card.Body>
        <Card.Body>
          <Link
            role="button"
            to={`/website/${website.id}/section/create`}
            className="btn btn-primary"
          >
            Create new Behavior
          </Link>
        </Card.Body>
      </Card>
      <Card id="advanced-options">
        <Card.Header>Advanced Options</Card.Header>
        <Card.Body>
          <Link to={`/website/${website.id}/inputs/botifyAnalytics/import`}>
            Import Punctual Crawl From Botify Analytics
          </Link>
          <br />
          <Link to={`/website/${website.id}/inputs/botifyAnalytics/automate`}>
            Activate Automation of Botify Analytics crawls
          </Link>
          <br />
          <Link to={`/website/${websiteId}/ba-config`}>
            Configure Page Edit for BA JS crawls
          </Link>
          <hr />
          <Link to={`/website/${websiteId}/setupChecker`}>Setup Checker</Link>
          <hr />
          <Link to={`/website/${websiteId}/segmentation`}>Segmentation</Link>
        </Card.Body>
      </Card>
      <Card id="preview">
        <Card.Header>Cache Preview</Card.Header>
        <Card.Body>
          <div className="form-group">
            <Form.Control
              type="text"
              placeholder="Enter an URL"
              onChange={(e) => setPreviewURL(e.target.value)}
              value={previewURL}
            />
            <Form.Group>
              <Form.Control
                as="select"
                onChange={(e) => setPreviewDevice(e.target.value)}
              >
                <option value="desktop" selected={previewDevice === "desktop"}>
                  Desktop
                </option>
                <option value="mobile" selected={previewDevice === "mobile"}>
                  Mobile
                </option>
              </Form.Control>
            </Form.Group>
          </div>
          <Button onClick={(e) => goToPreview()}>Preview</Button>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>PageWorkers</Card.Header>
        <Card.Body>
          <Link to={`/website/${websiteId}/pageEdit`}>Explore Edited URLs</Link>
          <br />
          <Link to={`/website/${websiteId}/pageEdit/import`}>
            Batch Import of HTML elements
          </Link>
        </Card.Body>
      </Card>
    </>
  );
}
