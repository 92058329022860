import { gql } from "@apollo/client";

export const UPDATE_INPUT_TEXT_LIST = gql`
  mutation UpdateInputTextList($input: UpdateInputTextListMutationInput!) {
    updateInputTextList(input: $input) {
      textListInput {
        id
        name
        nbUrls
        urls
      }
      errors {
        field
        messages
      }
    }
  }
`;
