import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { BaseCard } from "../../../monitoring/Home/Cards/Base";
import { reducebyDimensions, StackedBarChart } from "../../../common/charts";
import { VERSION_COLORS } from "./constants";

const GQL_SCOPE_QUERY = gql`
  query report($websiteId: String!, $moduleId: String!) {
    moduleReportingScope(websiteId: $websiteId, moduleId: $moduleId) {
      version
      date
      isInGsc
      nbUrls
    }
  }
`;

type IScopeEntry = {
  date: string;
  version: string;
  nb_urls: number;
  is_in_gsc: number;
};

export function ScopeCards({ variables }: any) {
  const { loading, error, data } = useQuery(GQL_SCOPE_QUERY, {
    variables,
  });

  const scope = data?.moduleReportingScope;
  const scopeGsc = data ? scope.filter((d: any) => d.isInGsc === true) : null;

  return (
    <>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error?.message}</p>}

      {data && (
        <Row>
          <Col md={6}>
            <BaseCard
              name="Scope"
              body={
                <ScopeCard
                  data={scope}
                  helpText="Cumulative distinct sum of pages found 90 days before first deployment"
                />
              }
            />
          </Col>
          <Col md={6}>
            <BaseCard
              name="Scope only on URLs found in Google Search Console"
              body={
                <ScopeCard
                  data={scopeGsc}
                  helpText="Cumulative distinct sum of pages found on GSC, starting from the first deployment"
                />
              }
            />
          </Col>
        </Row>
      )}
    </>
  );
}

export function ScopeCard({ data, helpText }: any) {
  const reducedData = reducebyDimensions(
    data,
    ["date", "version"],
    "nbUrls"
  ) as IScopeEntry[];
  const newData = reducedData.map((entry: IScopeEntry) => {
    const modifiedVersion =
      entry.version === null ? "No PW" : "v" + entry.version;
    return {
      ...entry,
      version: modifiedVersion,
    };
  });

  return (
    <>
      <StackedBarChart
        data={newData}
        dimension="date"
        splitBy="version"
        metric="nbUrls"
        colors={VERSION_COLORS}
        xTitle="Date"
        yTitle="# URLs"
        height="250px"
      />
      {helpText && (
        <p>
          <i>{helpText}</i>
        </p>
      )}
    </>
  );
}
