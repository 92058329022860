import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";

import { useQuery } from "@apollo/client";
import { omit } from "lodash";
import Container from "react-bootstrap/Container";
import { usePagination } from "./common/Pagination/usePagination";
import "./common/common.css";
import { GET_WEBSITES_LIST } from "./gql/websites/website/websitesList.gql";
import {
  PaginatedWebsitesWithFiltersQuery,
  PaginatedWebsitesWithFiltersQueryVariables,
} from "./gql/websites/website/websitesList.gql.generated";
import { ClusterFilter } from "./websites/websitesFilters/clusterFilter";
import {
  Filters,
  WebsitesFilterHandler,
} from "./websites/websitesFilters/helpers";
import { useWebsiteFilters } from "./websites/websitesFilters/useWebsitesFilters";
import { WebsitesFilters } from "./websites/websitesFilters/websitesFilters";
import { WebsitesList } from "./websites/websitesList/websitesList";

export function Home() {
  const { filters, setFilters } = useWebsiteFilters();
  const { page, setPage, pageSize } = usePagination();
  const { loading, error, data } = useQuery<
    PaginatedWebsitesWithFiltersQuery,
    PaginatedWebsitesWithFiltersQueryVariables
  >(GET_WEBSITES_LIST, {
    variables: {
      filters,
      pagination: {
        page,
        size: pageSize,
      },
    },
  });

  const websites = data?.websitesList?.websites ?? [];
  const websitesCount = data?.websitesList?.pageInfo.count ?? 0;

  const handleFilter: WebsitesFilterHandler = (filterKey, filterValue) => {
    setFilters((previousFilters) => {
      const updatedFilters: Filters = omit(previousFilters, filterKey);

      if (filterValue !== undefined) {
        updatedFilters[filterKey] = filterValue;
      }

      return updatedFilters;
    });
    // Get to first page if not currently on it when changing filters
    if (page !== 1) {
      setPage(1);
    }
  };

  const handleSearch = (value: string) =>
    handleFilter("nameHostsIdCluster", value || undefined);

  const errorMessage = error ? error.message : null;

  document.title = "Botify Activation";

  return (
    <Container>
      <h1>Welcome to Botify Activation Console</h1>
      <Card>
        <Card.Header as="h5">Websites</Card.Header>
        <Card.Body>
          <Form.Label>
            Search By Website name or id or By Cluster name
          </Form.Label>
          <Form.Control
            type="text"
            aria-label="filter websites or cluster input"
            placeholder="Filter websites or cluster"
            value={filters["nameHostsIdCluster"] ?? ""}
            onChange={(e) => handleSearch(e.target.value)}
            autoFocus={true}
          />
          <ClusterFilter
            onChange={handleFilter}
            filterKey="isDemo"
            filterValue={filters["isDemo"]}
          />

          <WebsitesFilters handleFilter={handleFilter} filters={filters} />

          {loading ? "Fetching" : null}
          {errorMessage ? (
            <Alert variant="danger">
              Something went wrong.
              <br />
              <pre>{errorMessage}</pre>
            </Alert>
          ) : null}
          {!error && !loading ? (
            <div className="card-body-negate-padding">
              <WebsitesList websites={websites} itemsCount={websitesCount} />
            </div>
          ) : null}
        </Card.Body>
      </Card>
    </Container>
  );
}
