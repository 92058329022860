import { gql } from "@apollo/client";

export const ANALYZE_SECTION_REFRESH_IMPACT = gql`
  mutation AnalyzeSectionRefreshImpact($websiteId: ID!, $sectionId: String!) {
    analyzeSectionRefreshImpact(websiteId: $websiteId, sectionId: $sectionId) {
      errors {
        message
      }

      totalInventoryUrls
      nbOfUrlsToRefresh
      maxAllowedUrlsToRefresh

      estimatedRefreshDuration
      maxAllowedRefreshDuration

      estimatedNbOfDelayedPages
      estimatedDelayRecoveryDuration

      impactLevel

      forceRefreshToken
    }
  }
`;
