import { gql } from "@apollo/client";

export const GET_WEBSITE_DEBUG_CONFIG = gql`
  query WebsiteDebugConfig($id: String!) {
    website(id: $id) {
      id
      name
      debugConfig {
        mainConfig
        draftMainConfig
        inputsConfig
        draftInputsConfig
        cronsConfig
        draftCronsConfig
        renderingJsCode
        renderingJsConfig
        draftRenderingJsCode
        deliveryJsConfig
      }
    }
  }
`;
