import { Title } from "./Title";
import { SelectorsHeader } from "./SelectorsHeader";
import { SubTitle } from "./SubTitle";

type HeaderComponent = React.FC<{ children: React.ReactNode }> & {
  Selectors: typeof SelectorsHeader;
  Title: typeof Title;
  SubTitle: typeof SubTitle;
};

export const Header: HeaderComponent = ({ children }) => {
  return (
    <div style={{ display: "grid", gap: "1em", marginBottom: "1em" }}>
      {children}
    </div>
  );
};

Header.Title = Title;
Header.Selectors = SelectorsHeader;
Header.SubTitle = SubTitle;
