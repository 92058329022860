import moment from "moment";
import { Button } from "react-bootstrap";

import { goToPreview } from "../../common/preview";
import { shortUrl, truncate } from "../../common/str";
import {
  IMetricCard,
  IPagesDefinition,
  IPreset,
} from "../../common/typing/website";
import {
  QueryIndexedPagesResult,
  QueryServedPagesResult,
} from "../../gql/types.generated";
import { Copy } from "../../components/Copy/Copy";

export const FIELDS_VERBOSE = {
  url: "URL",
  device: "Device",
  httpCode: "HTTP Code",
  timeToFetchMs: "Time to fetch (ms)",
  timeToRenderMs: "Time to render (ms)",
  datetime: "Date",
  nextRefreshTime: "Next Refresh Date",
  ttl: "TTL",
  indexed: "Indexed",
  notDeliverableReason: "Not Deliverable Reason",
};

export const CARDS: Record<
  string,
  IMetricCard<QueryIndexedPagesResult & QueryServedPagesResult>
> = {
  count: {
    name: "# Entries in cache",
    align: "right",
    display: (item, data) => {
      return <span>{Intl.NumberFormat().format(item.count)}</span>;
    },
  },
  countUrls: {
    name: "# URLs",
    align: "right",
    display: (item, data) => {
      return <span>{Intl.NumberFormat().format(item.countUrls)}</span>;
    },
  },
  canonicalUrl: {
    name: "Canonical URL",
    fields: ["canonicalUrl"],
    display: (item, data) => {
      return (
        <a href={item.canonicalUrl} target="_blank" rel="noreferrer">
          {shortUrl(item.canonicalUrl, 100)}
        </a>
      );
    },
  },
  exampleUrl: {
    name: "URL Example",
    fields: ["exampleUrl"],
    display: (item, data) => {
      return (
        <span>
          {shortUrl(item.exampleUrl, 100)} |{" "}
          <a href={item.exampleUrl} target="_blank" rel="noreferrer">
            Original Page
          </a>
        </span>
      );
    },
  },
  urlsSample: {
    name: "Sample of URLs (max 10)",
    fields: ["urlsSample"],
    display: (item, data) => {
      return (
        <span>
          {item.urlsSample.map((url) => {
            if (url) {
              return (
                <>
                  <a href={url} target="_blank" rel="noreferrer">
                    {shortUrl(url, 100)}
                  </a>
                  <br />
                </>
              );
            }
            return null;
          })}
        </span>
      );
    },
  },
  renderPerfs: {
    name: "Time to fetch / render",
    fields: ["timeToFetchMs", "timeToRenderMs"],
    display: (item, data) => {
      return (
        <span>
          {item.timeToFetchMs}ms
          <br />
          {item.timeToRenderMs}ms
        </span>
      );
    },
  },
  totalTime: {
    name: "Time to fetch + render",
    fields: ["timeToFetchMs", "timeToRenderMs"],
    display: (item, data) => {
      return <span>{item.timeToFetchMs + item.timeToRenderMs}ms </span>;
    },
  },
  ttlReadable: {
    name: "TTL",
    fields: ["ttl"],
    display: (item, data) => {
      return (
        <span>
          {moment
            .duration(item.ttl, "minutes")
            .humanize()
            .replace(" ", "\u00a0")}
        </span>
      );
    },
  },
  url: {
    name: "URL",
    fields: ["url", "sectionName"],
    display: (item, data) => {
      return (
        <>
          <Copy textToCopy={item.url} />
          {shortUrl(item.url, 80)}{" "}
          <Button
            variant="link"
            style={{ padding: 0 }}
            onClick={() => goToPreview(data, item.url, item.device)}
          >
            Preview
          </Button>{" "}
          ({item.sectionName})
        </>
      );
    },
  },

  queryStringKeys: {
    name: "Query String Keys",
    fields: ["queryStringKeys"],
    display: (item, data) => {
      return <span>{truncate(item.queryStringKeys, 100)}</span>;
    },
  },
  datetime: {
    name: "Date (UTC)",
    display: (item, data) => {
      return (
        <span style={{ whiteSpace: "nowrap" }}>
          {item.datetime.replace("+00:00", "")}
        </span>
      );
    },
  },
  indexed: {
    name: "Indexed",
    fields: ["indexed"],
    display: (item, data) => {
      return <span>{item.indexed ? "yes" : "no"}</span>;
    },
  },
  notDeliverableReason: {
    name: "Not Deliverable Reason",
    fields: ["notDeliverableReason"],
    display: (item, data) => {
      return <span>{item.notDeliverableReason}</span>;
    },
  },
};

export const INDEXED_PAGES_DEFINITION: IPagesDefinition = {
  fieldsVerbose: FIELDS_VERBOSE,
  gqlFunction: "queryIndexedPages",
  dimensions: [
    "url",
    "device",
    "datetime",
    "httpCode",
    "fetchTime",
    "renderTime",
    "ttl",
  ],
  cards: CARDS,
};

export const PRESET_RAW = {
  id: "last",
  cards: [
    "url",
    "device",
    "datetime",
    "httpCode",
    "renderPerfs",
    "totalTime",
    "ttlReadable",
    "indexed",
    "notDeliverableReason",
  ],
  orderBy: "-datetime",
};

export const PRESET_RAW_24: IPreset = {
  ...PRESET_RAW,
  id: "last24",
  startOffset: 1,
};

export const PRESET_RAW_DESKTOP: IPreset = {
  id: "last",
  cards: [
    "url",
    "device",
    "datetime",
    "httpCode",
    "renderPerfs",
    "totalTime",
    "ttlReadable",
  ],
  orderBy: "-datetime",
  filters: [{ field: "device", predicate: "eq", value: "desktop" }],
};

export const PRESET_RAW_MOBILE: IPreset = {
  id: "last",
  cards: [
    "url",
    "device",
    "datetime",
    "httpCode",
    "renderPerfs",
    "totalTime",
    "ttlReadable",
  ],
  orderBy: "-datetime",
  filters: [{ field: "device", predicate: "eq", value: "mobile" }],
};

export const PRESET_TOP: IPreset = {
  id: "top",
  cards: ["url", "httpCode", "device", "countUrls"],
  orderBy: "-countUrls",
};

export const PRESET_TOP_BAD_CANONICAL: IPreset = {
  id: "top",
  cards: ["canonicalUrl", "device", "urlsSample", "count", "countUrls"],
  orderBy: "-countUrls",
  filters: [{ field: "canonical_equal", predicate: "eq", value: false }],
};

export const PRESET_2XX: IPreset = {
  id: "3xx",
  cards: [
    "url",
    "device",
    "datetime",
    "httpCode",
    "renderPerfs",
    "totalTime",
    "ttlReadable",
  ],
  orderBy: "-datetime",
  filters: [{ field: "http_code", predicate: "eq", value: 200 }],
};

export const PRESET_3XX: IPreset = {
  id: "3xx",
  cards: [
    "url",
    "device",
    "datetime",
    "httpCode",
    "renderPerfs",
    "totalTime",
    "ttlReadable",
  ],
  orderBy: "-datetime",
  filters: [
    { field: "http_code", predicate: "gte", value: 300 },
    { field: "http_code", predicate: "lt", value: 400 },
  ],
};

export const PRESET_4XX: IPreset = {
  id: "4xx",
  cards: [
    "url",
    "device",
    "datetime",
    "httpCode",
    "renderPerfs",
    "totalTime",
    "ttlReadable",
  ],
  orderBy: "-datetime",
  filters: [
    { field: "http_code", predicate: "gte", value: 400 },
    { field: "http_code", predicate: "lt", value: 500 },
  ],
};

export const PRESET_5XX: IPreset = {
  id: "5xx",
  cards: [
    "url",
    "device",
    "datetime",
    "httpCode",
    "renderPerfs",
    "totalTime",
    "ttlReadable",
  ],
  orderBy: "-datetime",
  filters: [
    { field: "http_code", predicate: "gte", value: 500 },
    { field: "http_code", predicate: "lt", value: 600 },
  ],
};

export const PRESETS = {
  last: PRESET_RAW,
  last24: PRESET_RAW_24,
  last_desktop: PRESET_RAW_DESKTOP,
  last_mobile: PRESET_RAW_MOBILE,
  top: PRESET_TOP,
  topBadCanonical: PRESET_TOP_BAD_CANONICAL,
  "2xx": PRESET_2XX,
  "3xx": PRESET_3XX,
  "4xx": PRESET_4XX,
  "5xx": PRESET_5XX,
};

export const DEFAULT_START_OFFSET = 30;
