import { gql } from "@apollo/client";

export const GET_WEBSITES_LIST = gql`
  query PaginatedWebsitesWithFilters(
    $filters: WebsitesFilterInput
    $pagination: PaginationTypeInput
  ) {
    websitesList(filters: $filters, pagination: $pagination) {
      websites {
        id
        name
        productionVersion {
          id
          hosts
          enableSmartCacheRefresh
          useRenderingFarm
          linksDiscoveryInputs {
            id
          }
          botsDiscoveryInputs {
            id
          }
        }
        hasAdnEnabled
        cluster {
          id
          name
          cloud
          region
          domain
        }
      }
      pageInfo {
        count
        page
        size
      }
    }
  }
`;
