import { gql } from "@apollo/client";

export const UPDATE_SECTION_CODE = gql`
  mutation UpdateSectionCode($input: UpdateSectionCodeMutationInput!) {
    updateSectionCode(input: $input) {
      section {
        id
        renderingOnSetupBeforeRenderCode
        renderingOnInitCode
        renderingDomContentLoadedCode
        renderingOnloadCode
        renderingOnActionCode
        renderingOnWaitForCode
        renderingOndoneCode
        preBeamResponseCode
      }
    }
  }
`;
