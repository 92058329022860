import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { JsCodeType } from "../../../../gql/types.generated";
import { ActionsCell } from "./cells/ActionsCell";
import { NameCell } from "./cells/NameCell";
import { SectionsCell } from "./cells/SectionsCell";
import { StatusCell } from "./cells/StatusCell";
import { StepsCell } from "./cells/StepsCell";

export type ScriptsTableColumn = {
  key: string;
  header: React.ReactNode;
  cell: (
    script: JsCodeType,
    context: { dragListeners: SyntheticListenerMap | undefined }
  ) => JSX.Element;
};

export const SCRIPTS_TABLE_COLUMNS = [
  {
    key: "name",
    header: "Name",
    cell: ({ name }, { dragListeners }) => (
      <NameCell name={name} dragListeners={dragListeners} />
    ),
  },
  {
    key: "status",
    header: "Status",
    cell: StatusCell,
  },
  {
    key: "steps",
    header: "Rendering steps",
    cell: StepsCell,
  },
  {
    key: "behaviors",
    header: "Behaviors",
    cell: SectionsCell,
  },
  {
    key: "actions",
    header: "",
    cell: ActionsCell,
  },
] satisfies ScriptsTableColumn[];
