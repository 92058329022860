import React from "react";
import { Card, Table } from "react-bootstrap";

export const ActionsAndTransformations = () => {
  return (
    <>
      {" "}
      <h3>Extractions</h3>
      <h3>Actions</h3>
      <p>Actions apply modification into the final webpage </p>
      <h4>Update Title</h4>
      <p>Update the title anchor</p>
      <h4>Update H1</h4>
      <p>Update the H1 anchor</p>
      <Table style={{ width: "70%" }} striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Field Name</th>
            <th>Type</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>notExistsCssSelector</td>
            <td>string</td>
            <td>
              CSS Selector on which to create h1 anchor in case H1 doesn't exist
            </td>
          </tr>
          <tr>
            <td>notExistsStrategy</td>
            <td>enum(append|prepend|insertBefore|insertAfter)</td>
            <td>CSS injection strategy to apply in case H1 doesn't exist</td>
          </tr>
        </tbody>
      </Table>
      <h4>Update Description</h4>
      <p>Update the meta description</p>
      <h4>Update Canonical</h4>
      <p>Update the rel canonical</p>
      <h4>Update Element's Inner HTML</h4>
      <p>Update Inner HTML of specific element</p>
      <Table style={{ width: "70%" }} striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Field Name</th>
            <th>Type</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>cssSelector</td>
            <td>string</td>
            <td>CSS Selector on which to inject the field</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export const PageEditHelp = () => {
  return (
    <Card>
      <Card.Body className="moduleHelp">
        <p>
          <b>PageEdit</b> is a module that allows to store dedicated data for
          given URLs
        </p>
        <p>
          The configuration is splitted between 3 concepts :
          <br />- The schema on which you will declare the fields you want to
          store for each URL.
          <br />- Eventually some extracted elements on the page we may want to
          manipulate
          <br />- The actions you want to apply on the web page, by manipulating
          schema fields or extracted HTML elements.
        </p>
        <h3>Schema Fields</h3>
        Fields has following types :
        <ul>
          <li>String</li>
          <li>Integer</li>
          <li>Float</li>
          <li>Boolean</li>
        </ul>
        <p>You can make them required or not.</p>
        <ActionsAndTransformations />
      </Card.Body>
    </Card>
  );
};
