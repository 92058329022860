import { gql } from "@apollo/client";

export const CREATE_SETUP_CHECKER = gql`
  mutation CreateSetupChecker($input: CheckSetupMutationInput!) {
    checkSetup(input: $input) {
      success
      errorReason
      httpCode
      html
      url
      headers {
        key
        value
      }
    }
  }
`;
