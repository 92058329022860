import { useQuery } from "@apollo/client";

import { BarChart } from "../../../common/charts";
import { DataHandler } from "../../../components/DataHandler";
import type {
  QueryQueryServedPagesArgs,
  QueryServedPagesResult,
} from "../../../gql/types.generated";
import { getQuery } from "../../Helpers";
import type { IMonitoringOptions } from "../../types";
import { METRICS_MAPPING } from "../Constants";
import { Link } from "react-router-dom";

type HitsCardMetric = keyof typeof METRICS_MAPPING.servedPages;

interface IHitsCardProps {
  metric?: HitsCardMetric;
  options: IMonitoringOptions;
}

export function HitsCard({ options, metric = "countHits" }: IHitsCardProps) {
  const { loading, error, data } = useQuery<
    {
      queryServedPages: Pick<QueryServedPagesResult, "date" | HitsCardMetric>[];
    },
    QueryQueryServedPagesArgs
  >(
    getQuery({
      name: "HitsQuery",
      metrics: ["date", metric],
      dimensions: ["date"],
      orderBy: ["date"],
    }),
    {
      variables: {
        timestampStart: options.timestampStart30dMidnight,
        timestampEnd: options.timestampEnd,
      },
    }
  );

  if (error || loading || !data) {
    return (
      <DataHandler error={error} loading={loading} data={data} expectData />
    );
  }

  return (
    <>
      <BarChart
        data={data.queryServedPages}
        dimension="date"
        metric={metric}
        color={METRICS_MAPPING.servedPages[metric]?.color}
        xTitle="Date"
        yTitle="Number of Hits"
        height="250px"
      />
      Get Detail by Website :{" "}
      <Link to={"websites/servedPages/30d/" + metric}>Last 30 days</Link>,{" "}
      <Link to={"websites/servedPages/24h/" + metric}>Last 24 Hours</Link>
    </>
  );
}
