export const isValidJSONHeaders = (str?: string): boolean => {
  if (!str) {
    return true;
  }
  let json = null;
  try {
    json = JSON.parse(str);
  } catch (err) {
    return false;
  }
  return Object.entries(json).every(([name, value]) => {
    return /^\w+(-\w+)*$/.test(name) && typeof value === "string";
  });
};

export const isValidWorkerVersion = (versions: string[], str?: string): boolean => {
  if (!versions?.length) return false;
  if (!str) return false;
  return versions.includes(str);
};

export const isValidURL = (str?: string) => {
  if (!str) {
    return true;
  }
  try {
    new URL(str);
    return true;
  } catch (err) {
    return false;
  }
};
