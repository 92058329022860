import { Status } from "../typing";

const ICONS: Record<Status, string> = {
  SUCCESS: "✅",
  WARNING: "⚠️",
  FAIL: "❌",
};

export const StatusIcon = ({ status }: { status: Status }) => (
  <>{ICONS[status]}</>
);
