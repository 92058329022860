import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { BaseCard } from "../../../monitoring/Home/Cards/Base";
import {
  MultiLineChart,
  reducebyDimensions,
  StackedBarChart,
} from "../../../common/charts";

const GQL_METRICS_QUERY = gql`
  query report($websiteId: String!, $moduleId: String!) {
    EXPERIMENTAL_moduleReportingMetrics(
      websiteId: $websiteId
      moduleId: $moduleId
    ) {
      date
      impressionsWithPw
      impressionsWoPw
      clicksWithPw
      clicksWoPw
      avgImpressionsPerUrl
      ctr7d
      ctrWithPw7d
      uniqueRankings7d
      uniqueRankingsNew7d
      uniqueUrls7d
      avgPosition
      avgPositionRankingsLt1m
      uniqueUrls7d
      uniqueUrlsNew7d
      uniqueUrlsNew30d
    }
  }
`;

export function MetricsCards({ variables, versions }: any) {
  const { loading, error, data } = useQuery(GQL_METRICS_QUERY, {
    variables,
  });

  const metrics = data?.EXPERIMENTAL_moduleReportingMetrics;
  const impressions = data
    ? metrics.flatMap((d: any) => {
        return [
          { date: d.date, pw: "With PW", count: d.impressionsWithPw },
          { date: d.date, pw: "Without PW", count: d.impressionsWoPw },
        ];
      })
    : null;
  const clicks = data
    ? metrics.flatMap((d: any) => {
        return [
          { date: d.date, pw: "With PW", count: d.clicksWithPw },
          { date: d.date, pw: "Without PW", count: d.clicksWoPw },
        ];
      })
    : null;

  const annotations = versions
    .filter((v: any) => v.versionNum > 0 && v.versionDatePublished !== null)
    .map((v: any) => {
      return {
        xValue: v.versionDatePublished.slice(0, 10),
        label: "v" + v.versionNum,
      };
    });

  return (
    <>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error.message}</p>}

      {data && (
        <Row>
          <Col md={6}>
            <BaseCard
              name="Impressions"
              body={
                <MetricCard
                  id="impressions"
                  data={impressions}
                  metric="count"
                />
              }
            />
          </Col>
          <Col md={6}>
            <BaseCard
              name="Clicks"
              body={<MetricCard id="clicks" data={clicks} metric="count" />}
            />
          </Col>
          <Col md={6}>
            <BaseCard
              name="Impressions per URL"
              body={
                <MultiLineCard
                  data={metrics}
                  metrics={["avgImpressionsPerUrl"]}
                  colors={["#5A8BE6"]}
                  annotations={annotations}
                />
              }
            />
          </Col>
          <Col md={6}>
            <BaseCard
              name="CTR"
              body={
                <MultiLineCard
                  data={metrics}
                  metrics={["ctr7d", "ctrWithPw7d"]}
                  colors={["#5A8BE6", "#E54C47"]}
                  annotations={annotations}
                />
              }
            />
          </Col>
          <Col md={6}>
            <BaseCard
              name="Rankings (7 days window)"
              body={
                <MultiLineCard
                  data={metrics}
                  metrics={["uniqueRankings7d"]}
                  colors={["#E54C47"]}
                  annotations={annotations}
                />
              }
            />
          </Col>
          <Col md={6}>
            <BaseCard
              name="New Rankings"
              body={
                <MultiLineCard
                  data={metrics}
                  metrics={["uniqueRankingsNew7d"]}
                  colors={["#E54C47"]}
                  annotations={annotations}
                />
              }
            />
          </Col>
          <Col md={6}>
            <BaseCard
              name="Unique URLs"
              body={
                <MultiLineCard
                  data={metrics}
                  metrics={["uniqueUrls7d"]}
                  colors={["#5A8BE6", "#E54C47", "#F0C461"]}
                  annotations={annotations}
                />
              }
            />
          </Col>
          <Col md={6}>
            <BaseCard
              name="New URLs"
              body={
                <MultiLineCard
                  data={metrics}
                  metrics={["uniqueUrlsNew7d", "uniqueUrlsNew30d"]}
                  colors={["#E54C47", "#F0C461"]}
                  annotations={annotations}
                />
              }
            />
          </Col>

          <Col md={6}>
            <BaseCard
              name="Average Position"
              body={
                <MultiLineCard
                  data={metrics}
                  metrics={["avgPosition", "avgPositionRankingsLt1m"]}
                  colors={["#9047E6", "#5DF08A"]}
                  annotations={annotations}
                />
              }
            />
          </Col>
        </Row>
      )}
    </>
  );
}

export function MetricCard({ id, data, metric }: any) {
  const newData = reducebyDimensions(data, ["date", "pw"], metric);
  const colors: any = {
    impressions: "#5A8BE6",
    clicks: "#E54C47",
  };
  const finalColors = {
    "With PW": colors[id],
    "Without PW": "#D3D3D3",
  };
  return (
    <>
      <StackedBarChart
        data={newData}
        dimension="date"
        splitBy="pw"
        metric={metric}
        colors={finalColors}
        xTitle="Date"
        yTitle="# URLs"
        height="250px"
      />
    </>
  );
}

function MultiLineCard({ data, metrics, colors, annotations }: any) {
  return (
    <MultiLineChart
      data={data}
      dimension="date"
      metrics={metrics}
      colors={colors}
      xTitle="Date"
      yTitle="CTR"
      annotations={annotations}
    />
  );
}
