import { gql } from "@apollo/client";

export const MOVE_SECTION = gql`
  mutation MoveSection($input: SectionMoveMutationInput!) {
    sectionMove(input: $input) {
      section {
        id
        name
        sectionPriority
      }
      otherSection {
        id
        name
        sectionPriority
      }
    }
  }
`;
