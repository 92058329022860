import { gql } from "@apollo/client";

export const CREATE_ADN_CONFIG = gql`
  mutation CreateAdnConfig($input: CreateADNConfigMutationInput!) {
    createAdnConfig(input: $input) {
      errors {
        field
        messages
      }
      adnConfig {
        id
      }
    }
  }
`;
