import { useEffect, useState } from "react";

import { WebsiteIdParams } from "../types/routeParams";
import {
  GET_WEBSITE,
  GET_WEBSITE_MONITORING,
  GET_WEBSITE_MONITORING_SPEED,
  WebsiteMonitoringQuery,
  WebsiteMonitoringQueryVariables,
  WebsiteMonitoringSpeedQuery,
  WebsiteMonitoringSpeedQueryVariables,
  WebsiteQuery,
  WebsiteQueryVariables,
} from "../../gql/websites/website";
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import { useAdvancedMode } from "../../common/hooks";
import { DataHandler } from "../../components/DataHandler";
import {
  BarChart,
  cleanConsecutiveLabelsSameDay,
  populateEmptyPoints,
  removeTypenameFromData,
} from "../../common/charts";
import { moment } from "../../common/moment";
import { CheckComponent } from "../components/checkers";
import { Messager } from "../components/messager";
import { Button, Card, Col, Row, Table } from "react-bootstrap";
import { VersioningCard } from "./VersioningCard";
import { QuickAccessNav } from "./QuickAccessNav";
import { ConfigCard } from "./ConfigCard";
import { WebsiteHomeAdvancedSection } from "./WebsiteHomeAdvancedSection";
import { WebsiteType} from "../../gql/types.generated";
import { TokenScope } from "../../constants";
import { Link, useParams } from "react-router-dom";
import {FETCH_BOTIFY_PROJECT_LINK} from "../../gql/websites/website/fetchBotifyProjectLink.gql";
import {
  FetchBotifyProjectLinkMutation,
  FetchBotifyProjectLinkMutationVariables
} from "../../gql/websites/website/fetchBotifyProjectLink.gql.generated";

export function WebsiteHome() {
  const { websiteId } = useParams<WebsiteIdParams>() as WebsiteIdParams;

  const now = new Date();
  const coeff = 1000 * 60;
  const coeff24h = 1000 * 60 * 60 * 24;

  // Round to last minute
  const end = new Date(Math.round(now.getTime() / coeff) * coeff);
  const start = new Date(Math.round(end.getTime() - coeff24h));
  const tEnd = Math.floor(end.getTime() / 1000);
  start.setMinutes(0);
  const tStart = Math.floor(start.getTime() / 1000);

  const range = moment.range(start, end);
  const hours = Array.from(range.by("hour", { excludeEnd: true }));
  const hoursFormatted = hours.map((m) => m.format("YYYY-MM-DD HH"));

  const { loading, error, data } = useQuery<
    WebsiteQuery,
    WebsiteQueryVariables
  >(GET_WEBSITE, { variables: { id: websiteId } });

  const [metric, setMetric] = useState("countHits");
  const [linkToReport, setLinkToReport] = useState("");

  const [execute, { data: monitoringData }] = useLazyQuery<
    WebsiteMonitoringQuery,
    WebsiteMonitoringQueryVariables
  >(GET_WEBSITE_MONITORING, {
    variables: { id: websiteId, timestampStart: tStart, timestampEnd: tEnd },
  });
  const [executeSpeed, { data: speedData }] = useLazyQuery<
    WebsiteMonitoringSpeedQuery,
    WebsiteMonitoringSpeedQueryVariables
  >(GET_WEBSITE_MONITORING_SPEED, {
    variables: { id: websiteId, timestampStart: tStart, timestampEnd: tEnd },
  });

  const initialPreviewURL = localStorage.getItem("preview_" + websiteId) || "";
  const initialPreviewDevice =
    localStorage.getItem("previewDevice" + websiteId) || "mobile";
  const [previewURL, setPreviewURL] = useState(initialPreviewURL);
  const [previewDevice, setPreviewDevice] = useState(initialPreviewDevice);
  const { isAdvancedMode } = useAdvancedMode();

  useEffect(() => {
    if (data && data.website) {
      execute();
      executeSpeed();
    }
  }, [data, execute, executeSpeed]);
  useEffect(
    () => localStorage.setItem("preview_" + websiteId, previewURL),
    [previewURL, websiteId]
  );
  useEffect(
    () => localStorage.setItem("previewDevice" + websiteId, previewDevice),
    [previewDevice, websiteId]
  );

  const website = data?.website;

  const [fetchBotifyReportLink, {
    data: fetchBotifyReportLinkData,
    loading: fetchBotifyReportLinkLoading,
  } ] = useMutation<
      FetchBotifyProjectLinkMutation,
      FetchBotifyProjectLinkMutationVariables
  >(FETCH_BOTIFY_PROJECT_LINK);

  useEffect(
      () => {
        const link = fetchBotifyReportLinkData?.fetchBotifyProjectLink?.link
        if (link) {
          setLinkToReport(link)
          return
        }

        setLinkToReport(website?.linksToReport ?? "")
      },
      [website, fetchBotifyReportLinkData?.fetchBotifyProjectLink?.link]
  );

  if (error || loading || !website) {
    return (
      <DataHandler error={error} loading={loading} data={website} expectData />
    );
  }

  document.title = website.name + " | Botify Activation";

  let chartData = null;
  let chartIndexedData = null;

  if (monitoringData && monitoringData.website) {
    const emptyState = { countHits: 0, countUrls: 0 };

    const mutableServedByHourData = removeTypenameFromData(
      monitoringData.website.servedByHour
    );
    chartData = populateEmptyPoints(
      hoursFormatted,
      mutableServedByHourData,
      "dateHour",
      emptyState
    );

    cleanConsecutiveLabelsSameDay(chartData, "dateHour", "labelDateHour");
    const mutableIndexedByHourData = removeTypenameFromData(
      monitoringData.website.indexedByHour
    );

    chartIndexedData = populateEmptyPoints(
      hoursFormatted,
      mutableIndexedByHourData,
      "dateHour",
      emptyState
    );
    cleanConsecutiveLabelsSameDay(
      chartIndexedData,
      "dateHour",
      "labelDateHour"
    );
  }

  return (
    <div>
      <h1>
        {website.name} ({website.productionVersion?.hosts})
      </h1>
      <CheckComponent websiteId={websiteId} />
      <Messager />
      <Row>
        <Col>
          <VersioningCard website={website as WebsiteType} />
          {isAdvancedMode ? <QuickAccessNav /> : null}
        </Col>
        <Col>
          <Row>
            <Col>
              {linkToReport ? (
                <a target='_blank' rel='noreferrer' href={linkToReport}>Go to report project</a>
              ) : null}
              &nbsp;
              <Button
                  type="submit"
                  variant="outline-danger"
                  disabled={fetchBotifyReportLinkLoading}
                  onClick={() => {
                    fetchBotifyReportLink({variables: {website: websiteId}})
                  }}
              >
                {linkToReport ? "Refresh" : "Set report project" }
              </Button>
            </Col>
          </Row>
          {website.linksToDoc ? (
            <Row>
              <a href={website.linksToDoc}>Go to customer tracking document</a>
            </Row>
          ) : null}
        </Col>
      </Row>
      <>
        <div>
          <h3>
            Last 24 Hours (From {moment(start).format("MMMM Do, h:mm:ss a")} to{" "}
            {moment(end).format("MMMM Do, h:mm:ss a")})
          </h3>
        </div>

        {speedData && speedData.website && (
          <div className="row" id="cards-home">
            <div className="col-sm-4">
              <div className="card">
                <div className="card-body">
                  <p className="card-text"># Served Pages</p>
                  <h5 className="card-title">
                    {new Intl.NumberFormat().format(
                      Math.floor(
                        speedData.website?.queryServedPages?.[0]?.countHits ?? 0
                      )
                    )}
                  </h5>
                </div>
              </div>
            </div>

            <div className="col-sm-4">
              <div className="card">
                <div className="card-body">
                  <p className="card-text">Speed Gain on Cache Hit</p>
                  <h5 className="card-title">
                    {Math.round(
                      speedData.website?.queryServedPages?.[0]
                        ?.avgSpeedGainOnCacheHit ?? 0
                    )}
                    x
                  </h5>
                  <span>
                    {new Intl.NumberFormat().format(
                      Math.floor(
                        speedData.website?.queryServedPages?.[0]
                          ?.avgDeliveryTimeOnCacheHit ?? 0
                      )
                    )}{" "}
                    ms vs{" "}
                    {new Intl.NumberFormat().format(
                      Math.floor(
                        speedData.website?.queryServedPages?.[0]
                          ?.avgFetchingTimeOnCacheHit ?? 0
                      )
                    )}{" "}
                    ms
                  </span>
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="card">
                <div className="card-body">
                  <p className="card-text"># Indexed Pages</p>
                  <h5 className="card-title">
                    {new Intl.NumberFormat().format(
                      speedData.website?.queryIndexedPages?.[0]?.count ?? 0
                    )}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        )}

        <Card>
          <Card.Header>Served Pages Last 24 Hours</Card.Header>
          <Card.Body style={{ height: "400px" }}>
            {chartData && (
              <>
                <div>
                  <div className="websiteHomeChart">
                    <BarChart
                      data={chartData}
                      dimension="labelDateHour"
                      metric={metric}
                      color="#0099C6"
                      xTitle="date"
                      yTitle={
                        metric === "countHits"
                          ? "Number of Hits"
                          : "Number of URLs"
                      }
                    />
                  </div>
                  <Button onClick={(e) => setMetric("countHits")}>Hits</Button>
                  <Button onClick={(e) => setMetric("countUrls")}>URLs</Button>
                </div>
                <div>
                  <Link to={`/website/${websiteId}/beam/explorer/last24`}>
                    View URLs
                  </Link>
                </div>
              </>
            )}
            {!chartData && <div>Loading</div>}
          </Card.Body>
        </Card>

        <Card>
          <Card.Header>Indexed Pages Last 24 Hours</Card.Header>
          <Card.Body style={{ height: "380px" }}>
            {chartIndexedData && (
              <>
                <div>
                  <div className="websiteHomeChart">
                    <BarChart
                      data={chartIndexedData}
                      dimension="labelDateHour"
                      metric="countUrls"
                      color="#feb601"
                      xTitle="date"
                      yTitle="Number of Hits"
                    />
                  </div>
                </div>
                <div>
                  <Link to={`/website/${websiteId}/indexation/explorer/last24`}>
                    View URLs
                  </Link>
                </div>
              </>
            )}
            {!chartData && <div>Loading</div>}
          </Card.Body>
        </Card>
      </>
      {website.annotations.length > 0 ? (
        <Card>
          <Card.Header>History</Card.Header>
          <Card.Body className="card-with-table">
            <Table striped borderless hover border-left-0 border-right-0>
              <tbody>
                {website.annotations.map((entry) => (
                  <tr>
                    <td>{entry.date}</td>
                    <td>{entry.message}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      ) : null}
      <ConfigCard
        websiteId={website.id}
        configs={website.draftVersion?.configs ?? []}
      />
      <Card>
        <Card.Header>SpeedWorkers Deployment Credentials</Card.Header>
        <Card.Body>
          <p>
            <b>Website ID :</b> {website.id}
          </p>
          {website.apiKeys.length > 0 && (
            <>
              <p>
                <b>API Key :</b>{" "}
                {
                  website.apiKeys?.find((k) => k.scope === TokenScope.DELIVERY)
                    ?.id
                }
              </p>
              <p>
                <b>Service URL :</b> {website.beamDomain}
              </p>
            </>
          )}
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>API Keys</Card.Header>
        <Card.Body className="card-with-table">
          <Card.Text>
            <Table striped borderless hover border-left-0 border-right-0>
              <thead>
                <tr>
                  <th>Key</th>
                  <th>Scope</th>
                  <th>Active</th>
                </tr>
              </thead>
              <tbody>
                {website.apiKeys.map((entry) => (
                  <tr>
                    <td>{entry.id}</td>
                    <td>{entry.scope}</td>
                    <td>{entry.active ? "Yes" : "No"}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Text>
        </Card.Body>
      </Card>

      {website.botifyAnalyticsInputs.length > 0 ? (
        <Card>
          <Card.Header>Botify Analytics Sources</Card.Header>
          <Card.Body style={{ color: "red" }}>
            Please use Botify Analytics Sources only for demo purposes.
            Production websites should only be in "Smart Cache" mode
          </Card.Body>
          <Card.Body className="card-with-table">
            <Table striped borderless hover border-left-0 border-right-0>
              <thead>
                <tr>
                  <th>Description</th>
                  <th>Device</th>
                </tr>
              </thead>
              <tbody>
                {website.botifyAnalyticsInputs.map((entry) => (
                  <tr>
                    <td>
                      {entry.description}
                      {entry.inputType === "automation" && (
                        <>
                          <br />
                          <small>Has automations set</small>
                        </>
                      )}
                    </td>
                    <td>{entry.device}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
          <Card.Body>
            <Link
              role="button"
              to={`/website/${website.id}/inputs/botifyAnalytics/import`}
              className="btn btn-primary"
            >
              Import Punctual Crawl From Botify Analytics
            </Link>
            &nbsp;
            <Link to={`/website/${websiteId}/ba-config`}>
              Configure Page Edit for BA JS crawls
            </Link>
          </Card.Body>
        </Card>
      ) : null}
      {isAdvancedMode && (
        <WebsiteHomeAdvancedSection
          website={website}
          websiteId={websiteId}
          previewDevice={previewDevice}
          previewURL={previewURL}
          setPreviewDevice={setPreviewDevice}
          setPreviewURL={setPreviewURL}
        />
      )}
    </div>
  );
}
