import { useMutation, useQuery } from "@apollo/client";
import React, { useCallback, useEffect, useState } from "react";
import AceEditor from "react-ace";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Tab from "react-bootstrap/Tab";
import Table from "react-bootstrap/Table";
import Tabs from "react-bootstrap/Tabs";

// /!\ DO NOT SORT: it crashes if imported before react-ace
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/webpack-resolver";

import { keysOf } from "../common/array/utils";
import { checkCodeValid } from "../common/code_validator";
import { useLocalStorageSynchronizedState } from "../common/hooks";
import { SuffixKeys } from "../common/typing/utils";
import { DataHandler } from "../components/DataHandler";
import { QC_STATUSES_IDX } from "../constants";
import {
  ConfigType,
  HtmlUpdatesLogType,
  Maybe,
  PreviewJsMutation,
  SectionType,
} from "../gql/types.generated";
import {
  GET_WEBSITE,
  PreviewJsMutation as PreviewJsMutationDocument,
  PreviewJsMutationVariables,
  PREVIEW_JS,
  UpdateSectionCodeMutation,
  UpdateSectionCodeMutationVariables,
  UPDATE_SECTION_CODE,
  WebsiteQuery,
  WebsiteQueryVariables,
} from "../gql/websites/website";
import { BreadcrumbTitle } from "./components/header/Title";
import { SectionLink } from "./components/link";
import { ACTIONS_TEMPLATES } from "./constants";
import "./htmledit.css";
import { SectionIdParams, WebsiteIdParams } from "./types/routeParams";
import { useParams } from "react-router-dom";
import { Spinner } from "react-bootstrap";

function downloadHTML(text: string) {
  return "data:text/plain;charset=utf-8," + encodeURIComponent(text);
}

function getFilename(url: string) {
  let filename = url.substring(url.lastIndexOf("/") + 1);
  if (filename === "") {
    filename = "index.html";
  }
  if (!filename.endsWith(".html")) {
    filename += ".html";
  }
  return filename;
}

const getActionMessage = (args: HtmlUpdatesLogType) => {
  const action = args.action?.toLowerCase() ?? "";
  const template = ACTIONS_TEMPLATES[action as keyof typeof ACTIONS_TEMPLATES];
  const Component = template?.[args.element as keyof typeof template];

  if (!Component) return JSON.stringify(args);

  return (
    <div>
      <div>
        <Component {...args} />
      </div>
      {args.context && (
        <div>
          <strong>Context:</strong> {args.context}
        </div>
      )}
    </div>
  );
};

interface ISelectJsRenderingProps {
  section: Pick<SectionType, "desktopConfig" | "mobileConfig">;
  configs: ConfigType[];
  currentConfigId: string;
  setCurrentConfigId: React.Dispatch<React.SetStateAction<string | null>>;
}

function SelectJsRendering({
  section,
  configs,
  currentConfigId,
  setCurrentConfigId,
}: ISelectJsRenderingProps) {
  const getConfigLabel = (config: ConfigType) => {
    const devices = [];
    if (section.desktopConfig && config.id === section.desktopConfig.id) {
      devices.push("desktop");
    }
    if (section.mobileConfig && config.id === section.mobileConfig.id) {
      devices.push("mobile");
    }
    if (devices.length > 0) {
      return config.name + " (used for " + devices.join(" and ") + ")";
    }
    return config.name + " (config not registered yet for current behavior)";
  };

  return (
    <Form.Group>
      <Form.Control
        as="select"
        onChange={(e) => {
          setCurrentConfigId(e.target.value);
        }}
      >
        {configs
          .filter((c) => c.action === "JS")
          .map((config) => {
            return (
              <option
                value={config.id}
                selected={config.id === currentConfigId}
              >
                {getConfigLabel(config)}
              </option>
            );
          })}
      </Form.Control>
    </Form.Group>
  );
}

/**
 * Deserialization of `PreviewJsMutation.stats`
 */
interface IPreviewStats {
  htmlUpdates?: {
    log?: HtmlUpdatesLogType[];
    warningMessages?: string[];
  };
  qualityControl?: {
    all: {
      name: string;
      status: keyof typeof QC_STATUSES_IDX;
      unsuccessMessage: string;
    }[];
    errors: string[];
  };
  properties?: object;
}

interface IPreviewState
  extends Pick<
    PreviewJsMutation,
    | "jsError"
    | "serverError"
    | "statusCode"
    | "status"
    | "headers"
    | "jsCodeRendering"
    | "jsCodePreBeamResponse"
    | "logs"
  > {
  stats: IPreviewStats;
  html: string;
}

interface IResultTabProps {
  preview: Maybe<IPreviewState>;
  content: (preview: IPreviewState) => JSX.Element;
  displayOnlyOnSuccess?: boolean;
}

function ResultTab({
  preview,
  content,
  displayOnlyOnSuccess = false,
}: IResultTabProps) {
  if (!preview) {
    return <>Submit [Preview] button on Configuration tab to see results</>;
  }
  return (
    <>
      {preview.status === "rendering_failed" && (
        <Alert variant="danger">Rendering Failed !</Alert>
      )}
      {preview.status === "pre_beam_response_failed" && (
        <Alert variant="danger">Pre Beam Response Failed !</Alert>
      )}
      {(!displayOnlyOnSuccess || preview.status === "success") &&
        content(preview)}
    </>
  );
}

export const SECTION_CODE_STEPS_DEFINITION = {
  renderingOnSetupBeforeRender: {
    needRenderingFarm: false,
    context: "Rendering",
    label: "OnSetupBeforeRender",
  },
  renderingOnInit: {
    needRenderingFarm: true,
    context: "Rendering",
    label: "OnInit",
  },
  renderingDomContentLoaded: {
    needRenderingFarm: false,
    context: "Rendering",
    label: "Dom Loaded",
  },
  renderingOnload: {
    needRenderingFarm: false,
    context: "Rendering",
    label: "OnLoad",
  },
  renderingOnAction: {
    needRenderingFarm: true,
    context: "Rendering",
    label: "OnAction",
    hidden: true, // FIXME(SW-3246): temporarily disabled
  },
  renderingOnWaitFor: {
    needRenderingFarm: true,
    context: "Rendering",
    label: "OnWaitFor",
    hidden: true,
  },
  renderingOndone: {
    needRenderingFarm: false,
    context: "Rendering",
    label: "Done",
  },
  preBeamResponse: {
    needRenderingFarm: false,
    context: "Delivery",
    label: "Pre Beam Response",
  },
} as const;

function isStepVisible(step: {
  needRenderingFarm: boolean;
  label: string;
  hidden?: boolean;
}) {
  return !step.hidden;
}

export const SECTION_CODE_STEPS = keysOf(SECTION_CODE_STEPS_DEFINITION).filter(
  (step) => isStepVisible(SECTION_CODE_STEPS_DEFINITION[step])
);

type Step = (typeof SECTION_CODE_STEPS)[number];

export const stepIsDisabled = (step: Step, useRenderingFarm: boolean) => {
  return (
    SECTION_CODE_STEPS_DEFINITION[step].needRenderingFarm && !useRenderingFarm
  );
};

/**
 * Object having keys from {@link SECTION_CODE_STEPS} suffixed
 * with both `Code` and `CodeIsValid`.
 *
 * @example
 * const codes: ICodesState = {
 *   renderingOnloadCode: "abc",
 *   renderingOnloadCodeIsValid: false,
 * }
 */
interface ICodesState
  extends Partial<
    SuffixKeys<Step, "Code", string> & SuffixKeys<Step, "CodeIsValid", boolean>
  > {}

export function WebsiteSectionHtmlEdit() {
  const { websiteId, sectionId } = useParams<
    WebsiteIdParams & SectionIdParams
  >() as WebsiteIdParams & SectionIdParams;
  const { loading, error, data } = useQuery<
    WebsiteQuery,
    WebsiteQueryVariables
  >(GET_WEBSITE, {
    variables: { id: websiteId },
  });

  const [previewURL, setPreviewURL] = useLocalStorageSynchronizedState({
    key: "htmlEditPreviewURL" + websiteId,
    defaultValue: "",
  });

  const website = data?.website;
  const section = website?.draftVersion?.sections?.find(
    ({ stableId }) => stableId === sectionId
  );

  const getInitialConfigId = useCallback(() => {
    if (section?.desktopConfig?.action === "JS") {
      return section.desktopConfig.id;
    }
    if (section?.mobileConfig?.action === "JS") {
      return section.mobileConfig.id;
    }
    const firstJsConfig = website?.draftVersion?.configs?.find(
      (c) => c.action === "JS"
    );
    return firstJsConfig?.id ?? null;
  }, [website, section]);

  const getInitialCodes = useCallback((): ICodesState => {
    const delta = {};
    SECTION_CODE_STEPS.forEach((step) => {
      const key: keyof ICodesState = `${step}Code`;
      appendToCodeState(delta, key, section?.[key] ?? "");
    });
    return delta;
  }, [section]);

  const stepIsDisabledForWebsite = (step: Step) =>
    stepIsDisabled(step, !!website?.draftVersion?.useRenderingFarm);

  useEffect(() => {
    setCurrentConfigId(getInitialConfigId());
    setCodes(getInitialCodes());
  }, [getInitialCodes, getInitialConfigId, website]);

  const [currentConfigId, setCurrentConfigId] = useState(getInitialConfigId());
  const [codes, setCodes] = useState(getInitialCodes());

  const [preview, setPreview] = useState<IPreviewState | null>(null);
  const [tabKey, setTabKey] = useState("configuration");

  const [submitCompileJs, { loading: previewJsLoading }] = useMutation<
    PreviewJsMutationDocument,
    PreviewJsMutationVariables
  >(PREVIEW_JS, {
    onCompleted({ previewJs }) {
      if (!previewJs || !previewJs.stats || typeof previewJs.html !== "string")
        return;
      const BASE_REGEXP =
        /<head[^>]*>[^]*?<base\s+href=".*"\s*\/>[^]*?<\/head>/i;
      const preview: IPreviewState = {
        ...previewJs,
        stats: (previewJs.html
          ? JSON.parse(previewJs.stats ?? "")
          : "") as IPreviewStats,
        html: BASE_REGEXP.exec(previewJs.html)
          ? previewJs.html
          : previewJs.html.replace(
              /<head([^>]*)>/,
              `<head$1><base href="${previewURL}" />`
            ),
      };
      setPreview(preview);
    },
  });
  const [submitSaveCode] = useMutation<
    UpdateSectionCodeMutation,
    UpdateSectionCodeMutationVariables
  >(UPDATE_SECTION_CODE, {
    onCompleted() {
      setSaveLabel("Saved");
      setTimeout(() => setSaveLabel("Save"), 3000);
    },
  });
  const [step, setStep] = useState<Step>("preBeamResponse");

  // Labels
  const [saveLabel, setSaveLabel] = useState("Save");

  function appendToCodeState(
    obj: Record<string, unknown>,
    name: string,
    value: string
  ) {
    obj[name] = value;
    if (value === undefined) {
      obj[name + "IsValid"] = true;
    } else {
      obj[name + "IsValid"] = checkCodeValid(value);
    }
    return obj;
  }

  const updateCode = (name: string, value: string) => {
    const delta = appendToCodeState({}, name, value);

    setCodes({
      ...codes,
      ...delta,
    });
  };

  const allCodesAreValid = () =>
    SECTION_CODE_STEPS.every(
      (step) =>
        !codes[`${step}Code`] ||
        (codes[`${step}Code`] && codes[`${step}CodeIsValid`])
    );

  if (error || loading || !website || !section) {
    return (
      <DataHandler
        error={error}
        loading={loading}
        data={website && section}
        expectData
      />
    );
  }

  if (!currentConfigId) {
    return (
      <>
        At least one javascript config must be set on this website in order to
        render pages
      </>
    );
  }

  const getStepClass = (stepName: string) => {
    if (stepName === step) {
      return "btn btn-primary";
    }
    return "btn btn-secondary";
  };

  return (
    <>
      <div className="row">
        <div className="col-9">
          <BreadcrumbTitle
            website={website}
            steps={[
              "Behaviors",
              <SectionLink
                websiteId={websiteId}
                sectionStableId={section.stableId}
              >
                {section.name}
              </SectionLink>,
              "HTML Edit",
            ]}
          />
        </div>
        <div className="col-3 text-right">
          <SectionLink websiteId={websiteId} sectionStableId={section.stableId}>
            Go back to behavior
          </SectionLink>
        </div>
      </div>
      <div className="row h-100">
        <div className="col-12 h-100">
          {preview &&
            (preview.statusCode === 301 || preview.statusCode === 302) && (
              <div>Redirect found with Status Code {preview.statusCode}</div>
            )}

          <div className="h-100">
            <Tabs
              defaultActiveKey="profile"
              id="uncontrolled-tab-example"
              activeKey={tabKey}
              onSelect={(k) => setTabKey(k ?? "")}
            >
              <Tab eventKey="configuration" title="Configuration">
                <div>
                  <div className="btn-group mr-2" role="group">
                    {SECTION_CODE_STEPS.map((step) => {
                      const { context, label } =
                        SECTION_CODE_STEPS_DEFINITION[step];
                      return (
                        <button
                          type="button"
                          className={getStepClass(step)}
                          onClick={() => setStep(step)}
                        >
                          <span className="small">{context}</span>
                          <br />
                          {label}
                        </button>
                      );
                    })}
                  </div>
                  <div>
                    <>
                      <AceEditor
                        mode="javascript"
                        name={step}
                        onChange={(newCode) =>
                          updateCode(`${step}Code`, newCode)
                        }
                        value={codes[`${step}Code`] ?? ""}
                        width="auto"
                        height="400px"
                        style={{
                          marginTop: 16,
                          marginBottom: 16,
                          backgroundColor: stepIsDisabledForWebsite(step)
                            ? "#ededed"
                            : undefined,
                        }}
                        readOnly={stepIsDisabledForWebsite(step)}
                      />

                      {!allCodesAreValid() && (
                        <small style={{ color: "red" }}>
                          JS Syntax is not valid on steps:{" "}
                          {SECTION_CODE_STEPS.map((step) => {
                            return codes[`${step}CodeIsValid`] === false
                              ? step
                              : null;
                          })
                            .filter((i) => i != null)
                            .join(", ")}
                        </small>
                      )}
                    </>
                  </div>
                  <Form.Control
                    type="text"
                    placeholder="Enter an URL"
                    onChange={(e) => setPreviewURL(e.target.value)}
                    value={previewURL}
                  />
                  <SelectJsRendering
                    section={section as SectionType}
                    configs={website.draftVersion?.configs!}
                    currentConfigId={currentConfigId}
                    setCurrentConfigId={setCurrentConfigId}
                  />

                  <Button
                    disabled={!allCodesAreValid() || previewJsLoading}
                    onClick={(e) => {
                      setPreview(null);
                      submitCompileJs({
                        variables: {
                          input: {
                            website: websiteId,
                            renderingOnSetupBeforeRenderCode:
                              codes.renderingOnSetupBeforeRenderCode || "",
                            renderingOnInitCode:
                              codes.renderingOnInitCode || "",
                            renderingDomContentLoadedCode:
                              codes.renderingDomContentLoadedCode || "",
                            renderingOnloadCode:
                              codes.renderingOnloadCode || "",
                            renderingOnActionCode:
                              codes.renderingOnActionCode || "",
                            renderingOnWaitForCode:
                              codes.renderingOnWaitForCode || "",
                            renderingOndoneCode:
                              codes.renderingOndoneCode || "",
                            preBeamResponseCode:
                              codes.preBeamResponseCode || "",
                            url: previewURL,
                            configId: currentConfigId,
                          },
                        },
                      });
                    }}
                  >
                    {previewJsLoading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Preview"
                    )}
                  </Button>
                  <Button
                    disabled={!allCodesAreValid()}
                    onClick={(e) => {
                      setSaveLabel("Saving");
                      submitSaveCode({
                        variables: {
                          input: {
                            id: section.id,
                            renderingOnSetupBeforeRenderCode:
                              codes.renderingOnSetupBeforeRenderCode,
                            renderingOnInitCode: codes.renderingOnInitCode,
                            renderingDomContentLoadedCode:
                              codes.renderingDomContentLoadedCode,
                            renderingOnloadCode: codes.renderingOnloadCode,
                            renderingOnActionCode: codes.renderingOnActionCode,
                            renderingOnWaitForCode:
                              codes.renderingOnWaitForCode,
                            renderingOndoneCode: codes.renderingOndoneCode,
                            preBeamResponseCode: codes.preBeamResponseCode,
                          },
                        },
                      });
                    }}
                  >
                    {saveLabel}
                  </Button>

                  {preview?.jsError && (
                    <div>
                      <h4>Js Error</h4> {preview.jsError}
                    </div>
                  )}

                  {preview?.serverError && (
                    <div>
                      <h4>Server Error</h4>
                      <pre>{preview.serverError}</pre>
                    </div>
                  )}

                  {preview && Object.keys(preview.stats).length > 0 && (
                    <div id="console">
                      {preview.stats?.htmlUpdates?.warningMessages &&
                        preview.stats.htmlUpdates.warningMessages.length >
                          0 && (
                          <div>
                            <h4>
                              Warning Messages (
                              {preview.stats.htmlUpdates.warningMessages.length}
                              )
                            </h4>

                            {preview.stats.htmlUpdates.warningMessages.map(
                              (entry) => {
                                return <div>{entry}</div>;
                              }
                            )}
                          </div>
                        )}

                      {preview?.stats?.qualityControl?.all?.length && (
                        <>
                          <h4>Quality Control</h4>
                          {preview.stats.qualityControl.errors?.length && (
                            <div>
                              Execution Errors :{" "}
                              {preview.stats.qualityControl.errors.map((e) => (
                                <p>{e}</p>
                              ))}
                            </div>
                          )}

                          {preview.stats.qualityControl.all.map((qc) => {
                            return (
                              <div>
                                <span
                                  className={
                                    "badge badge-" + QC_STATUSES_IDX[qc.status]
                                  }
                                >
                                  {QC_STATUSES_IDX[qc.status]}
                                </span>{" "}
                                {qc.name}
                                {qc.unsuccessMessage && (
                                  <>/ Reason: {qc.unsuccessMessage}</>
                                )}
                              </div>
                            );
                          })}
                        </>
                      )}
                    </div>
                  )}
                </div>
              </Tab>
              <Tab eventKey="html" title="HTML">
                <ResultTab
                  preview={preview}
                  content={(preview) => (
                    <>
                      {preview?.html ? (
                        <>
                          <div>
                            <a
                              href={downloadHTML(preview.html)}
                              download={getFilename(previewURL)}
                            >
                              Download HTML
                            </a>
                          </div>

                          <pre id="html">{preview.html}</pre>
                        </>
                      ) : (
                        <>
                          Empty HTML, possibly a fallback to origin, consult
                          logs
                        </>
                      )}
                    </>
                  )}
                />
              </Tab>
              <Tab eventKey="preview" title="Preview" className="h-100">
                {/* sandbox Prevent redirects on top page */}
                <ResultTab
                  preview={preview}
                  content={(preview) => (
                    <iframe
                      title="Preview HTML"
                      className="h-100"
                      srcDoc={preview.html}
                      width="100%"
                      height="400px"
                      sandbox=""
                    ></iframe>
                  )}
                />
              </Tab>
              <Tab eventKey="headers" title="Headers">
                <ResultTab
                  preview={preview}
                  content={(preview) => (
                    <>
                      {" "}
                      <h4>Headers</h4>
                      <Table>
                        {preview?.headers?.map(([key, val]) => {
                          return (
                            <tr>
                              <td>{key}</td>
                              <td>{val}</td>
                            </tr>
                          );
                        })}
                      </Table>
                    </>
                  )}
                />
              </Tab>

              <Tab
                eventKey="html-updates"
                title={
                  "HTML Updates (" +
                  (preview
                    ? (preview?.stats?.htmlUpdates?.log || []).length
                    : "x") +
                  ")"
                }
              >
                <ResultTab
                  preview={preview}
                  content={(preview) => (
                    <>
                      {preview.stats?.htmlUpdates?.log?.map((entry) => {
                        return (
                          <div className="item">
                            {getActionMessage(entry)} <hr />
                          </div>
                        );
                      })}
                    </>
                  )}
                />
              </Tab>
              <Tab eventKey="debug" title="Debug">
                <ResultTab
                  preview={preview}
                  content={(preview) => (
                    <>
                      {preview && preview.jsCodeRendering && (
                        <div>
                          <h4>JS Generated Code</h4>
                          <p>Rendering</p>
                          <pre id="html">{preview.jsCodeRendering}</pre>
                          <p>Pre Beam Response</p>
                          <pre id="html">{preview.jsCodePreBeamResponse}</pre>
                        </div>
                      )}
                    </>
                  )}
                />
              </Tab>

              <Tab
                eventKey="logs"
                title={
                  "Logs (" +
                  (preview?.logs ? (preview.logs || []).length : "x") +
                  ")"
                }
              >
                <ResultTab
                  preview={preview}
                  content={(preview) => (
                    <>
                      {preview.logs?.length && (
                        <div>
                          <h4>Logs ({preview.logs.length})</h4>

                          {preview.logs.map((entry) => {
                            return <div>{entry}</div>;
                          })}
                        </div>
                      )}{" "}
                    </>
                  )}
                />
              </Tab>
              <Tab
                eventKey="properties"
                title={
                  "Properties (" +
                  (preview?.stats?.properties
                    ? Object.keys(preview.stats?.properties)?.length
                    : "x") +
                  ")"
                }
              >
                <ResultTab
                  preview={preview}
                  displayOnlyOnSuccess={true}
                  content={(preview) => (
                    <>
                      {preview &&
                      Object.keys(preview.stats?.properties ?? {}).length >
                        0 ? (
                        <div>
                          <h4>Properties</h4>

                          <Table>
                            {Object.entries(
                              preview.stats?.properties ?? {}
                            ).map(([name, value]) => {
                              return (
                                <tr>
                                  <td>{name}</td>
                                  <td>{value}</td>
                                </tr>
                              );
                            })}
                          </Table>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
}
