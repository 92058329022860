import { gql } from "@apollo/client";

export const GET_WEBSITE_INVENTORY_LIVE = gql`
  query WebsiteInventoryLive($id: String!) {
    website(id: $id) {
      id
      beamDomain
      beamWebsiteId
      previewToken
      allTimeConfigs {
        stableId
        name
      }
      allTimeInputs {
        stableId
        name
        inputType
        lastVersion
      }
      inventory {
        stats {
          results {
            sectionName
            priority
            configName
            inputGroup
            inputs {
              id
              name
            }
            count
            countInIndex
            countInStale
            countExpired
            countNotIndexed
            samplesInIndex {
              url
              device
            }
            samplesInStale {
              url
              device
            }
            samplesExpired {
              url
              device
            }
            samplesNotIndexed {
              url
              device
            }
          }
          error
        }
      }
    }
  }
`;
