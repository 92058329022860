import { useLocation, useNavigate } from "react-router-dom";
import { Filters } from "./helpers";

const FILTER_PARAM_KEY = "filters";

export function useWebsiteFilters() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const filtersParams = searchParams.get(FILTER_PARAM_KEY);
  const filters = filtersParams ? JSON.parse(atob(filtersParams)) : {} as Filters;

  const currentParams = Object.fromEntries(
    new URLSearchParams(search).entries()
  );

  const setFiltersParams = (newFilters: Filters) => {
    const encodedParams = btoa(JSON.stringify(newFilters))
    navigate({
      search: new URLSearchParams({
        ...currentParams,
        [FILTER_PARAM_KEY]: encodedParams,
      }).toString(),
    });
  };

  return {
    filters,
    setFilters: (
      stateUpdateCallback: (previousFilters: Filters) => Filters
    ) => {
      const newFilters = stateUpdateCallback(filters);
      setFiltersParams(newFilters);
    },
  };
}
