import { gql } from "@apollo/client";

export const FETCH_BOTIFY_PROJECT_LINK = gql`
  mutation fetchBotifyProjectLink(
    $website: ID!
  ) {
    fetchBotifyProjectLink(
      website: $website
    ) {
      link
      message
    }
  }
`;
