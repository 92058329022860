import { gql } from "@apollo/client";

export const QUALITY_CONTROL_WARNING = gql`
  query QualityControlWarning(
    $id: String!
    $timestampStart: Int!
    $timestampEnd: Int!
  ) {
    website(id: $id) {
      id
      queryServedPages(
        timestampStart: $timestampStart
        timestampEnd: $timestampEnd
        orderBy: ["date"]
        dimensions: [
          "qualityControlName"
          "date"
          "countWarningQualityControls"
          "searchEngine"
        ]
      ) {
        searchEngine
        qualityControlName
        date
        countWarningQualityControls
      }
    }
  }
`;
