import { getFrozenDate } from "../constants";

export const readableTime = function (seconds: string) {
  let sec_num = parseInt(seconds, 10); // don't forget the second param
  let times: Record<string, number> = {};
  times["day"] = Math.floor(sec_num / 3600 / 24);
  sec_num -= times["day"] * 24 * 3600;
  times["hour"] = Math.floor(sec_num / 3600);
  sec_num -= times["hour"] * 3600;
  times["minute"] = Math.floor(sec_num / 60);
  sec_num -= times["minute"] * 60;
  times["second"] = sec_num;

  return Object.keys(times).reduce((str, key) => {
    if (times[key] === 1) {
      str += (str.length > 1 ? " " : "") + "1 " + key;
    } else if (times[key] > 1) {
      str += (str.length > 1 ? " " : "") + times[key] + " " + key + "s";
    }
    return str;
  }, "");
};

export function dateToTimestamp(dt: Date): number {
  return Math.trunc(dt.getTime() / 1000);
}

export function dateToMidnight(dt: string | number | Date) {
  const d = new Date(dt);
  d.setUTCHours(0, 0, 0, 0);
  return d;
}

export function dateToEndOfDay(dt: string | number | Date) {
  const d = new Date(dt);
  d.setUTCHours(23, 59, 59, 0);
  return d;
}

export function timestampToDate(ts: number) {
  return new Date(ts * 1000);
}

export function getDates(websiteId: string, startOffsetInDay: number) {
  const now = new Date();
  const coeff = 1000 * 60;
  const msByDay = 1000 * 60 * 60 * 24;
  // Round to last minute
  const frozenEndDate = getFrozenDate(websiteId);
  const end =
    frozenEndDate !== null
      ? frozenEndDate
      : new Date(Math.round(now.getTime() / coeff) * coeff);
  const start = new Date(
    Math.round(end.getTime() - msByDay * startOffsetInDay)
  );
  start.setMinutes(0);

  return {
    start: start,
    end: end,
  };
}
