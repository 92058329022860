import { useState } from "react";
import { Card, Tab, Tabs } from "react-bootstrap";

interface IndexationDetailsProps {
  details: any;
}

export function IndexationDetails({ details }: IndexationDetailsProps) {
  const [tabKey, setTabKey] = useState("full-json");

  return (
    <Tabs
      defaultActiveKey="full-json"
      activeKey={tabKey}
      onSelect={(k) => setTabKey(k ?? "")}
    >
      <Tab eventKey="http-headers" title="HTTP Headers">
        <Card>
          <Card.Header>Http query headers</Card.Header>
          <Card.Body>
            <pre>
              {details?.http_query_headers
                ? atob(details?.http_query_headers)
                : "No query headers"}
            </pre>
          </Card.Body>
        </Card>
        <Card>
          <Card.Header>Http response headers</Card.Header>
          <Card.Body>
            <pre>
              {details?.http_response_headers
                ? atob(details?.http_response_headers)
                : "No response headers"}
            </pre>
          </Card.Body>
        </Card>
      </Tab>

      <Tab eventKey="http_response_body" title="HTTP Response body">
        <Card>
          <Card.Header>Http response body</Card.Header>
          <Card.Body>
            <pre>
              {details?.http_response_body
                ? atob(details?.http_response_body)
                : "No response body"}
            </pre>
          </Card.Body>
        </Card>
      </Tab>

      <Tab eventKey="full-json" title="Debug">
        <Card>
          <pre>{JSON.stringify(details, null, 2)}</pre>
        </Card>
      </Tab>
    </Tabs>
  );
}
