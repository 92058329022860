import { gql } from "@apollo/client";

export const PUSH_VERSION_MUTATION = gql`
  mutation PushVersion($comment: String, $website: ID!) {
    pushDraftToProduction(comment: $comment, website: $website) {
      id
      draftVersion {
        id
        createdDate
        version
        comment
      }
      productionVersion {
        id
        createdDate
        version
        comment
      }
    }
  }
`;
