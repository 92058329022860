import { gql } from "@apollo/client";

import { WEBSITE_VERSION_FRAGMENT } from "./websiteVersionFragment.gql";

export const CREATE_DRAFT_VERSION = gql`
  ${WEBSITE_VERSION_FRAGMENT}
  mutation CreateDraftVersion($baseVersion: ID!, $website: ID!) {
    createDraftVersion(baseVersion: $baseVersion, website: $website) {
      id
      draftVersion {
        ...WebsiteVersionMainFieldFragment
      }
    }
  }
`;
