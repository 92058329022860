import { SectionType } from "../../gql/types.generated";

interface ISortableSection
  extends Pick<SectionType, "fallbackSection" | "sectionPriority"> {}

export const sortSections = (a: ISortableSection, b: ISortableSection) => {
  if (a.fallbackSection) {
    return 1;
  }
  if (b.fallbackSection) {
    return -1;
  }
  if (a.sectionPriority < b.sectionPriority) {
    return -1;
  }
  return 1;
};
