import { GET_PAGE_EDITS } from "../../gql/websites/page-edit";
import { DataTable } from "../components/dataTable";
import { Header } from "../components/header";
import { PageParams, WebsiteIdParams } from "../types/routeParams";
import { Link, useParams } from "react-router-dom";

export function Explore() {
  const { websiteId, page } = useParams<
    WebsiteIdParams & PageParams
  >() as WebsiteIdParams & PageParams;
  const pageAsNumber = Number(page);
  const initialPage = isNaN(pageAsNumber) ? 1 : pageAsNumber;

  const linkFn = (entry: { id: string; url: string }) => (
    <Link to={`/website/${websiteId}/pageEdit/detail/${entry.id}`}>
      {entry.url}
    </Link>
  );

  return (
    <div>
      <Header.Title websiteId={websiteId} name="Page Edit" />
      <DataTable
        websiteId={websiteId}
        gqlQuery={GET_PAGE_EDITS}
        cols={[linkFn, "device"]}
        initialPage={initialPage}
      />
    </div>
  );
}
