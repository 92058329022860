import {
  RefreshRule,
  SectionIgnoreParametersCascading,
  SectionMode,
  SectionModeNotFoundStatusCode,
  SectionType,
} from "../../gql/types.generated";

export interface IForm
  extends Pick<
    SectionType,
    | "name"
    | "mode"
    | "refreshFrequency"
    | "refreshPriority"
    | "maxStale"
    | "rules"
    | "htmlContentTypes"
    | "jsRedirectStatusCode"
    | "overrideRenderConfigs"
    | "liveMode"
    | "ignoreParameters"
    | "ignoreParametersReplaceLinks"
    | "ignoreParametersStrategy"
    | "ignoreParametersCascading"
    | "maxSpeed"
    | "liveMaxSpeed"
    | "modeCustomHtmlHtml"
    | "modeCustomHtmlStatusCode"
    | "modeNotFoundHtml"
    | "modeNotFoundStatusCode"
    | "modeRedirectLocation"
    | "modeRedirectJs"
    | "modeRedirectStatusCode"
    | "modeRedirectStrategy"
  > {
  customRefreshRules: string; // override with JSON string
  desktopConfig?: string | null; // override with config id
  mobileConfig?: string | null; // override with config id
  liveScope: string[];
}

export type FormField = keyof IForm;

export type FormValue<Field extends FormField> = IForm[Field];

export type FieldWithFinalValue = Extract<
  FormField,
  "refreshFrequency" | "refreshPriority" | "maxStale"
>;

export const baseForm = () => ({
  name: "",
  mode: SectionMode.Allow,
  customRefreshRules: "[]",
  overrideRenderConfigs: false,
  modeNotFoundStatusCode: SectionModeNotFoundStatusCode.Code_404,
  ignoreParametersCascading: SectionIgnoreParametersCascading.Append,
  liveScope: ["on_expired", "on_cache_miss"],
  modeRedirectJs: "",
  htmlContentTypes: "text/html",
  ignoreParameters: "",
  modeCustomHtmlHtml: "",
  modeNotFoundHtml: "",
  modeRedirectLocation: "",
});

export const getInitialFormValues = (section: SectionType | null): IForm => {
  if (!section) {
    return baseForm();
  }
  return {
    ...baseForm(),
    ...{
      id: section.id,
      name: section.name,
      mode: section.mode,
      refreshFrequency: section.refreshFrequency,
      refreshPriority: section.refreshPriority,
      maxStale: section.maxStale,
      rules: section.rules,
      htmlContentTypes: section.htmlContentTypes,
      // graphql mutation doesn't take jsRedirectStatusCode has an ENUM value, let's send the status code with "status_" prefix
      jsRedirectStatusCode: section.jsRedirectStatusCode,
      desktopConfig: section.desktopConfig?.id,
      mobileConfig: section.mobileConfig?.id,
      liveMode: section.liveMode,
      liveScope: section.liveScope || [],
      customRefreshRules: JSON.stringify(
        sanitizeRefreshRules(
          (section.customRefreshRules as RefreshRule[]) ?? []
        )
      ),
      overrideRenderConfigs: section.fallbackSection
        ? false
        : section.overrideRenderConfigs,
      ignoreParameters: section.ignoreParameters,
      ignoreParametersStrategy: section.ignoreParametersStrategy,
      ignoreParametersReplaceLinks: section.ignoreParametersReplaceLinks,
      ignoreParametersCascading: section.ignoreParametersCascading,
      modeNotFoundStatusCode: section.modeNotFoundStatusCode,
      modeNotFoundHtml: section.modeNotFoundHtml,
      modeRedirectStrategy: section.modeRedirectStrategy,
      modeRedirectStatusCode: section.modeRedirectStatusCode,
      modeRedirectJs: section.modeRedirectJs,
      modeRedirectLocation: section.modeRedirectLocation,
      modeCustomHtmlStatusCode: section.modeCustomHtmlStatusCode,
      modeCustomHtmlHtml: section.modeCustomHtmlHtml,
      maxSpeed: section.maxSpeed,
      liveMaxSpeed: section.liveMaxSpeed,
    },
  };
};

interface IGetFinalValueOptions<Field extends FieldWithFinalValue> {
  field: Field;
  form: IForm;
  isFallbackSection: boolean;
  fallbackSection: SectionType;
}

export const getFinalValue = <Field extends FieldWithFinalValue>({
  field,
  form,
  isFallbackSection,
  fallbackSection,
}: IGetFinalValueOptions<Field>): FormValue<Field> => {
  if (isFallbackSection || form[field]) {
    return form[field];
  }
  return fallbackSection[field];
};

// Remove typename
const sanitizeRefreshRules = (rules: RefreshRule[]) =>
  [...rules].map(({ __typename, ...rule }) => {
    const filters = rule.filters
      ?.map((filter) => {
        if (filter) {
          const { __typename, ...rest } = filter;
          return rest;
        }
        return null;
      })
      .filter(Boolean);

    return { ...rule, filters };
  });
