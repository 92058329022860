import { gql } from "@apollo/client";

export const GET_WEBSITE_SUMMARY = gql`
  query WebsiteSummary($id: String!) {
    website(id: $id) {
      id
      name
    }
  }
`;
