import React from "react";
import { ModuleType } from "../Settings";
import { PageEditHelp } from "./PageEditor";

export const HelpForModuleType = ({
  moduleType,
}: {
  moduleType: ModuleType;
}) => {
  return <>{moduleType === "PageEditor" && <PageEditHelp />}</>;
};
