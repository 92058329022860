export function objectFlip<
  K extends symbol | number | string,
  V extends symbol | number | string
>(obj: Record<K, V>) {
  const ret = {} as Record<V, K>;

  for (const key in obj) {
    ret[obj[key]] = key;
  }

  return ret;
}

export function sortConfig(x: unknown): unknown {
  if (typeof x !== "object" || !x) return x;
  if (Array.isArray(x)) return x.map(sortConfig);
  return Object.keys(x)
    .sort()
    .reduce(
      (o, k) => ({
        ...o,
        // @ts-expect-error
        [k]: sortConfig(x[k]),
      }),
      {}
    );
}
