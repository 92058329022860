import { gql } from "@apollo/client";

export const GET_WEBSITE_INDEXATION_EVENT_DETAIL = gql`
query WebsiteIndexationEvent($id: String!, $pageKey: BigInt!, $pageId: BigInt!, $fileUriHash: BigInt!, $eventTimestamp: Int!) {
    website(id: $id) {
      indexEventDetail(
        pageKey: $pageKey
        pageId: $pageId
        fileUriHash: $fileUriHash
        eventTimestamp: $eventTimestamp
      ) {
        ftlRawPage
        error
      }
    }
}
`