import { useQuery, gql } from "@apollo/client";

import _ from "lodash";
import { useState } from "react";
import { Card, Form as BForm } from "react-bootstrap";

import { camelToSnakeCase } from "../../../common/str";
import { DataHandler } from "../../../components/DataHandler";
import { ModulesListType, WebsiteType } from "../../../gql/types.generated";
import { Header } from "../../components/header";
import { WebsiteIdParams } from "../../types/routeParams";
import { HelpForModuleType } from "../help/Base";
import { ModuleType } from "../Settings";
import { ModuleForm } from "./Base";
import { Link, useNavigate, useParams } from "react-router-dom";

const CREATE_MUTATION = gql`
  mutation CreateModule($input: CreateModuleMutationInput!) {
    createModule(input: $input) {
      module {
        id
        stableId
        moduleType
        name
        config
      }
      errors {
        field
        messages
      }
    }
  }
`;

export function Create() {
  const { websiteId } = useParams<WebsiteIdParams>() as WebsiteIdParams;
  const navigate = useNavigate();
  const query = gql`
    query Website($id: String!) {
      website(id: $id) {
        id
        modulesTypes {
          id
          name
          jsonschema
        }
        draftVersion {
          id
          sections {
            id
            stableId
            mode
            name
          }
        }
      }
    }
  `;

  const { loading, error, data } = useQuery<{
    website: Pick<WebsiteType, "id" | "modulesTypes" | "draftVersion">;
  }>(query, {
    variables: { id: websiteId },
  });

  const [moduleType, setModuleType] = useState<ModuleType | null>(null);

  const onCompleted = () => {
    window.scrollTo(0, 0);
    navigate(
      {
        pathname: "/website/" + websiteId + "/modules",
      },
      {
        state: {
          message: {
            value: "Module is created",
            status: "success",
          },
        },
      }
    );
  };

  if (error || loading) return <DataHandler error={error} loading={loading} />;

  const modulesIdx: Record<string, ModulesListType> = {};
  data?.website.modulesTypes?.forEach((module: ModulesListType) => {
    modulesIdx[module.id] = module;
  });

  return (
    <div>
      <Header.Title
        websiteId={websiteId}
        name={
          <>
            <Link to={`/website/${websiteId}/modules`}>Modules</Link> {"> "}
            Create
          </>
        }
      />

      <Card>
        <Card.Body>
          <h3>Select Module Type</h3>

          <BForm.Control
            name="mode"
            as="select"
            onChange={(e) => setModuleType(e.target.value as ModuleType)}
          >
            <option>Select a Module</option>

            {_.sortBy(data?.website.modulesTypes, (m) => m).map((module) => {
              return (
                <option value={module.id} selected={moduleType === module.id}>
                  {module.name}
                </option>
              );
            })}
          </BForm.Control>
        </Card.Body>
      </Card>

      {moduleType && (
        <>
          <HelpForModuleType moduleType={moduleType} />
          <ModuleForm
            gql={CREATE_MUTATION}
            moduleType={moduleType}
            jsonschema={JSON.parse(modulesIdx[moduleType].jsonschema)}
            onCompleted={onCompleted}
            extraFormData={{
              website: data?.website.id,
              moduleType: camelToSnakeCase(moduleType).toUpperCase(),
            }}
            initialFormData={null}
          />
        </>
      )}
    </div>
  );
}
