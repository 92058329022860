import { useQuery } from "@apollo/client";
import moment from "moment";
import { DataHandler } from "../../components/DataHandler";

import { InterceptorCheckerJobType, Maybe } from "../../gql/types.generated";
import {
  GET_WEBSITE_SETTINGS_CHECKER_JOBS,
  WebsiteSettingsCheckerJobsQuery,
  WebsiteSettingsCheckerJobsQueryVariables,
} from "../../gql/websites/website";
import { STATUSES_ICONS, TEST_STATUSES } from "../constants";
import { getGlobalStatus } from "../helpers";
import { StatusIcon } from "./StatusIcon";
import { Link } from "react-router-dom";

export const CheckComponent = ({ websiteId }: { websiteId: string }) => {
  // Refresh component every 10s
  const { loading, error, data } = useQuery<
    WebsiteSettingsCheckerJobsQuery,
    WebsiteSettingsCheckerJobsQueryVariables
  >(GET_WEBSITE_SETTINGS_CHECKER_JOBS, {
    variables: { id: websiteId },
    pollInterval: 10000,
  });

  if (error || loading) return <DataHandler error={error} loading={loading} />;

  const getFinalInterceptorStatus = (
    jobs: { testStatus?: Maybe<string> }[]
  ) => {
    let hasWarning = false;
    let hasFail = false;
    jobs.forEach((job) => {
      if (job.testStatus === TEST_STATUSES.FAIL) {
        hasFail = false;
      } else if (job.testStatus === TEST_STATUSES.WARNING) {
        hasWarning = true;
      }
    });
    if (hasFail) return TEST_STATUSES.FAIL;
    return hasWarning ? TEST_STATUSES.WARNING : TEST_STATUSES.SUCCESS;
  };

  const getNearestJobDate = (jobs: { dateCreated: string }[]) => {
    let nearestDate: string = "";
    jobs.forEach((job) => {
      if (!nearestDate || job.dateCreated > nearestDate) {
        nearestDate = job.dateCreated;
      }
    });
    return nearestDate;
  };

  const result = data?.website;
  const lastSettingsCheckerJob = result?.lastSettingsCheckerJob?.[0] ?? null;
  const runningSettingsCheckerJob =
    result?.runningSettingsCheckerJob?.[0] ?? null;
  const interceptorsCheckerJobs = (result?.interceptorCheckerConfigs ?? [])
    .map(({ lastJob }) => lastJob)
    .filter(Boolean) as InterceptorCheckerJobType[]; // TS fails to exclude null | undefined

  return (
    <div>
      {lastSettingsCheckerJob?.result && (
        <div>
          Last Rendering Check :&nbsp;
          <Link to={`/website/${websiteId}/settingsCheckerJob/last`}>
            <StatusIcon
              status={getGlobalStatus(
                JSON.parse(lastSettingsCheckerJob.result).toUpperCase()
              )}
            />{" "}
            {moment(lastSettingsCheckerJob.dateCreated).fromNow()}
          </Link>
        </div>
      )}
      {runningSettingsCheckerJob && (
        <div>
          Running : {moment(runningSettingsCheckerJob.dateCreated).fromNow()}
        </div>
      )}
      {interceptorsCheckerJobs.length > 0 && (
        <div>
          Last Interceptors Check :{" "}
          <Link to={`/website/${websiteId}/interceptors`}>
            {STATUSES_ICONS[getFinalInterceptorStatus(interceptorsCheckerJobs)]}{" "}
            {moment(getNearestJobDate(interceptorsCheckerJobs)).fromNow()}
          </Link>
        </div>
      )}
    </div>
  );
};
