import { gql } from "@apollo/client";

export const GET_SECTION_DELIVERY = gql`
  query SectionDelivery(
    $id: String!
    $timestampStart: Int!
    $timestampEnd: Int!
  ) {
    website(id: $id) {
      id
      queryServedPages(
        timestampStart: $timestampStart
        timestampEnd: $timestampEnd
        orderBy: ["date"]
        dimensions: [
          "sectionName"
          "date"
          "delivered"
          "deliveryOriginStatus"
          "countHits"
          "searchEngine"
        ]
      ) {
        searchEngine
        sectionName
        date
        delivered
        deliveryOriginStatus
        countHits
      }
    }
  }
`;
