import { useState } from "react";
import { Form } from "react-bootstrap";
import { RedirectChecker, UrlCheck } from "../../../common/redirect";

export const RedirectCustomJsTester = ({ customJs }: { customJs: string }) => {
  const [urls, setUrls] = useState("");

  const checker = new RedirectChecker(customJs);

  const checkedUrls: [url: string, check: UrlCheck][] = urls
    .split("\n")
    .filter((url) => url !== "")
    .map((url) => [url, checker.checkUrl(url)]);

  return (
    <Form.Group>
      <Form.Label>Enter URLs to test redirects</Form.Label>
      <Form.Control
        name="modeRedirectJs"
        as="textarea"
        value={urls}
        onChange={(e) => setUrls(e.target.value)}
        style={{ height: "100px" }}
      />
      <Form.Text>
        {checkedUrls.length > 0 && (
          <>
            Results :<br />
            <table>
              <tr>
                <td>URL</td>
                <td>Redirect URL</td>
                <td>Success</td>
                <td>Error Message</td>
              </tr>
              {checkedUrls.map(([url, check]) => {
                return (
                  <tr>
                    <td>{url}</td>
                    <td>{check.success ? check.newUrl : ""}</td>
                    <td>{check.success ? "yes" : "no"}</td>
                    <td>{!check.success ? check.error : ""}</td>
                  </tr>
                );
              })}
            </table>
          </>
        )}
      </Form.Text>
    </Form.Group>
  );
};
