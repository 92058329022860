import { gql } from "@apollo/client";

export const GET_WEBSITE_DELIVERY_MONITORING = gql`
  query WebsiteDeliveryMonitoring(
    $id: String!
    $timestampStart: Int!
    $timestampEnd: Int!
  ) {
    website(id: $id) {
      id
      queryServedPages(
        timestampStart: $timestampStart
        timestampEnd: $timestampEnd
        orderBy: ["date"]
        dimensions: [
          "deliveryOriginStatus"
          "delivered"
          "cacheHit"
          "httpCodeIndexedPage"
          "httpCodeBeam"
          "searchEngine"
          "bot"
          "resolvedDevice"
          "notDeliveredReason"
          "qualityControlStatusSucceeded"
          "date"
        ]
      ) {
        deliveryOriginStatus
        delivered
        cacheHit
        httpCodeIndexedPage
        httpCodeBeam
        searchEngine
        bot
        resolvedDevice
        notDeliveredReason
        qualityControlStatusSucceeded
        date
        countUrls
        countHits
        sumFetchingTime
        sumDeliveryTime
      }
    }
  }
`;
