import { checkCodeValid } from "./code_validator";

export type UrlCheck =
  | { success: true; newUrl: string }
  | { success: false; error: string };

export class RedirectChecker {
  customCode: string;
  isValid: boolean;

  constructor(customCode: string) {
    this.customCode = customCode;
    this.isValid = checkCodeValid(customCode);
  }

  checkUrl(url: string): UrlCheck {
    if (!this.isValid) {
      return { success: false, error: "Bad JS Syntax" };
    } else {
      try {
        // eslint-disable-next-line no-new-func
        const redirectFn = new Function("url", this.customCode);
        const newUrl = redirectFn(url);
        return { success: true, newUrl: newUrl };
      } catch (error) {
        return { success: false, error: "error" + error };
      }
    }
  }
}
