import { pick } from "lodash";
import {
  JsCodeStep,
  JsCodeType,
  PreviewJsMutationInput,
} from "../../../../gql/types.generated";
import { StepField } from "../../core/steps";
import { CodeByStep } from "../ScriptEditor";
import { FormPreviewJsInput, ScriptSubmitFormValues } from "./typing";

export const scriptToFormValues = (
  script: JsCodeType
): ScriptSubmitFormValues => ({
  ...pick(
    script,
    "id",
    "name",
    "description",
    "active",
    "applyOnWebsite",
    "onSetupBeforeRenderCode",
    "onInitCode",
    "onDomContentLoadedCode",
    "onLoadCode",
    "onDoneCode",
    "onPreBeamResponseCode"
  ),
  sections: script.sections.map(({ id }) => id),
  websiteVersion: script.websiteVersion.id,
});

export const defaultFormValues = (
  websiteVersion: string
): ScriptSubmitFormValues => ({
  name: "",
  description: "",
  active: false,
  applyOnWebsite: false,
  onSetupBeforeRenderCode: "",
  onInitCode: "",
  onDomContentLoadedCode: "",
  onLoadCode: "",
  onDoneCode: "",
  onPreBeamResponseCode: "",
  sections: [],
  websiteVersion,
});

export const codeByStepToFields = (
  codeByStep: CodeByStep
): Record<StepField, string> => ({
  onSetupBeforeRenderCode: codeByStep[JsCodeStep.SetupBeforeRender],
  onInitCode: codeByStep[JsCodeStep.Init],
  onDomContentLoadedCode: codeByStep[JsCodeStep.DomContentLoaded],
  onLoadCode: codeByStep[JsCodeStep.Load],
  onDoneCode: codeByStep[JsCodeStep.Done],
  onPreBeamResponseCode: codeByStep[JsCodeStep.PreBeamResponse],
  onActionCode: "",
  onWaitForCode: "",
});

export const getInitialCodeByStep = (
  form: ScriptSubmitFormValues
): CodeByStep => ({
  [JsCodeStep.Init]: form.onInitCode ?? "",
  [JsCodeStep.DomContentLoaded]: form.onDomContentLoadedCode ?? "",
  [JsCodeStep.Load]: form.onLoadCode ?? "",
  [JsCodeStep.SetupBeforeRender]: form.onSetupBeforeRenderCode ?? "",
  [JsCodeStep.Done]: form.onDoneCode ?? "",
  [JsCodeStep.PreBeamResponse]: form.onPreBeamResponseCode ?? "",
});

export const codeByStepToPreviewJsInput = ({
  codeByStep,
  id,
  name,
}: {
  codeByStep: CodeByStep;
  id?: string;
  name?: string;
}): Pick<
  PreviewJsMutationInput,
  | "snippetId"
  | "snippetName"
  | "renderingOnSetupBeforeRenderCode"
  | "renderingOnInitCode"
  | "renderingDomContentLoadedCode"
  | "renderingOnloadCode"
  | "renderingOnActionCode"
  | "renderingOnWaitForCode"
  | "renderingOndoneCode"
  | "preBeamResponseCode"
> => ({
  snippetId: id,
  snippetName: name,
  renderingOnSetupBeforeRenderCode:
    codeByStep[JsCodeStep.SetupBeforeRender] || "",
  renderingOnInitCode: codeByStep[JsCodeStep.Init] || "",
  renderingDomContentLoadedCode: codeByStep[JsCodeStep.DomContentLoaded] || "",
  renderingOnloadCode: codeByStep[JsCodeStep.Load] || "",
  renderingOndoneCode: codeByStep[JsCodeStep.Done] || "",
  preBeamResponseCode: codeByStep[JsCodeStep.PreBeamResponse] || "",
});

export const formToScriptCode = (
  form: ScriptSubmitFormValues
): FormPreviewJsInput => ({
  id: form.id,
  name: form.name,
  onDomContentLoadedCode: form.onDomContentLoadedCode ?? "",
  onDoneCode: form.onDoneCode ?? "",
  onInitCode: form.onInitCode ?? "",
  onLoadCode: form.onLoadCode ?? "",
  onPreBeamResponseCode: form.onPreBeamResponseCode ?? "",
  onSetupBeforeRenderCode: form.onSetupBeforeRenderCode ?? "",
  onActionCode: "",
  onWaitForCode: "",
});
