import React from "react";
import {
  useGetUserInfo,
  useLocalStorageSynchronizedState,
} from "./common/hooks";

import { noop } from "lodash";

export const AdvancedModeContext = React.createContext({
  isAdvancedMode: false,
  setIsAdvancedMode: (newAdvancedMode: boolean) => {},
  standardModeOnly: true,
});

export const AdvancedModeContextProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { isSuperuser } = useGetUserInfo();

  const [_isAdvancedMode, _setIsAdvancedMode] =
    useLocalStorageSynchronizedState<`${boolean}`>({
      key: "appMode",
      defaultValue: `${false}`,
    });
  const store = isSuperuser
    ? {
        isAdvancedMode: _isAdvancedMode === `${true}`,
        setIsAdvancedMode: (v: boolean) => _setIsAdvancedMode(`${v}`),
        standardModeOnly: false,
      }
    : {
        isAdvancedMode: false,
        setIsAdvancedMode: noop,
        standardModeOnly: true,
      };

  return (
    <AdvancedModeContext.Provider value={store}>
      {children}
    </AdvancedModeContext.Provider>
  );
};
