import { gql } from "@apollo/client";

export const GET_DRAFT_VERSION_LIGHT = gql`
  query DraftVersionLight($websiteId: String!) {
    website(id: $websiteId) {
      id
      name
      draftVersion {
        id
        sections {
          id
          name
          mode
        }
      }
    }
  }
`;
