import { gql } from "@apollo/client";

const GET_ALL_WEBSITES = gql`
  query getAllWebsites {
    allWebsites {
      id
      name
      productionVersion {
        id
        hosts
      }
      cluster {
        id
        name
      }
    }
  }
`;

export { GET_ALL_WEBSITES };
