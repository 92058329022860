import {
  closestCenter,
  DndContext,
  DragEndEvent,
  PointerSensor,
  useSensor,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { createContext, useContext } from "react";
import { JsCodeType } from "../../../../gql/types.generated";
import { moveScript, mustFindScript, orderScripts } from "../../core/scripts";
import { ScriptsTableColumn } from "./columns";
import { UseScriptsTableValues } from "./useScriptsTable";

export interface IScriptsTableContext {
  columns: ScriptsTableColumn[];
  activateScript: (script: JsCodeType) => Promise<void>;
  pauseScript: (script: JsCodeType) => Promise<void>;
  deleteScript: (script: JsCodeType) => Promise<void>;
}

const ScriptsTableContext = createContext<IScriptsTableContext | null>(null);

export const useScriptsTableContext = () => {
  const scriptsTableContext = useContext(ScriptsTableContext);
  if (!scriptsTableContext) {
    throw new Error("Context not set: ScriptsTableContext");
  }
  return scriptsTableContext;
};

export type ScriptsTableContextControlledProps = IScriptsTableContext &
  React.PropsWithChildren<{
    orderedScripts: JsCodeType[];
    shownScripts: JsCodeType[];
    onReorder: (scripts: JsCodeType[]) => void;
  }>;

const ScriptsTableContextControlled = ({
  columns,
  orderedScripts,
  shownScripts,
  onReorder,
  activateScript,
  pauseScript,
  deleteScript,
  children,
}: ScriptsTableContextControlledProps) => {
  const pointerSensor = useSensor(PointerSensor);

  const handleDragEnd = (event: DragEndEvent) => {
    if (!event.over) {
      return;
    }

    const initialPosition = mustFindScript(
      orderedScripts,
      event.active.id as string
    ).position;
    const targetPosition = mustFindScript(
      orderedScripts,
      event.over.id as string
    ).position;

    if (targetPosition === initialPosition) {
      return;
    }

    const movedScripts = moveScript(
      orderedScripts,
      initialPosition,
      targetPosition
    );
    const reorderedScripts = orderScripts(movedScripts);
    onReorder(reorderedScripts);
  };

  return (
    <DndContext
      sensors={[pointerSensor]}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={shownScripts}
        strategy={verticalListSortingStrategy}
      >
        <ScriptsTableContext.Provider
          value={{ columns, activateScript, pauseScript, deleteScript }}
        >
          {children}
        </ScriptsTableContext.Provider>
      </SortableContext>
    </DndContext>
  );
};

export const makeScriptsTableContext =
  (
    props: Omit<ScriptsTableContextControlledProps, "children">
  ): UseScriptsTableValues["ScriptsTableContext"] =>
  ({ children }: React.PropsWithChildren) => (
    <ScriptsTableContextControlled {...props}>
      {children}
    </ScriptsTableContextControlled>
  );
