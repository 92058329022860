import { JsCodeStep, JsCodeType } from "../../../../../gql/types.generated";
import { getStepLabel } from "../../../core/steps";

import { InlineList } from "./InlineList";

export const StepsCell = ({ steps }: JsCodeType) => (
  <InlineList
    entries={steps ?? []}
    render={(step) => <StepLabel step={step} />}
  />
);

const StepLabel = ({ step }: { step: JsCodeStep }) => (
  <span className="text-nowrap">{getStepLabel(step)}</span>
);
