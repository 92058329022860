import { useMutation } from "@apollo/client";
import {
  ANALYZE_URLS_REFRESH_IMPACT,
  AnalyzeUrlsRefreshImpactMutation,
  AnalyzeUrlsRefreshImpactMutationVariables,
  FORCE_URLS_REFRESH,
  ForceUrlsRefreshMutation,
  ForceUrlsRefreshMutationVariables,
  ANALYZE_SECTION_REFRESH_IMPACT,
  AnalyzeSectionRefreshImpactMutation,
  AnalyzeSectionRefreshImpactMutationVariables,
  FORCE_SECTION_REFRESH,
  ForceSectionRefreshMutation,
  ForceSectionRefreshMutationVariables,
} from "../../gql/websites/forceRefresh";
import { ForceRefreshStrategy } from "./types";

export function useForceRefresh(strategy: ForceRefreshStrategy) {
  const forceUrlsRefresh = useForceUrlsRefresh();
  const forceSectionRefresh = useForceSectionRefresh();
  switch (strategy) {
    case "rawlist":
      return forceUrlsRefresh;
    case "section":
      return forceSectionRefresh;
    default:
      throw new Error(
        `Invariant: invalid force refresh strategy "${strategy}".`
      );
  }
}

function useForceUrlsRefresh() {
  const [
    analyzeImpact,
    {
      data: impactAnalysisData,
      loading: isImpactAnalysisLoading,
      error: impactAnalysisError,
    },
  ] = useMutation<
    AnalyzeUrlsRefreshImpactMutation,
    AnalyzeUrlsRefreshImpactMutationVariables
  >(ANALYZE_URLS_REFRESH_IMPACT);
  const [
    forceRefresh,
    {
      data: forceRefreshData,
      loading: isForceRefreshLoading,
      error: forceRefreshError,
    },
  ] = useMutation<ForceUrlsRefreshMutation, ForceUrlsRefreshMutationVariables>(
    FORCE_URLS_REFRESH
  );

  return {
    // Impact Analysis
    analyzeImpact,
    isImpactAnalysisLoading,
    impactAnalysisData: impactAnalysisData?.analyzeUrlsRefreshImpact,
    impactAnalysisError,
    // Force Refresh
    forceRefresh,
    isForceRefreshLoading,
    forceRefreshData: forceRefreshData?.forceUrlsRefresh,
    forceRefreshError,
  };
}

function useForceSectionRefresh() {
  const [
    analyzeImpact,
    {
      data: impactAnalysisData,
      loading: isImpactAnalysisLoading,
      error: impactAnalysisError,
    },
  ] = useMutation<
    AnalyzeSectionRefreshImpactMutation,
    AnalyzeSectionRefreshImpactMutationVariables
  >(ANALYZE_SECTION_REFRESH_IMPACT);
  const [
    forceRefresh,
    {
      data: forceRefreshData,
      loading: isForceRefreshLoading,
      error: forceRefreshError,
    },
  ] = useMutation<
    ForceSectionRefreshMutation,
    ForceSectionRefreshMutationVariables
  >(FORCE_SECTION_REFRESH);

  return {
    // Impact Analysis
    analyzeImpact,
    isImpactAnalysisLoading,
    impactAnalysisData: impactAnalysisData?.analyzeSectionRefreshImpact,
    impactAnalysisError,
    // Force Refresh
    forceRefresh,
    isForceRefreshLoading,
    forceRefreshData: forceRefreshData?.forceSectionRefresh,
    forceRefreshError,
  };
}
