export function SelectorsHeader({
  selectorComponents,
}: {
  selectorComponents: Array<React.ReactNode>;
}) {
  return (
    <div
      style={{
        gap: "1em",
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      {selectorComponents}
    </div>
  );
}
