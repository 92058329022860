import { useQuery } from "@apollo/client";
import { sortBy, sumBy } from "lodash";
import { ReactNode, useState } from "react";
import { Button, Modal, Table } from "react-bootstrap";
import Card from "react-bootstrap/Card";

import { reducebyDimensionsFn } from "../common/charts";
import { toPercent } from "../common/math";
import { goToPreview, IWebsitePreviewURL } from "../common/preview";
import { shortUrl } from "../common/str";
import { DataHandler } from "../components/DataHandler";
import {
  WebsiteInventoryStatsResultType,
  WebsiteInventoryStatsSamplesType,
} from "../gql/types.generated";
import {
  GET_WEBSITE_INVENTORY_LIVE,
  WebsiteInventoryLiveQuery,
  WebsiteInventoryLiveQueryVariables,
} from "../gql/websites/website";
import { Header } from "./components/header";
import "./inventory.css";
import { WebsiteIdParams } from "./types/routeParams";
import { useParams } from "react-router-dom";

const DIMENSIONS = [
  {
    field: "sectionName",
    verbose: "Behavior Name",
  },
  { field: "configName", verbose: "Config" },
  { field: "priority", verbose: "Priority" },
  { field: "inputGroup", verbose: "Input Groups" },
] as const;

const EQUIVALENT_DIMENSIONS = {
  inputGroup: ["inputs"],
} as const;

const formatNumber = (n: number) => {
  return new Intl.NumberFormat().format(n);
};

interface IAggregatedResults
  extends Pick<
    WebsiteInventoryStatsResultType,
    | "count"
    | "countInIndex"
    | "countInStale"
    | "countExpired"
    | "countNotIndexed"
    | "samplesInIndex"
    | "samplesInStale"
    | "samplesExpired"
    | "samplesNotIndexed"
  > {}

const aggregateResults = (
  result: Partial<IAggregatedResults>,
  entries: IAggregatedResults[]
) => {
  result.count = sumBy(entries, "count");
  result.countInIndex = sumBy(entries, "countInIndex");
  result.countInStale = sumBy(entries, "countInStale");
  result.countExpired = sumBy(entries, "countExpired");
  result.countNotIndexed = sumBy(entries, "countNotIndexed");
  result.samplesInIndex = entries.flatMap((e) => e.samplesInIndex);
  result.samplesInStale = entries.flatMap((e) => e.samplesInStale);
  result.samplesExpired = entries.flatMap((e) => e.samplesExpired);
  result.samplesNotIndexed = entries.flatMap((e) => e.samplesNotIndexed);
};

interface ITopMetricsCards {
  results: WebsiteInventoryStatsResultType[];
}

function TopMetricsCard({ results }: ITopMetricsCards) {
  const newResults = reducebyDimensionsFn<IAggregatedResults>({
    data: results,
    dimensions: [],
    fn: aggregateResults,
  });
  const topMetrics = newResults[0];
  return (
    <div className="row" id="cards-home">
      <div className="col-sm-3">
        <div className="card">
          <div className="card-body">
            <p className="card-text"># Pages</p>
            <h5 className="card-title">{formatNumber(topMetrics.count)}</h5>
            <p>{formatNumber(topMetrics.countInIndex)} currently indexed</p>
          </div>
        </div>
      </div>
      <div className="col-sm-3">
        <div className="card">
          <div className="card-body">
            <p className="card-text"># Pages in stale</p>
            <h5 className="card-title">
              {formatNumber(topMetrics.countInStale)}
            </h5>
          </div>
        </div>
      </div>
      <div className="col-sm-3">
        <div className="card">
          <div className="card-body">
            <p className="card-text"># Expired Pages</p>
            <h5 className="card-title">
              {formatNumber(topMetrics.countExpired)}
            </h5>
          </div>
        </div>
      </div>
      <div className="col-sm-3">
        <div className="card">
          <div className="card-body">
            <p className="card-text"># Not Indexed Pages</p>
            <h5 className="card-title">
              {formatNumber(topMetrics.countNotIndexed)}
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
}

interface IEntryLineProps {
  name: ReactNode;
  entry: IAggregatedResults;
  website: IWebsitePreviewURL;
}

function EntryLine({ name, entry, website }: IEntryLineProps) {
  const [samples, setSamples] = useState<
    WebsiteInventoryStatsSamplesType[] | null
  >(null);
  const closeModal = () => setSamples(null);

  const ShowSamplesButton = ({
    samples,
  }: {
    samples: WebsiteInventoryStatsSamplesType[];
  }) => (
    <Button
      variant="link"
      className="sampleLink"
      onClick={() => setSamples(samples)}
    >
      www
    </Button>
  );

  return (
    <>
      {samples && (
        <Modal size="lg" onHide={closeModal} show>
          <Modal.Header closeButton>
            <Modal.Title>URLs Samples</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {samples.map(({ url, device }) => (
              <div>
                {shortUrl(url, 100)} on <code>{device}</code>{" "}
                <Button
                  variant="link"
                  onClick={() => goToPreview(website, url, device)}
                >
                  Preview
                </Button>
              </div>
            ))}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={closeModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <tr>
        <td className="entry" style={{ width: "30%" }}>
          {name}
        </td>
        <td align="right">{formatNumber(entry.count)}</td>
        <td style={{ width: "100px" }}>
          <div className="progress">
            <div
              className="progress-bar bg-success"
              role="progressbar"
              style={{
                width: toPercent(entry.countInIndex, entry.count) + "%",
              }}
            />
            <div
              className="progress-bar bg-warning"
              role="progressbar"
              style={{
                width: toPercent(entry.countInStale, entry.count) + "%",
              }}
            />
            <div
              className="progress-bar bg-danger"
              role="progressbar"
              style={{
                width: toPercent(entry.countExpired, entry.count) + "%",
              }}
            />
            <div
              className="progress-bar bg-info"
              role="progressbar"
              style={{
                width: toPercent(entry.countNotIndexed, entry.count) + "%",
              }}
            />
          </div>
        </td>
        <td align="right">
          {formatNumber(entry.countInIndex)}{" "}
          <ShowSamplesButton samples={entry.samplesInIndex} />
        </td>
        <td align="right">
          {formatNumber(entry.countInStale)}{" "}
          <ShowSamplesButton samples={entry.samplesInStale} />
        </td>
        <td align="right">
          {formatNumber(entry.countExpired)}{" "}
          <ShowSamplesButton samples={entry.samplesExpired} />
        </td>
        <td align="right">
          {formatNumber(entry.countNotIndexed)}{" "}
          <ShowSamplesButton samples={entry.samplesNotIndexed} />
        </td>
      </tr>
    </>
  );
}

type Dimension = (typeof DIMENSIONS)[number];

interface ISectionDetailsProps {
  results: WebsiteInventoryStatsResultType[];
  selectedDimension: Dimension;
  subDimension: Dimension;
  filteredValue: unknown;
  dimensionToNameResolver: DimensionToNameResolver;
  website: IWebsitePreviewURL;
}

function SectionDetails({
  results,
  selectedDimension,
  subDimension,
  filteredValue,
  dimensionToNameResolver,
  website,
}: ISectionDetailsProps) {
  const newResultsPriority =
    reducebyDimensionsFn<WebsiteInventoryStatsResultType>({
      data: results.filter(
        (res) =>
          dimensionToNameResolver[selectedDimension.field](res) ===
          filteredValue
      ),
      dimensions: [
        subDimension.field,
        ...(EQUIVALENT_DIMENSIONS[
          subDimension.field as keyof typeof EQUIVALENT_DIMENSIONS
        ] ?? []),
      ],
      fn: aggregateResults,
    });
  const newResultsPrioritySorted = sortBy(
    newResultsPriority,
    (res: WebsiteInventoryStatsResultType) => {
      return -res.count;
    }
  );

  return (
    <>
      <tr className="small-table-header">
        <td colSpan={7}>By {subDimension.verbose}</td>
      </tr>
      {newResultsPrioritySorted.map((entry) => (
        <EntryLine
          name={dimensionToNameResolver[subDimension.field](entry)}
          entry={entry}
          website={website}
        />
      ))}
    </>
  );
}

type DimensionToNameResolver = Record<
  keyof Pick<
    WebsiteInventoryStatsResultType,
    "sectionName" | "priority" | "configName" | "inputGroup"
  >,
  (entry: WebsiteInventoryStatsResultType) => string
>;

interface ISectionsTableCardProps {
  website: IWebsitePreviewURL;
  results: WebsiteInventoryStatsResultType[];
  dimensionToNameResolver: DimensionToNameResolver;
}

function SectionsTableCard({
  website,
  results,
  dimensionToNameResolver,
}: ISectionsTableCardProps) {
  const [selectedDimension, setSelectedDimension] = useState<Dimension>(
    DIMENSIONS[0]
  );
  const [filteredValue, setFilteredValue] = useState<string | null>(null);

  const newResults = reducebyDimensionsFn<WebsiteInventoryStatsResultType>({
    data: results,
    dimensions: [
      selectedDimension.field,
      ...(EQUIVALENT_DIMENSIONS[
        selectedDimension.field as keyof typeof EQUIVALENT_DIMENSIONS
      ] || []),
    ],
    fn: aggregateResults,
  });

  const newResultsSorted = sortBy(
    newResults,
    (res: WebsiteInventoryStatsResultType) => {
      return -res.count;
    }
  );

  return (
    <Card>
      <Card.Header>Inventory By Dimension</Card.Header>
      <Card.Body>
        <div>
          {DIMENSIONS.map((dim, i) => (
            <>
              {i !== 0 && " | "}
              <Button
                variant="link"
                style={{ padding: 0 }}
                onClick={() => {
                  setFilteredValue(null);
                  setSelectedDimension(dim);
                }}
              >
                {dim.verbose}
              </Button>
            </>
          ))}
        </div>

        <Table
          id="inventory"
          striped
          bordered
          hover
          className="white"
          size="sm"
        >
          <thead>
            {" "}
            <tr>
              <td>{selectedDimension.verbose}</td>

              <td colSpan={2}>Total Pages</td>
              <td>Total Pages In Index</td>
              <td>Total Pages In Stale</td>
              <td>Total Pages Expired</td>
              <td>Total Pages Not Indexed Yet</td>
            </tr>
          </thead>
          <tbody>
            {newResultsSorted.map((entry) => {
              const name = (
                <>
                  {dimensionToNameResolver[selectedDimension.field](entry)}{" "}
                  <Button
                    variant="link"
                    onClick={() =>
                      setFilteredValue(
                        dimensionToNameResolver[selectedDimension.field](entry)
                      )
                    }
                  >
                    (+)
                  </Button>
                </>
              );
              return (
                <>
                  {dimensionToNameResolver[selectedDimension.field](entry) ===
                    filteredValue && (
                    <tr className="ruler">
                      <td colSpan={7}></td>
                    </tr>
                  )}

                  <EntryLine name={name} entry={entry} website={website} />
                  {dimensionToNameResolver[selectedDimension.field](entry) ===
                    filteredValue && (
                    <>
                      {DIMENSIONS.filter(
                        (subDimension) =>
                          subDimension.field !== selectedDimension.field
                      ).map((subDimension) => (
                        <SectionDetails
                          results={results}
                          selectedDimension={selectedDimension}
                          filteredValue={filteredValue}
                          subDimension={subDimension}
                          dimensionToNameResolver={dimensionToNameResolver}
                          website={website}
                        />
                      ))}
                      <tr className="ruler">
                        <td colSpan={7}></td>
                      </tr>
                    </>
                  )}
                </>
              );
            })}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
}

export function WebsiteInventory() {
  const { websiteId } = useParams<WebsiteIdParams>() as WebsiteIdParams;

  const { loading, error, data } = useQuery<
    WebsiteInventoryLiveQuery,
    WebsiteInventoryLiveQueryVariables
  >(GET_WEBSITE_INVENTORY_LIVE, {
    variables: { id: websiteId },
  });

  if (error || loading) return <DataHandler error={error} loading={loading} />;

  const dimensionToNameResolver: DimensionToNameResolver = {
    sectionName: (entry) => entry.sectionName,
    priority: (entry) => entry.priority,
    configName: (entry) => entry.configName,
    inputGroup: (entry) => entry.inputs.map((v) => v.name).join(" + "),
  };

  const website = data?.website;
  const results = website?.inventory?.stats?.results;
  const hasResults = !!website && !!results?.length;

  return (
    <div>
      <Header.Title websiteId={websiteId} name="Inventory" />
      {hasResults && (
        <>
          <TopMetricsCard results={results} />
          <SectionsTableCard
            results={results}
            dimensionToNameResolver={dimensionToNameResolver}
            website={website}
          />
        </>
      )}
      {!hasResults && (
        <>No data! You might need to enable Smart Cache Refresh.</>
      )}
    </div>
  );
}
