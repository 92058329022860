import { Home } from "./Home";
import { Create } from "./edit/Create";
import { Update } from "./edit/Update";
import { Route, Routes } from "react-router-dom";

export function ModulesRoutes() {
  return (
    <div>
      <Routes>
        <Route index element={<Home />} />
        <Route path="create" element={<Create />} />
        <Route path=":moduleType/:stableId/*" element={<Update />} />
      </Routes>
    </div>
  );
}
