import { useQuery } from "@apollo/client";
import Container from "react-bootstrap/Container";

import { ExplorerTable } from "../../common/explorer";
import { WebsiteType } from "../../gql/types.generated";
import {
  GET_WEBSITE,
  WebsiteQuery,
  WebsiteQueryVariables,
} from "../../gql/websites/website";
import { DataHandler } from "../../components/DataHandler";
import { DateComponent } from "../components/date";
import { Header } from "../components/header";
import { PresetParams, WebsiteIdParams } from "../types/routeParams";
import {
  DEFAULT_START_OFFSET,
  PRESETS,
  SERVED_PAGES_DEFINITION,
} from "./Settings";
import { getDates } from "../../common/time";
import { IPreset } from "../../common/typing/website";
import { Link, useParams } from "react-router-dom";

export function BingContentSubmissionExplorer() {
  const { websiteId, preset } = useParams<
    WebsiteIdParams & PresetParams
  >() as WebsiteIdParams & PresetParams;
  const startOffsetInPreset = (PRESETS as Record<string, IPreset>)?.[preset]
    ?.startOffset;
  const { start, end } = getDates(
    websiteId,
    startOffsetInPreset || DEFAULT_START_OFFSET
  );
  const { loading, error, data } = useQuery<
    WebsiteQuery,
    WebsiteQueryVariables
  >(GET_WEBSITE, {
    variables: { id: websiteId },
  });
  const {
    startDate,
    endDate,
    component: dateComponent,
  } = DateComponent(websiteId, start, end, !!startOffsetInPreset);

  const website = data?.website;

  if (error || loading || !website) {
    return (
      <DataHandler error={error} loading={loading} data={website} expectData />
    );
  }

  return (
    <Container fluid>
      <Header>
        <Header.Title
          websiteId={websiteId}
          name="Bing Content Submission Explorer"
        />
        <Header.Selectors selectorComponents={[dateComponent]} />
      </Header>

      <div>
        <Link to={`/website/${websiteId}/bingContentSubmission/explorer/last`}>
          Last Served Pages
        </Link>{" "}
        {" | "}
        <Link
          to={`/website/${websiteId}/bingContentSubmission/explorer/last24`}
        >
          Last Served Pages (24h)
        </Link>{" "}
        {" | "}
        <Link to={`/website/${websiteId}/bingContentSubmission/explorer/top`}>
          Top Submitted Pages
        </Link>
        {" | "}
        <Link to={`/website/${websiteId}/bingContentSubmission/explorer/fails`}>
          Top Failed Submissions
        </Link>
        {" | "}
      </div>

      <ExplorerTable
        website={website as WebsiteType}
        startDate={startDate}
        endDate={endDate}
        currentPreset={preset}
        presets={PRESETS}
        definitions={SERVED_PAGES_DEFINITION}
      />
    </Container>
  );
}
