import { WebsiteLink } from "./WebsiteLink";

interface ISectionLinkProps {
  websiteId: string;
  sectionStableId: string;
  children: React.ReactNode;
}

export const SectionLink = ({
  sectionStableId,
  websiteId,
  children,
}: ISectionLinkProps) => (
  <WebsiteLink websiteId={websiteId} path={`/section/${sectionStableId}`}>
    {children}
  </WebsiteLink>
);
