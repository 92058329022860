import { gql } from "@apollo/client";

export const SECTION_TESTER = gql`
  mutation urlTester(
    $website: ID!
    $urls: [String!]!
    $overloadedSection: SectionInput
  ) {
    urlTester(
      website: $website
      urls: $urls
      overloadedSection: $overloadedSection
    ) {
      results {
        url
        matchingSection {
          id
          name
          mode
          matchingRules {
            id
            group
            field
            value
            operator
          }
        }
      }
    }
  }
`;
