import { useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { Alert, Button, Card, Form } from "react-bootstrap";
import { HistoryRecords } from "./HistoryRecords";
import { IndexationDetails } from "./IndexationDetails";
import { useParams } from "react-router-dom";
import { WebsiteIdParams } from "../types/routeParams";
import { GET_WEBSITE_INDEXATION_HISTORY } from "../../gql/websites/history/getWebsiteIndexationHistory.gql";
import { GET_WEBSITE_INDEXATION_EVENT_DETAIL } from "../../gql/websites/history/getWebsiteIndexationEventDetail.gql";
import {
  WebsiteHistoryQuery,
  WebsiteHistoryQueryVariables,
} from "../../gql/websites/history/getWebsiteIndexationHistory.gql.generated";
import {
  WebsiteIndexationEventQuery,
  WebsiteIndexationEventQueryVariables,
} from "../../gql/websites/history/getWebsiteIndexationEventDetail.gql.generated";
import moment from "moment";

export function WebsiteIndexationHistory() {
  const { websiteId } = useParams<WebsiteIdParams>() as WebsiteIdParams;

  const [url, setUrl] = useState<string>("");

  const [getIndexationHistory, { data: records, error: recordsError }] =
    useLazyQuery<WebsiteHistoryQuery, WebsiteHistoryQueryVariables>(
      GET_WEBSITE_INDEXATION_HISTORY
    );

  const [getRawPageDetail, { data: rawPageDetail, error: detailError }] =
    useLazyQuery<
      WebsiteIndexationEventQuery,
      WebsiteIndexationEventQueryVariables
    >(GET_WEBSITE_INDEXATION_EVENT_DETAIL);

  const errorMessage =
    recordsError?.message ||
    detailError?.message ||
    rawPageDetail?.website?.indexEventDetail?.error;

  const searchRecords = async (e: React.FormEvent<HTMLFormElement>) => {
    e.stopPropagation();
    e.preventDefault();

    getIndexationHistory({
      variables: {
        timestampStart: moment().subtract(60, "days").unix(),
        timestampEnd: moment().unix(),
        filters: `[{"field": "url", "predicate": "eq", "value": "${url}"}]`,
        id: websiteId,
      },
    });
  };

  const getRawPage = async (historyRecord: any) => {
    getRawPageDetail({
      variables: {
        pageKey: historyRecord.pageKey,
        pageId: historyRecord.pageId,
        fileUriHash: historyRecord.fileUriHash,
        eventTimestamp: moment(historyRecord.datetime).unix(),
        id: websiteId,
      },
    });
  };

  return (
    <div>
      <Card>
        <Card.Header>
          <b>Indexed Page History</b>
        </Card.Header>
        <Card.Body>
          {errorMessage ? (
            <Alert variant="danger">
              Something went wrong.
              <br />
              <pre style={{ whiteSpace: "normal" }}>{errorMessage}</pre>
            </Alert>
          ) : null}
          <Form onSubmit={searchRecords}>
            <Form.Group>
              <Form.Label>URL</Form.Label>
              <Form.Control
                id="url"
                type="text"
                onChange={(e) => setUrl(e.target.value)}
              ></Form.Control>
            </Form.Group>
            <Button type="submit">Search</Button>
          </Form>
        </Card.Body>
      </Card>

      {records?.website?.queryIndexedPages?.length ? (
        <HistoryRecords
          records={records?.website?.queryIndexedPages}
          onGetRawPage={getRawPage}
        />
      ) : null}

      {rawPageDetail?.website?.indexEventDetail ? (
        <IndexationDetails
          details={
            rawPageDetail?.website?.indexEventDetail.ftlRawPage
              ? JSON.parse(rawPageDetail?.website?.indexEventDetail.ftlRawPage)
              : {}
          }
        />
      ) : null}
    </div>
  );
}
