import { ReactNode } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

import { Link } from "react-router-dom";
import { ClusterType, WebsiteType } from "../../gql/types.generated";
import AdnIcon from "./adn.svg";

interface ICluster
  extends Pick<ClusterType, "name" | "domain" | "cloud" | "region"> {}

function ClusterInfo({ cluster }: { cluster: ICluster }) {
  return (
    <>
      Service: {cluster.cloud}
      <br />
      Region: {cluster.region}
      <br />
      Name: {cluster.name}
      <br />
      Domain: {cluster.domain}
      <br />
    </>
  );
}

function ClusterIcon({ cluster }: { cluster: ICluster }) {
  switch (cluster.cloud) {
    case "AWS":
      return (
        <HomeEntryIcon
          iconName="box"
          overlayTitle="Cluster"
          overlayContent={<ClusterInfo cluster={cluster} />}
        />
      );
    case "GCP":
      return (
        <HomeEntryIcon
          iconName="google"
          overlayTitle="Cluster"
          overlayContent={<ClusterInfo cluster={cluster} />}
        />
      );
    default:
      return null;
  }
}

export function ClusterEntry({ cluster }: { cluster: ICluster }) {
  return (
    <div className="home-entry home-entry-cluster">
      <div>{cluster.name}</div>
      <ClusterIcon cluster={cluster} />
    </div>
  );
}

interface IHomeEntryIconProps {
  iconName?: string;
  iconContent?: ReactNode;
  overlayTitle: string;
  overlayContent: ReactNode;
}

function HomeEntryIcon({
  iconName,
  iconContent = null,
  overlayTitle,
  overlayContent,
}: IHomeEntryIconProps) {
  return (
    <OverlayTrigger
      trigger="hover"
      placement="auto"
      overlay={
        <Popover id={overlayTitle}>
          <Popover.Header as="h3">{overlayTitle}</Popover.Header>
          <Popover.Body>{overlayContent}</Popover.Body>
        </Popover>
      }
    >
      <span className="text-color">
        {iconName ? <i className={`bi bi-${iconName}`} /> : iconContent}
      </span>
    </OverlayTrigger>
  );
}

function ADNFeature({ website }: { website: WebsiteType }) {
  const adnEnabled = website.hasAdnEnabled;

  if (!adnEnabled) {
    return null;
  }

  return (
    <Link to={`/website/${website.id}/adn`}>
      <HomeEntryIcon
        iconContent={<img src={AdnIcon} alt="adn" />}
        overlayTitle="Activation Delivery Network"
        overlayContent="Activation Delivery Network is currently deployed in production"
      />
    </Link>
  );
}

function SmartCacheRefreshFeature({ website }: { website: WebsiteType }) {
  const smrEnabled = website.productionVersion?.enableSmartCacheRefresh;

  if (smrEnabled) {
    return null;
  }

  return (
    <Link to={`/website/${website.id}/settings`}>
      <HomeEntryIcon
        iconName="repeat"
        overlayTitle="Smart Cache Refresh"
        overlayContent="Smart cache refresh is disabled, the inventory will not be updated"
      />
    </Link>
  );
}

function RenderingFarmFeature({ website }: { website: WebsiteType }) {
  const rfEnabled = website.productionVersion?.useRenderingFarm;

  if (!rfEnabled) {
    return null;
  }

  return (
    <Link to={`/website/${website.id}/settings`}>
      <HomeEntryIcon
        iconName="diagram-3"
        overlayTitle="Rendering Farm"
        overlayContent="The rendering farm is enabled. This feature is still in beta"
      />
    </Link>
  );
}

function LinksDiscoveryInputs({ website }: { website: WebsiteType }) {
  const ldiEnabled = !!website.productionVersion?.linksDiscoveryInputs?.length;

  if (!ldiEnabled) {
    return null;
  }

  return (
    <Link to={`/website/${website.id}`}>
      <HomeEntryIcon
        iconName="link-45deg"
        overlayTitle="Links Discovery Sources"
        overlayContent="There are links discovery sources configured. This can lead to uncontrolled growth of the inventory"
      />
    </Link>
  );
}

function BotsDiscoveryInputs({ website }: { website: WebsiteType }) {
  const bdiEnabled = !!website.productionVersion?.botsDiscoveryInputs?.length;

  if (!bdiEnabled) {
    return null;
  }

  return (
    <Link to={`/website/${website.id}`}>
      <HomeEntryIcon
        iconName="robot"
        overlayTitle="Bots Discovery Sources"
        overlayContent="There are bots discovery sources configured. This can lead to uncontrolled growth of the inventory"
      />
    </Link>
  );
}

export function WebsiteEntry({ website }: { website: WebsiteType }) {
  return (
    <div className="home-entry home-entry-website">
      <div className="website-title">
        <Link to={`website/${website.id}`}>
          {website.name} ({website.productionVersion?.hosts ?? ""})
        </Link>
      </div>
      <div className="website-feature">
        <ADNFeature website={website} />
        <RenderingFarmFeature website={website} />
        <LinksDiscoveryInputs website={website} />
        <BotsDiscoveryInputs website={website} />
        <SmartCacheRefreshFeature website={website} />
        <span className="website-id">{website.id}</span>
      </div>
    </div>
  );
}
