import { gql } from "@apollo/client";
import { SECTION_FIELDS_FRAGMENT } from "./sectionFieldsFragment.gql";

export const CREATE_SECTION = gql`
  ${SECTION_FIELDS_FRAGMENT}
  mutation CreateSection($input: InternalCreateSectionMutationInput!) {
    internalCreateSection(input: $input) {
      section {
        ...SectionFields
      }
      errors {
        field
        messages
      }
    }
  }
`;
