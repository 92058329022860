import { JsCodeType, SectionType } from "../../../gql/types.generated";

export type ScriptCode = Pick<
  JsCodeType,
  | "onActionCode"
  | "onDomContentLoadedCode"
  | "onDoneCode"
  | "onInitCode"
  | "onLoadCode"
  | "onPreBeamResponseCode"
  | "onSetupBeforeRenderCode"
  | "onWaitForCode"
>;

export const filterSearchedScripts = (scripts: JsCodeType[], search: string) =>
  search ? scripts.filter(scriptNameMatches(search)) : scripts;

const scriptNameMatches =
  (value: string) =>
  ({ name }: JsCodeType) =>
    name.toLowerCase().includes(value.toLowerCase());

export const orderScripts = (scripts: JsCodeType[]) =>
  [...scripts].sort(scriptsByPosition);

export const scriptsByPosition = (a: JsCodeType, b: JsCodeType) =>
  a.position - b.position;

export const moveScript = (
  scripts: JsCodeType[],
  from: JsCodeType["position"],
  to: JsCodeType["position"]
): JsCodeType[] => {
  if (from === to) return scripts;

  return scripts.map((script) => {
    const isMovingScript = script.position === from;

    if (isMovingScript) {
      return { ...script, position: to };
    }

    const shouldMoveUp = script.position > from && script.position <= to;
    const shouldMoveDown = script.position < from && script.position >= to;
    const positionDelta = shouldMoveUp ? -1 : shouldMoveDown ? 1 : 0;

    return { ...script, position: script.position + positionDelta };
  });
};

export const mustFindScript = (
  scripts: JsCodeType[],
  id: JsCodeType["id"]
): JsCodeType => {
  const foundScript = scripts.find((script) => script.id === id);
  if (!foundScript) {
    throw new Error(`Script with id ${id} not found`);
  }
  return foundScript;
};

export type FilterScriptsOptions =
  | { sectionId: SectionType["id"]; sectionStableId?: never }
  | { sectionId?: never; sectionStableId?: SectionType["id"] };

export const filterScriptsOnSection = (
  scripts: JsCodeType[],
  { sectionId, sectionStableId }: FilterScriptsOptions
) =>
  scripts.filter(
    (script) =>
      script.applyOnWebsite ||
      script.sections.some(
        ({ id, stableId }) => id === sectionId || stableId === sectionStableId
      )
  );
