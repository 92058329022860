import { useMutation, useQuery } from "@apollo/client";
import React from "react";
import Alert from "react-bootstrap/Alert";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";

import { getEnv } from "../../common/env";
import { DataHandler } from "../../components/DataHandler";
import { AdnEnvironment } from "../../gql/types.generated";
import {
  CreateAdnConfigMutation,
  CreateAdnConfigMutationVariables,
  CREATE_ADN_CONFIG,
  DeployAdnConfigMutation,
  DeployAdnConfigMutationVariables,
  DEPLOY_ADN_CONFIG,
  GetAdnConfigQuery,
  GetAdnConfigQueryVariables,
  GET_ADN_CONFIG,
  UpdateAdnConfigMutation,
  UpdateAdnConfigMutationVariables,
  UPDATE_ADN_CONFIG,
} from "../../gql/websites/adn";
import {
  GET_WEBSITE_SUMMARY,
  WebsiteSummaryQuery,
  WebsiteSummaryQueryVariables,
} from "../../gql/websites/website";
import { ADNConfigForm, IADNConfigFormProps } from "./ADNConfigForm";
import { Link, useParams } from "react-router-dom";
import { ConfigIdParams, WebsiteIdParams } from "../types/routeParams";

export function ADNConfig() {
  const { websiteId, configId } = useParams<
    WebsiteIdParams & ConfigIdParams
  >() as WebsiteIdParams & ConfigIdParams;
  const websiteQuery = useQuery<
    WebsiteSummaryQuery,
    WebsiteSummaryQueryVariables
  >(GET_WEBSITE_SUMMARY, {
    variables: { id: websiteId },
  });
  const adnConfigQuery = useQuery<
    GetAdnConfigQuery,
    GetAdnConfigQueryVariables
  >(GET_ADN_CONFIG, {
    variables: { id: configId },
  });
  const [deployAdnConfig, deployAdnConfigResult] = useMutation<
    DeployAdnConfigMutation,
    DeployAdnConfigMutationVariables
  >(DEPLOY_ADN_CONFIG);
  const [cloneAdnConfig, cloneAdnConfigResult] = useMutation<
    CreateAdnConfigMutation,
    CreateAdnConfigMutationVariables
  >(CREATE_ADN_CONFIG, {
    refetchQueries: [{ query: GET_ADN_CONFIG, variables: { id: configId } }],
  });
  const [updateAdnConfig, updateAdnConfigResult] = useMutation<
    UpdateAdnConfigMutation,
    UpdateAdnConfigMutationVariables
  >(UPDATE_ADN_CONFIG, {
    refetchQueries: [{ query: GET_ADN_CONFIG, variables: { id: configId } }],
  });

  const loading = websiteQuery.loading || adnConfigQuery.loading;
  const error = websiteQuery.error || adnConfigQuery.error;
  const data = { ...websiteQuery.data, ...adnConfigQuery.data };
  const { website, adnConfig } = data;

  if (error || loading || !website || !adnConfig) {
    return (
      <DataHandler
        error={error}
        loading={loading}
        data={website && adnConfig}
        expectData
      />
    );
  }

  const updateOrCloning = adnConfig.isDraft
    ? updateAdnConfigResult.data?.updateAdnConfig
    : cloneAdnConfigResult.data?.createAdnConfig;

  const handleDeployment = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const env = (formData.get("environment") ??
      AdnEnvironment.Test) as AdnEnvironment;

    // Avoid multiple submissions
    if (deployAdnConfigResult.loading) {
      return;
    }

    // Avoid unintentional submissions
    if (
      !window.confirm(
        `You are trying to deploy the lightweight CDN configuration "${configId}" of the website "${website.name}" (${website.id}) to the "${env}" env. \n\nAre you sure you know what you are doing?`
      )
    ) {
      return;
    }

    try {
      await deployAdnConfig({
        variables: { configId, environment: env },
      });
    } catch (err) {
      // handled by useMutation
    }
  };

  const handleUpdate: IADNConfigFormProps["onSubmit"] = async (
    formData,
    errors
  ) => {
    const isFormValid = Object.keys(errors).length === 0;

    if (!isFormValid) {
      return;
    }

    try {
      if (adnConfig.isDraft) {
        await updateAdnConfig({
          variables: { input: formData },
        });
      } else {
        const { id, ...rest } = formData;
        await cloneAdnConfig({
          variables: { input: { ...rest, website: websiteId } },
        });
      }
    } catch (err) {
      // handled by useMutation
    }

    // Show error/success messages
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const env = getEnv();

  return (
    <div>
      <h1>
        <Link to={`/website/${website.id}`}>{website.name}</Link> &gt;{" "}
        <Link to={`/website/${website.id}/adn`}>Lightweight CDN</Link> &gt; ADN
        Config {adnConfig.id}
      </h1>

      <Card>
        <Card.Header>
          Deploy configuration <Badge bg="danger">Danger</Badge>
        </Card.Header>
        <Card.Body>
          <Form action="" onSubmit={handleDeployment}>
            {deployAdnConfigResult.called &&
              !deployAdnConfigResult.loading &&
              !deployAdnConfigResult.error && (
                <Alert variant="success">
                  Deployment successfully launched.
                </Alert>
              )}
            {deployAdnConfigResult.error && (
              <Alert variant="danger">
                Something went wrong.
                <br />
                <pre>
                  {JSON.stringify(deployAdnConfigResult.error, null, 2)}
                </pre>
              </Alert>
            )}
            {!deployAdnConfigResult.error && !deployAdnConfigResult.data && (
              <Alert variant="warning">
                Deploying the lightweight CDN is dangerous. This feature must
                only be used by the dev and infra team.
              </Alert>
            )}
            <Form.Group controlId="environment">
              <Form.Label>
                The environment to deploy the ADN Config to
              </Form.Label>
              <Form.Select name="environment" required>
                <option value="test">Test</option>
                <option value="staging">Staging</option>
                {env === "beta" ? (
                  <option value="production">Production</option>
                ) : null}
              </Form.Select>
            </Form.Group>
            <Button
              type="submit"
              variant="danger"
              disabled={deployAdnConfigResult.loading}
            >
              {deployAdnConfigResult.loading ? "Deploying…" : "Deploy"}
            </Button>
          </Form>
        </Card.Body>
      </Card>

      <Card>
        <Card.Header
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <span>Configuration</span>
          <span>* required</span>
        </Card.Header>
        <Card.Body>
          <ADNConfigForm
            adnConfig={adnConfig}
            submitting={
              updateAdnConfigResult.loading || cloneAdnConfigResult.loading
            }
            onSubmit={handleUpdate}
            messages={
              <>
                {updateOrCloning?.adnConfig &&
                  !updateAdnConfigResult.error &&
                  updateOrCloning.errors.length === 0 && (
                    <Alert variant="success">
                      {adnConfig.isDraft ? (
                        <>{adnConfig.id} configuration successfully updated!</>
                      ) : (
                        <>
                          {" "}
                          Configuration successfully cloned with changes.{" "}
                          <Link
                            to={`/website/${websiteId}/adn/edit/${updateOrCloning.adnConfig.id}`}
                          >
                            View
                          </Link>
                        </>
                      )}
                    </Alert>
                  )}
                {updateAdnConfigResult.error ? (
                  <Alert variant="danger">
                    Something went wrong.
                    <br />
                    <pre>
                      {JSON.stringify(updateAdnConfigResult.error, null, 2)}
                    </pre>
                  </Alert>
                ) : null}
                {updateOrCloning && updateOrCloning.errors.length > 0 ? (
                  <Alert variant="danger">
                    Some fields are invalid:
                    <ul style={{ marginBottom: 0 }}>
                      {updateOrCloning.errors.map(({ field, messages }) => (
                        <li key={field}>
                          <strong>{field}</strong>: {messages}
                        </li>
                      ))}
                    </ul>
                  </Alert>
                ) : null}
              </>
            }
          />
        </Card.Body>
      </Card>
    </div>
  );
}
