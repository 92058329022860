import { gql } from "@apollo/client";

export const GET_ADN_CONFIG = gql`
  query GetADNConfig($id: ID!) {
    adnConfig(id: $id) {
      id
      createdDate
      modifiedDate
      failoverHostHeader
      failoverUserAgent
      failoverRequestHeaders
      failoverRequestHeadersFilters
      failoverResponseHeaders
      customerOriginUrl
      customerOriginPort
      customerOriginTimeout
      swOriginTimeout
      swOriginRewrite
      swRealOrigin
      ignoredPathsRegex
      workerVersion
      gdprModeEnabled
      verifiedBotsEnabled
      interceptedBotsRegex
      urlCacheHit
      isDraft
      deployTests
    }
  }
`;
