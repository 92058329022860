import {
  useForm as originalUseForm,
  FieldValues,
  UseFormProps,
  UseFormReturn,
  UseFormRegister,
} from "react-hook-form";

export * from "react-hook-form";

type UseForm = <TFieldValues extends FieldValues = FieldValues, TContext = any>(
  props?: UseFormProps<TFieldValues, TContext>
) => UseFormReturn<TFieldValues, TContext> & {
  originalRegister: UseFormRegister<TFieldValues>;
};

// Add utilities for more interop with react-bootstrap
export const useForm: UseForm = (...args) => {
  const {
    register: originalRegister,
    formState,
    ...rest
  } = originalUseForm(...args);
  const { touchedFields, errors } = formState;

  const register: typeof originalRegister = (name, options) => {
    return {
      ...originalRegister(name, options),
      isValid: Boolean(touchedFields[name] && !errors[name]),
      isInvalid: Boolean(errors[name]),
    };
  };

  return {
    ...rest,
    formState,
    originalRegister,
    register,
  };
};
