import { Dropdown } from "react-bootstrap";
import { JsCodeType } from "../../../../../gql/types.generated";
import { useGoToScriptEdition } from "../../../hooks/useGoToScriptEdition";
import { useScriptsTableContext } from "../ScriptsTableContext";

export const ActionsCell = (script: JsCodeType) => {
  const goToScriptEdition = useGoToScriptEdition();
  const { activateScript, pauseScript, deleteScript } =
    useScriptsTableContext();

  const actions = [
    {
      id: "edit",
      icon: "pencil-square",
      label: "Edit",
      handle: () => goToScriptEdition(script.id),
    },
    script.active
      ? {
          id: "pause",
          icon: "pause",
          label: "Pause",
          handle: () => pauseScript(script),
        }
      : {
          id: "activate",
          icon: "play",
          label: "Activate",
          handle: () => activateScript(script),
        },
    {
      id: "delete",
      icon: "trash",
      label: "Delete",
      handle: () => {
        if (window.confirm(getDeleteConfirmationMessage(script))) {
          deleteScript(script);
        }
      },
    },
  ] satisfies { id: string; icon: string; label: string; handle: () => void }[];

  return (
    <div className="d-flex justify-content-end">
      <Dropdown>
        <Dropdown.Toggle variant="" id="dropdown-basic" bsPrefix="no-caret">
          <i className="bi bi-three-dots-vertical" />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {actions.map(({ id, icon, label, handle }) => (
            <Dropdown.Item key={id} onClick={handle}>
              <i className={`bi bi-${icon}`} />
              <span style={{ marginLeft: "8px" }}>{label}</span>
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

const getDeleteConfirmationMessage = ({ name, sections }: JsCodeType): string =>
  `Are you sure you want to delete the script "${name}"?`
    .concat(
      sections.length > 0
        ? ` It will be detached from behavior(s) ${sections.map((s) => `"${s.name}"`).join(", ")}.`
        : ""
    )
    .concat(" This action cannot be undone.");
