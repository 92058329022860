import { ScopeCards } from "./Scope";
import { MetricsCards } from "./Metrics";
import { ModuleType as ModuleTypeGQL } from "../../../gql/types.generated";

export interface IReportingHomeProps {
  websiteId: string;
  moduleId: string;
  versions?: Array<Partial<ModuleTypeGQL | null>> | null;
}

export function ReportingHome({
  websiteId,
  moduleId,
  versions,
}: IReportingHomeProps) {
  const variables = {
    websiteId: websiteId,
    moduleId: moduleId,
  };

  return (
    <>
      <ScopeCards variables={variables} />
      <MetricsCards variables={variables} versions={versions} />
    </>
  );
}
