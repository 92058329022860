import Card from "react-bootstrap/Card";

import { Header } from "../components/header";
import { WebsiteIdParams } from "../types/routeParams";
import { UrlTester } from "./urlTester";
import { useParams } from "react-router-dom";

export function WebsiteUrlTester() {
  const { websiteId } = useParams<WebsiteIdParams>() as WebsiteIdParams;
  return (
    <div>
      <Header.Title websiteId={websiteId} name="URL Tester" />
      <Card>
        <Card.Body>
          <UrlTester websiteId={websiteId} />
        </Card.Body>
      </Card>
    </div>
  );
}
