import { gql } from "@apollo/client";

export const CREATE_JOB = gql`
  mutation CreateImportJob($input: CreateImportJobMutationInput!) {
    createImportJob(input: $input) {
      importJob {
        id
      }
      errors {
        field
        messages
      }
    }
  }
`;
