import React from "react";
import Button from "react-bootstrap/Button";
import {Link, useParams} from "react-router-dom";
import {Card} from "react-bootstrap";
import {WebsiteIdParams} from "../types/routeParams";
import {useMutation, useQuery} from "@apollo/client";
import {
    GET_WEBSITE, PW_OFFBOARDING,
    WebsiteQuery,
    WebsiteQueryVariables
} from "../../gql/websites/website";
import {DataHandler} from "../../components/DataHandler";
import {MutationPwOffboardingArgs, PwOffboarding} from "../../gql/types.generated";

export function PwSettings() {
    const { websiteId } = useParams<WebsiteIdParams>() as WebsiteIdParams;

    const { loading, error, data } = useQuery<
        WebsiteQuery,
        WebsiteQueryVariables
    >(GET_WEBSITE, {
        variables: { id: websiteId },
    });

    if (error || loading || !data || !data.website) {
        return (
            <DataHandler error={error} loading={loading} data={data} expectData />
        );
    }

    return (
        <PwSettingsLoaded
            websiteId={websiteId}
            websiteName={data.website.name}
        />
    );
}

export function PwSettingsLoaded({websiteId, websiteName}: {
    websiteId: WebsiteIdParams["websiteId"];
    websiteName: string;
}) {
    const [pwOffboarding] = useMutation<
        PwOffboarding,
        MutationPwOffboardingArgs
    >(PW_OFFBOARDING);

    async function offboardAction() {
        if (window.confirm("Confirm offboarding ?")) {
            try {
                await pwOffboarding({
                    variables: {
                        id: websiteId,
                    },
                });
            } catch (err) {
                // handled by useMutation
            }
        }
    }

    return (
      <>
          <h1>
              <Link to={`/website/${websiteId}`}>{websiteName}</Link> &gt; PageWorkers Settings
          </h1>

          <Card>
              <Card.Header>Offboarding will deactivate website on PageWorkers Cluster and serve an empty tag instead.</Card.Header>
              <Card.Body>
                  <Button
                      type="button"
                      onClick={offboardAction}
                      variant="danger"
                  >Offboard {websiteName} ({websiteId})</Button>
              </Card.Body>
          </Card>
      </>
    );
}
