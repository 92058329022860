import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { MainCard } from "./Cards/Main";
import { HitsCard } from "./Cards/Hits";
import { BaseCard } from "./Cards/Base";
import { IndexedCard } from "./Cards/Indexed";
import { getOptions } from "../Settings";
import { HitsByHourCard } from "./Cards/HitsByHour";

export function Home() {
  const options = getOptions();

  return (
    <Container fluid id="monitoring">
      <Row>
        <Col style={{ padding: "10px", textAlign: "center" }}>
          You are very curious! If you have any charts ideas to display here,
          talk to @ampelmann on slack!
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <BaseCard
            name="Main Metrics Last 24 Hours"
            body={<MainCard options={options} />}
          />
        </Col>
        <Col md={6}>
          <BaseCard
            name="Hits Last 24 Hours"
            body={<HitsByHourCard options={options} />}
          />
        </Col>
        <Col md={6}>
          <BaseCard name="Hits by day" body={<HitsCard options={options} />} />
        </Col>

        <Col md={6}>
          <BaseCard
            name="Delivered Hits by day"
            body={<HitsCard options={options} metric="countHitsDelivered" />}
          />
        </Col>
        <Col md={6}>
          <BaseCard
            name="Not Delivered Hits by day"
            body={<HitsCard options={options} metric="countHitsNotDelivered" />}
          />
        </Col>
        <Col md={6}>
          <BaseCard
            name="Indexed Pages by day"
            body={<IndexedCard options={options} />}
          />
        </Col>
      </Row>
    </Container>
  );
}
