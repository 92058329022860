import { gql } from "@apollo/client";

export const CREATE_WEBSITE = gql`
  mutation CreateWebsite(
    $name: String!
    $hosts: String!
    $cluster: ID!
    $pageworkersCluster: ID
  ) {
    createWebsite(
      input: {
        name: $name
        hosts: $hosts
        cluster: $cluster
        pageworkersCluster: $pageworkersCluster
      }
    ) {
      website {
        id
        name
      }
    }
  }
`;
