import { gql } from "@apollo/client";

export const ALL_WEBSITES_HEALTH = gql`
  query Health {
    allWebsites {
      id
      name
      healthInterceptorStatus
      healthInterceptorLastChecked
      healthSettingsStatus
      healthSettingsLastChecked
    }
  }
`;
