import { useMutation, useQuery } from "@apollo/client";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer-continued";

import { useLocalStorageSynchronizedState } from "../common/hooks";
import { sortConfig } from "../common/object";
import { DataHandler } from "../components/DataHandler";
import { WebsiteIdParams } from "./types/routeParams";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  GET_WEBSITE_DEBUG_CONFIG,
  PUSH_VERSION_MUTATION,
  PushVersionMutation,
  PushVersionMutationVariables,
  WebsiteDebugConfigQuery,
  WebsiteDebugConfigQueryVariables,
} from "../gql/websites/website";
import Alert from "react-bootstrap/Alert";

export function WebsiteVersioning() {
  const navigate = useNavigate();
  const { websiteId } = useParams<WebsiteIdParams>() as WebsiteIdParams;

  const { loading, error, data } = useQuery<
    WebsiteDebugConfigQuery,
    WebsiteDebugConfigQueryVariables
  >(GET_WEBSITE_DEBUG_CONFIG, {
    variables: { id: websiteId },
    fetchPolicy: "network-only",
  });

  const [debug, setDebug] = useLocalStorageSynchronizedState<`${boolean}`>({
    key: "pushToProdDebug",
    defaultValue: "false",
  });
  const isDebug = debug === "true";
  const toggleDebug = () =>
    setDebug((prev) => (prev === "true" ? "false" : "true"));

  const [comment, setComment] = useState("");
  const [createVersion, { error: errorCreateVersion }] = useMutation<
    PushVersionMutation,
    PushVersionMutationVariables
  >(PUSH_VERSION_MUTATION, {
    onCompleted: (data) => {
      window.scrollTo(0, 0);
      navigate(
        {
          pathname: "/website/" + websiteId,
        },
        {
          state: {
            message: {
              value: "Version pushed into production",
              status: "success",
            },
          },
        },
      );
    },
  });

  if (error || loading || !data?.website?.debugConfig) {
    return (
      <DataHandler
        error={error}
        loading={loading}
        data={data?.website?.debugConfig}
        expectData
      />
    );
  }

  const submitForm = async () => {
    try {
      await createVersion({
        variables: { comment: comment, website: websiteId },
      });
    } catch {}
  };

  return (
    <div>
      <h1>
        <Link to={`/website/${data.website.id}`}>{data.website.name}</Link>
        {" >"}
        Push Draft Version to Production
      </h1>

      {errorCreateVersion && (
        <Alert variant="danger">
          Something went wrong.
          <br />
          <pre>{errorCreateVersion.message}</pre>
        </Alert>
      )}

      <Button variant="link" onClick={toggleDebug}>
        {isDebug ? "Hide debug" : "Show files config debug"}
      </Button>

      {isDebug && (
        <>
          <Card>
            <Card.Header>Main Config Diff</Card.Header>
            <Card.Body>
              {data.website.debugConfig.mainConfig &&
              data.website.debugConfig.draftMainConfig ? (
                <ReactDiffViewer
                  oldValue={JSON.stringify(
                    sortConfig(JSON.parse(data.website.debugConfig.mainConfig)),
                    null,
                    2,
                  )}
                  newValue={JSON.stringify(
                    sortConfig(
                      JSON.parse(data.website.debugConfig.draftMainConfig),
                    ),
                    null,
                    2,
                  )}
                  compareMethod={DiffMethod.WORDS}
                  splitView={true}
                />
              ) : (
                <>Missing data</>
              )}
            </Card.Body>
          </Card>

          <Card>
            <Card.Header>Inputs Config Diff</Card.Header>
            <Card.Body>
              {data.website.debugConfig.inputsConfig &&
              data.website.debugConfig.draftInputsConfig ? (
                <ReactDiffViewer
                  oldValue={JSON.stringify(
                    sortConfig(
                      JSON.parse(data.website.debugConfig.inputsConfig),
                    ),
                    null,
                    2,
                  )}
                  newValue={JSON.stringify(
                    sortConfig(
                      JSON.parse(data.website.debugConfig.draftInputsConfig),
                    ),
                    null,
                    2,
                  )}
                  compareMethod={DiffMethod.WORDS}
                  splitView={true}
                />
              ) : (
                <>Missing data</>
              )}
            </Card.Body>
          </Card>

          <Card>
            <Card.Header>Crons Config Diff</Card.Header>
            <Card.Body>
              {data.website.debugConfig.cronsConfig &&
              data.website.debugConfig.draftCronsConfig ? (
                <ReactDiffViewer
                  oldValue={JSON.stringify(
                    sortConfig(
                      JSON.parse(data.website.debugConfig.cronsConfig),
                    ),
                    null,
                    2,
                  )}
                  newValue={JSON.stringify(
                    sortConfig(
                      JSON.parse(data.website.debugConfig.draftCronsConfig),
                    ),
                    null,
                    2,
                  )}
                  compareMethod={DiffMethod.WORDS}
                  splitView={true}
                />
              ) : (
                <>Missing data</>
              )}
            </Card.Body>
          </Card>

          <Card>
            <Card.Header>Rendering JS Diff</Card.Header>
            <Card.Body>
              {data.website.debugConfig.renderingJsCode &&
              data.website.debugConfig.draftRenderingJsCode ? (
                <ReactDiffViewer
                  oldValue={data.website.debugConfig.renderingJsCode}
                  newValue={data.website.debugConfig.draftRenderingJsCode}
                  compareMethod={DiffMethod.WORDS}
                  splitView={true}
                />
              ) : (
                <>Missing data</>
              )}
            </Card.Body>
          </Card>
        </>
      )}

      <Card>
        <Card.Body>
          <Form.Group>
            <Form.Label>Comment</Form.Label>
            <Form.Control
              name="comment"
              type="text"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Enter Comment"
            />
            <Form.Text>
              Comment about version being pushed to production
            </Form.Text>
          </Form.Group>

          <Button className="float-right" onClick={submitForm}>
            Confirm Push Draft Version To Production
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
}
