export const VERSION_COLORS = {
  "No PW": "#D3D3D3",
  v0: "#FF9F55",
  v1: "#FFCF7A",
  v2: "#FFE9A8",
  v3: "#FFC854",
  v4: "#FFAA21",
  v5: "#FFBF47",
  v6: "#FFD876",
  v7: "#FFEEB7",
  v8: "#FFA5FF",
  v9: "#D967FF",
  v10: "#A93DFF",
  v11: "#DA76FF",
  v12: "#EFA8FF",
  v13: "#C68CFF",
  v14: "#9F71FF",
  v15: "#73A8FF",
  v16: "#96C6FF",
  v17: "#B2DEFF",
  v18: "#FDB1FF",
  v19: "#FFC8F3",
  v20: "#FFE4EC",
};
