export type Filters = Record<string, NonNullable<FilterValue>>;
export type FilterValue = string | boolean | undefined;
export type WebsitesFilterHandler = (
  filterKey: string,
  filterValue: FilterValue
) => void;

export type FilterInputValue = "both" | "enabled" | "disabled";

export const getInputValue = (filterValue: FilterValue): FilterInputValue => {
  if (filterValue === undefined) {
    return "both";
  }

  return filterValue ? "enabled" : "disabled";
};

export const getFilterValue = (inputValue: FilterInputValue): FilterValue => {
  const isEnabled = inputValue === "enabled";
  const filterValue = inputValue === "both" ? undefined : isEnabled;

  return filterValue;
};
