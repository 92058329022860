import { useQuery } from "@apollo/client";
import { useState } from "react";

import {
  ExplorerTable,
  SearchEngineComponent,
  SectionComponent,
} from "../../common/explorer";
import { IFilter } from "../../common/typing/filter";
import { DataHandler } from "../../components/DataHandler";
import { WebsiteType } from "../../gql/types.generated";
import {
  GET_WEBSITE,
  WebsiteQuery,
  WebsiteQueryVariables,
} from "../../gql/websites/website";
import { DateComponent } from "../components/date";
import { Header } from "../components/header";
import {
  DEFAULT_START_OFFSET,
  PRESETS,
  SERVED_PAGES_DEFINITION,
} from "./Settings";
import { getDates } from "../../common/time";
import { IPreset } from "../../common/typing/website";
import { PresetParams, WebsiteIdParams } from "../types/routeParams";
import { Link, useParams } from "react-router-dom";

export function WebsiteBeamExplorer() {
  const { websiteId, preset } = useParams<
    WebsiteIdParams & PresetParams
  >() as WebsiteIdParams & PresetParams;
  const startOffsetInPreset = (PRESETS as Record<string, IPreset>)?.[preset]
    ?.startOffset;
  const { start, end } = getDates(
    websiteId,
    startOffsetInPreset || DEFAULT_START_OFFSET
  );
  const { loading, error, data } = useQuery<
    WebsiteQuery,
    WebsiteQueryVariables
  >(GET_WEBSITE, {
    variables: { id: websiteId },
  });
  const {
    startDate,
    endDate,
    component: dateComponent,
  } = DateComponent(websiteId, start, end, !!startOffsetInPreset);

  const [section, setSection] = useState<string | null>(null);
  const [searchEngine, setSearchEngine] = useState<number | null>(null);
  const [bot, setBot] = useState<number | null>(null);

  const website = data?.website;

  if (error || loading || !website) {
    return (
      <DataHandler error={error} loading={loading} data={website} expectData />
    );
  }

  return (
    <div>
      <Header>
        <Header.Title websiteId={websiteId} name="Served Pages" />
        <Header.Selectors
          selectorComponents={[
            <SearchEngineComponent
              setSearchEngine={setSearchEngine}
              setBot={setBot}
              searchEngine={searchEngine}
              bot={bot}
            />,
            <SectionComponent
              sections={website.productionVersion?.sections ?? []}
              setSection={setSection}
            />,
            dateComponent,
          ]}
        />
      </Header>

      <div>
        <Link to={`/website/${websiteId}/beam/explorer/last`}>
          Last Served Pages
        </Link>{" "}
        {" | "}
        <Link to={`/website/${websiteId}/beam/explorer/last24`}>
          Last Served Pages (24h)
        </Link>{" "}
        {" | "}
        <Link to={`/website/${websiteId}/beam/explorer/top`}>
          Top Served Pages
        </Link>
        {" | "}
        <Link to={`/website/${websiteId}/beam/explorer/topCacheHit`}>
          Top Served Pages (Cache Hit)
        </Link>
        {" | "}
        <Link to={`/website/${websiteId}/beam/explorer/topBadCanonical`}>
          Top Served Pages (Bad Canonical)
        </Link>
        {" | "}
        <Link to={`/website/${websiteId}/beam/explorer/topLiveFetch`}>
          Top Live Fetched Pages
        </Link>
        {" | "}
        <Link to={`/website/${websiteId}/beam/explorer/topCacheMiss`}>
          Top Served Pages (Cache Miss / Any Reason)
        </Link>
        {" | "}
        <Link to={`/website/${websiteId}/beam/explorer/topNotCached`}>
          Top Served Pages (Cache Miss / Not Cached)
        </Link>
        {" | "}
        <Link to={`/website/${websiteId}/beam/explorer/topBlocked`}>
          Top Served Pages (Cache Miss / Blocked by Behavior)
        </Link>
        {" | "}
        <Link to={`/website/${websiteId}/beam/explorer/topJsError`}>
          Top Served Pages (Cache Miss / JS Error)
        </Link>
        {" | "}
        <Link
          to={`/website/${websiteId}/beam/explorer/topNotCachedByDirectoryN1`}
        >
          Not Cached Analysis by directory N1
        </Link>{" "}
        /{" "}
        <Link
          to={`/website/${websiteId}/beam/explorer/topNotCachedByDirectoryN2`}
        >
          N2
        </Link>{" "}
        /{" "}
        <Link
          to={`/website/${websiteId}/beam/explorer/topNotCachedByQueryString`}
        >
          Query String
        </Link>{" "}
        /{" "}
        <Link
          to={`/website/${websiteId}/beam/explorer/topNotCachedCacheUrlByQueryString`}
        >
          Cache URL (ignored params) Query String
        </Link>
      </div>

      <ExplorerTable
        website={website as WebsiteType}
        startDate={startDate}
        endDate={endDate}
        currentPreset={preset}
        presets={PRESETS}
        definitions={SERVED_PAGES_DEFINITION}
        additionalFilters={getAdditionalFilters({ searchEngine, bot, section })}
      />
    </div>
  );
}

const getAdditionalFilters = ({
  searchEngine,
  bot,
  section,
}: {
  searchEngine: number | null;
  bot: number | null;
  section: string | null;
}): IFilter[] => {
  const additionalFilters: IFilter[] = [];
  if (searchEngine) {
    additionalFilters.push({
      field: "search_engine_id",
      predicate: "eq",
      value: searchEngine,
    });
  }
  if (bot) {
    additionalFilters.push({
      field: "bot_id",
      predicate: "eq",
      value: bot,
    });
  }
  if (section) {
    additionalFilters.push({
      field: "section_name",
      predicate: "eq",
      value: section,
    });
  }
  return additionalFilters;
};
