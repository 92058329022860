import { gql } from "@apollo/client";

export const FORCE_SECTION_REFRESH = gql`
  mutation ForceSectionRefresh(
    $websiteId: ID!
    $sectionId: String!
    $forceRefreshToken: String!
    $options: ForceRefreshOptions
  ) {
    forceSectionRefresh(
      websiteId: $websiteId
      sectionId: $sectionId
      forceRefreshToken: $forceRefreshToken
      options: $options
    ) {
      errors {
        message
      }

      ok
    }
  }
`;
