/**
 * Returns a copy of `array` having the item at the given `position`
 * swapped with its previous or next neighbor depending on `direction`.
 * Any out-of-boundaries combination is no-op.
 *
 * @example
 * swapNeighbors(["a", "b", "c"], 1, "left") === ["b", "a", "c"]
 *
 * @example
 * swapNeighbors(["a", "b", "c"], 1, "right") === ["a", "c", "b"]
 */
export function swapNeighbors<T>(
  array: T[],
  index: number,
  direction: "left" | "right"
): T[] {
  const leftIndex = direction === "left" ? index - 1 : index;
  const rightIndex = leftIndex + 1;

  if (leftIndex < 0 || rightIndex > array.length - 1) return array;

  return [
    ...array.slice(0, leftIndex),
    array[rightIndex],
    array[leftIndex],
    ...array.slice(rightIndex + 1),
  ];
}

/**
 * Same as `Object.keys`, except it's properly typed.
 *
 * @example
 * Object.keys({ a: 1, b: 2 }) // type: string[]
 * keysOf({ a: 1, b: 2 }) // type: ("a" | "b")[]
 */
export const keysOf = <K extends string>(v: Record<K, unknown>): K[] =>
  // @ts-expect-error Object.keys signature `string[]` is too wide
  Object.keys(v);
