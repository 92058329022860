import DatePicker from "react-datepicker";

import { useLocalStorageDateSynchronizedState } from "../../common/hooks";
import { dateToEndOfDay } from "../../common/time";
import { useState } from "react";

import "./date.css";

export function DateComponent(
  websiteId: string,
  start: Date,
  end: Date,
  isLocalValue: boolean = false
) {
  const now = new Date();
  const endOfDay = dateToEndOfDay(now);
  const ttl = endOfDay.getTime() - now.getTime();
  // Keep in local storage until tomorrow because endDate may change
  const [locality, setLocality] = useState<boolean>(!!isLocalValue);

  const [startDate, setStartDateInternal, restoreStartDateCache] =
    useLocalStorageDateSynchronizedState({
      key: "startDate:" + websiteId,
      defaultValue: start,
      roundToMidnight: true,
      ttl: ttl,
    });
  const [endDate, setEndDateInternal, restoreEndDateCache] =
    useLocalStorageDateSynchronizedState({
      key: "endDate:" + websiteId,
      defaultValue: end,
      roundToMidnight: true,
      ttl: ttl,
    });
  const setStartDate = (value: Date | null, isLocalValue?: boolean) => {
    if (value === null) return;
    if (endDate.getTime() - value.getTime() > 45 * 24 * 3600 * 1000) {
      alert("You cannot select more than 45 days");
    } else {
      setStartDateInternal(value, isLocalValue);
    }
  };
  const setEndDate = (value: Date | null, isLocalValue?: boolean) => {
    if (value === null) return;
    if (value.getTime() - startDate.getTime() > 45 * 24 * 3600 * 1000) {
      alert("You cannot select more than 45 days");
    } else {
      setEndDateInternal(value, isLocalValue);
    }
  };
  const forceLocalValue = () => {
    setStartDate(start, true);
    setEndDate(end, true);
  };
  const restoreCache = () => {
    restoreStartDateCache();
    restoreEndDateCache();
  };

  if (isLocalValue !== locality) {
    if (isLocalValue) {
      forceLocalValue();
    } else {
      restoreCache();
    }
    setLocality(!!isLocalValue);
  }

  return {
    startDate: startDate,
    endDate: endDate,
    component: (
      <div className="d-flex align-items-center" style={{ gap: "0.5em" }}>
        <DatePicker
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          className="form-control date-picker"
          dateFormat="MMMM d, yyyy"
        />
        <> - </>
        <DatePicker
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          className="form-control date-picker"
          dateFormat="MMMM d, yyyy"
        />
      </div>
    ),
  };
}
