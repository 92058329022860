import { useMutation, useQuery } from "@apollo/client";
import { omit } from "lodash";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { useLocalStorageSynchronizedState } from "../common/hooks";
import { DataHandler } from "../components/DataHandler";
import { HtmlUpdatesLogType, WebsiteType } from "../gql/types.generated";
import {
  GET_WEBSITE_BA_CONFIG,
  PreviewCpapMutation,
  PreviewCpapMutationVariables,
  PREVIEW_CPAP,
  UpdateBaConfigCodeMutation,
  UpdateBaConfigCodeMutationVariables,
  UPDATE_BA_CONFIG_CODE,
  WebsiteBaConfigQuery,
  WebsiteBaConfigQueryVariables,
} from "../gql/websites/website";
import { ACTIONS_TEMPLATES } from "./constants";
import "./htmledit.css";
import { WebsiteIdParams } from "./types/routeParams";
import { Link, useParams } from "react-router-dom";

const getActionMessage = (args: HtmlUpdatesLogType) => {
  const action = args.action?.toLowerCase() ?? "";
  const template = ACTIONS_TEMPLATES[action as keyof typeof ACTIONS_TEMPLATES];
  return (
    template?.[args.element as keyof typeof template]?.(args) ??
    JSON.stringify(args)
  );
};

const DEFAULT_JS_RULES = `!dom\n!2tld`;

interface IDeviceConfigProps {
  deviceName: string;
  jsonConfig: string;
}

const DeviceConfig = ({ jsonConfig, deviceName }: IDeviceConfigProps) => {
  if (!jsonConfig) return null;

  const config = JSON.parse(jsonConfig);
  return (
    <div>
      <h3 className="text-capitalize">{deviceName} Config</h3>
      <p>
        <a
          href="https://app.botify.com/tools/cpap/v4/pocketRenderPro.html"
          target="_blank"
          rel="noreferrer"
        >
          Click here to test on BA Javascript render test tool
        </a>
      </p>
      <h4>Rules</h4>
      <pre>{config.beta.pap_mini_rules.join("\n")}</pre>
      <h4>JS Code</h4>
      <pre>{config.beta.injectJsAfter}</pre>
      <h4>Final code to paste to your BA settings</h4>
      <pre>{jsonConfig}</pre>
    </div>
  );
};

type Device = "desktop" | "mobile";

/**
 * Deserialization of `PreviewCpapMutationPayload.stats`
 */
interface IPreviewStats {
  htmlUpdates?: {
    log: HtmlUpdatesLogType[];
  };
  qualityControl: {
    success: string[];
    warning: string[];
    fail: string[];
    errors: string[];
  };
}

type BaSettingsState = Pick<
  WebsiteType,
  | "id"
  | "baDesktopCode"
  | "baDesktopOnLoadCode"
  | "baDesktopJsRules"
  | "baDesktopJsConfig"
  | "baMobileCode"
  | "baMobileOnLoadCode"
  | "baMobileJsRules"
  | "baMobileJsConfig"
>;

export function WebsiteBaConfigEdit() {
  const { websiteId } = useParams<WebsiteIdParams>() as WebsiteIdParams;
  const { loading, error, data } = useQuery<
    WebsiteBaConfigQuery,
    WebsiteBaConfigQueryVariables
  >(GET_WEBSITE_BA_CONFIG, {
    variables: { id: websiteId },
  });

  const website = data?.website ?? null;

  const [baSettings, setBaSettings] = useState<BaSettingsState | null>(null);

  useEffect(() => {
    if (!website) return;
    setBaSettings({
      ...omit(website, ["__typename", "name"]),
      id: websiteId,
      baDesktopJsRules: website?.baDesktopJsRules ?? DEFAULT_JS_RULES,
      baMobileJsRules: website?.baMobileJsRules ?? DEFAULT_JS_RULES,
    });
  }, [website, websiteId]);

  const [previewDevice, setPreviewDevice] = useState<Device>("desktop");

  const [previewURL, setPreviewURL] = useLocalStorageSynchronizedState({
    key: "baConfigPreviewURL" + websiteId,
    defaultValue: "",
  });

  const [
    submitCompileJs,
    { data: dataPreview, loading: loadingSubmitCompileJs },
  ] = useMutation<PreviewCpapMutation, PreviewCpapMutationVariables>(
    PREVIEW_CPAP
  );
  const [submitSaveCode, { data: dataSubmit, loading: loadingSubmitSaveCode }] =
    useMutation<
      UpdateBaConfigCodeMutation,
      UpdateBaConfigCodeMutationVariables
    >(UPDATE_BA_CONFIG_CODE);

  const preview = dataPreview?.previewCpap;
  const previewStats: IPreviewStats | null = preview?.stats
    ? JSON.parse(preview.stats)
    : null;
  const updatedConfig = dataSubmit?.updateBaConfig?.website;

  function updateBaSettingsState(
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) {
    const { name, value } = event.target;
    if (!baSettings) {
      return;
    }
    setBaSettings({ ...baSettings, [name]: value });
  }

  if (error || loading || !website) {
    return (
      <DataHandler error={error} loading={loading} data={website} expectData />
    );
  }

  const isSubmitting = loadingSubmitCompileJs || loadingSubmitSaveCode;

  return (
    <>
      <div className="row">
        <div className="col-9">
          <h1>
            <Link to={`/website/${websiteId}`}>{website.name}</Link>
            {" >"}
            BA Config
          </h1>
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <Form.Group>
            <Form.Control
              as="select"
              value={previewDevice}
              onChange={(e) => setPreviewDevice(e.target.value as Device)}
            >
              <option value="desktop">Desktop or Responsive Crawl</option>
              <option value="mobile">Mobile Crawl</option>
            </Form.Control>
          </Form.Group>
          {previewDevice === "desktop" && (
            <div>
              <div>
                <p>OnLoad Code to execute</p>
                <textarea
                  className="code"
                  name="baDesktopOnLoadCode"
                  defaultValue={baSettings?.baDesktopOnLoadCode ?? ""}
                  onChange={updateBaSettingsState}
                />
                <p>OnDone Code to execute</p>
                <textarea
                  className="code"
                  name="baDesktopCode"
                  defaultValue={baSettings?.baDesktopCode ?? ""}
                  onChange={updateBaSettingsState}
                />
              </div>
              <p>BA JS Rules</p>
              <div>
                <textarea
                  className="ba_config"
                  name="baDesktopJsRules"
                  defaultValue={
                    baSettings?.baDesktopJsRules ?? DEFAULT_JS_RULES
                  }
                  onChange={updateBaSettingsState}
                />
              </div>
            </div>
          )}
          {previewDevice === "mobile" && (
            <div>
              <div>
                <p>OnLoad Code to execute</p>
                <textarea
                  className="code"
                  name="baMobileOnLoadCode"
                  defaultValue={baSettings?.baMobileOnLoadCode ?? ""}
                  onChange={updateBaSettingsState}
                />
                <p>OnDone Code to execute</p>
                <textarea
                  className="code"
                  name="baMobileCode"
                  defaultValue={baSettings?.baMobileCode ?? ""}
                  onChange={updateBaSettingsState}
                />
              </div>
              <p>BA JS Rules</p>
              <div>
                <textarea
                  className="ba_config"
                  name="baMobileJsRules"
                  defaultValue={baSettings?.baMobileJsRules ?? DEFAULT_JS_RULES}
                  onChange={updateBaSettingsState}
                />
              </div>
            </div>
          )}
          <Form.Control
            type="text"
            placeholder="Enter an URL"
            onChange={(e) => setPreviewURL(e.target.value)}
            value={previewURL}
          />
          <Button
            onClick={() => {
              submitCompileJs({
                variables: {
                  input: {
                    website: websiteId,
                    onLoadCode:
                      previewDevice === "desktop"
                        ? baSettings?.baDesktopOnLoadCode
                        : baSettings?.baMobileOnLoadCode,
                    code:
                      previewDevice === "desktop"
                        ? baSettings?.baDesktopCode
                        : baSettings?.baMobileCode,
                    jsRules:
                      previewDevice === "desktop"
                        ? baSettings?.baDesktopJsRules
                        : baSettings?.baMobileJsRules,
                    url: previewURL,
                    device: previewDevice,
                  },
                },
              });
            }}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Preview"
            )}
          </Button>
          &nbsp;
          <Button
            onClick={() => {
              if (!baSettings) return;
              submitSaveCode({
                variables: {
                  input: baSettings,
                },
              });
            }}
            disabled={isSubmitting || !baSettings}
          >
            {isSubmitting ? <Spinner animation="border" size="sm" /> : "Save"}
          </Button>
          {preview?.jsError && (
            <div>
              <h4>Js Error</h4> {preview.jsError}
            </div>
          )}
          {preview && (
            <div>
              {preview.logs && (
                <div>
                  <h4>Logs ({preview.logs.length})</h4>

                  {preview.logs.map((entry) => {
                    return <div>{entry}</div>;
                  })}
                </div>
              )}

              {previewStats !== null && previewStats.htmlUpdates && (
                <div id="console">
                  {(previewStats.qualityControl.success.length > 0 ||
                    previewStats.qualityControl.warning.length > 0 ||
                    previewStats.qualityControl.fail.length > 0 ||
                    previewStats.qualityControl.errors.length > 0) && (
                    <div>
                      <h4>Quality Control</h4>
                      {previewStats.qualityControl.errors.length > 0 && (
                        <div>
                          Execution Errors :{" "}
                          {previewStats.qualityControl.errors.map((e) => (
                            <p>{e}</p>
                          ))}
                        </div>
                      )}

                      {previewStats.qualityControl.success.length > 0 && (
                        <div>
                          <span className="badge badge-success">Success</span>{" "}
                          {previewStats.qualityControl.success}
                        </div>
                      )}
                      {previewStats.qualityControl.warning.length > 0 && (
                        <div>
                          <span className="badge badge-warning">Warning</span>{" "}
                          {previewStats.qualityControl.warning}
                        </div>
                      )}
                      {previewStats.qualityControl.fail.length > 0 && (
                        <div>
                          <span className="badge badge-danger">Fail</span>{" "}
                          {previewStats.qualityControl.fail}
                        </div>
                      )}
                    </div>
                  )}

                  <h4>Changes ({previewStats.htmlUpdates.log.length})</h4>

                  {previewStats.htmlUpdates.log.map((entry) => {
                    return (
                      <div className="item">
                        {getActionMessage(entry)} <hr />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
        </div>
        <div className="col-8">
          {preview?.status === "404" && (
            <div>Sorry, {previewURL} not found</div>
          )}

          {preview?.status === "200" && (
            <div>
              <h4>Raw HTML</h4>
              <pre id="html">{preview.html}</pre>

              <h4>Preview</h4>
              <iframe
                title="Preview"
                srcDoc={preview.html ?? ""}
                width="100%"
                height="400px"
                sandbox=""
              />
              {/* sandbox Prevent redirects on top page */}

              {preview?.screenshot && (
                <div>
                  <h4>Screenshot</h4>
                  <img
                    src={"data:image/png;base64, " + preview.screenshot}
                    alt="Preview screenshot"
                  />
                </div>
              )}
            </div>
          )}

          <DeviceConfig
            deviceName="desktop"
            jsonConfig={
              updatedConfig?.baDesktopJsConfig ?? website.baDesktopJsConfig!
            }
          />
          <DeviceConfig
            deviceName="mobile"
            jsonConfig={
              updatedConfig?.baMobileJsConfig ?? website.baMobileJsConfig!
            }
          />
        </div>
      </div>
    </>
  );
}
