import { gql } from "@apollo/client";

export const GET_WEBSITE_INDEXATION_HISTORY = gql`
query WebsiteHistory($id: String!, $timestampStart: Int!, $timestampEnd: Int!, $filters: JSONString) {
    website(id: $id) {
       queryIndexedPages(
        timestampStart: $timestampStart
        timestampEnd: $timestampEnd
        page: 1
        size: 10
        dimensions: []
        filters: $filters
        orderBy: ["-datetime"]
      ) {
        url
        datetime
        indexed
        httpCode
        device
        pageSize
        fileUriHash
        pageId
        pageKey
      }
    }
}`