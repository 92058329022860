import { useMutation } from "@apollo/client";
import {
  CreateDraftVersionMutation,
  CreateDraftVersionMutationVariables,
  CREATE_DRAFT_VERSION,
} from "../../gql/websites/website";

export function useCreateDraft() {
  return useMutation<
    CreateDraftVersionMutation,
    CreateDraftVersionMutationVariables
  >(CREATE_DRAFT_VERSION, {
    onCompleted() {
      // The draft mutation doesn't return `draftVersion` scoped under the
      // `Website` model, so it doesn't refresh the rest of the queries.
      // We'll do a full page refresh for now.
      window.location.reload();
    },
  });
}
