import { useMutation, useQuery, MutationResult } from "@apollo/client";
import moment from "moment";
import React, { useState } from "react";
import Alert from "react-bootstrap/Alert";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import Table from "react-bootstrap/Table";

import { getAdminLink } from "../../constants";
import {
  AdnConfigStatusesByEnvType,
  AdnConfigStatusStatus,
  AdnConfigType,
  AdnEnvironment,
} from "../../gql/types.generated";
import {
  GET_WEBSITE_ADN_CONFIGS,
  RollbackAdnConfigMutation,
  RollbackAdnConfigMutationVariables,
  ROLLBACK_ADN_CONFIG,
  WebsiteAdnConfigsQuery,
  WebsiteAdnConfigsQueryVariables,
} from "../../gql/websites/adn";
import { Link, useParams } from "react-router-dom";
import { WebsiteIdParams } from "../types/routeParams";

type EnvPagination = Record<
  "test" | "staging" | "production",
  { offset: 0; limit: 5 }
>;

interface IRollbackCardProps {
  rollback: MutationResult<RollbackAdnConfigMutation>;
  rollbackEnv: (env: AdnEnvironment) => void;
  message: string;
}

function RollbackCard({ rollback, rollbackEnv, message }: IRollbackCardProps) {
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const selectEnvElement = (
      event.target as HTMLFormElement
    ).elements.namedItem("environment") as HTMLSelectElement;
    const env = selectEnvElement.value as AdnEnvironment;

    rollbackEnv(env);
  };

  return (
    <Card>
      <Card.Header>
        Rollback currently deployed configuration{" "}
        <Badge bg="danger">Danger</Badge>
      </Card.Header>
      <Card.Body>
        <Form action="" onSubmit={handleSubmit}>
          {rollback.called &&
            message &&
            !rollback.loading &&
            !rollback.error && <Alert variant="info">{message}</Alert>}
          {rollback.error && (
            <Alert variant="danger">
              Something went wrong.
              <br />
              <pre>{JSON.stringify(rollback.error, null, 2)}</pre>
            </Alert>
          )}
          <Form.Group controlId="environment">
            <Form.Label>
              The environment on which you want to rollback the ADN Config
            </Form.Label>
            <Form.Select name="environment" required>
              <option value={AdnEnvironment.Staging}>Staging</option>
              <option value={AdnEnvironment.Production}>Production</option>
            </Form.Select>
          </Form.Group>
          <Button type="submit" variant="danger" disabled={rollback.loading}>
            {rollback.loading ? "Rolling back..." : "Rollback"}
          </Button>
        </Form>
      </Card.Body>
    </Card>
  );
}

interface IADNConfigurationsCardProps {
  websiteId: string;
  adnConfigs: Pick<AdnConfigType, "id" | "workerVersion" | "modifiedDate">[];
}

function ADNConfigurationsCard({
  websiteId,
  adnConfigs,
}: IADNConfigurationsCardProps) {
  return (
    <Card>
      <Card.Header>ADN Configurations</Card.Header>
      <Card.Body>
        {adnConfigs.length === 0 && <p>No configuration yet</p>}
        <ListGroup>
          {adnConfigs.map((config) => (
            <ListGroup.Item
              key={config.id}
              className="d-flex justify-content-between"
            >
              <span>
                {config.id} - {config.workerVersion}
              </span>{" "}
              <span>
                <span
                  title={moment(config.modifiedDate).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )}
                >
                  Last modified {moment(config.modifiedDate).fromNow()}
                </span>
                <Link
                  className="p-0 ml-2"
                  to={`/website/${websiteId}/adn/edit/${config.id}`}
                >
                  View
                </Link>
              </span>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Card.Body>
    </Card>
  );
}

interface IADNConfigurationsByEnvCard {
  websiteId: string;
  adnConfigStatusesByEnv: AdnConfigStatusesByEnvType[];
  rollback: MutationResult<RollbackAdnConfigMutation>;
  rollbackEnv: (env: AdnEnvironment) => void;
  envsPagination: EnvPagination;
  onEnvsPaginationChange: React.Dispatch<React.SetStateAction<EnvPagination>>;
}

function ADNConfigurationsByEnvCard({
  websiteId,
  adnConfigStatusesByEnv,
  rollback,
  rollbackEnv,
  envsPagination,
  onEnvsPaginationChange,
}: IADNConfigurationsByEnvCard) {
  const handleClick = (item: AdnConfigStatusesByEnvType) => {
    onEnvsPaginationChange((prevEnvsPagination) => {
      const env = item.environment as keyof EnvPagination;
      return {
        ...prevEnvsPagination,
        [env]: {
          ...prevEnvsPagination[env],
          limit: (prevEnvsPagination[env].limit +=
            prevEnvsPagination[env].limit),
        },
      };
    });
  };

  return (
    <Card>
      <Card.Header>ADN Configurations by env</Card.Header>
      <Card.Body>
        {adnConfigStatusesByEnv.map((config, i) => {
          const statuses = config.adnConfigStatuses ?? [];
          const deployedAdnConfigStatus = statuses.find(
            ({ status }) => status === AdnConfigStatusStatus.Deployed
          );

          return (
            <Card key={i}>
              <Card.Header className="d-flex justify-content-between align-items-center">
                <div>
                  {config.environment}
                  {deployedAdnConfigStatus && (
                    <>
                      <span>: </span>
                      <span className="text-success">
                        {deployedAdnConfigStatus.adnConfig.id} - worker{" "}
                        {deployedAdnConfigStatus.adnConfig.workerVersion}
                      </span>
                      <Link
                        className="p-0 ml-2"
                        to={`/website/${websiteId}/adn/edit/${deployedAdnConfigStatus.adnConfig.id}`}
                      >
                        (View)
                      </Link>
                    </>
                  )}
                </div>
                {deployedAdnConfigStatus && config.environment !== "test" && (
                  <div className="d-flex justify-content-end align-items-center">
                    {deployedAdnConfigStatus.previousAdnConfigStatus && (
                      <Link
                        className="p-0 ml-2 mr-2"
                        to={`/website/${websiteId}/adn/edit/${deployedAdnConfigStatus.previousAdnConfigStatus.adnConfig.id}`}
                      >
                        (View previous config)
                      </Link>
                    )}
                    <Button
                      variant="danger"
                      disabled={rollback.loading}
                      className="btn-sm"
                      onClick={(event) => {
                        event.preventDefault();
                        rollbackEnv(config.environment as AdnEnvironment);
                      }}
                    >
                      Rollback
                    </Button>
                  </div>
                )}
              </Card.Header>
              <Card.Body>
                <Table striped bordered>
                  <thead>
                    <tr>
                      <th>ADN Config</th>
                      <th>Status</th>
                      <th>Datetime</th>
                      <th>By</th>
                      <th>Admin link</th>
                    </tr>
                  </thead>
                  <tbody>
                    {statuses.length === 0 && (
                      <tr>
                        <td colSpan={5} className="small text-center">
                          No deployments history
                        </td>
                      </tr>
                    )}
                    {statuses.map((adnConfigStatus) => (
                      <tr
                        key={adnConfigStatus.id}
                        className={
                          deployedAdnConfigStatus?.id === adnConfigStatus.id
                            ? "text-success"
                            : ""
                        }
                      >
                        <td>
                          {adnConfigStatus.adnConfig.id} - worker{" "}
                          {adnConfigStatus.adnConfig.workerVersion}
                          <Link
                            className="p-0 ml-2"
                            to={`/website/${websiteId}/adn/edit/${adnConfigStatus.adnConfig.id}`}
                          >
                            (View)
                          </Link>
                        </td>
                        <td>{adnConfigStatus.status}</td>
                        <td>
                          {moment(adnConfigStatus.createdDate).format(
                            "MMMM Do, Y, h:mm:ss a"
                          )}
                        </td>
                        <td>
                          {adnConfigStatus.author?.email ||
                            adnConfigStatus.author?.username}
                        </td>
                        <td>
                          <a
                            href={`${getAdminLink()}/websites/adnconfigstatus/${
                              adnConfigStatus.id
                            }/change/`}
                          >
                            Status {adnConfigStatus.id}
                          </a>
                        </td>
                      </tr>
                    ))}
                    {statuses.length >= 5 &&
                      statuses.length ===
                        envsPagination[
                          config.environment as keyof EnvPagination
                        ].limit && (
                        <tr>
                          <td colSpan={5} className="text-center font-italic">
                            <Button
                              variant="link"
                              className="btn-sm font-italic"
                              onClick={(event) => {
                                event.preventDefault();
                                handleClick(config);
                              }}
                            >
                              Load more...
                            </Button>
                          </td>
                        </tr>
                      )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          );
        })}
      </Card.Body>
    </Card>
  );
}

export function ADNConfigsList() {
  const { websiteId } = useParams<WebsiteIdParams>() as WebsiteIdParams;
  const [envsPagination, setEnvsPagination] = useState<EnvPagination>({
    test: { offset: 0, limit: 5 },
    staging: { offset: 0, limit: 5 },
    production: { offset: 0, limit: 5 },
  });
  const { loading, error, data } = useQuery<
    WebsiteAdnConfigsQuery,
    WebsiteAdnConfigsQueryVariables
  >(GET_WEBSITE_ADN_CONFIGS, {
    variables: {
      id: websiteId,
      pagination: JSON.stringify(envsPagination),
    },
  });
  const [rollbackAdnConfig, rollback] = useMutation<
    RollbackAdnConfigMutation,
    RollbackAdnConfigMutationVariables
  >(ROLLBACK_ADN_CONFIG);
  const [message, setMessage] = useState("");

  async function rollbackEnv(env: AdnEnvironment) {
    // Avoid multiple submissions
    if (rollback.loading) {
      return;
    }

    // Avoid unintentional submissions
    if (
      !window.confirm(
        `Are you sure you want to rollback the currently deployed lightweight CDN configuration of the website "${data?.website?.name}" (${websiteId}) on the "${env}" env?`
      )
    ) {
      return;
    }

    try {
      const { data } = await rollbackAdnConfig({
        variables: { environment: env, website: websiteId },
      });

      setMessage(data?.rollbackAdnConfig?.message ?? "");
    } catch (err) {
      // handled by useMutation
    }
  }

  return (
    <div>
      <h1>
        <Link to={`/website/${websiteId}`}>
          {data?.website?.name ?? websiteId}
        </Link>{" "}
        &gt; Lightweight CDN
      </h1>

      {loading && <p>Loading…</p>}
      {error && <p>Error! {error.message}</p>}

      <Card>
        <Card.Header>Actions</Card.Header>
        <Card.Body>
          <Link role="button" to={`/website/${websiteId}/adn/create`}>
            Create a new configuration
          </Link>
        </Card.Body>
      </Card>

      <RollbackCard
        rollback={rollback}
        rollbackEnv={rollbackEnv}
        message={message}
      />

      {!loading && !error && data && (
        <>
          <ADNConfigurationsCard
            websiteId={websiteId}
            adnConfigs={data?.website?.adnConfigs ?? []}
          />

          <ADNConfigurationsByEnvCard
            websiteId={websiteId}
            adnConfigStatusesByEnv={
              (data?.website?.adnConfigStatusesByEnv ??
                []) as AdnConfigStatusesByEnvType[]
            }
            rollback={rollback}
            rollbackEnv={rollbackEnv}
            envsPagination={envsPagination}
            onEnvsPaginationChange={setEnvsPagination}
          />
        </>
      )}
    </div>
  );
}
