import { useEffect } from "react";
import Container from "react-bootstrap/Container";

import { Home } from "./Home/Home";
import { WebsitesMetric } from "./WebsitesMetric";
import { Health } from "./Health";
import { Route, Routes } from "react-router-dom";

export function MonitoringRoutes() {
  useEffect(() => {
    document.title = "Monitoring | Botify Activation";
  }, []);

  return (
    <Container fluid>
      <Routes>
        <Route index element={<Home />} />
        <Route path="health" element={<Health />} />
        <Route
          path="websites/:db/:period/:metric"
          element={<WebsitesMetric />}
        />
      </Routes>
    </Container>
  );
}
