import { gql } from "@apollo/client";

export const GET_WEBSITE_CONFIGS = gql`
  query WebsiteConfigs($id: String!) {
    website(id: $id) {
      id
      draftVersion {
        id
        configs {
          id
        }
      }
    }
  }
`;
