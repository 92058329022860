import { useQuery } from "@apollo/client";
import { DocumentNode } from "graphql";
import { ReactNode, useState } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import { PageEditType } from "../../gql/types.generated";
import { Button } from "react-bootstrap";

type ColRenderer =
  | ((entry: PageEditType) => ReactNode)
  | keyof Omit<PageEditType, "htmlBlocks" | "website">;

function isPageTypeKey(
  col: ColRenderer
): col is keyof Omit<PageEditType, "htmlBlocks" | "website"> {
  if (typeof col === "string") {
    return true;
  }
  return false;
}

interface IDataTableProps {
  websiteId: string;
  cols: ColRenderer[];
  gqlQuery: DocumentNode;
  initialPage: number;
}

export const DataTable = ({
  websiteId,
  cols,
  gqlQuery,
  initialPage,
}: IDataTableProps) => {
  const [page, setPage] = useState(initialPage);
  const [search, setSearch] = useState("");

  const { error, data: rawData } = useQuery(gqlQuery, {
    variables: { websiteId: websiteId, page: page, search: search },
  });
  // GQL returns data.xxx.object, call setData to access to final object through `data` state

  const data = rawData ? rawData[Object.keys(rawData)[0]] : undefined;

  if (error) return <>Error! {error.message}</>;

  return (
    <>
      <Container>
        <Row>
          <Col xs={6}>
            <Form.Control
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              size="sm"
              placeholder="Search an URL"
              style={{ marginBottom: "10px" }}
            />
          </Col>
          {data && (
            <>
              <Col xs={6} className="text-right">
                Page {data.page} of {data.pages} |&nbsp;
                {page > 1 && (
                  <Button
                    variant="link"
                    style={{ padding: 0 }}
                    onClick={() => setPage(page - 1)}
                  >
                    Previous Page
                  </Button>
                )}{" "}
                {page > 1 && page < data.pages ? <>|&nbsp;</> : ""}
                {page < data.pages && (
                  <Button
                    variant="link"
                    style={{ padding: 0 }}
                    onClick={(e) => setPage(page + 1)}
                  >
                    Next Page
                  </Button>
                )}
              </Col>
            </>
          )}
        </Row>
      </Container>

      {data && (
        <Table striped hover>
          <tbody>
            {data.objects.map((pa: PageEditType) => (
              <tr>
                {cols.map((col) => (
                  <td>{isPageTypeKey(col) ? pa[col] : col(pa)}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </>
  );
};
