import { gql } from "@apollo/client";

export const GET_WEBSITE_QUERY_JOBS = gql`
  query WebsiteQueryJobs($id: String!, $filters: JSONString) {
    website(id: $id) {
      id
      queryJobs(
        orderBy: []
        dimensions: ["uniqueName"]
        filters: $filters
        size: 100
      ) {
        uniqueName
        dateCreated
        dateLastUpdate
        dateFinished
        lastJobOutput
        lastJobParams
        events {
          date
          status
          instanceId
        }
      }
    }
  }
`;
