import { useMutation, useQuery } from "@apollo/client";
import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";

import {
  CreateAdnConfigMutation,
  CreateAdnConfigMutationVariables,
  CREATE_ADN_CONFIG,
} from "../../gql/websites/adn";
import {
  GET_WEBSITE_SUMMARY,
  WebsiteSummaryQuery,
  WebsiteSummaryQueryVariables,
} from "../../gql/websites/website";
import { DataHandler } from "../../components/DataHandler";
import { ADNConfigForm, IADNConfigFormProps } from "./ADNConfigForm";
import { Link, useParams } from "react-router-dom";
import { WebsiteIdParams } from "../types/routeParams";

export function ADNConfigCreation() {
  const { websiteId } = useParams<WebsiteIdParams>() as WebsiteIdParams;
  const { loading, error, data } = useQuery<
    WebsiteSummaryQuery,
    WebsiteSummaryQueryVariables
  >(GET_WEBSITE_SUMMARY, {
    variables: { id: websiteId },
  });
  const [createAdnConfig, createAdnConfigResult] = useMutation<
    CreateAdnConfigMutation,
    CreateAdnConfigMutationVariables
  >(CREATE_ADN_CONFIG);

  const website = data?.website;
  const createAdnConfigData = createAdnConfigResult.data?.createAdnConfig;

  if (error || loading || !website) {
    return (
      <DataHandler error={error} loading={loading} data={website} expectData />
    );
  }

  const onSubmit: IADNConfigFormProps["onSubmit"] = async (
    formData,
    errors
  ) => {
    const isFormValid = Object.keys(errors).length === 0;

    if (!isFormValid) {
      return;
    }

    try {
      await createAdnConfig({
        variables: { input: formData },
      });
    } catch (err) {
      // handled by useMutation
    }

    // Show error/success messages
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  return (
    <div>
      <h1>
        <Link to={`/website/${website.id}`}>{website.name}</Link> &gt;{" "}
        <Link to={`/website/${website.id}/adn`}>Lightweight CDN</Link> &gt;
        Create a new ADN Config
      </h1>

      <Card>
        <Card.Header
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <span>New configuration</span>
          <span>* required</span>
        </Card.Header>
        <Card.Body>
          <ADNConfigForm
            websiteId={website.id}
            onSubmit={onSubmit}
            submitting={createAdnConfigResult.loading}
            messages={
              <>
                {createAdnConfigData?.adnConfig &&
                  !createAdnConfigResult.error &&
                  createAdnConfigData.errors.length === 0 && (
                    <Alert variant="success">
                      New configuration successfully created.{" "}
                      <Link
                        to={`/website/${websiteId}/adn/edit/${createAdnConfigData.adnConfig.id}`}
                      >
                        View
                      </Link>
                    </Alert>
                  )}
                {createAdnConfigResult.error ? (
                  <Alert variant="danger">
                    Something went wrong.
                    <br />
                    <pre>
                      {JSON.stringify(createAdnConfigResult.error, null, 2)}
                    </pre>
                  </Alert>
                ) : null}
                {createAdnConfigData &&
                createAdnConfigData.errors.length > 0 ? (
                  <Alert variant="danger">
                    Some fields are invalid:
                    <ul style={{ marginBottom: 0 }}>
                      {createAdnConfigData.errors.map(({ field, messages }) => (
                        <li key={field}>
                          <strong>{field}</strong>: {messages}
                        </li>
                      ))}
                    </ul>
                  </Alert>
                ) : null}
              </>
            }
          />
        </Card.Body>
      </Card>
    </div>
  );
}
