import { ApolloError } from "@apollo/client/errors";
import { isNil } from "lodash";
import { ReactNode } from "react";
import { Alert, Spinner } from "react-bootstrap";

interface IDataHandlerProps {
  error?: ApolloError | string | null;
  loading?: boolean;
  data?: unknown;
  expectData?: boolean;
  noDataMessage?: ReactNode;
}

/**
 * Provides basic hanlding for errors, loading state and missing data.
 *
 * @example
 * if (error || loading) {
 *   return <DataHandler error={error} loading={loading} />
 * }
 *
 * @example
 * const draftVersion = data?.website?.draftVersion
 * if (error || loading || !draftVersion) {
 *   return (
 *     <DataHandler
 *       expectData
 *       data={draftVersion}
 *       error={error}
 *       loading={loading}
 *     />
 *   )
 * }
 */
export const DataHandler = ({
  error,
  loading,
  data,
  expectData = false,
  noDataMessage = "No Data",
}: IDataHandlerProps) => {
  if (error) {
    return (
      <Alert variant="danger">
        Error! {error instanceof ApolloError ? error.message : `${error}`}
      </Alert>
    );
  }

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center pt-5">
        <Spinner role="spinbutton" animation="border" />
      </div>
    );
  }

  if (expectData && isNil(data)) {
    return <>{noDataMessage}</>;
  }

  return null;
};
