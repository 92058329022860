import { Pagination as BsPagination } from "react-bootstrap";
import "./Pagination.css";
import { usePagination } from "./usePagination";

// Approx max tiles is enough and simplify implementation
const APPROX_MAX_PAGINATION_TILES = 10;

export interface IPaginationProps {
  itemsCount: number;
}

export function Pagination({ itemsCount }: IPaginationProps) {
  const { page, setPage, pageSize } = usePagination();
  const tilesNumber = Math.ceil(itemsCount / pageSize);
  const currentTileIndex = page - 1;

  const handleTileClick = (tileIndex: number) => {
    if (tileIndex >= 0 && tileIndex < tilesNumber) {
      // +1 because page is based on 1 as the
      setPage(tileIndex + 1);
    }
  };

  const paginationTileMustBeDisplayed = (tileIndex: number) => {
    if (tileIndex < APPROX_MAX_PAGINATION_TILES) {
      return true;
    }
    if (tileIndex === tilesNumber - 1) {
      return true;
    }
    if (
      tileIndex === currentTileIndex ||
      tileIndex === currentTileIndex - 1 ||
      tileIndex === currentTileIndex + 1
    ) {
      return true;
    }
    return false;
  };

  const getPaginationTiles = () => {
    const paginationTiles = [];
    let lastTileIsDisabled;

    for (let tileIndex = 0; tileIndex < tilesNumber; tileIndex++) {
      if (paginationTileMustBeDisplayed(tileIndex)) {
        lastTileIsDisabled = false;
        paginationTiles.push(
          <NumberedPaginationTile
            key={`tiles-${tileIndex}`}
            tileIndex={tileIndex}
            active={tileIndex === currentTileIndex}
            onClick={() => handleTileClick(tileIndex)}
          />
        );
      } else if (!lastTileIsDisabled) {
        lastTileIsDisabled = true;
        paginationTiles.push(
          <DisabledPaginationTile key={`tiles-${tileIndex}`} />
        );
      }
    }

    return [
      <BsPagination.First
        key="tiles-first"
        onClick={() => handleTileClick(0)}
      />,
      <BsPagination.Prev
        key="tiles-prev"
        onClick={() => handleTileClick(currentTileIndex - 1)}
      />,
      ...paginationTiles,
      <BsPagination.Next
        key="tiles-next"
        onClick={() => handleTileClick(currentTileIndex + 1)}
      />,
      <BsPagination.Last
        key="tiles-last"
        onClick={() => handleTileClick(tilesNumber - 1)}
      />,
    ];
  };

  return (
    <BsPagination className="pagination-container">
      {getPaginationTiles()}
    </BsPagination>
  );
}

interface INumberedPaginationTileProps {
  tileIndex: number;
  active: boolean;
  onClick: () => void;
}

function NumberedPaginationTile({
  tileIndex,
  ...additionalProps
}: INumberedPaginationTileProps) {
  return (
    <BsPagination.Item {...additionalProps}>{tileIndex + 1}</BsPagination.Item>
  );
}

function DisabledPaginationTile() {
  return <BsPagination.Item disabled={true}>...</BsPagination.Item>;
}
