import { gql } from "@apollo/client";

export const WEBSITE_SCRIPT_FRAGMENT = gql`
  fragment WebsiteScriptFragment on JSCodeType {
    id
    stableId
    name
    description
    active
    position
    onActionCode
    onDomContentLoadedCode
    onDoneCode
    onInitCode
    onLoadCode
    onPreBeamResponseCode
    onSetupBeforeRenderCode
    onWaitForCode
    steps
    applyOnWebsite
    sections {
      id
      stableId
      name
    }
    websiteVersion {
      id
    }
  }
`;
