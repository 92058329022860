import { gql } from "@apollo/client";

export const PREVIEW_JS = gql`
  mutation PreviewJS($input: PreviewJsMutationInput!) {
    previewJs(input: $input) {
      html
      stats
      status
      statusCode
      headers
      logs
      serverError
      jsError
      jsCodeRendering
      jsCodePreBeamResponse
      errors {
        field
        messages
      }
    }
  }
`;
