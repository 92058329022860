import React from "react";
import Card from "react-bootstrap/Card";

interface IBaseCardProps {
  name: React.ReactNode | React.ReactNode[];
  body: React.ReactNode | React.ReactNode[];
}

export function BaseCard({ name, body }: IBaseCardProps) {
  return (
    <Card>
      <Card.Header>{name}</Card.Header>
      <Card.Body>{body}</Card.Body>
    </Card>
  );
}
