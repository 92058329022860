import { Link } from "react-router-dom";
import { moment } from "../../common/moment";
import { WebsiteType } from "../../gql/types.generated";
import { ResetDraftButton } from "../components/resetDraftButton";

interface IVersioningCardProps {
  website: WebsiteType;
}

export function VersioningCard({ website }: IVersioningCardProps) {
  const { draftVersion, productionVersion } = website;
  if (!draftVersion || !productionVersion) {
    return <>Missing draft or production version</>;
  }
  return (
    <div>
      <p>
        Draft : v{draftVersion.version} | Production : v
        {productionVersion.version} created{" "}
        {moment(productionVersion.modifiedDate).fromNow()}
        {productionVersion.comment && <> | {productionVersion.comment} </>}
        {" | "}
        <Link to={`/website/${website.id}/versioning`}>Push to Production</Link>
        <ResetDraftButton
          websiteId={website.id}
          productionVersionId={productionVersion.id}
        />
      </p>
    </div>
  );
}
