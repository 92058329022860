import Alert from "react-bootstrap/Alert";
import { ApolloError } from "@apollo/client/errors";
import { ErrorType } from "../../../gql/types.generated";

interface IErrorBannerProps {
  message: string;
  error: ApolloError | ErrorType[];
}

export function ErrorBanner({ message, error }: IErrorBannerProps) {
  return (
    <Alert variant="danger">
      {message}
      <br />
      <pre>{JSON.stringify(error, null, 2)}</pre>
    </Alert>
  );
}
