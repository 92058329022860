import { useMutation } from "@apollo/client";
import {
  MOVE_SECTION,
  MoveSectionMutation,
  MoveSectionMutationVariables,
} from "../../gql/websites/website";
import { SectionLink } from "../components/link";
import { Button } from "react-bootstrap";
import { SectionType } from "../../gql/types.generated";

interface ISectionEntryProps {
  index: number;
  section: Pick<
    SectionType,
    | "id"
    | "stableId"
    | "name"
    | "mode"
    | "fallbackSection"
    | "desktopConfig"
    | "mobileConfig"
  >;
  sectionsCount: number;
  websiteId: string;
}

export const SectionEntry = ({
  index,
  section,
  sectionsCount,
  websiteId,
}: ISectionEntryProps) => {
  const [submitMove] = useMutation<
    MoveSectionMutation,
    MoveSectionMutationVariables
  >(MOVE_SECTION);

  const moveSection = (direction: "up" | "down") => () => {
    submitMove({
      variables: {
        input: {
          website: websiteId,
          section: section.id,
          direction,
        },
      },
    });
  };

  const isFallback = index === sectionsCount - 1;
  const isLast = index === sectionsCount - 2;
  const isFirst = index === 0;

  const canMoveUp = !isFirst && !isFallback; // fallback cannot be moved
  const canMoveDown = !isLast && !isFallback;

  return (
    <tr>
      <td>
        <SectionLink websiteId={websiteId} sectionStableId={section.stableId}>
          {section.fallbackSection
            ? "Main Behavior (Fallback Behavior)"
            : section.name}{" "}
          {section.mode === "BLOCK" && <small>(Blocked)</small>}
          {section.mode === "REDIRECT" && <small>(Redirect)</small>}
          {section.mode === "NOT_FOUND" && <small>(Not Found)</small>}
        </SectionLink>
      </td>
      <td>
        {section.desktopConfig && (
          <span>
            Desktop : {section.desktopConfig.name}
            <br />
          </span>
        )}
        {section.mobileConfig && (
          <span>Mobile : {section.mobileConfig.name}</span>
        )}
      </td>
      <td>
        {!section.fallbackSection && (
          <span>
            {canMoveUp && (
              <Button
                variant="link"
                style={{ padding: 0 }}
                onClick={moveSection("up")}
              >
                Move up
              </Button>
            )}
            {canMoveUp && canMoveDown && " "}
            {canMoveDown && (
              <Button
                variant="link"
                style={{ padding: 0 }}
                onClick={moveSection("down")}
              >
                Move down
              </Button>
            )}
          </span>
        )}
      </td>
    </tr>
  );
};
