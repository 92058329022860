import { gql } from "@apollo/client";

export const GET_WEBSITE_ADN_CONFIGS = gql`
  query WebsiteADNConfigs($id: String!, $pagination: JSONString) {
    website(id: $id) {
      id
      name
      adnConfigs {
        id
        modifiedDate
        workerVersion
      }
      adnConfigStatusesByEnv(pagination: $pagination) {
        environment
        adnConfigStatuses {
          id
          adnConfig {
            id
            workerVersion
          }
          previousAdnConfigStatus(status: "deployed") {
            adnConfig {
              id
            }
          }
          status
          createdDate
          author {
            username
            email
          }
        }
      }
    }
  }
`;
