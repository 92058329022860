import { getHost } from "./host";

export const getEnv = () => {
  const host = getHost();
  const match = host.match(/^app.(dev|beta|preprod|sandbox\d+).ftl.page$/);
  return match ? match[1] : null;
};

const ENVS = {
  beta: "beta",
  dev: "dev",
  preprod: "preprod",
};

export function isBetaEnv() {
  const env = getEnv();

  return ENVS.beta === env;
}
