import { QC_STATUSES_IDX } from "../../../../constants";
import {
  HtmlUpdatesLogType,
  PreviewerConfigDevice,
  PreviewJsMutation as PreviewJsMutationResponse,
} from "../../../../gql/types.generated";

/** Deserialization of `PreviewJsMutation.stats` */
export interface IPreviewStats {
  htmlUpdates?: {
    log?: HtmlUpdatesLogType[];
    warningMessages?: string[];
  };
  qualityControl?: {
    all: {
      name: string;
      status: keyof typeof QC_STATUSES_IDX;
      unsuccessMessage: string;
    }[];
    errors: string[];
  };
  properties?: object;
}

export interface IPreviewState
  extends Pick<
    PreviewJsMutationResponse,
    | "url"
    | "jsError"
    | "serverError"
    | "statusCode"
    | "status"
    | "headers"
    | "jsCodeRendering"
    | "jsCodePreBeamResponse"
    | "logs"
  > {
  stats: IPreviewStats;
  html: string;
}

export const addBaseTagToHtml = (html: string, base: string) =>
  hasBaseTag(html)
    ? html
    : html.replace(/<head([^>]*)>/, `<head$1><base href="${base}" />`);

const hasBaseTag = (html: string) =>
  /<head[^>]*>[^]*?<base\s+href=".*"\s*\/>[^]*?<\/head>/i.exec(html);

export const PREVIEW_DEVICES = [
  { value: PreviewerConfigDevice.Desktop, label: "Desktop" },
  { value: PreviewerConfigDevice.Mobile, label: "Mobile" },
] satisfies { value: PreviewerConfigDevice; label: string }[];
