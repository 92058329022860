import { useQuery } from "@apollo/client";
import {
  GET_WEBSITE_CONFIGS,
  WebsiteConfigsQuery,
  WebsiteConfigsQueryVariables,
} from "../../gql/websites/website";
import { DataHandler } from "../../components/DataHandler";
import { Card, Table } from "react-bootstrap";

import { ConfigType } from "../../gql/types.generated";
import { Link } from "react-router-dom";

interface IConfigCardProps {
  websiteId: string;
  configs: Pick<ConfigType, "id" | "name" | "action" | "maxSpeed">[];
}

export function ConfigCard({ websiteId, configs }: IConfigCardProps) {
  const { loading, error, data } = useQuery<
    WebsiteConfigsQuery,
    WebsiteConfigsQueryVariables
  >(GET_WEBSITE_CONFIGS, {
    variables: { id: websiteId },
  });

  const draftConfigs = data?.website?.draftVersion?.configs;

  if (error || loading || !draftConfigs) {
    return (
      <DataHandler
        error={error}
        loading={loading}
        data={draftConfigs}
        noDataMessage="No configs found"
        expectData
      />
    );
  }

  return (
    <Card>
      <Card.Header>Page Processing Configurations</Card.Header>
      <Card.Body className="card-with-table">
        <Card.Text>
          <Table striped borderless hover border-left-0 border-right-0>
            <thead>
              <tr>
                <th>Name</th>
                <th>Processor</th>
                <th>Max Speed</th>
              </tr>
            </thead>
            <tbody>
              {configs.map((entry) => (
                <tr>
                  <td>
                    <Link to={`/website/${websiteId}/config/${entry.id}`}>
                      {entry.name}
                    </Link>
                  </td>
                  <td>{entry.action}</td>
                  <td>{entry.maxSpeed} p/s</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Card.Text>
      </Card.Body>
    </Card>
  );
}
