import { useState } from "react";

import { WebsiteIdParams } from "../types/routeParams";
import { useMutation, useQuery } from "@apollo/client";
import { Card, Form, Button, Spinner, Alert } from "react-bootstrap";

import {
  GET_WEBSITE,
  UPDATE_WEBSITE_SETTINGS,
  UpdateSettingsMutation,
  WebsiteQuery,
  WebsiteQueryVariables,
} from "../../gql/websites/website";
import { getNonNullList } from "../../common/typings";
import { DataHandler } from "../../components/DataHandler";
import { FormControlElement } from "../../common/typing/form";
import { useAdvancedMode } from "../../common/hooks";
import { AdvancedModeSettings } from "./AdvanceModeSettings";
import { FormKey, FormValue, IWebsiteVersionSettings } from "./typing";
import { WebsiteVersionType } from "../../gql/types.generated";
import { Link, useNavigate, useParams } from "react-router-dom";

export function WebsiteSettings() {
  const { websiteId } = useParams<WebsiteIdParams>() as WebsiteIdParams;

  const { loading, error, data } = useQuery<
    WebsiteQuery,
    WebsiteQueryVariables
  >(GET_WEBSITE, {
    variables: { id: websiteId },
  });

  if (error || loading || !data || !data.website) {
    return (
      <DataHandler error={error} loading={loading} data={data} expectData />
    );
  }
  const draft = data.website.draftVersion;

  if (!draft) {
    return <DataHandler data={draft} expectData />;
  }

  return (
    <WebsiteSettingsLoaded
      websiteId={websiteId}
      draft={draft as WebsiteVersionType}
      websiteName={data.website.name}
    />
  );
}

function WebsiteSettingsLoaded({
  draft,
  websiteId,
  websiteName,
}: {
  draft: WebsiteVersionType;
  websiteId: WebsiteIdParams["websiteId"];
  websiteName: string;
}) {
  const navigate = useNavigate();
  const [form, setValues] = useState<IWebsiteVersionSettings>({
    id: draft.id,
    hosts: draft.hosts,
    enableSmartCacheRefresh: draft.enableSmartCacheRefresh,
    filterBadBots: draft.filterBadBots,
    customClientIpHeader: draft.customClientIpHeader ?? "",
    noDeliveryStatusCode: draft.noDeliveryStatusCode ?? 0,
    useRenderingFarm: draft.useRenderingFarm,
    maxSpeed: Number(draft.maxSpeed),
    liveMaxSpeed: Number(draft.liveMaxSpeed ?? draft.maxSpeed),
    enableIncrementalCache: draft.enableIncrementalCache,
    indexMinimumRefreshInterval: Math.floor(
      draft.indexMinimumRefreshInterval / 60
    ),
    pagesMinimumRefreshInterval: Math.floor(
      draft.pagesMinimumRefreshInterval / 60
    ),
  });
  const [isSaving, setIsSaving] = useState(false);
  const [updateSettings] = useMutation<UpdateSettingsMutation>(
    UPDATE_WEBSITE_SETTINGS,
    {
      onCompleted: (data) => {
        const updatedWebsiteSettings = data?.updateWebsiteSettings;
        if (!updatedWebsiteSettings) return;

        window.scrollTo(0, 0);
        const errors = getNonNullList(updatedWebsiteSettings?.errors ?? []);
        if (errors.length !== 0) {
          setConfigError(errors.map(({ messages }) => messages).join("\n"));
        } else {
          navigate(
            {
              pathname: "/website/" + websiteId,
            },
            {
              state: {
                message: { value: "Settings are saved", status: "success" },
              },
            }
          );
        }
      },
    }
  );
  const [configError, setConfigError] = useState("");
  const { isAdvancedMode } = useAdvancedMode();

  const updateField: React.ChangeEventHandler<FormControlElement> = (e) =>
    setValues({
      ...form,
      [e.target.name]: e.target.value || null,
    });

  const updateFieldNumber: React.ChangeEventHandler<HTMLInputElement> = (e) =>
    setValues({
      ...form,
      [e.target.name]: e.target.valueAsNumber || null,
    });

  const updateFieldByName = <K extends FormKey, V extends FormValue<K>>(
    name: K,
    value: V
  ) => {
    setValues((prev) => ({
      ...(prev as NonNullable<typeof form>), // already guarded against null
      [name]: value,
    }));
  };

  const updateCheck: React.ChangeEventHandler<HTMLInputElement> = (e) =>
    setValues({
      ...form,
      [e.target.name]: e.target.checked,
    });

  const submitSettings = async () => {
    setIsSaving(true);
    try {
      await updateSettings({
        variables: {
          input: {
            ...form,
            indexMinimumRefreshInterval: form.indexMinimumRefreshInterval * 60,
            pagesMinimumRefreshInterval: form.pagesMinimumRefreshInterval * 60,
          },
        },
      });
    } catch (err) {
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div>
      <h1>
        <Link to={`/website/${websiteId}`}>{websiteName}</Link> &gt; Settings
      </h1>

      {configError && <Alert variant="danger">{configError}</Alert>}

      <Card>
        <Card.Header>Main Settings</Card.Header>
        <Card.Body>
          <Form.Group controlId="hosts">
            <Form.Label>Domains</Form.Label>
            <Form.Control
              name="hosts"
              type="text"
              value={form.hosts}
              onChange={updateField}
              placeholder="Enter Domains"
            />
            <Form.Text>
              You can declare multiple domains by separating them with a comma.
              declaring "site.com" will also handle subdomains like
              "xxx.site.com"
            </Form.Text>
          </Form.Group>
          <Form.Group controlId="enableSmartCacheRefresh">
            <Form.Label>Cache Refresh</Form.Label>
            <Form.Check
              name="enableSmartCacheRefresh"
              type="checkbox"
              checked={form.enableSmartCacheRefresh}
              onChange={updateCheck}
            />
          </Form.Group>
        </Card.Body>
      </Card>
      {isAdvancedMode ? (
        <AdvancedModeSettings
          form={form}
          updateCheck={updateCheck}
          updateField={updateField}
          updateFieldNumber={updateFieldNumber}
          updateFieldByName={updateFieldByName}
        />
      ) : null}

      <Button
        className="float-right"
        id="submitButton"
        onClick={submitSettings}
        disabled={isSaving}
      >
        {isSaving ? <Spinner animation="border" size="sm" /> : "Submit"}
      </Button>
    </div>
  );
}
