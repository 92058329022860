import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Table from "react-bootstrap/Table";

import { DataHandler } from "../../components/DataHandler";
import { ImportJobStatus } from "../../gql/types.generated";
import { GET_JOB, JobQuery, JobQueryVariables } from "../../gql/websites/job";
import { Header } from "../components/header";
import { JobIdParams, WebsiteIdParams } from "../types/routeParams";
import { Link, useParams } from "react-router-dom";

/** Deserialization of `JobType["result"]` */
interface IJobResult {
  objCreated: number;
  objUpdated: number;
  blocksCreated: number;
  blocksUpdated: number;
  errors: {
    countTotal: number;
    counters: [key: string, value: number][];
    samples: [line: number, type: string][];
  };
}

export function JobDetail() {
  const { websiteId, jobId } = useParams<
    WebsiteIdParams & JobIdParams
  >() as WebsiteIdParams & JobIdParams;

  const [pollInterval, setPollInterval] = useState(3000);
  const { loading, error, data } = useQuery<JobQuery, JobQueryVariables>(
    GET_JOB,
    { variables: { id: jobId, websiteId }, pollInterval: pollInterval }
  );

  const job = data?.importJob;
  const isJobDone = job?.status === ImportJobStatus.Done;
  const jobResult: IJobResult | null = isJobDone
    ? JSON.parse(job.result ?? "")
    : null;

  useEffect(
    function () {
      if (job?.status === "DONE") {
        setPollInterval(0);
      }
    },
    [job]
  );

  if (error || loading || !job) {
    return (
      <DataHandler
        error={error}
        loading={loading}
        data={job}
        noDataMessage="Loading Job"
        expectData
      />
    );
  }

  return (
    <div>
      <Header.Title
        websiteId={websiteId}
        name={
          <React.Fragment>
            Page Edit {"> "}
            <Link to={`/website/${websiteId}/pageEdit/import`}>Import</Link>
          </React.Fragment>
        }
      />

      <h1>Job {job.id}</h1>
      <p>
        Status : {job.status} | Job Type : {job.importType}
      </p>

      {jobResult && (
        <div>
          <Card>
            <Card.Header>Summary</Card.Header>
            <Card.Body className="card-with-table">
              <Table striped borderless hover border-left-0 border-right-0>
                <tbody>
                  {" "}
                  <tr>
                    <td>Number of PageEdit objects created</td>
                    <td>{jobResult.objCreated}</td>
                  </tr>
                  {job.importType === "html_block" && (
                    <>
                      <tr>
                        <td>Number of HTML Blocks created</td>
                        <td>{jobResult.blocksCreated}</td>
                      </tr>
                      <tr>
                        <td>Number of HTML Blocks updated</td>
                        <td>{jobResult.blocksUpdated}</td>
                      </tr>
                    </>
                  )}
                  {job.importType === "metadata" && (
                    <tr>
                      <td>Number of PageEdit objects updated</td>
                      <td>{jobResult.objUpdated}</td>
                    </tr>
                  )}
                  <tr>
                    <td>Number of errors</td>
                    <td>{jobResult.errors.countTotal}</td>
                  </tr>
                  {jobResult.errors.countTotal > 0 && (
                    <tr>
                      <td>
                        Errors detail:{" "}
                        {jobResult.errors.counters.map((item, index) => (
                          <>
                            {index !== 0 && ", "}
                            <b>{item[0]}:</b> {item[1]}
                          </>
                        ))}
                      </td>
                      <td></td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Card.Body>
          </Card>

          {jobResult.errors.countTotal > 0 ? (
            <Card>
              <Card.Header>
                {jobResult.errors.countTotal < 50
                  ? "All errors"
                  : "50 first errors"}
              </Card.Header>
              <Card.Body className="card-with-table">
                <Table striped borderless hover border-left-0 border-right-0>
                  <thead>
                    <tr>
                      <td>Line</td>
                      <td>Error Type</td>
                    </tr>
                  </thead>
                  <tbody>
                    {jobResult.errors.samples.map((entry) => {
                      return (
                        <tr>
                          <td>{entry[0]}</td>
                          <td>{entry[1]}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          ) : (
            ""
          )}
        </div>
      )}
      <Link to={`/website/${websiteId}/pageEdit/import`}>
        Back to import page
      </Link>
    </div>
  );
}
