import { useNavigate, useParams } from "react-router-dom";
import { JsCodeType } from "../../../gql/types.generated";
import { WebsiteIdParams } from "../../types/routeParams";

/**
 * Returns a function that navigates to a script edition page
 * given its ID.
 * It assumes `:websiteId` in the URL and won't work otherwise.
 */
export const useGoToScriptEdition = () => {
  const websiteId = useParams<WebsiteIdParams>().websiteId!;
  const navigate = useNavigate();

  return (id: JsCodeType["id"]) =>
    navigate(`/website/${websiteId}/scripts/edit/${id}`);
};
