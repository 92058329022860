import React from "react";

type InlineListProps<T> = {
  entries: T[];
  render: (entry: T) => React.ReactNode;
};

export const InlineList = <T,>({ entries, render }: InlineListProps<T>) => (
  <div>
    {entries.map((entry, i) => (
      <React.Fragment key={i}>
        {i !== 0 && ", "}
        <span className="text-nowrap">{render(entry)}</span>
      </React.Fragment>
    ))}
  </div>
);
