import { useQuery } from "@apollo/client";
import moment from "moment";
import Card from "react-bootstrap/Card";

import { DataHandler } from "../components/DataHandler";
import {
  GET_WEBSITE_SETTINGS_CHECKER_LAST_JOB,
  WebsiteSettingsCheckerLastJobQuery,
  WebsiteSettingsCheckerLastJobQueryVariables,
} from "../gql/websites/website";
import { Header } from "./components/header";
import { StatusIcon } from "./components/StatusIcon";
import { getGlobalStatus, getPreviewLink } from "./helpers";
import { WebsiteIdParams } from "./types/routeParams";
import { Status } from "./typing";
import { useParams } from "react-router-dom";

interface IResult {
  status: Status;
  url: string;
  device: string;
  controls: {
    all: {
      status: Status;
      name: string;
      unsuccessMessage: string;
    }[];
    errors: string[];
  };
}

export function WebsiteSettingsCheckerJob() {
  const { websiteId } = useParams<WebsiteIdParams>() as WebsiteIdParams;

  const { loading, error, data } = useQuery<
    WebsiteSettingsCheckerLastJobQuery,
    WebsiteSettingsCheckerLastJobQueryVariables
  >(GET_WEBSITE_SETTINGS_CHECKER_LAST_JOB, {
    variables: { id: websiteId },
  });

  const website = data?.website;
  const job = website?.lastJob?.[0];

  if (error || loading || !job || !job.result || !job.status) {
    return (
      <DataHandler
        error={error}
        loading={loading}
        data={job?.result && job.status}
        expectData
      />
    );
  }

  const results = JSON.parse(job.result) as IResult[];
  const globalStatus = getGlobalStatus(results);

  return (
    <div>
      <Header.Title websiteId={websiteId} name="Settings Jobs" />
      <Card>
        <Card.Body>
          <div>
            Created {moment(job.dateCreated).format("MMMM Do YYYY, h:mm:ss a")}
          </div>
          <div className="text-xl-center">
            <strong>
              Status {globalStatus} <StatusIcon status={globalStatus} />
            </strong>
          </div>
          {results.map((result) => {
            return (
              <div>
                <StatusIcon status={result.status} /> Check{" "}
                <a
                  href={getPreviewLink(website, result.url, result.device)}
                  target="_blank"
                  rel="noreferrer"
                >
                  {result.url}
                </a>{" "}
                on {result.device}
                <br />
                {result.controls.errors.length > 0 && (
                  <div>
                    {" "}
                    &nbsp;&nbsp; <StatusIcon status="FAIL" /> Quality Controls
                    could not be launched: {result.controls.errors.join(",")}
                  </div>
                )}
                {result.controls.all.map((qc) => (
                  <div>
                    &nbsp;&nbsp; <StatusIcon status={qc.status} /> {qc.name}
                    {qc.unsuccessMessage && (
                      <div className="subMessage">{qc.unsuccessMessage}</div>
                    )}
                  </div>
                ))}
              </div>
            );
          })}
        </Card.Body>
      </Card>
    </div>
  );
}
