import { gql } from "@apollo/client";

export const GET_PRODUCTION_SECTION_SUMMARIES = gql`
  query GetProductionSectionSummaries($websiteId: String!) {
    website(id: $websiteId) {
      id
      productionVersion {
        id
        sections {
          id
          name
          mode
        }
      }
    }
  }
`;
