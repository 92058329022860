import { gql } from "@apollo/client";

export const GET_WEBSITE_MONITORING_SPEED = gql`
  query WebsiteMonitoringSpeed(
    $id: String!
    $timestampStart: Int!
    $timestampEnd: Int!
  ) {
    website(id: $id) {
      id
      queryServedPages(
        timestampStart: $timestampStart
        timestampEnd: $timestampEnd
      ) {
        countHits
        avgSpeedGain
        avgSpeedGainOnCacheHit
        avgFetchingTime
        avgFetchingTimeOnCacheHit
        avgDeliveryTimeOnCacheHit
      }
      queryIndexedPages(
        timestampStart: $timestampStart
        timestampEnd: $timestampEnd
      ) {
        countUrls
        count
      }
    }
  }
`;
