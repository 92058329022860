import { useState } from "react";
import { Alert } from "react-bootstrap";
import { useLocation } from "react-router-dom";

export const Messager = () => {
  const [showAlert, setShowAlert] = useState(true);
  const location = useLocation();
  return (
    <>
      {showAlert && location.state && location.state.message && (
        <Alert
          variant={location.state.message.status}
          dismissible
          onClose={() => setShowAlert(false)}
        >
          {location.state.message.value}
        </Alert>
      )}
    </>
  );
};
