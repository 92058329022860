import { BarDatum, BarSvgProps, ResponsiveBar } from "@nivo/bar";
import { LegendProps } from "@nivo/legends";
import { ResponsivePie } from "@nivo/pie";

type BarLegend = LegendProps & { dataFrom: "keys" | "indexes" };

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.

const SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"] as const;

const DEFAULT_BAR_LEGENDS: BarLegend[] = [
  {
    dataFrom: "keys",
    anchor: "bottom-right",
    direction: "column",
    justify: false,
    translateX: 150,
    translateY: 0,
    itemsSpacing: 2,
    itemWidth: 140,
    itemHeight: 20,
    itemDirection: "left-to-right",
    itemOpacity: 0.85,
    symbolSize: 20,
    effects: [
      {
        on: "hover",
        style: {
          itemOpacity: 1,
        },
      },
    ],
  },
];

const DEFAULT_BAR_MARGIN = {
  top: 10,
  right: 150,
  bottom: 80,
  left: 60,
} as const;

function abbreviateNumber(number: number) {
  // what tier? (determines SI symbol)
  const tier = (Math.log10(number) / 3) | 0;

  // if zero, we don't need a suffix
  if (tier === 0) return number;

  // get suffix and determine scale
  const suffix = SI_SYMBOL[tier];
  const scale = Math.pow(10, tier * 3);

  // scale the number
  const scaled = number / scale;

  // format number and add suffix
  return scaled.toFixed(1) + suffix;
}

interface IBarProps
  extends Pick<
    BarSvgProps<BarDatum>,
    "data" | "keys" | "colors" | "legends" | "margin" | "indexBy"
  > {
  legendMetric: string;
}

const Bar = ({
  data,
  keys,
  legendMetric,
  indexBy,
  colors = { scheme: "nivo" },
  legends = DEFAULT_BAR_LEGENDS,
  margin = DEFAULT_BAR_MARGIN,
}: IBarProps) => (
  <ResponsiveBar
    data={data}
    keys={keys}
    indexBy={indexBy}
    margin={margin}
    padding={0.3}
    colors={colors}
    axisTop={null}
    axisRight={null}
    axisBottom={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: -45,
      legend: "Date",
      legendPosition: "middle",
      legendOffset: 62,
    }}
    axisLeft={{
      tickSize: 5,
      tickPadding: 5,
      tickRotation: 0,
      legend: legendMetric,
      legendPosition: "middle",
      legendOffset: -50,
      format: (value) => abbreviateNumber(Number(value)),
    }}
    tooltipLabel={(value) => value.toLocaleString()}
    labelSkipWidth={12}
    labelSkipHeight={12}
    labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
    legends={legends}
    animate={false}
  />
);

interface ILastHoursBarProps
  extends Pick<
    IBarProps,
    "data" | "legendMetric" | "colors" | "margin" | "legends"
  > {
  metric: string;
}

const LastHoursBar = ({
  data,
  metric,
  legendMetric,
  colors,
}: ILastHoursBarProps) => (
  // No legend for charts by last hour
  <Bar
    data={data}
    keys={[metric]}
    legendMetric={legendMetric}
    indexBy="labelDateHour"
    colors={colors}
    margin={{ top: 10, right: 0, bottom: 80, left: 60 }}
    legends={[]}
  />
);

const Pie = ({ data }: { data: Array<BarDatum & { color: string }> }) => {
  const colors = data.map(({ color }) => color);
  return (
    <ResponsivePie
      data={data}
      margin={{ top: 40, right: 0, bottom: 80, left: 0 }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={0}
      colors={colors}
      borderWidth={1}
      borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
      arcLabelsSkipAngle={10}
      arcLabelsRadiusOffset={0.5}
      arcLabelsTextColor="#333333"
      arcLinkLabelsOffset={0}
      arcLinkLabelsDiagonalLength={16}
      arcLinkLabelsStraightLength={24}
      arcLinkLabelsThickness={1}
      arcLinkLabelsColor={{ from: "color" }}
      arcLinkLabelsSkipAngle={10}
      arcLinkLabelsTextColor="#333333"
      animate={false}
    />
  );
};

export { Bar, LastHoursBar, Pie };
