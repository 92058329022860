import { Card, Form } from "react-bootstrap";
import { DurationShortcuts } from "../../common/durationShortcuts";
import { INCREMENTAL_CACHE_REFRESH_INTERVALS } from "./constant";
import { FormControlElement } from "../../common/typing/form";
import { FormKey, FormValue, IWebsiteVersionSettings } from "./typing";

interface IAdvancedModeSettings {
  form: IWebsiteVersionSettings;
  updateCheck: React.ChangeEventHandler<HTMLInputElement>;
  updateField: React.ChangeEventHandler<FormControlElement>;
  updateFieldNumber: React.ChangeEventHandler<HTMLInputElement>;
  updateFieldByName: <K extends FormKey, V extends FormValue<K>>(
    name: K,
    value: V
  ) => void;
}

export function AdvancedModeSettings({
  form,
  updateCheck,
  updateField,
  updateFieldNumber,
  updateFieldByName,
}: IAdvancedModeSettings) {
  return (
    <>
      <Card>
        <Card.Header>Advanced Settings</Card.Header>
        <Card.Body>
          <Form.Group controlId="useRenderingFarm">
            <Form.Label>Use Rendering Farm</Form.Label>
            <Form.Check
              name="useRenderingFarm"
              type="checkbox"
              checked={form.useRenderingFarm}
              onChange={updateCheck}
            />
          </Form.Group>
          <Form.Group controlId="maxSpeed">
            <Form.Label>Max Speed (On Rendering Farm), in pages/sec</Form.Label>
            <Form.Control
              name="maxSpeed"
              type="number"
              value={form.maxSpeed}
              onChange={updateFieldNumber}
              placeholder="Enter Max Speed"
            />
          </Form.Group>
          <Form.Group controlId="liveMaxSpeed">
            <Form.Label>
              Live-Rendering Max Speed (On Rendering Farm), in pages/sec
            </Form.Label>
            <Form.Control
              name="liveMaxSpeed"
              type="number"
              value={form.liveMaxSpeed}
              isInvalid={
                form.liveMaxSpeed > form.maxSpeed
              }
              onChange={updateFieldNumber}
              placeholder="Enter Live Max Speed"
            />
            <Form.Control.Feedback type="invalid">
              Live Max-Speed has to be {"<="} Max-Speed
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="enableIncrementalCache">
            <Form.Label>Enable Incremental Cache</Form.Label>
            <Form.Check
              name="enableIncrementalCache"
              type="checkbox"
              checked={form.enableIncrementalCache}
              onChange={updateCheck}
            />
          </Form.Group>
          <Form.Group controlId="indexMinimumRefreshInterval">
            <Form.Label>Index minimum refresh interval</Form.Label>
            <Form.Control
              name="indexMinimumRefreshInterval"
              type="number"
              placeholder="Enter minimum refresh interval for index"
              value={form.indexMinimumRefreshInterval}
              onChange={updateFieldNumber}
              disabled={!form.enableIncrementalCache}
            />
            <DurationShortcuts
              onClick={(durationInMinutes) =>
                updateFieldByName(
                  "indexMinimumRefreshInterval",
                  durationInMinutes
                )
              }
              durations={INCREMENTAL_CACHE_REFRESH_INTERVALS}
            />
          </Form.Group>
          <Form.Group controlId="pagesMinimumRefreshInterval">
            <Form.Label>Pages minimum refresh interval</Form.Label>
            <Form.Control
              name="pagesMinimumRefreshInterval"
              type="number"
              placeholder="Enter minimum refresh interval for pages"
              value={form.pagesMinimumRefreshInterval}
              onChange={updateFieldNumber}
              disabled={!form.enableIncrementalCache}
            />
            <DurationShortcuts
              onClick={(durationInMinutes) =>
                updateFieldByName(
                  "pagesMinimumRefreshInterval",
                  durationInMinutes
                )
              }
              durations={INCREMENTAL_CACHE_REFRESH_INTERVALS}
            />
          </Form.Group>
        </Card.Body>
      </Card>
      <Card>
        <Card.Header>Interceptor Configuration</Card.Header>
        <Card.Body>
          <Form.Group controlId="filterBadBots">
            <Form.Label>Filter Bad Bots</Form.Label>
            <Form.Check
              name="filterBadBots"
              type="checkbox"
              checked={form.filterBadBots}
              onChange={updateCheck}
            />
          </Form.Group>

          <Form.Group controlId="noDeliveryStatusCode">
            <Form.Label>
              Status Code Returned to Interceptor when SpeedWorkers is not able
              to deliver a cached page
            </Form.Label>
            <Form.Control
              name="noDeliveryStatusCode"
              type="number"
              value={form.noDeliveryStatusCode}
              onChange={updateFieldNumber}
              placeholder="Enter Status Code"
            />
            <Form.Text className="text-muted">
              We recommend :
              <ul>
                <li>
                  202 for Akamai CDN (in order to avoid specific cache config
                  for bad status codes)
                </li>
                <li>403 for Amazon Cloudfront</li>
                <li>If not filled, 403 will be returned by default</li>
              </ul>
            </Form.Text>
          </Form.Group>

          <Form.Group controlId="customClientIpHeader">
            <Form.Label>Custom Client IP Header</Form.Label>
            <Form.Control
              name="customClientIpHeader"
              type="text"
              value={form.customClientIpHeader}
              onChange={updateField}
              placeholder="Enter Custom Client IP Header"
            />
            <Form.Text className="text-muted">
              By default, SW expects X-Sw-Client-Ip. Akamai default is
              True-Client-Ip. This header is mandatory to filter bad bots.
            </Form.Text>
          </Form.Group>
        </Card.Body>
      </Card>
    </>
  );
}
