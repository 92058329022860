export const botsRegex = {
  allShards: [
    "applebot/",
    "baiduspider/",
    "baiduspider+",
    "bingbot/",
    "botify-bot-sw-",
    "googlebot/",
    "google-inspectiontool/",
    "googleother",
    "yandexbot/",
    "yandexmobilebot/",
    "yeti/",
  ],
  /** Joins all shards into a single regex string */
  fromShards(shards: string[]) {
    return shards.join("|");
  },
  /** Split a single regex string into multiple shards */
  toShards(regex?: string) {
    if (!regex) return [];
    return regex.split("|");
  },
};
