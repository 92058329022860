import { useMutation, useQuery } from "@apollo/client";
import { omit } from "lodash";
import { ChangeEvent, useEffect, useState } from "react";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";

import { FormControlElement } from "../common/typing/form";
import { DataHandler } from "../components/DataHandler";
import { ConfigType } from "../gql/types.generated";
import {
  GET_WEBSITE,
  UPDATE_WEBSITE_CONFIG,
  WebsiteConfigMutation,
  WebsiteConfigMutationVariables,
  WebsiteQuery,
  WebsiteQueryVariables,
} from "../gql/websites/website";
import {
  EMULATION_DEVICE_CHOICES,
  FORBIDDEN_UA,
  UA_BY_DEVICE,
} from "./constants";
import { ConfigIdParams, WebsiteIdParams } from "./types/routeParams";
import { Link, useNavigate, useParams } from "react-router-dom";

function WebsiteConfig() {
  const { websiteId, configId } = useParams<
    WebsiteIdParams & ConfigIdParams
  >() as WebsiteIdParams & ConfigIdParams;
  const navigate = useNavigate();
  const { loading, error, data } = useQuery<
    WebsiteQuery,
    WebsiteQueryVariables
  >(GET_WEBSITE, {
    variables: { id: websiteId },
  });
  const [config, setConfig] = useState<ConfigType | null>(null);
  const [updateConfig] = useMutation<
    WebsiteConfigMutation,
    WebsiteConfigMutationVariables
  >(UPDATE_WEBSITE_CONFIG);
  const [configError, setConfigError] = useState<string | null>(null);

  useEffect(() => {
    const configs = data?.website?.draftVersion?.configs;
    const config = configs?.find((element) => element.id === configId);
    if (!config) return;
    setConfig(omit(config, ["__typename"]));
  }, [data, configId]);

  const website = data?.website;

  if (error || loading || !website || !config) {
    return (
      <DataHandler
        error={error}
        loading={loading}
        data={config}
        noDataMessage={
          <>
            Config Not Found: <code>{configId}</code>
          </>
        }
        expectData
      />
    );
  }

  const userAgentInvalid = config.userAgent?.match(FORBIDDEN_UA);

  const updateField = (e: ChangeEvent<FormControlElement>) => {
    setConfig({
      ...config,
      [e.target.name]: e.target.value,
    });
  };
  const updateFieldNumber = (e: ChangeEvent<HTMLInputElement>) => {
    setConfig({
      ...config,
      [e.target.name]: e.target.valueAsNumber,
    });
  };

  const updateUserAgent = (value: string) => {
    setConfig({
      ...config,
      userAgent: value,
    });
  };

  const submitConfig = async () => {
    const { data: dataUpdate } = await updateConfig({
      variables: { input: config },
    });
    const updatedConfig = dataUpdate?.updateConfig;
    const errors = updatedConfig?.errors;

    window.scrollTo(0, 0);
    if (errors?.length) {
      setConfigError(errors.map((error) => error?.messages).join("\n"));
    } else {
      navigate(
        {
          pathname: "/website/" + websiteId,
        },
        {
          state: {
            message: { value: config.name + " is saved", status: "success" },
          },
        }
      );
    }
  };

  return (
    <div>
      <h1>
        <Link to={`/website/${website.id}`}>{website.name}</Link>
        {" >"}
        Processing Configurations {"> "}
        {config.name}
      </h1>

      {configError && <Alert variant="danger">{configError}</Alert>}

      <Card>
        <Card.Header>Main Settings</Card.Header>
        <Card.Body>
          <Form.Group>
            <Form.Label>Process As</Form.Label>

            <Form.Check
              type="radio"
              label="HTML"
              name="action"
              value="HTML"
              checked={config.action === "HTML"}
              onChange={updateField}
            />
            <Form.Check
              type="radio"
              label="JS"
              name="action"
              value="JS"
              checked={config.action === "JS"}
              onChange={updateField}
            />
            <Form.Check
              type="radio"
              label="Do nothing, skip the request"
              name="action"
              value="DO_NOTHING"
              checked={config.action === "DO_NOTHING"}
              onChange={updateField}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Name</Form.Label>
            <Form.Control
              name="name"
              type="text"
              value={config.name}
              onChange={updateField}
              placeholder="Enter Name"
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Max Speed</Form.Label>
            <Form.Control
              name="maxSpeed"
              type="number"
              value={config.maxSpeed ?? 0}
              onChange={updateFieldNumber}
              placeholder="Enter Max Speed"
            />
            <Form.Text>
              Max Speed is temporary located on Config while waiting for
              rendering farm which will allow to set a Max Speed depending on
              page behavior.
            </Form.Text>
          </Form.Group>

          {config.action === "JS" && (
            <Form.Group>
              <Form.Label>Emulation Device</Form.Label>
              <Form.Control
                as="select"
                name="emulationDevice"
                value={config.emulationDevice}
                onChange={updateField}
              >
                {EMULATION_DEVICE_CHOICES.map(([id, label]) => (
                  <option
                    value={id}
                    disabled={
                      id !== "DESKTOP" &&
                      !website?.draftVersion?.useRenderingFarm
                    }
                  >
                    {label}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          )}

          <Form.Group>
            <Form.Label>User Agent</Form.Label>
            <Form.Control
              name="userAgent"
              type="text"
              value={config.userAgent ?? ""}
              onChange={updateField}
              placeholder="Enter User Agent"
              isInvalid={!!userAgentInvalid}
            />
            <Form.Text>
              Or select an preconfigured User Agent :{" "}
              {UA_BY_DEVICE.map((entry, i) => (
                <span>
                  <Button
                    variant="link"
                    style={{ padding: 0 }}
                    onClick={() => updateUserAgent(entry.value)}
                  >
                    {entry.name}
                  </Button>
                  {i < UA_BY_DEVICE.length - 1 ? "," : ""}{" "}
                </span>
              ))}
            </Form.Text>
          </Form.Group>

          <Form.Group>
            <Form.Label>Read Timeout</Form.Label>
            <Form.Control
              name="timeout"
              type="number"
              value={config.timeout}
              onChange={updateFieldNumber}
              placeholder="Enter Read Timeout"
            />
            <Form.Text>
              Maximum time to wait for a page, in milliseconds.
            </Form.Text>
          </Form.Group>

          <Form.Group>
            <Form.Label>Extra Headers</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              name="extraHeaders"
              value={config.extraHeaders ?? ""}
              onChange={updateField}
            />
            <Form.Text>One line per header</Form.Text>
          </Form.Group>

          {config.action === "JS" ? (
            <Form.Group>
              <Form.Label>JS Rendering Rules</Form.Label>
              <Form.Control
                as="textarea"
                rows={10}
                name="renderingRules"
                value={config.renderingRules ?? ""}
                onChange={updateField}
              />
            </Form.Group>
          ) : (
            ""
          )}

          <Button className="float-right" onClick={submitConfig}>
            Submit
          </Button>
        </Card.Body>
      </Card>
    </div>
  );
}

export { WebsiteConfig };
