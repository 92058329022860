import { JsCodeStep, JsCodeType } from "../../../gql/types.generated";

export const ORDERED_STEPS = [
  JsCodeStep.SetupBeforeRender,
  JsCodeStep.Init,
  JsCodeStep.DomContentLoaded,
  JsCodeStep.Load,
  JsCodeStep.Done,
  JsCodeStep.PreBeamResponse,
] as const;

const STEP_LABELS = {
  [JsCodeStep.SetupBeforeRender]: "OnSetupBeforeRender",
  [JsCodeStep.Init]: "OnInit",
  [JsCodeStep.DomContentLoaded]: "Dom Loaded",
  [JsCodeStep.Load]: "OnLoad",
  [JsCodeStep.Done]: "OnDone",
  [JsCodeStep.PreBeamResponse]: "Pre Beam Response",
  [JsCodeStep.Action]: "", // not handled
  [JsCodeStep.WaitFor]: "", // not handled
} satisfies Record<JsCodeStep, string>;

export const getStepLabel = (step: JsCodeStep): string => STEP_LABELS[step];

export type StepField = Extract<
  keyof JsCodeType,
  | "onActionCode"
  | "onDomContentLoadedCode"
  | "onDoneCode"
  | "onInitCode"
  | "onLoadCode"
  | "onPreBeamResponseCode"
  | "onSetupBeforeRenderCode"
  | "onWaitForCode"
>;

type ExecutionContext = "Rendering" | "Delivery";

type StepDefinition = {
  id: JsCodeStep;
  field: StepField;
  label: string;
  executionContext: ExecutionContext;
  needsRenderingFarm: boolean;
  hidden: boolean;
};

const STEPS = {
  [JsCodeStep.SetupBeforeRender]: {
    id: JsCodeStep.SetupBeforeRender,
    field: "onSetupBeforeRenderCode",
    label: "OnSetupBeforeRender",
    executionContext: "Rendering",
    needsRenderingFarm: false,
    hidden: false,
  },
  [JsCodeStep.Init]: {
    id: JsCodeStep.Init,
    field: "onInitCode",
    label: "OnInit",
    executionContext: "Rendering",
    needsRenderingFarm: true,
    hidden: false,
  },
  [JsCodeStep.DomContentLoaded]: {
    id: JsCodeStep.DomContentLoaded,
    field: "onDomContentLoadedCode",
    label: "Dom Loaded",
    executionContext: "Rendering",
    needsRenderingFarm: false,
    hidden: false,
  },
  [JsCodeStep.Load]: {
    id: JsCodeStep.Load,
    field: "onLoadCode",
    label: "OnLoad",
    executionContext: "Rendering",
    needsRenderingFarm: false,
    hidden: false,
  },
  [JsCodeStep.Done]: {
    id: JsCodeStep.Done,
    field: "onDoneCode",
    label: "OnDone",
    executionContext: "Rendering",
    needsRenderingFarm: false,
    hidden: false,
  },
  [JsCodeStep.PreBeamResponse]: {
    id: JsCodeStep.PreBeamResponse,
    field: "onPreBeamResponseCode",
    label: "Pre Beam Response",
    executionContext: "Delivery",
    needsRenderingFarm: false,
    hidden: false,
  },
  [JsCodeStep.Action]: {
    id: JsCodeStep.Action,
    field: "onActionCode",
    label: "",
    executionContext: "Rendering",
    needsRenderingFarm: true,
    hidden: true, // not handled (SW-3246)
  },
  [JsCodeStep.WaitFor]: {
    id: JsCodeStep.WaitFor,
    field: "onWaitForCode",
    label: "",
    executionContext: "Rendering",
    needsRenderingFarm: true,
    hidden: true, // not handled (SW-3246)
  },
} as const satisfies Record<JsCodeStep, StepDefinition>;

/** Steps that are not hidden */
export type VisibleStep = {
  [K in keyof typeof STEPS]: (typeof STEPS)[K] extends { hidden: true }
    ? never
    : K;
}[keyof typeof STEPS];

/** Step definitions that are not hidden */
export type VisibleStepDefinition = {
  [K in keyof typeof STEPS]: (typeof STEPS)[K] extends { hidden: true }
    ? never
    : (typeof STEPS)[K];
}[keyof typeof STEPS];

export const getStepField = <S extends JsCodeStep>(
  step: S
): (typeof STEPS)[S]["field"] => STEPS[step].field;

export const getCodeForStep = (script: JsCodeType, step: JsCodeStep): string =>
  script[getStepField(step)];

export const canUseStep = (
  { hidden, needsRenderingFarm }: StepDefinition,
  hasRenderingFarm: boolean
) => !hidden && (!needsRenderingFarm || hasRenderingFarm);

export const getVisibleSteps = (): VisibleStepDefinition[] =>
  // @ts-expect-error TS fails to infer type from Array.filter
  // Note: this is fixed in a recent version of TS.
  Object.values(STEPS).filter(({ hidden }) => !hidden);
