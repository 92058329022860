import { useMutation, useQuery } from "@apollo/client";

import { Button, Col, Container, Row } from "react-bootstrap";
import { Route, Routes, useParams } from "react-router-dom";

import { DataHandler } from "../components/DataHandler";

import { useAdvancedMode } from "../common/hooks";
import { Error404Page } from "../components/Error404Page/Error404Page";
import {
  CreateDraftVersionMutation,
  CreateDraftVersionMutationVariables,
  CREATE_DRAFT_VERSION,
  GET_WEBSITE,
  WebsiteQuery,
  WebsiteQueryVariables,
} from "../gql/websites/website";
import { ADNRoutingSystem } from "./adn/ADNRoutingSystem";
import { WebsiteBaConfigEdit } from "./ba_config";
import { WebsiteBeamExplorer } from "./beam/Explorer";
import { WebsiteBeamHome } from "./beam/Home";
import { BingContentSubmissionExplorer } from "./bingContentSubmission/Explorer";
import { BingContentSubmissionHome } from "./bingContentSubmission/Home";
import { WebsiteNav } from "./components/nav";
import { WebsiteConfig } from "./config";
import { WebsiteDebug } from "./debug/Home";
import { WebsiteForceRefresh } from "./forceRefresh";
import { WebsiteSectionHtmlEdit } from "./htmledit";
import { WebsiteIndexationExplorer } from "./indexation/Explorer";
import { WebsiteIndexationHistory } from "./indexation/History";
import { WebsiteIndexationHome } from "./indexation/Home";
import { InputsRoutes } from "./inputs/Routes";
import { WebsiteInterceptors } from "./interceptors/Home";
import { WebsiteInventoryHistory } from "./inventoryHistory";
import { WebsiteInventory } from "./inventoryLive";
import { ModulesRoutes } from "./modules/Routes";
import { PageEdit } from "./pageEdit/home";
import { PwSettings } from "./pwSettings";
import { ScriptsRouting } from "./scripts/ScriptsRouting";
import { WebsiteSearchEngineIndex } from "./searchEngineIndex";
import { WebsiteSection } from "./sections";
import { WebsiteSegmentation } from "./segments";
import { WebsiteSettings } from "./settings";
import { WebsiteSettingsCheckerJob } from "./settingsChecker";
import { SetupChecker } from "./setupChecker";
import { WebsiteIdParams } from "./types/routeParams";
import { WebsiteUrlTester } from "./urlTester/websiteUrlTester";
import { WebsiteVersioning } from "./versioning";
import { WebsiteHome } from "./websiteHome";

export function WebsiteRouting() {
  const { websiteId } = useParams<WebsiteIdParams>() as WebsiteIdParams;
  const { loading, error, data } = useQuery<
    WebsiteQuery,
    WebsiteQueryVariables
  >(GET_WEBSITE, {
    variables: { id: websiteId },
  });

  const { isAdvancedMode } = useAdvancedMode();

  const website = data?.website;
  const draftVersion = website?.draftVersion;

  if (error || loading || !website || !draftVersion) {
    return (
      <DataHandler
        error={error}
        loading={loading}
        data={website && draftVersion}
        noDataMessage={
          <NoDraftVersionWarning
            websiteId={websiteId}
            productionVersionId={website?.productionVersion?.id ?? ""}
          />
        }
        expectData
      />
    );
  }

  return (
    <Container fluid className="h-100">
      <Row className="h-100">
        <Col xs={2}>
          <WebsiteNav website={website} />
        </Col>
        <Col xs={10} className="h-100">
          <Routes>
            {/* Standard Mode Routes */}
            <Route index element={<WebsiteHome />} />
            <Route path="settings" element={<WebsiteSettings />} />
            <Route path="scripts/*" element={<ScriptsRouting />} />
            <Route path="inventory" element={<WebsiteInventoryHistory />} />
            <Route path="inventory/live" element={<WebsiteInventory />} />
            <Route
              path="searchEngineIndex"
              element={<WebsiteSearchEngineIndex />}
            />
            <Route path="indexation" element={<WebsiteIndexationHome />} />
            <Route
              path="indexation/history"
              element={<WebsiteIndexationHistory />}
            />
            <Route
              path="indexation/explorer/:preset"
              element={<WebsiteIndexationExplorer />}
            />
            <Route path="beam" element={<WebsiteBeamHome />} />
            <Route
              path="beam/explorer/:preset"
              element={<WebsiteBeamExplorer />}
            />
            <Route
              path="bingContentSubmission"
              element={<BingContentSubmissionHome />}
            />
            <Route
              path="bingContentSubmission/explorer/:preset"
              element={<BingContentSubmissionExplorer />}
            />
            <Route path="url-tester" element={<WebsiteUrlTester />} />
            <Route path="ba-config" element={<WebsiteBaConfigEdit />} />
            <Route path="inputs/*" element={<InputsRoutes />} />
            <Route path="versioning" element={<WebsiteVersioning />} />
            <Route path="config/:configId" element={<WebsiteConfig />} />
            {/* Advance Mode Routes */}
            {isAdvancedMode && (
              <>
                <Route path="section/create" element={<WebsiteSection />} />
                <Route path="section/:sectionId" element={<WebsiteSection />} />
                <Route
                  path="section/:sectionId/editor"
                  element={<WebsiteSectionHtmlEdit />}
                />
                <Route path="interceptors" element={<WebsiteInterceptors />} />
                <Route path="setupChecker" element={<SetupChecker />} />
                <Route path="pageEdit/*" element={<PageEdit />} />

                <Route
                  path="settingsCheckerJob/last"
                  element={<WebsiteSettingsCheckerJob />}
                />
                <Route path="debug" element={<WebsiteDebug />} />
                <Route path="forceRefresh" element={<WebsiteForceRefresh />} />
                <Route path="segmentation" element={<WebsiteSegmentation />} />
                <Route path="modules/*" element={<ModulesRoutes />} />
                <Route path="pw-settings/*" element={<PwSettings />} />
                <Route path="adn/*" element={<ADNRoutingSystem />}></Route>
              </>
            )}
            <Route path="*" element={<Error404Page />} />
          </Routes>
        </Col>
      </Row>
    </Container>
  );
}

interface INoDraftVersionWarningProps {
  websiteId: string;
  productionVersionId: string;
}

function NoDraftVersionWarning({
  websiteId,
  productionVersionId,
}: INoDraftVersionWarningProps) {
  const [_createDraft] = useMutation<
    CreateDraftVersionMutation,
    CreateDraftVersionMutationVariables
  >(CREATE_DRAFT_VERSION, {
    onCompleted() {
      // The draft mutation doesn't return `draftVersion` scoped under the
      // `Website` model, so it doesn't refresh the rest of the queries.
      // We'll do a full page refresh for now.
      window.location.reload();
    },
  });

  function createDraft() {
    _createDraft({
      variables: {
        website: websiteId,
        baseVersion: productionVersionId,
      },
    });
  }

  return (
    <Container fluid className="h-100">
      <h2>No draft version</h2>
      <p>
        The FTL app can only work if a draft version is defined for the website.
      </p>
      <p>
        You can create one by clicking on the button below, but please know this
        will be visible to the customer.
      </p>
      <Button onClick={createDraft}>Create draft version</Button>
    </Container>
  );
}
