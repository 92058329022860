import React from "react";
import { Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";

// duration is in minutes
const DefaultDurations = [
  { label: "1 day", value: 24 * 60 },
  { label: "2 days", value: 24 * 60 * 2 },
  { label: "3 days", value: 24 * 60 * 3 },
  { label: "7 days", value: 24 * 60 * 7 },
  { label: "14 days", value: 24 * 60 * 14 },
  { label: "30 days", value: 24 * 60 * 30 },
];

interface IDurationShortcutsProps {
  children?: React.ReactElement;
  onClick: (durationInMinutes: number) => void;
  durations?: Array<{
    label: string;
    value: number;
  }>;
}

export function DurationShortcuts({
  children,
  onClick,
  durations,
}: IDurationShortcutsProps) {
  const handleClick = (e: React.MouseEvent, durationValue: number) => {
    e.preventDefault();
    onClick(durationValue);
  };
  const displayedDurations = durations ?? DefaultDurations;

  return (
    <Form.Text>
      {children ? (
        <>
          {children}
          <br />
        </>
      ) : null}
      Shortcut to:&nbsp;
      {displayedDurations.map((duration, index) => (
        <React.Fragment key={duration.value}>
          {index > 0 ? ", " : null}
          <Button
            variant="link"
            className="link"
            onClick={(e) => handleClick(e, duration.value)}
          >
            {duration.label}
          </Button>
        </React.Fragment>
      ))}
    </Form.Text>
  );
}
