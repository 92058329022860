import {
  SectionIgnoreParametersStrategy,
  SectionLiveMode,
  SectionType,
} from "../../gql/types.generated";

interface ISortableSection extends Pick<SectionType, "refreshPriority"> {}

export const sortByRefreshPriority = (
  a: ISortableSection,
  b: ISortableSection
) => ((a.refreshPriority ?? 0) < (b.refreshPriority ?? 0) ? -1 : 1);

export const getLiveModeLabel = (liveMode: SectionLiveMode) => {
  switch (liveMode) {
    case SectionLiveMode.NoLive:
      return "No Live";
    case SectionLiveMode.LiveFetchLegacy:
      return "Live Fetch Legacy";
    case SectionLiveMode.LiveFetchOnly:
      return "Live Fetch Only";
    case SectionLiveMode.LiveFetchAndRender:
      return "Live Fetch And Render";
  }
};

export const getIgnoreParametersStrategyLabel = (
  strategy: SectionIgnoreParametersStrategy
) => {
  switch (strategy) {
    case SectionIgnoreParametersStrategy.Serve:
      return "Serve page";
    case SectionIgnoreParametersStrategy.ServeAndAddCanonical:
      return "Served page and add canonical attribute to URL without parameters if tag was not set";
    case SectionIgnoreParametersStrategy.ServeAndForceCanonical:
      return "Served page and force canonical attribute to URL without parameters even if it tag was already set";
    case SectionIgnoreParametersStrategy.TemporaryRedirect:
      return "Apply a Temporary redirect to the page with ignored parameters";
    case SectionIgnoreParametersStrategy.PermanentRedirect:
      return "Apply a Permanent 301 redirect to the page with ignored parameters";
  }
};

export const LiveScope = [
  ["on_stale", "On stale"],
  ["on_expired", "On expired"],
  ["on_qc_failed", "On quality control failed"],
  ["on_cache_miss", "On cache miss"],
] as const;
