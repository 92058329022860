import { useQuery } from "@apollo/client";
import _ from "lodash";
import { useState } from "react";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import {
  BarChart,
  reducebyDimensions,
  StackedBarChart,
} from "../common/charts";
import { SearchEngineComponent } from "../common/explorer";
import { DataHandler } from "../components/DataHandler";
import { SearchEngineIndexResultType } from "../gql/types.generated";
import {
  GET_WEBSITE_SEARCH_ENGINE_INDEX,
  GET_WEBSITE_SEARCH_ENGINE_INDEX_AGE,
  WebsiteSearchEngineIndexAgeQuery,
  WebsiteSearchEngineIndexAgeQueryVariables,
  WebsiteSearchEngineIndexQuery,
  WebsiteSearchEngineIndexQueryVariables,
} from "../gql/websites/website";
import { Header } from "./components/header";
import { WebsiteIdParams } from "./types/routeParams";
import { useParams } from "react-router-dom";

export function WebsiteSearchEngineIndex() {
  const { websiteId } = useParams<WebsiteIdParams>() as WebsiteIdParams;
  const [nbDays, setNbDays] = useState(90);
  const [searchEngine, setSearchEngine] = useState<number | null>(1);
  const [bot, setBot] = useState<number | null>(null);

  return (
    <div>
      <Header>
        <Header.Title websiteId={websiteId} name="Search Engine Index" />
        <Header.SubTitle
          subTitle={
            <p>
              For each day, we take all pages that Search Engines bots asked in
              a {nbDays}-days rolling sum, whatever they were cached by SW or
              not.
            </p>
          }
        />
        <Header.Selectors
          selectorComponents={[
            <SearchEngineComponent
              setSearchEngine={setSearchEngine}
              setBot={setBot}
              bot={bot}
              searchEngine={searchEngine}
            />,
            <Form.Control
              as="select"
              onChange={(e) => setNbDays(parseInt(e.target.value))}
            >
              <option value="7" selected={nbDays === 7}>
                7 days
              </option>
              <option value="30" selected={nbDays === 30}>
                30 days
              </option>
              <option value="90" selected={nbDays === 90}>
                90 days
              </option>
            </Form.Control>,
          ]}
        />
      </Header>

      {searchEngine !== null && (
        <>
          <WebsiteSearchEngineIndexByDay
            websiteId={websiteId}
            nbDays={nbDays}
            searchEngine={searchEngine}
            bot={bot}
          />
          <WebsiteSearchEngineIndexAgeByDay
            websiteId={websiteId}
            searchEngine={searchEngine}
          />
        </>
      )}
    </div>
  );
}

interface IWebsiteSearchEngineIndexByDayProps {
  websiteId: string;
  nbDays: number;
  searchEngine: number;
  bot: number | null;
}

export function WebsiteSearchEngineIndexByDay({
  websiteId,
  nbDays,
  searchEngine,
  bot,
}: IWebsiteSearchEngineIndexByDayProps) {
  const { loading, error, data } = useQuery<
    WebsiteSearchEngineIndexQuery,
    WebsiteSearchEngineIndexQueryVariables
  >(GET_WEBSITE_SEARCH_ENGINE_INDEX, {
    variables: {
      id: websiteId,
      nbDays: nbDays,
      searchEngine: searchEngine,
      bot: bot,
    },
  });

  const website = data?.website;
  const results = website?.querySearchEngineIndex?.results;
  const errorMsg =
    error?.message ?? data?.website?.querySearchEngineIndex?.error;

  if (error || loading || !results) {
    return (
      <DataHandler
        error={errorMsg}
        loading={loading}
        data={results}
        expectData
      />
    );
  }

  return (
    <>
      <ChartMain results={results} nbDays={nbDays} />
      <ChartBySection results={results} nbDays={nbDays} />
    </>
  );
}

interface IChartProps {
  results: SearchEngineIndexResultType[];
  nbDays: number;
}

function ChartBySection({ results, nbDays }: IChartProps) {
  return (
    <Card>
      <Card.Header>Search Engine Index Size By Day</Card.Header>
      <Card.Body>
        <p>
          <b>{nbDays}-days Rolling Sum of Index Size</b>
        </p>
        <Row className="websiteHomeChart">
          <StackedBarChart
            data={results}
            dimension="date"
            splitBy={["sectionName"]}
            metric="count"
            colors={{ true: "#23c451", false: "#F84F30" }}
            xTitle="Date"
            yTitle="Number of Pages"
            sortByX={true}
          />
        </Row>
      </Card.Body>
    </Card>
  );
}

function ChartMain({ results, nbDays }: IChartProps) {
  return (
    <Card>
      <Card.Header>Search Engine Index Size By Behavior</Card.Header>
      <Card.Body>
        <p>
          <b>{nbDays}-days Rolling Sum of Index Size</b>
        </p>
        <Row className="websiteHomeChart">
          <BarChart
            data={reducebyDimensions(_(results).value(), ["date"], "count")}
            dimension="date"
            metric="count"
            color="blue"
            xTitle="Date"
            yTitle="Number of Pages"
            sortByX={true}
          />
        </Row>
      </Card.Body>
    </Card>
  );
}

interface IWebsiteSearchEngineIndexAgeByDayProps {
  websiteId: string;
  searchEngine: number;
}

export function WebsiteSearchEngineIndexAgeByDay({
  websiteId,
  searchEngine,
}: IWebsiteSearchEngineIndexAgeByDayProps) {
  const { loading, error, data } = useQuery<
    WebsiteSearchEngineIndexAgeQuery,
    WebsiteSearchEngineIndexAgeQueryVariables
  >(GET_WEBSITE_SEARCH_ENGINE_INDEX_AGE, {
    variables: {
      id: websiteId,
      searchEngine: searchEngine,
    },
  });

  const querySearchEngineIndexAge = data?.website?.querySearchEngineIndexAge;
  const errorMsg = error?.message ?? querySearchEngineIndexAge?.error;

  if (error || loading || !querySearchEngineIndexAge) {
    return (
      <DataHandler
        error={errorMsg}
        loading={loading}
        data={querySearchEngineIndexAge}
        expectData
      />
    );
  }

  const ageData = querySearchEngineIndexAge.results?.map((item) => ({
    ...item,
    ageRange: DAY_RANGE_LABELS[item.ageRange as keyof typeof DAY_RANGE_LABELS],
  }));

  return (
    <Card>
      <Card.Header>Index Age of Last Crawl</Card.Header>
      <Card.Body>
        <Row className="websiteHomeChart">
          <p>
            For each day, return the number of pages crawled by search engine by
            their last crawl age
          </p>
          <StackedBarChart
            data={ageData}
            dimension="date"
            splitBy={["ageRange"]}
            metric="count"
            colors={DAY_RANGE_LABEL_COLORS}
            xTitle="Date"
            yTitle="Number of Pages"
            sortByX
          />
        </Row>
      </Card.Body>
    </Card>
  );
}

type Label = typeof DAY_RANGE_LABELS[keyof typeof DAY_RANGE_LABELS];

// Prefix by num because labels are ordered by alphanum
const DAY_RANGE_LABELS = {
  d00: "00 Same day",
  d01: "01 day old",
  d02: "02 days old",
  d03: "03 days old",
  d04: "04 days old",
  d05: "05 days old",
  d06: "06 days old",
  d07: "07 days old",
  d15: "15 days old",
  d30: "30 days old",
  d60: "60 days old",
  d90: "90 days old",
  d90_plus: "90+ days old",
} as const;

const DAY_RANGE_LABEL_COLORS: Record<Label, string> = {
  [DAY_RANGE_LABELS.d00]: "#8a1a32",
  [DAY_RANGE_LABELS.d01]: "#BBC016",
  [DAY_RANGE_LABELS.d02]: "#60A548",
  [DAY_RANGE_LABELS.d03]: "#db5e8b",
  [DAY_RANGE_LABELS.d04]: "#EE9922",
  [DAY_RANGE_LABELS.d05]: "#9AA0DF",
  [DAY_RANGE_LABELS.d06]: "#B6D1E3",
  [DAY_RANGE_LABELS.d07]: "#783DBA",
  [DAY_RANGE_LABELS.d15]: "#8b47d7",
  [DAY_RANGE_LABELS.d30]: "#DAC687",
  [DAY_RANGE_LABELS.d60]: "#0659DE",
  [DAY_RANGE_LABELS.d90]: "#EC6D0E",
  [DAY_RANGE_LABELS.d90_plus]: "#e0390f",
};
