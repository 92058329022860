interface IDurationProps {
  ms: number;
}

export function Duration({ ms }: IDurationProps) {
  if (!ms) return null;
  if (ms < 60000) {
    return <>Less than a minute</>;
  }
  const durationInSecs = Math.floor(ms / 1000);
  const days = Math.floor(durationInSecs / (24 * 3600));
  const hours = Math.floor(durationInSecs / 3600) % 24;
  const minutes = Math.floor(durationInSecs / 60) % 60;
  return (
    <>
      {[
        days ? `${days} days` : null,
        hours ? `${hours} hours` : null,
        minutes ? `${minutes} minutes` : null,
      ]
        .filter(Boolean)
        .join(" ")}
    </>
  );
}