import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";

import { useFormInputSynchronizedWithLS } from "../../common/hooks";
import { ImportTypeChoices as ImportType } from "../../gql/types.generated";
import { CREATE_JOB } from "../../gql/websites/job";
import { Header } from "../components/header";
import { WebsiteIdParams } from "../types/routeParams";
import { Link, useNavigate, useParams } from "react-router-dom";

export function Import() {
  const { websiteId } = useParams<WebsiteIdParams>() as WebsiteIdParams;
  const navigate = useNavigate();

  const [blockUri, setBlockUri] = useFormInputSynchronizedWithLS({
    key: `blockUri${websiteId}`,
    defaultValue: "",
  });
  const [metadataUri, setMetadataUri] = useFormInputSynchronizedWithLS({
    key: `metadataUri${websiteId}`,
    defaultValue: "",
  });

  const [importType, setImportType] = useState(ImportType.Metadata);
  const [validated, setValidated] = useState(false);
  const [buttonValue, setButtonValue] = useState("Launch Import");
  const [submitJob] = useMutation(CREATE_JOB, {
    update(_, mutationData) {
      navigate(
        "/website/" +
          websiteId +
          "/pageEdit/job/" +
          mutationData.data.createImportJob.importJob.id
      );
    },
  });

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    setValidated(true);
    setButtonValue("Importing");
    await submitJob({
      variables: {
        input: {
          website: websiteId,
          uri: importType === ImportType.HtmlBlock ? blockUri : metadataUri,
          importType: importType,
        },
      },
    });
  }

  return (
    <div>
      <Header.Title
        websiteId={websiteId}
        name={
          <>
            Page Edit {"> "}
            <Link to={`/website/${websiteId}/pageEdit/import`}>Import</Link>
          </>
        }
      />

      <Card>
        <Card.Body>
          <ToggleButtonGroup name="importType">
            <ToggleButton
              variant="primary"
              checked={importType === ImportType.Metadata}
              onClick={() => setImportType(ImportType.Metadata)}
              value={importType}
              id="button-metadata"
            >
              Metadata
            </ToggleButton>
            <ToggleButton
              variant="primary"
              checked={importType === ImportType.HtmlBlock}
              onClick={() => setImportType(ImportType.HtmlBlock)}
              value={importType}
              id="button-import-type"
            >
              HTML Block
            </ToggleButton>
          </ToggleButtonGroup>

          {importType === ImportType.HtmlBlock ? (
            <div>
              <p>Please provide an URL containing one line per entry</p>
              <p>Data split by tab (\t)</p>
              <p>
                Columns
                <ul>
                  <li>URL</li>
                  <li>Device. One of : desktop, device, desktop_and_mobile</li>
                  <li>
                    Block name (used as an identifier if you want to erase this
                    block with an updated HTML Content)
                  </li>
                  <li>CSS Selector</li>
                  <li>
                    Strategy. One of append, prepend, insertAfter, insertBefore,
                    replace.
                  </li>
                  <li>HTML Value</li>
                </ul>
              </p>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Label>Gist URI</Form.Label>
                  <Form.Control
                    value={blockUri}
                    onChange={setBlockUri}
                    required
                  />
                </Form.Group>
                <Button type="submit" className="float-right">
                  {buttonValue}
                </Button>
              </Form>
            </div>
          ) : (
            ""
          )}

          {importType === ImportType.Metadata ? (
            <div>
              <p>Please provide an URL containing one line per entry</p>
              <p>Data splited by tab (\t)</p>
              <p>
                Columns
                <ul>
                  <li>URL</li>
                  <li>Device. One of : desktop, device, desktop_and_mobile</li>
                  <li>
                    Property. One of : title, description, h1,
                    permanentRedirect, temporaryRedirect
                  </li>
                  <li>Property Value</li>
                </ul>
              </p>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Group>
                  <Form.Label>Gist URI</Form.Label>
                  <Form.Control
                    value={metadataUri}
                    onChange={setMetadataUri}
                    required
                  />
                </Form.Group>
                <Button type="submit" className="float-right">
                  {buttonValue}
                </Button>
              </Form>
            </div>
          ) : (
            ""
          )}
        </Card.Body>
      </Card>
    </div>
  );
}
