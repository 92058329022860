import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { DataHandler } from "../../../components/DataHandler";
import { JsCodeType, UpdateJsCodeMutation } from "../../../gql/types.generated";
import { UPDATE_WEBSITE_SCRIPT } from "../../../gql/websites/scripts/updateWebsiteScript";
import { UpdateJsCodeMutationVariables } from "../../../gql/websites/scripts/updateWebsiteScript.generated";
import { BreadcrumbTitle } from "../../components/header/Title";
import { ScriptIdParams } from "../../types/routeParams";
import {
  ScriptSubmitForm,
  ScriptSubmitFormProps,
  ScriptSubmitFormValues,
} from "../components/ScriptSubmitForm";
import { scriptToFormValues } from "../components/ScriptSubmitForm/mappers";
import { useScriptByIdQuery } from "../hooks/useScriptsQuery";

export const ScriptEditionPage = () => {
  const scriptId = useParams<ScriptIdParams>().scriptId!;
  const { script, website, loading, error } = useScriptByIdQuery(scriptId);

  if (loading || error || !website || !script) {
    return (
      <DataHandler
        expectData
        data={website && script}
        error={error}
        loading={loading}
      />
    );
  }

  return (
    <div>
      <BreadcrumbTitle
        website={website}
        steps={["Script Management", script.name]}
      />
      <ScriptEditionForm
        script={script}
        sections={website.draftVersion?.sections ?? []}
      />
    </div>
  );
};

type ScriptEditionFormProps = {
  script: JsCodeType;
  sections: ScriptSubmitFormProps["sections"];
};

const ScriptEditionForm = ({ script, sections }: ScriptEditionFormProps) => {
  const [updateWebsiteScript, { loading }] = useMutation<
    UpdateJsCodeMutation,
    UpdateJsCodeMutationVariables,
    { scriptName: string }
  >(UPDATE_WEBSITE_SCRIPT, {
    onCompleted: (data) => {
      // FIXME: fix type generation
      // @ts-expect-error generated type is incorrect (missing updateJsCode field)
      const updatedScript = data?.updateJsCode?.jsCode;
      if (updatedScript) {
        toast(`Script "${updatedScript.name}" successfully updated.`, {
          type: "success",
        });
      }
    },
    onError: (error, clientOptions) => {
      const { scriptName } = clientOptions!.context!;
      toast(`Failed to update script "${scriptName}": ${error.message}`, {
        type: "error",
      });
    },
  });

  const submitForm = (formValues: ScriptSubmitFormValues) => {
    updateWebsiteScript({
      variables: { script: { ...formValues, id: script.id } },
      context: { scriptName: script.name },
    });
  };

  return (
    <ScriptSubmitForm
      base={scriptToFormValues(script)}
      onSubmit={submitForm}
      submitting={loading}
      sections={sections}
    />
  );
};
