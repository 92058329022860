import { useMutation } from "@apollo/client";
import React, { useState } from "react";
import { Button, Table } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";

import ShowMoreText from "react-show-more-text";

import {
  useFormInputSynchronizedWithLS,
  useLocalStorageSynchronizedState,
} from "../common/hooks";
import {
  CreateSetupCheckerMutation,
  CreateSetupCheckerMutationVariables,
  CREATE_SETUP_CHECKER,
} from "../gql/websites/website";
import { Header } from "./components/header";
import { WebsiteIdParams } from "./types/routeParams";
import { useParams } from "react-router-dom";

export function SetupChecker() {
  const { websiteId } = useParams<WebsiteIdParams>() as WebsiteIdParams;
  const [validated, setValidated] = useState(false);
  const [url, setUrl] = useFormInputSynchronizedWithLS({
    key: websiteId + "setupCheckerUrl",
    defaultValue: "https://www.site.com/",
  });
  const [options, setOptions] = useState("");
  const [checkText, setCheckText] = useFormInputSynchronizedWithLS({
    key: websiteId + "setupCheckerText",
    defaultValue: "",
  });
  const [userAgent, setUserAgent] = useLocalStorageSynchronizedState({
    key: websiteId + "setupCheckerUA",
    defaultValue: "botify-sw-test-bot",
  });

  const DEFAULT_BUTTON_VALUE = "Check";
  const [buttonValue, setButtonValue] = useState(DEFAULT_BUTTON_VALUE);
  const [result, setResult] =
    useState<CreateSetupCheckerMutation["checkSetup"]>(null);
  const [submitJob] = useMutation<
    CreateSetupCheckerMutation,
    CreateSetupCheckerMutationVariables
  >(CREATE_SETUP_CHECKER, {
    update(_, mutationData) {
      if (mutationData?.data?.checkSetup) {
        setResult(mutationData.data.checkSetup);
      }
    },
  });

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    setButtonValue("Checking");
    // Resetting previous result
    setResult({});
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    setValidated(true);
    await submitJob({
      variables: {
        input: {
          website: websiteId,
          url: url,
          userAgent: userAgent,
          options: options,
          checkText: checkText,
        },
      },
    });
    setButtonValue(DEFAULT_BUTTON_VALUE);
  }

  const UserAgentShortcutButton = ({ value }: { value: string }) => (
    <Button variant="link" onClick={() => setUserAgent(value)} size="sm">
      {value}
    </Button>
  );

  return (
    <>
      <Header>
        <Header.Title websiteId={websiteId} name="Setup Checker" />
        <Header.SubTitle
          subTitle={
            <p>
              This service will introspect your domain to check if SpeedWorkers
              is correctly set up.
            </p>
          }
        />
      </Header>

      <Card>
        <Card.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>
                Fill an URL which is in the SpeedWorkers cache
              </Form.Label>
              <Form.Control value={url} onChange={setUrl} required />
              <Form.Text>
                Please ensure this URL is correctly in the SpeedWorkers cache
              </Form.Text>
            </Form.Group>
            <Form.Group>
              <Form.Label>Text that should appears on HTML page</Form.Label>
              <Form.Control
                value={checkText}
                onChange={setCheckText}
                required
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>User-Agent</Form.Label>
              <Form.Control
                value={userAgent}
                onChange={(e) => setUserAgent(e.target.value)}
                required
              />
              <Form.Text>
                <p>
                  Shortcut to{" "}
                  <UserAgentShortcutButton value="botify-sw-test-bot" />
                  <UserAgentShortcutButton value="googlebot" />
                  <UserAgentShortcutButton value="bingbot" />
                </p>
              </Form.Text>
            </Form.Group>

            <Form.Group>
              <Form.Label>Mode</Form.Label>
              <Form.Control
                name="options"
                as="select"
                onChange={(e) => setOptions(e.target.value)}
              >
                <option value="">No option</option>
                <option value="always-success">Always Success</option>
                <option value="always-notfound">Always Not Found</option>
                <option value="always-timeout">Always Timeout</option>
              </Form.Control>
              <Form.Text>
                <>
                  <b>No option :</b> Should return page if stored by
                  SpeedWorkers
                  <br />
                  <b>Always Success</b> : Should return "Success" as HTML result
                  and header x-ftlcdn-status=false
                  <br />
                  <b>Always Not Found / Always Timeout :</b> Should fallback to
                  customer server, no x-ftlcdn-status header should be returned
                </>
              </Form.Text>
            </Form.Group>
            <Button type="submit" className="float-right">
              {buttonValue}
            </Button>
          </Form>

          {result?.success !== undefined && (
            <>
              <hr />
              <h2>
                {result.success === true && "✅ Success"}
                {result.success === false && "❌ Failed"}
              </h2>

              {result.errorReason && <p>Reason : {result.errorReason}</p>}

              <p>Http Code : {result.httpCode}</p>

              <h4>Html Result</h4>

              <ShowMoreText
                /* Default options */
                lines={3}
                more="Show more"
                less="Show less"
                anchorClass=""
                expanded={false}
              >
                {result.html}
              </ShowMoreText>

              <h4>Response Headers</h4>

              <Table>
                <tbody>
                  {result.headers?.map((h) => (
                    <tr>
                      <td style={{ width: "200px" }}>{h.key}</td>
                      <td>{h.value}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </>
          )}
        </Card.Body>
      </Card>
    </>
  );
}
