import { useQuery } from "@apollo/client";
import {
  ChangeEvent,
  ChangeEventHandler,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";

import { AdvancedModeContext } from "../../context";
import { GET_USER_INFO, UserInfoQuery } from "../../gql/auth";
import { dateToTimestamp, timestampToDate } from "../time";
import { FormControlElement } from "../typing/form";

export function useFormInput<T extends HTMLInputElement>(
  initialValue: string
): [string, ChangeEventHandler<T>] {
  const [value, setValue] = useState(initialValue);
  const onChange = (event: ChangeEvent<T>) => setValue(event.target.value);

  return [value, onChange];
}

function setWithExpiry(key: string, value: unknown, ttl: number) {
  const now = new Date();

  // `item` is an object which contains the original value
  // as well as the time when it's supposed to expire
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

function getWithExpiry(key: string) {
  const itemStr = localStorage.getItem(key);
  // if the item doesn't exist, return null
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  // compare the expiry time of the item with the current time
  if (now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    // and return null
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
}

interface IUseLocalStorageSynchronizedStateArgs<T extends string = string> {
  key: string;
  defaultValue: T;
  ttl?: number | null;
}

export function useLocalStorageSynchronizedState<T extends string = string>(
  options: IUseLocalStorageSynchronizedStateArgs
): [value: T, setValue: Dispatch<SetStateAction<T>>];
export function useLocalStorageSynchronizedState<T extends string>({
  key,
  defaultValue,
  ttl = null,
}: IUseLocalStorageSynchronizedStateArgs<T>): [
  value: T,
  setValue: Dispatch<SetStateAction<T>>,
] {
  const [value, setValue] = useState<T>(() => {
    const storedValue = ttl ? getWithExpiry(key) : localStorage.getItem(key);
    return storedValue !== null ? storedValue : defaultValue;
  });

  useEffect(() => {
    try {
      if (ttl) {
        setWithExpiry(key, value, ttl);
      } else {
        localStorage.setItem(key, `${value}`);
      }
    } catch (err) {
      // Because it throws when the LS is full, nothing to do really
    }
  }, [value, key, ttl]);

  return [value, setValue];
}

interface IUseLocalStorageDateSynchronizedStateArgs {
  key: string;
  defaultValue: Date;
  roundToMidnight: boolean;
  ttl: number | null;
}

export function useLocalStorageDateSynchronizedState({
  key,
  defaultValue,
  roundToMidnight = true,
  ttl = null,
}: IUseLocalStorageDateSynchronizedStateArgs): [
  value: Date,
  onChange: (value: Date, isLocalValue?: boolean) => void,
  onRestoreCache: () => void,
] {
  const getInitialValue = () => {
    const cached = ttl ? getWithExpiry(key) : localStorage.getItem(key);
    return cached ? timestampToDate(parseInt(cached)) : defaultValue;
  };
  const [value, setValue] = useState(getInitialValue);

  const onChange = (value: Date, isLocalValue?: boolean) => {
    if (roundToMidnight === true) {
      value.setUTCHours(0, 0, 0, 0);
    }
    const storedValue = dateToTimestamp(value).toString();
    if (!isLocalValue) {
      if (ttl) {
        setWithExpiry(key, storedValue, ttl);
      } else {
        localStorage.setItem(key, storedValue);
      }
    }
    setValue(value);
  };
  const onRestoreCache = () => {
    setValue(getInitialValue());
  };

  return [value, onChange, onRestoreCache];
}

interface IUseFormInputSynchronizedWithLSArgs {
  key: string;
  defaultValue: string;
}

export function useFormInputSynchronizedWithLS({
  key,
  defaultValue,
}: IUseFormInputSynchronizedWithLSArgs): [
  value: string,
  onChange: ChangeEventHandler<FormControlElement>,
] {
  const [value, setValue] = useLocalStorageSynchronizedState({
    key,
    defaultValue,
  });
  const onChange = (event: ChangeEvent<FormControlElement>) =>
    setValue(event.target.value);
  return [value, onChange];
}

export const useAdvancedMode = () => useContext(AdvancedModeContext);

export const useRefreshRulesDebug = (): [
  isDebug: boolean,
  setDebug: (v: boolean) => void,
] => {
  const [debug, setDebug] = useLocalStorageSynchronizedState<`${boolean}`>({
    key: "refreshRulesDebug",
    defaultValue: `${true}`,
    ttl: 3600 * 24 * 7,
  });

  return [debug === "true", (value: boolean) => setDebug(`${value}`)];
};

export const useGetUserInfo = () => {
  const { loading, data, error } = useQuery<UserInfoQuery>(GET_USER_INFO);

  return { loading, isSuperuser: data?.currentUser?.isSuperuser, error };
};
