import { useMutation, useQuery } from "@apollo/client";
import moment from "moment";
import React, { useState } from "react";
import Alert from "react-bootstrap/Alert";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import DatePicker from "react-datepicker";

import { DataHandler } from "./components/DataHandler";
import {
  AdnConfigStatusType,
  AdnEnvironment,
  RollbackAllAdnConfigsMessages,
  WebsiteType,
} from "./gql/types.generated";
import {
  GetWebsitesWithAdnQuery,
  GetWebsitesWithAdnQueryVariables,
  GET_WEBSITES_WITH_ADN,
  RollbackAllAdnConfigsMutation,
  RollbackAllAdnConfigsMutationVariables,
  ROLLBACK_ALL_ADN_CONFIGS,
} from "./gql/websites/adn";
import { Link } from "react-router-dom";

export const LightweightCdn = () => {
  const { loading, error, data } = useQuery<
    GetWebsitesWithAdnQuery,
    GetWebsitesWithAdnQueryVariables
  >(GET_WEBSITES_WITH_ADN);
  const [rollbackAllAdnConfigs, rollbacks] = useMutation<
    RollbackAllAdnConfigsMutation,
    RollbackAllAdnConfigsMutationVariables
  >(ROLLBACK_ALL_ADN_CONFIGS);
  const [datetimeValue, setDatetimeValue] = useState<Date | null>(new Date());
  const [messages, setMessages] = useState<RollbackAllAdnConfigsMessages[]>([]);

  if (error || loading || !data) {
    return (
      <DataHandler error={error} loading={loading} data={data} expectData />
    );
  }

  const websitesWithAdn: GetWebsitesWithAdnQuery["allWebsites"] = [];
  const statusesByWebsiteId: Record<
    WebsiteType["id"],
    GetWebsitesWithAdnQuery["lastAdnConfigStatuses"]
  > = {};

  data.lastAdnConfigStatuses.forEach((status) => {
    const websiteId = status.adnConfig.website.id;
    // Keep only websites with adn configs and find each one only once
    if (!statusesByWebsiteId[websiteId]) {
      statusesByWebsiteId[websiteId] = [];
      const website = data.allWebsites.find((w) => w.id === websiteId);
      if (website) {
        websitesWithAdn.push(website);
      }
    }
    statusesByWebsiteId[websiteId].push(status);
  });

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const env = (formData.get("environment") ??
      AdnEnvironment.Test) as AdnEnvironment;

    // Avoid multiple submissions
    if (rollbacks.loading) {
      return;
    }

    // Avoid unintentional submissions
    if (
      !window.confirm(
        `Are you sure you want to rollback all the currently deployed lightweight CDN configurations of all websites on the "${env}" env?`
      )
    ) {
      return;
    }

    try {
      const response = await rollbackAllAdnConfigs({
        variables: {
          environment: env,
          datetimeStr: datetimeValue
            ? datetimeValue.toISOString()
            : new Date().toISOString(),
        },
      });
      setMessages(response.data?.rollbackAllAdnConfigs?.messages ?? []);
    } catch (err) {
      // handled by useMutation
    }
  };

  return (
    <Container>
      <h1>Lightweight CDN Dashboard</h1>
      <Card>
        <Card.Header>
          Rollback all currently deployed configurations{" "}
          <Badge bg="danger">Danger</Badge>
        </Card.Header>
        <Card.Body>
          <Form action="" onSubmit={handleSubmit}>
            {rollbacks.called &&
              messages.length > 0 &&
              !rollbacks.loading &&
              !rollbacks.error && (
                <Alert variant="info">
                  <ul>
                    {messages.map((message) => (
                      <li key={message.websiteId}>
                        {message.websiteId}: {message.message}
                      </li>
                    ))}
                  </ul>
                </Alert>
              )}
            {rollbacks.error && (
              <Alert variant="danger">
                Something went wrong.
                <br />
                <pre>{JSON.stringify(rollbacks.error, null, 2)}</pre>
              </Alert>
            )}
            <Form.Group controlId="environment">
              <Form.Label>
                The environment on which you want to rollback all the ADN
                Configs
              </Form.Label>
              <Form.Control name="environment" as="select" required>
                <option value="staging">Staging</option>
                <option value="production">Production</option>
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                The date at which you want to rollback all the ADN Configs
              </Form.Label>
              <div>
                <DatePicker
                  selected={datetimeValue}
                  onChange={(value) => {
                    setDatetimeValue(value);
                  }}
                  dateFormat="yyyy/MM/dd h:mm:ss aa"
                  showTimeSelect
                  className="form-control"
                />
              </div>
            </Form.Group>
            <Button type="submit" variant="danger" disabled={rollbacks.loading}>
              {rollbacks.loading
                ? "Rolling back..."
                : "Rollback all ADN Configs"}
            </Button>
          </Form>
        </Card.Body>
      </Card>

      {websitesWithAdn.length === 0 ? <p>No website with ADN</p> : null}

      {websitesWithAdn.map((website) => {
        const hosts = website.productionVersion?.hosts.split(",") ?? [];
        return (
          <Card key={website.id}>
            <Card.Header className="d-flex justify-content-between">
              <span>
                {website.id} - {website.name}
              </span>{" "}
              <span>
                {hosts.map((host, index) => {
                  const url = `https://${host}`;
                  return (
                    <React.Fragment key={host}>
                      <a href={url} target="_blank" rel="noreferrer">
                        {url}
                      </a>
                      {index === hosts.length - 1 ? null : ", "}
                    </React.Fragment>
                  );
                })}
              </span>
            </Card.Header>
            <Card.Body>
              <ListGroup>
                {statusesByWebsiteId[website.id]
                  .sort(byEnvOrder)
                  .map((adnStatus) => (
                    <ListGroup.Item
                      key={adnStatus.id}
                      className="d-flex justify-content-between"
                    >
                      <span>
                        {adnStatus.environment} -{" "}
                        <Link
                          to={`/website/${website.id}/adn/edit/${adnStatus.adnConfig.id}`}
                        >
                          {adnStatus.adnConfig.id}
                        </Link>{" "}
                        - {adnStatus.adnConfig.workerVersion}
                      </span>
                      <span title={formatStatus(adnStatus.status, "tooltip")}>
                        {formatStatus(adnStatus.status)}
                        {" at "}
                        {moment(adnStatus.createdDate).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )}
                      </span>
                    </ListGroup.Item>
                  ))}
              </ListGroup>
            </Card.Body>
          </Card>
        );
      })}
    </Container>
  );
};

const statusEnvOrder = {
  TEST: 0,
  STAGING: 1,
  PRODUCTION: 2,
};

function byEnvOrder<
  T extends { environment: AdnConfigStatusType["environment"] }
>(a: T, b: T) {
  return statusEnvOrder[a.environment] - statusEnvOrder[b.environment];
}

const statusFormats: Record<
  AdnConfigStatusType["status"],
  { label: string; tooltip: string }
> = {
  READY_TO_DEPLOY: {
    label: "Ready to deploy",
    tooltip:
      "This config's deployment was requested but is not yet in progress",
  },
  DEPLOYING: {
    label: "Deploying",
    tooltip: "This config's deployment is in progress",
  },
  DEPLOYED: {
    label: "Deployed",
    tooltip: "This config is active on the environment",
  },
  EARLY_FAILED: {
    label: "Early failed",
    tooltip:
      "This config's deployment failed before changing the environment. The previous config is still active on the environment.",
  },
  FAILED: {
    label: "Failed",
    tooltip: "This config's deployment failed.",
  },
  ROLLING_BACK: {
    label: "Rolling back",
    tooltip: "This config is being rolled back to the previous config.",
  },
};

function formatStatus(
  status: AdnConfigStatusType["status"],
  key: "label" | "tooltip" = "label"
) {
  return statusFormats[status][key] ?? status;
}
