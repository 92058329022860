import { gql } from "@apollo/client";

export const UPDATE_WEBSITE_CONFIG = gql`
  mutation WebsiteConfig($input: ConfigMutationInput!) {
    updateConfig(input: $input) {
      config {
        id
        name
        action
        renderingRules
        extraHeaders
        emulationDevice
        userAgent
        timeout
        maxSpeed
      }
      errors {
        field
        messages
      }
    }
  }
`;
