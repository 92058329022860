import { Badge } from "react-bootstrap";

export const StatusCell = ({ active }: { active: boolean }) => {
  const badgeProps: StatusBadgeProps = active
    ? {
        label: "Active",
        icon: "record",
        variant: "success",
      }
    : {
        label: "Paused",
        icon: "pause",
        variant: "primary",
      };
  return <StatusBadge {...badgeProps} />;
};

type StatusBadgeProps = {
  label: string;
  icon: string;
  variant: React.ComponentProps<typeof Badge>["bg"];
};

const StatusBadge = ({ variant, label, icon }: StatusBadgeProps) => (
  <Badge
    className="d-inline-flex align-items-center"
    style={{ fontWeight: "normal", padding: "4px 8px", fontSize: "12px" }}
    pill
    bg={variant}
  >
    <i className={`bi bi-${icon} fs-6 mr-4`} />
    {label}
  </Badge>
);
