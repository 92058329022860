import { useQuery } from "@apollo/client";
import _ from "lodash";
import { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "react-datepicker/dist/react-datepicker.css";

import { Pie } from "../../Charts";
import { LineChart, StackedBarChart, stringToColor } from "../../common/charts";
import { dateToTimestamp } from "../../common/time";
import { DataHandler } from "../../components/DataHandler";
import {
  BOTS_COLORS,
  CACHE_HIT_COLORS,
  DEVICES_COLORS,
  HTTP_CODES_COLORS,
} from "../../constants";
import { QueryServedPagesResult } from "../../gql/types.generated";
import {
  GET_SECTION_DELIVERY,
  GET_WEBSITE_DELIVERY_MONITORING,
  QualityControlFailQuery,
  QualityControlFailQueryVariables,
  QualityControlWarningQuery,
  QualityControlWarningQueryVariables,
  QUALITY_CONTROL_FAIL,
  QUALITY_CONTROL_WARNING,
  SectionDeliveryQuery,
  SectionDeliveryQueryVariables,
  WebsiteDeliveryMonitoringQuery,
  WebsiteDeliveryMonitoringQueryVariables,
} from "../../gql/websites/monitoring";
import { DateComponent } from "../components/date";
import { Header } from "../components/header";
import { getDates } from "../../common/time";
import "./Home.css";
import { DEFAULT_START_OFFSET } from "./Settings";
import { WebsiteIdParams } from "../types/routeParams";
import { useParams } from "react-router-dom";

const convertToNivoPie = (
  data: Array<any>,
  dim: string,
  metric: string,
  colorMapping?: Record<string, string>
) => {
  return data.map((entry) => {
    const label = entry[dim] === null ? "null" : entry[dim].toString();
    return {
      id: label,
      label: label,
      value: entry[metric],
      color:
        colorMapping !== undefined && colorMapping[label] !== undefined
          ? colorMapping[label]
          : stringToColor(label),
    };
  });
};

const reducebyDimensions = (
  data: Array<any>,
  dimensions: string[],
  metric: string,
  fn = _.sumBy
) => {
  const newData = _(data)
    .groupBy((entry: any) => {
      return _.map(dimensions, (v: string) => {
        return entry[v];
      });
    })
    .map((entries: any[]) => {
      const newEntry: Record<string, string | number> = {};
      _.forEach(dimensions, (d: string) => {
        newEntry[d] = entries[0][d];
      });
      newEntry[metric] = fn(entries, metric);
      return newEntry;
    })
    .value();

  return newData;
};

interface ISectionComponentProps {
  websiteId: string;
  timestampStart: number;
  timestampEnd: number;
  searchEngine: string;
}

const SectionComponent = ({
  websiteId,
  timestampStart,
  timestampEnd,
  searchEngine,
}: ISectionComponentProps) => {
  const { loading, error, data } = useQuery<
    SectionDeliveryQuery,
    SectionDeliveryQueryVariables
  >(GET_SECTION_DELIVERY, {
    variables: {
      id: websiteId,
      timestampStart: timestampStart,
      timestampEnd: timestampEnd,
    },
  });

  const [filterOn, setFilterOn] = useState<string | null>(null);

  if (error || loading || !data) {
    return (
      <DataHandler error={error} loading={loading} data={data} expectData />
    );
  }

  const filterByStatus = <
    Result extends Pick<
      QueryServedPagesResult,
      "delivered" | "deliveryOriginStatus"
    >
  >(
    results: Array<Result>
  ): Array<Result> => {
    let fn = null;
    if (filterOn === "delivered") {
      fn = (e: Result) => e.delivered === true;
    } else if (filterOn === "notDelivered") {
      fn = (e: Result) => e.delivered === false;
    } else if (filterOn === "cacheHit") {
      fn = (e: Result) => e.deliveryOriginStatus === "cache_hit";
    } else if (filterOn === "cacheMiss") {
      fn = (e: Result) => e.deliveryOriginStatus === "cache_miss";
    } else if (filterOn === "liveFetched") {
      fn = (e: Result) => e.deliveryOriginStatus === "live_fetched";
    }
    if (fn) {
      return results.filter(fn);
    }
    return results;
  };

  let stats = filterBySearchEngine(
    searchEngine,
    filterByStatus(data.website?.queryServedPages ?? [])
  );

  return (
    <>
      {" "}
      <Card>
        <Card.Header>Behaviors</Card.Header>
        <Card.Body>
          <Form.Control
            as="select"
            onChange={(e) => setFilterOn(e.target.value)}
          >
            <option value="">All Statuses</option>
            <option value="delivered">Delivered Pages</option>
            <option value="notDelivered">Not Delivered Pages</option>
            <option value="cacheHit">Cache Hit Pages</option>
            <option value="cacheMiss">Cache Miss Pages</option>
            <option value="liveFetched">Live Fetched Pages</option>
          </Form.Control>

          <Row className="websiteHomeChart">
            <Col sm={3}>
              <Pie
                data={convertToNivoPie(
                  reducebyDimensions(
                    _(stats).value(),
                    ["sectionName"],
                    "countHits"
                  ),
                  "sectionName",
                  "countHits",
                  {}
                )}
              />
            </Col>
            <Col sm={9}>
              <StackedBarChart
                data={stats}
                dimension="date"
                splitBy={["sectionName"]}
                metric="countHits"
                colors={{}}
                xTitle="Date"
                yTitle="Number of Hits"
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

interface IQualityControlWarningComponentProps {
  websiteId: string;
  timestampStart: number;
  timestampEnd: number;
  searchEngine: string;
}

const QualityControlWarningComponent = ({
  websiteId,
  timestampStart,
  timestampEnd,
  searchEngine,
}: IQualityControlWarningComponentProps) => {
  const { loading, error, data } = useQuery<
    QualityControlWarningQuery,
    QualityControlWarningQueryVariables
  >(QUALITY_CONTROL_WARNING, {
    variables: {
      id: websiteId,
      timestampStart: timestampStart,
      timestampEnd: timestampEnd,
    },
  });

  if (error || loading || !data) {
    return (
      <DataHandler error={error} loading={loading} data={data} expectData />
    );
  }

  const stats = filterBySearchEngine(
    searchEngine,
    data.website?.queryServedPages ?? []
  );

  return (
    <Card>
      <Card.Header>Quality Control Warnings</Card.Header>
      <Card.Body>
        <Row className="websiteHomeChart">
          <Col sm={3}>
            <Pie
              data={convertToNivoPie(
                reducebyDimensions(
                  _(stats)
                    .filter(
                      (e) =>
                        !!e.countWarningQualityControls &&
                        e.countWarningQualityControls > 0
                    )
                    .value(),
                  ["qualityControlName"],
                  "countWarningQualityControls"
                ),
                "qualityControlName",
                "countWarningQualityControls",
                {}
              )}
            />
          </Col>
          <Col sm={9}>
            <StackedBarChart
              data={stats.filter(
                (e) =>
                  e.countWarningQualityControls &&
                  e.countWarningQualityControls > 0
              )}
              dimension="date"
              splitBy={["qualityControlName"]}
              metric="countWarningQualityControls"
              colors={{}}
              xTitle="Date"
              yTitle="Number of Hits"
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

interface IQualityControlFailComponentProps {
  websiteId: string;
  timestampStart: number;
  timestampEnd: number;
  searchEngine: string;
}

const QualityControlFailComponent = ({
  websiteId,
  timestampStart,
  timestampEnd,
  searchEngine,
}: IQualityControlFailComponentProps) => {
  const { loading, error, data } = useQuery<
    QualityControlFailQuery,
    QualityControlFailQueryVariables
  >(QUALITY_CONTROL_FAIL, {
    variables: {
      id: websiteId,
      timestampStart: timestampStart,
      timestampEnd: timestampEnd,
    },
  });

  if (error || loading || !data) {
    return (
      <DataHandler error={error} loading={loading} data={data} expectData />
    );
  }

  const stats = filterBySearchEngine(
    searchEngine,
    data.website?.queryServedPages ?? []
  );

  return (
    <Card>
      <Card.Header>Quality Control Failures</Card.Header>
      <Card.Body>
        <Row className="websiteHomeChart">
          <Col sm={3}>
            <Pie
              data={convertToNivoPie(
                reducebyDimensions(
                  _(stats)
                    .filter(
                      (e) =>
                        !!e.countFailedQualityControls &&
                        e.countFailedQualityControls > 0
                    )
                    .value(),
                  ["qualityControlName"],
                  "countFailedQualityControls"
                ),
                "qualityControlName",
                "countFailedQualityControls",
                {}
              )}
            />
          </Col>
          <Col sm={9}>
            <StackedBarChart
              data={stats.filter(
                (e) =>
                  e.countFailedQualityControls &&
                  e.countFailedQualityControls > 0
              )}
              dimension="date"
              splitBy={["qualityControlName"]}
              metric="countFailedQualityControls"
              colors={{}}
              xTitle="Date"
              yTitle="Number of Hits"
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

function filterBySearchEngine<
  Result extends Pick<QueryServedPagesResult, "searchEngine">
>(searchEngine: string, results: Array<Result>) {
  if (searchEngine !== "") {
    return results.filter((e) => e.searchEngine === searchEngine);
  }
  return results;
}

interface IChartsCardsProps {
  websiteId: string;
  startDate: Date;
  endDate: Date;
}

function ChartsCards({ websiteId, startDate, endDate }: IChartsCardsProps) {
  const tStart = dateToTimestamp(startDate);
  const tEnd = dateToTimestamp(endDate);
  const {
    loading,
    error,
    data: monitoringData,
  } = useQuery<
    WebsiteDeliveryMonitoringQuery,
    WebsiteDeliveryMonitoringQueryVariables
  >(GET_WEBSITE_DELIVERY_MONITORING, {
    variables: { id: websiteId, timestampStart: tStart, timestampEnd: tEnd },
  });

  const queryServedPages = monitoringData?.website?.queryServedPages;

  const [searchEngine, setSearchEngine] = useState("");
  const [cache, setCache] = useState<{ searchEngines: string[] } | null>(null);

  const [stats, setStats] = useState<Exclude<
    typeof queryServedPages,
    "undefined"
  > | null>(null);
  // const [nbCacheHit, setNbCacheHit] = useState(null);
  // const [nbServed, setNbServed] = useState(null);

  useEffect(() => {
    if (monitoringData) {
      let localCache = {
        searchEngines: _(monitoringData.website?.queryServedPages ?? [])
          .map((res) => res.searchEngine)
          .uniq()
          .value()
          .filter((se): se is string => !!se),
      };
      setCache(localCache);

      if (monitoringData.website?.queryServedPages) {
        const localStats = filterBySearchEngine(
          searchEngine,
          monitoringData.website.queryServedPages
        );
        setStats(localStats);
        // setNbCacheHit(
        //   _(localStats)
        //     .filter((e) => e.cacheHit === true)
        //     .sumBy((e) => e.countHits)
        // );
        // setNbServed(_(localStats).sumBy((e) => e.countHits));
      }
    }
  }, [monitoringData, searchEngine]);

  if (error || loading || !monitoringData || !cache || !stats) {
    return (
      <DataHandler
        error={error}
        loading={loading}
        data={monitoringData && cache && stats}
        expectData
      />
    );
  }

  return (
    <div>
      <Form.Group>
        <Form.Control
          as="select"
          onChange={(e) => setSearchEngine(e.target.value)}
        >
          <option value="">All Search Engines</option>
          {cache.searchEngines.map((se) => (
            <option value={se}>{se}</option>
          ))}
        </Form.Control>
      </Form.Group>

      <Card>
        <Card.Header>Delivery</Card.Header>
        <Card.Body>
          {stats.length > 0 ? (
            <div>
              <b>Delivery Success :</b>{" "}
              {getPercent(
                _(stats)
                  .filter((e) => e.delivered === true)
                  .sumBy((e) => e.countHits ?? 0),
                _(stats).sumBy((e) => e.countHits ?? 0)
              )}
              %
            </div>
          ) : null}
          <Row className="websiteHomeChart">
            <Col sm={3}>
              <Pie
                data={convertToNivoPie(
                  reducebyDimensions(stats, ["delivered"], "countHits"),
                  "delivered",
                  "countHits",
                  CACHE_HIT_COLORS
                )}
              />
            </Col>
            <Col sm={9}>
              <StackedBarChart
                data={stats}
                dimension="date"
                splitBy={["delivered"]}
                metric="countHits"
                colors={{ true: "#23c451", false: "#F84F30" }}
                xTitle="Date"
                yTitle="Number of Hits"
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card>
        <Card.Header>Cache Hit/Miss</Card.Header>
        <Card.Body>
          {stats.length > 0 ? (
            <div>
              <b>Cache Hit :</b>{" "}
              {getPercent(
                _(stats)
                  .filter((e) => e.deliveryOriginStatus === "cache_hit")
                  .sumBy((e) => e.countHits ?? 0),
                _(stats).sumBy((e) => e.countHits ?? 0)
              )}
              % /&nbsp;
              <b>Live Fetch :</b>{" "}
              {getPercent(
                _(stats)
                  .filter((e) => e.deliveryOriginStatus === "live_fetched")
                  .sumBy((e) => e.countHits ?? 0),
                _(stats).sumBy((e) => e.countHits ?? 0)
              )}
              % /&nbsp;
              <b>Cache Miss :</b>{" "}
              {getPercent(
                _(stats)
                  .filter((e) => e.deliveryOriginStatus === "cache_miss")
                  .sumBy((e) => e.countHits ?? 0),
                _(stats).sumBy((e) => e.countHits ?? 0)
              )}
              %
            </div>
          ) : null}
          <Row className="websiteHomeChart">
            <Col sm={3}>
              <Pie
                data={convertToNivoPie(
                  reducebyDimensions(
                    stats,
                    ["deliveryOriginStatus"],
                    "countHits"
                  ),
                  "deliveryOriginStatus",
                  "countHits",
                  CACHE_HIT_COLORS
                )}
              />
            </Col>
            <Col sm={9}>
              <StackedBarChart
                data={stats}
                dimension="date"
                splitBy={["deliveryOriginStatus"]}
                metric="countHits"
                colors={CACHE_HIT_COLORS}
                xTitle="Date"
                yTitle="Number of Hits"
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card>
        <Card.Header>Average Speed Gain on Cache Hit</Card.Header>
        <Card.Body>
          <Row className="websiteHomeChart">
            <LineChart
              data={reducebyDimensions(
                stats.filter((e) => e.cacheHit === true),
                ["date"],
                "speedGain",
                function (entries, metric) {
                  return (
                    _.sumBy(entries, "sumFetchingTime") /
                    _.sumBy(entries, "sumDeliveryTime")
                  );
                }
              )}
              dimension="date"
              metric="speedGain"
              color="#feb601"
              xTitle="date"
              yTitle="Speed Gain"
            />
          </Row>
        </Card.Body>
      </Card>

      <Card>
        <Card.Header>
          HTTP Status Codes from Origin Pages on Cache Hit
        </Card.Header>
        <Card.Body>
          <Row className="websiteHomeChart">
            <Col sm={3}>
              <Pie
                data={convertToNivoPie(
                  reducebyDimensions(
                    _(stats)
                      .filter((res) => res.cacheHit === true)
                      .value(),
                    ["httpCodeIndexedPage"],
                    "countHits"
                  ),
                  "httpCodeIndexedPage",
                  "countHits",
                  HTTP_CODES_COLORS
                )}
              />
            </Col>
            <Col sm={9}>
              <StackedBarChart
                data={stats.filter((e) => e.cacheHit === true)}
                dimension="date"
                splitBy={["httpCodeIndexedPage"]}
                metric="countHits"
                colors={HTTP_CODES_COLORS}
                xTitle="Date"
                yTitle="Number of Hits"
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card>
        <Card.Header>Not Delivered Reasons</Card.Header>
        <Card.Body>
          <Row className="websiteHomeChart">
            <Col sm={3}>
              <Pie
                data={convertToNivoPie(
                  reducebyDimensions(
                    _(stats)
                      .filter((res) => res.delivered === false)
                      .value(),
                    ["notDeliveredReason"],
                    "countHits"
                  ),
                  "notDeliveredReason",
                  "countHits"
                )}
              />
            </Col>
            <Col sm={9}>
              <StackedBarChart
                data={stats.filter((e) => e.delivered === false)}
                dimension="date"
                splitBy={["notDeliveredReason"]}
                metric="countHits"
                xTitle="Date"
                yTitle="Number of Hits"
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card>
        <Card.Header>Bots Distribution</Card.Header>
        <Card.Body>
          <Row className="websiteHomeChart">
            <Col sm={3}>
              <Pie
                data={convertToNivoPie(
                  reducebyDimensions(stats, ["bot"], "countHits"),
                  "bot",
                  "countHits",
                  BOTS_COLORS
                )}
              />
            </Col>
            <Col sm={9}>
              <StackedBarChart
                data={stats}
                dimension="date"
                splitBy={["bot"]}
                metric="countHits"
                colors={BOTS_COLORS}
                xTitle="Date"
                yTitle="Number of Hits"
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card>
        <Card.Header>Resolved Devices on Delivered Pages</Card.Header>
        <Card.Body>
          <Row className="websiteHomeChart">
            <Col sm={3}>
              <Pie
                data={convertToNivoPie(
                  reducebyDimensions(
                    _(stats)
                      .filter((res) => res.delivered === true)
                      .value(),
                    ["resolvedDevice"],
                    "countHits"
                  ),
                  "resolvedDevice",
                  "countHits",
                  DEVICES_COLORS
                )}
              />
            </Col>
            <Col sm={9}>
              <StackedBarChart
                data={stats.filter((e) => e.delivered === true)}
                dimension="date"
                splitBy={["resolvedDevice"]}
                metric="countHits"
                colors={DEVICES_COLORS}
                xTitle="Date"
                yTitle="Number of Hits"
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card>
        <Card.Header>Quality Control Check</Card.Header>
        <Card.Body>
          {stats.length > 0 ? (
            <div>
              <b>Succeeded :</b>{" "}
              {getPercent(
                _(stats)
                  .filter(
                    (e) =>
                      e.cacheHit === true &&
                      e.qualityControlStatusSucceeded === true
                  )
                  .sumBy((e) => e.countHits ?? 0),
                _(stats)
                  .filter((e) => e.cacheHit === true)
                  .sumBy((e) => e.countHits ?? 0)
              )}
              %
            </div>
          ) : null}
          <Row className="websiteHomeChart">
            <Col sm={3}>
              <Pie
                data={convertToNivoPie(
                  reducebyDimensions(
                    _(stats)
                      .filter((e) => e.cacheHit === true)
                      .value(),
                    ["qualityControlStatusSucceeded"],
                    "countHits"
                  ),
                  "qualityControlStatusSucceeded",
                  "countHits",
                  CACHE_HIT_COLORS
                )}
              />
            </Col>
            <Col sm={9}>
              <StackedBarChart
                data={stats.filter((e) => e.cacheHit === true)}
                dimension="date"
                splitBy={["qualityControlStatusSucceeded"]}
                metric="countHits"
                colors={{ true: "#23c451", false: "#F84F30" }}
                xTitle="Date"
                yTitle="Number of Hits"
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <SectionComponent
        websiteId={websiteId}
        timestampStart={tStart}
        timestampEnd={tEnd}
        searchEngine={searchEngine}
      />

      <QualityControlWarningComponent
        websiteId={websiteId}
        timestampStart={tStart}
        timestampEnd={tEnd}
        searchEngine={searchEngine}
      />
      <QualityControlFailComponent
        websiteId={websiteId}
        timestampStart={tStart}
        timestampEnd={tEnd}
        searchEngine={searchEngine}
      />
    </div>
  );
}

function WebsiteBeamHome() {
  const { websiteId } = useParams<WebsiteIdParams>() as WebsiteIdParams;
  const { start, end } = getDates(websiteId, DEFAULT_START_OFFSET);
  const {
    startDate,
    endDate,
    component: dateComponent,
  } = DateComponent(websiteId, start, end);

  return (
    <div>
      <Header>
        <Header.Title websiteId={websiteId} name="Served Pages Dashboard" />
        <Header.Selectors selectorComponents={[dateComponent]} />
      </Header>

      <ChartsCards
        websiteId={websiteId}
        startDate={startDate}
        endDate={endDate}
      />
    </div>
  );
}

function getPercent(val: number, total: number): string {
  return ((val / total) * 100).toFixed(1);
}

export { WebsiteBeamHome };
