import { gql } from "@apollo/client";

export const WEBSITE_VERSION_FRAGMENT = gql`
  fragment WebsiteVersionMainFieldFragment on WebsiteVersionType {
    createdDate
    modifiedDate
    version
    comment
    hosts
    enableSmartCacheRefresh
    noDeliveryStatusCode
    filterBadBots
    customClientIpHeader
    useRenderingFarm
    maxSpeed
    liveMaxSpeed
    enableIncrementalCache
    indexMinimumRefreshInterval
    pagesMinimumRefreshInterval
  }
`;
