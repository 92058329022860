import { gql } from "@apollo/client";

export const GET_WEBSITE_MODULE = gql`
  query GetModule($websiteId: String!, $stableId: String!) {
    website(id: $websiteId) {
      id
      modulesTypes {
        id
        name
        jsonschema
      }
      moduleConfig: moduleFromDraftStableId(stableId: $stableId) {
        id
        stableId
        config
        name
        description
        datasets {
          name
          schema
        }
      }
      allVersions: allVersionsFromModule(
        websiteId: $websiteId
        stableId: $stableId
      ) {
        id
        stableId
        versionNum
        versionStatus
        versionDatePublished
        dateLastEdited
        lastDeployedBy
        comment
      }
    }
  }
`;
