import Cookies from "js-cookie";
import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client/core";

import { AUTH_TOKEN } from "./constants";
import { getEnv } from "./common/env";

const uri = () => {
  // Enabling the usage of the client in tests.
  const env = process.env.NODE_ENV === "test" ? undefined : getEnv();

  if (!env) {
    // We don't match any env URL, we're probably running in local
    return (
      process.env.REACT_APP_GRAPHQL_URL ||
      "http://api.local.activation.botify.com:8000/graphql"
    );
  }

  return env === "beta"
    ? "https://api.activation.botify.com/graphql"
    : `https://api.${env}.activation.botify.com/graphql`;
};

const httpLink = createHttpLink({
  uri: uri,
  credentials: "include",
});

const middlewareLink = new ApolloLink((operation, forward) => {
  const token = localStorage.getItem(AUTH_TOKEN);
  operation.setContext({
    headers: {
      "X-CSRFToken": Cookies.get("csrftoken"),
      authorization: token ? `JWT ${token}` : "",
    },
  });
  return forward(operation);
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: middlewareLink.concat(httpLink),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "network-only",
    },
    query: {
      fetchPolicy: "network-only",
    },
  },
});

export default client;
