import { getEnv } from "./common/env";
import { getHost } from "./common/host";
import { objectFlip } from "./common/object";

export const AUTH_TOKEN = "auth-token";

export const getFrozenDate = (websiteId: string | null) => {
  const host = getHost();
  if (!websiteId || host !== "localhost") return null;
  switch (websiteId) {
    case "SQ42GG":
      return new Date("2020-09-23 06:00");
    case "all":
      return new Date("2020-11-21 06:00");
    default:
      return null;
  }
};

export const getAdminLink = () => {
  const env = getEnv();
  if (!env) {
    // We don't match any env URL, we're probably running in local
    return "http://api.local.activation.botify.com:8000/admin";
  }

  return env === "beta"
    ? "https://api.activation.botify.com/admin-46EQ"
    : `https://api.${env}.activation.botify.com/admin`;
};

export const CACHE_HIT_COLORS = {
  hit: "#23c451",
  miss: "#F84F30",
  true: "#23c451",
  false: "#F84F30",
  null: "gray",
  cache_hit: "#23c451",
  cache_miss: "#F84F30",
  live_fetched: "green",
} as const;

export const HTTP_CODES_COLORS = {
  200: "#23c451",
  301: "orange",
  302: "orange",
  404: "blue",
  500: "#F84F30",
} as const;

export const BOTS_COLORS = {
  "Google Desktop": "#3366CB",
  "Google Smartphone": "#0099C6",
  "Yandex Desktop": "#DB3912",
  "Yandex Mobile": "#DD4477",
  "Bing Desktop": "#FE9901",
  "Bing Mobile": "#ed6639",
  "Baidu Desktop": "#990099",
  Unknown: "#D9D9D9",
} as const;

export const SEARCH_ENGINES_COLORS = {
  google: "#3366CB",
  bing: "#FE9901",
  yandex: "#DB3912",
  baidu: "#990099",
} as const;

export const DEVICES_COLORS = {
  desktop: "#3366CB",
  mobile: "#FE9901",
  Desktop: "#3366CB",
  Mobile: "#FE9901",
  Unknown: "#D9D9D9",
} as const;

export const QC_STATUSES = {
  success: 0,
  warning: 1,
  fail: 2,
  error: 3,
} as const;

export const QC_STATUSES_IDX = objectFlip(QC_STATUSES);

export const TokenScope = {
  DELIVERY: "DELIVERY",
  PREVIEW: "PREVIEW",
  INVENTORY: "INVENTORY",
} as const;
