import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { JsCodeType } from "../../../../../gql/types.generated";

type NameCellProps = {
  name: JsCodeType["name"];
  dragListeners: SyntheticListenerMap | undefined;
};

export const NameCell = ({ name, dragListeners }: NameCellProps) => (
  <div>
    <span {...dragListeners} style={{ marginRight: "8px", cursor: "grab" }}>
      <i className="bi bi-grip-vertical" />
    </span>
    {name}
  </div>
);
