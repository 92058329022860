import { goToPreview } from "../../common/preview";
import { shortUrl } from "../../common/str";
import {
  IMetricCard,
  IPagesDefinition,
  IPreset,
} from "../../common/typing/website";
import { Copy } from "../../components/Copy/Copy";
import {
  QueryContentSubmitPagesResult,
  QueryServedPagesResult,
} from "../../gql/types.generated";
import { Button } from "react-bootstrap";

const goToGoogleCache = (url: string) => {
  const newPageUrl =
    "https://webcache.googleusercontent.com/search?q=cache:" + url;
  window.open(newPageUrl, "_blank");
};

export const FIELDS_VERBOSE = {
  url: "URL",
};

export const CARDS: Record<
  string,
  IMetricCard<QueryContentSubmitPagesResult & QueryServedPagesResult>
> = {
  submitted: {
    name: "Submitted",
    fields: ["succeed", "errorMessage"],
    display: (item) => {
      return (
        <>
          {item.succeed ? "Yes" : "No"}
          {!item.succeed && (
            <>
              <br />
              {item.errorMessage}
            </>
          )}
        </>
      );
    },
  },
  exampleUrl: {
    name: "URL Example",
    fields: ["exampleUrl"],
    display: (item, data) => {
      return (
        <>
          {shortUrl(item.exampleUrl, 100)} |{" "}
          <a href={item.exampleUrl} target="_blank" rel="noreferrer">
            Original Page
          </a>
        </>
      );
    },
  },
  urlEnriched: {
    name: "URL",
    fields: ["url", "devicesFound"],
    display: (item, data) => {
      return (
        <div>
          <Copy textToCopy={item.url} />
          {shortUrl(item.url, 100)}{" "}
          {item.devicesFound.map((device) => {
            return (
              <span>
                <Button
                  variant="link"
                  style={{ padding: 0 }}
                  onClick={() =>
                    goToPreview(
                      data,
                      item.url,
                      !device || device === "responsive" ? "na" : device
                    )
                  }
                >
                  Preview {device}
                </Button>
                &nbsp;
              </span>
            );
          })}
          |{" "}
          <Button
            variant="link"
            style={{ padding: 0 }}
            onClick={() => goToGoogleCache(item.url)}
          >
            Google Cache
          </Button>
          {" | "}
          <a href={item.url} target="_blank" rel="noreferrer">
            Original Page
          </a>
        </div>
      );
    },
  },
  succeed: {
    display: (item) => <>{item.succeed ? "Yes" : "No"}</>,
  },

  datetime: {
    name: "Date (UTC)",
    display: (item) => {
      return (
        <span style={{ whiteSpace: "nowrap" }}>
          {item.datetime.replace("+00:00", "")}
        </span>
      );
    },
  },
};

export const SERVED_PAGES_DEFINITION: IPagesDefinition = {
  fieldsVerbose: FIELDS_VERBOSE,
  gqlFunction: "queryContentSubmitPages",
  dimensions: [
    "url",
    "device",
    "datetime",
    "succeed",
    "countSubmissions",
    "countSubmissionsSuccess",
    "countSubmissionsFailed",
  ],
  cards: CARDS,
};

export const PRESET_RAW: IPreset = {
  id: "last",
  cards: ["urlEnriched", "service", "device", "datetime", "submitted"],
  orderBy: "-datetime",
};

export const PRESET_RAW_24: IPreset = {
  ...PRESET_RAW,
  id: "last24",
  startOffset: 1,
};

export const PRESET_TOP: IPreset = {
  id: "top",
  cards: [
    "urlEnriched",
    "service",
    "countSubmissions",
    "countSubmissionsFailed",
  ],
  orderBy: "-countSubmissions",
};

export const PRESET_FAILS: IPreset = {
  id: "top",
  cards: ["urlEnriched", "datetime", "service", "errorCode", "errorMessage"],
  orderBy: "-datetime",
  filters: [{ field: "succeed", predicate: "eq", value: false }],
};

export const PRESETS = {
  last: PRESET_RAW,
  last24: PRESET_RAW_24,
  top: PRESET_TOP,
  fails: PRESET_FAILS,
} as const;

export const DEFAULT_START_OFFSET = 30;
