import { gql } from "@apollo/client";
import { WEBSITE_SCRIPT_FRAGMENT } from "./websiteScriptFragment.gql";

export const UPDATE_WEBSITE_SCRIPT = gql`
  ${WEBSITE_SCRIPT_FRAGMENT}
  mutation UpdateJSCode($script: UpdateJSCodeMutationInput!) {
    updateJsCode(input: $script) {
      jsCode {
        ...WebsiteScriptFragment
      }
    }
  }
`;
