import { useQuery, NetworkStatus } from "@apollo/client";

import Form from "react-bootstrap/Form";

import { DataHandler } from "../components/DataHandler";
import {
  LIST_SEARCH_ENGINES,
  SearchEnginesQuery,
  SearchEnginesQueryVariables,
} from "../gql/monitoring";
import type { SectionType, WebsiteType } from "../gql/types.generated";
import { generateTable, useGqlQuery } from "./table";
import { dateToEndOfDay, dateToTimestamp } from "./time";
import { IFilter } from "./typing/filter";
import { IPagesDefinition, IPreset } from "./typing/website";

interface ISectionComponentProps {
  sections: Pick<SectionType, "name">[];
  setSection: (value: string) => void;
}

export function SectionComponent({
  sections,
  setSection,
}: ISectionComponentProps) {
  return (
    <Form.Control
      name="behavior"
      as="select"
      onChange={(e) => setSection(e.target.value)}
    >
      <option>Select a behavior</option>
      {sections.map(({ name }) => (
        <option key={name} value={name}>
          {name}
        </option>
      ))}
    </Form.Control>
  );
}

interface ISearchEngineComponentProps {
  setSearchEngine: (searchEngineId: number | null) => void;
  setBot: (botId: number | null) => void;
  searchEngine: number | null;
  bot: number | null;
}

export function SearchEngineComponent({
  setSearchEngine,
  setBot,
  searchEngine,
  bot,
}: ISearchEngineComponentProps) {
  const { loading, error, data } = useQuery<
    SearchEnginesQuery,
    SearchEnginesQueryVariables
  >(LIST_SEARCH_ENGINES);

  if (error || loading || !data) {
    return (
      <DataHandler error={error} loading={loading} data={data} expectData />
    );
  }

  return (
    <Form.Control
      name="searchEngine"
      as="select"
      onChange={(e) => {
        if (e.target.value.startsWith("se:")) {
          setSearchEngine(parseInt(e.target.value.split(":")[1]));
          setBot(null);
        } else if (e.target.value.startsWith("bot:")) {
          const result = e.target.value.split(":")[1].split(",");
          setSearchEngine(parseInt(result[0]));
          setBot(parseInt(result[1]));
        } else {
          setBot(null);
          setSearchEngine(null);
        }
      }}
    >
      <option>Select a Search Engine</option>
      {data.listSearchEngines?.map((se) => (
        <>
          {" "}
          <option
            value={"se:" + se.id}
            selected={se.id === searchEngine && !bot}
          >
            {se.name}
          </option>
          {se.bots &&
            se.bots.map((b) => (
              <option
                value={"bot:" + se.id + "," + b.id}
                selected={b.id === bot}
              >
                -- {se.name} {b.name}
              </option>
            ))}
        </>
      ))}
    </Form.Control>
  );
}

interface IExplorerTableProps {
  website: WebsiteType;
  startDate: Date;
  endDate: Date;
  definitions: IPagesDefinition;
  presets: Record<string, IPreset>;
  currentPreset: string;
  additionalFilters?: IFilter[];
}

export function ExplorerTable({
  website,
  startDate,
  endDate,
  definitions,
  presets,
  currentPreset,
  additionalFilters = [],
}: IExplorerTableProps) {
  const tStart = dateToTimestamp(startDate);
  const tEnd = dateToTimestamp(dateToEndOfDay(endDate));

  const {
    loading,
    error,
    data: monitoringData,
    networkStatus,
  } = useGqlQuery({
    websiteId: website.id,
    tStart,
    tEnd,
    datamodel: definitions,
    preset: presets[currentPreset],
    additionalFilters,
  });

  if (error || loading) <DataHandler error={error} loading={loading} />;

  const showTable =
    networkStatus !== NetworkStatus.loading &&
    monitoringData &&
    monitoringData.website;

  return (
    <>
      {showTable
        ? generateTable(
            monitoringData.website[definitions.gqlFunction],
            definitions,
            presets[currentPreset],
            website
          )
        : "Loading"}
    </>
  );
}
