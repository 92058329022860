import { gql } from "@apollo/client";

export const UPDATE_WEBSITE_MODULE = gql`
  mutation UpdateModule($input: UpdateModuleMutationInput!) {
    updateModule(input: $input) {
      module {
        id
        stableId
        config
        name
        description
        datasets {
          name
          schema
        }
      }
      errors {
        field
        messages
      }
    }
  }
`;
