import { gql } from "@apollo/client";

export const GET_WEBSITE_SEARCH_ENGINE_INDEX_AGE = gql`
  query WebsiteSearchEngineIndexAge($id: String!, $searchEngine: Int!) {
    website(id: $id) {
      id
      querySearchEngineIndexAge(searchEngineId: $searchEngine) {
        results {
          ageRange
          date
          count
        }
        error
      }
    }
  }
`;
