import { useState, forwardRef, Ref, ComponentProps } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";

interface IInputLockableProps extends ComponentProps<typeof FormControl> {}

export const InputLockable = forwardRef(
  (props: IInputLockableProps, ref: Ref<HTMLInputElement>) => {
    const [isLocked, setIsLocked] = useState(true);
    return (
      <InputGroup>
          <Button
            variant={isLocked ? "secondary" : "primary"}
            onClick={(e) => setIsLocked((prev) => !prev)}
          >
            <i className={isLocked ? "bi-lock" : "bi-unlock"}></i>
          </Button>
        <FormControl ref={ref} {...props} readOnly={isLocked} />
      </InputGroup>
    );
  }
);
