import { Button, Form, InputGroup } from "react-bootstrap";
import { SectionType } from "../../../gql/types.generated";
import { FormField, FormValue, IForm } from "../form";

interface ISectionFieldProps<K extends FormField, V = FormValue<K>> {
  form: IForm;
  setForm: React.Dispatch<React.SetStateAction<IForm>>;
  isFallbackSection: boolean;
  fallbackSection: SectionType;
  formElement: JSX.Element;
  field: K;
  fallbackValue?: V;
  mapping?: V extends string | number ? Record<V, string> : never;
}

export const SectionField = <K extends FormField>({
  form,
  setForm,
  isFallbackSection,
  fallbackSection,
  formElement,
  field,
  mapping,
  fallbackValue = null,
}: ISectionFieldProps<K>) => {
  const isSet = !!(form[field] ?? false);

  const toggleFormValue = () => {
    const nextValue = (() => {
      if (isSet) return null;
      return fallbackValue ?? fallbackSection[field];
    })();
    setForm((form) => ({ ...form, [field]: nextValue }));
  };

  return (
    <InputGroup>
      {!isFallbackSection && (
        <Button
          variant={isSet ? "primary" : "secondary"}
          onClick={toggleFormValue}
        >
          <i className={isSet ? "bi-lock" : "bi-unlock"} />
        </Button>
      )}

      {isFallbackSection || isSet ? (
        formElement
      ) : (
        <Form.Control
          type="text"
          value={
            "Main Behavior : " +
            (mapping && fallbackSection[field]
              ? // TODO: safe typing: remove assertions
                mapping[
                  fallbackSection[
                    field as keyof SectionType
                  ] as keyof typeof mapping
                ]
              : fallbackSection[field])
          }
          disabled={true}
        />
      )}
    </InputGroup>
  );
};
