import { useMutation, useQuery } from "@apollo/client";
import { gql } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";

import {
  useFormInput,
  useFormInputSynchronizedWithLS,
} from "../../../common/hooks";
import { DataHandler } from "../../../components/DataHandler";
import { BotifyAnalyticsInputType } from "../../../gql/types.generated";
import {
  GET_WEBSITE,
  WebsiteQuery,
  WebsiteQueryVariables,
} from "../../../gql/websites/website";
import { Header } from "../../components/header";
import { WebsiteIdParams } from "../../types/routeParams";
import { useParams } from "react-router-dom";

const GQL_CREATE_AUTOMATION = gql`
  mutation Automation($input: RegisterBotifyAnalyticsAutomationMutationInput!) {
    createBotifyAnalyticsAutomation(input: $input) {
      botifyAnalyticsInput {
        id
        description
        device
        inputType
        automationUsername
        automationProject
        automationId
      }
      success
      errors {
        field
        messages
      }
    }
  }
`;

export function Automate() {
  const { websiteId } = useParams<WebsiteIdParams>() as WebsiteIdParams;
  const { loading, error, data } = useQuery<
    WebsiteQuery,
    WebsiteQueryVariables
  >(GET_WEBSITE, {
    variables: { id: websiteId },
  });

  const [ownerSlug, setOwnerSlug] = useFormInputSynchronizedWithLS({
    key: "analyticsAutomateOwner" + websiteId,
    defaultValue: "",
  });
  const [projectSlug, setProjectSlug] = useFormInputSynchronizedWithLS({
    key: "analyticsAutomateProject" + websiteId,
    defaultValue: "",
  });
  const [device, setDevice] = useFormInput("");

  const [validated, setValidated] = useState(false);
  const [inputs, setInputs] = useState<BotifyAnalyticsInputType[]>([]);

  const [submitAutomation] = useMutation(GQL_CREATE_AUTOMATION, {
    update(cache, { data: { createBotifyAnalyticsAutomation } }) {
      setInputs([
        ...inputs,
        createBotifyAnalyticsAutomation.botifyAnalyticsInput,
      ]);
    },
  });

  useEffect(() => {
    if (data?.website?.botifyAnalyticsInputs) {
      setInputs(
        data.website.botifyAnalyticsInputs as BotifyAnalyticsInputType[]
      );
    }
  }, [data]);

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    const form = event.currentTarget;
    event.preventDefault();
    if (form.checkValidity() === false) {
      event.stopPropagation();
    }

    setValidated(true);

    await submitAutomation({
      variables: {
        input: {
          website: websiteId,
          device: device,
          automationProject: projectSlug,
          automationUsername: ownerSlug,
        },
      },
    });
  }

  if (error || loading) return <DataHandler error={error} loading={loading} />;

  return (
    <React.Fragment>
      <Header.Title
        websiteId={websiteId}
        name="Sources > Botify Analytics > Automate"
      />

      <Card>
        <Card.Header>Inputs with automation</Card.Header>
        <Card.Body>
          {inputs.filter((input) => input.inputType === "automation").length ===
            0 && "No automation registered yet"}
          {inputs
            .filter((input) => input.inputType === "automation")
            .map((input) => {
              return (
                <>
                  <p>
                    <b>Device</b> : {input.device} / <b>Username</b> :{" "}
                    {input.automationUsername} / <b>Project Slug</b> :{" "}
                    {input.automationProject}/{" "}
                    <a
                      target="blank"
                      href={`https://app.botify.com/${input.automationUsername}/${input.automationProject}/`}
                    >
                      Link to Project on Botify
                    </a>
                  </p>
                  {Number(input.automationId) > 0 && (
                    <p>
                      <b>Automation</b> :{" "}
                      <a
                        target="blank"
                        href={`https://app.botify.com/admin/projects/jobautomation/?public_id=${input.automationId}`}
                      >
                        https://app.botify.com/admin/projects/jobautomation/?public_id=
                        {input.automationId}
                      </a>
                    </p>
                  )}
                  {!input.automationId && (
                    <p>
                      <b>
                        Automation ID cannot be retrieved, but you can find all
                        automations on this link :
                      </b>{" "}
                      <a
                        target="blank"
                        href="https://app.botify.com/admin/projects/jobautomation/"
                      >
                        https://app.botify.com/admin/projects/jobautomation/
                      </a>
                    </p>
                  )}
                  <hr />
                </>
              );
            })}
        </Card.Body>
      </Card>

      <Card>
        <Card.Header>Register a new automation</Card.Header>
        <Card.Body>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Device</Form.Label>
              <Form.Control
                as="select"
                value={device}
                onChange={setDevice}
                required
              >
                <option value="">Select a source...</option>
                {[
                  ["desktop", "Desktop"],
                  ["mobile", "Mobile"],
                  ["na", "Adaptive or Responsive for any device"],
                ].map((entry) => (
                  <option value={entry[0]} key={entry[0]}>
                    {entry[1]}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Project Owner</Form.Label>
              <Form.Control
                value={ownerSlug}
                onChange={setOwnerSlug}
                required
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Project Slug</Form.Label>
              <Form.Control
                value={projectSlug}
                onChange={setProjectSlug}
                required
              />
            </Form.Group>

            <Form.Text>
              How to find Project Owner, Project Slug and Analysis Slug?
              <br />
              Go to a Botify Site Crawler analysis report and take its URL (ex :
              https://app.botify.com/paul-mccartney/thebeatles.com/?analysisSlug=20200101)
              <br />
              Project Owner is <b>paul-mccartney</b>, Project Slug is{" "}
              <b>thebeatles.com</b> and Analysis Slug is <b>20200101</b>
            </Form.Text>

            <Button type="submit" className="float-right">
              Create Automation
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
