import { useQuery, gql } from "@apollo/client";
import Card from "react-bootstrap/Card";

import { DataHandler } from "../components/DataHandler";
import { Header } from "./components/header";
import { WebsiteIdParams } from "./types/routeParams";
import { useParams } from "react-router-dom";

export function WebsiteSegmentation() {
  const { websiteId } = useParams<WebsiteIdParams>() as WebsiteIdParams;
  const query = gql`
    query Website($id: String!) {
      website(id: $id) {
        id
        draftVersion {
          id
          segmentationRules
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(query, {
    variables: { id: websiteId },
  });

  if (error || loading) return <DataHandler error={error} loading={loading} />;

  return (
    <div>
      <Header.Title websiteId={websiteId} name="Segmentation Rules" />
      <Card>
        <Card.Body>
          Segmentation may not works correctly on certain cases :
          <ul>
            <li>
              In case of whitelisted params + ignored params with wildcards
              which have the same prefix (ex: +utm_campaign,utm*){" "}
            </li>
            <li>In case of *,+any_param</li>
          </ul>
          <pre>{data.website.draftVersion.segmentationRules}</pre>
        </Card.Body>
      </Card>
    </div>
  );
}
