export const capitalize = (s: string) => {
  // TODO: Remove when type are resolved
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export function shortUrl(url: string, l?: number) {
  l = l ?? 50;
  const chunk_l = l / 2;
  url = url.replace("http://", "").replace("https://", "");

  if (url.length <= l) {
    return url;
  }

  const start_chunk = shortString(url, chunk_l, false);
  const end_chunk = shortString(url, chunk_l, true);
  return start_chunk + "..." + end_chunk;
}

function shortString(s: string, l: number, reverse?: boolean) {
  const stop_chars = [" ", "/", "&"];
  const acceptable_shortness = l * 0.8; // When to start looking for stop characters
  reverse = reverse ?? false;
  s = reverse ? s.split("").reverse().join("") : s;
  let short_s = "";

  for (var i = 0; i < l - 1; i++) {
    short_s += s[i];
    if (i >= acceptable_shortness && stop_chars.indexOf(s[i]) >= 0) {
      break;
    }
  }
  if (reverse) {
    return short_s.split("").reverse().join("");
  }
  return short_s;
}

export function truncate(str: string, l: number) {
  if (str.length > l) {
    return str.substring(0, l - 1) + "...";
  }
  return str;
}

export function camelToSnakeCase(text: string) {
  const inputLines = text.split("\n");
  const outputLines = inputLines.map((line) => {
    return Array.from(line).reduce((acc, v, i) => {
      if (i === 0) {
        acc += v.toLocaleLowerCase();
        return acc;
      }
      if (v.toLocaleUpperCase() === v) {
        acc += "_";
      }
      acc += v.toLocaleLowerCase();
      return acc;
    }, "");
  });
  return outputLines.join("\n");
}

export const snakeToCamelCase = (s: string) => {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace("-", "").replace("_", "");
  });
};

export const copyStrToClipboard = async (text: string) => {
  try {
    await navigator.clipboard.writeText(text);
  } catch (err) {}
};
