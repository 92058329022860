import { useQuery } from "@apollo/client";
import Table from "react-bootstrap/Table";

import { capitalize } from "../../common/str";
import { DataHandler } from "../../components/DataHandler";
import { HtmlBlockType, PageEditType } from "../../gql/types.generated";
import {
  GET_PAGE_EDIT,
  PageEditQuery,
  PageEditQueryVariables,
} from "../../gql/websites/page-edit";
import { Header } from "../components/header";
import { PageEditIdParams, WebsiteIdParams } from "../types/routeParams";
import { useParams } from "react-router-dom";

type PickPageEdit<K extends keyof PageEditType> = Pick<PageEditType, K>;

function HtmlBlock({
  block,
}: {
  block: Pick<
    HtmlBlockType,
    "name" | "cssSelector" | "insertStrategy" | "innerHtml"
  >;
}) {
  return (
    <Table striped>
      <tr>
        <td>
          <b>Name</b>
        </td>
        <td>{block.name}</td>
      </tr>
      <tr>
        <td>
          <b>CSS Selector</b>
        </td>
        <td>{block.cssSelector}</td>
      </tr>
      <tr>
        <td>
          <b>Insert Strategy</b>
        </td>
        <td>{block.insertStrategy}</td>
      </tr>
      <tr>
        <td>
          <b>InnerHTML</b>
        </td>
        <td>
          <code>{block.innerHtml}</code>
        </td>
      </tr>
    </Table>
  );
}

function Redirect({
  pageEdit,
}: {
  pageEdit: PickPageEdit<"permanentRedirect" | "temporaryRedirect">;
}) {
  const { permanentRedirect, temporaryRedirect } = pageEdit;
  return (
    <>
      {" "}
      <h3>Redirects</h3>
      <p>
        {" "}
        {permanentRedirect &&
          `Forced permanent redirect to ${permanentRedirect}`}
        {temporaryRedirect &&
          `Forced temporary redirect to ${temporaryRedirect}`}
      </p>
    </>
  );
}

function Metadata({
  pageEdit,
}: {
  pageEdit: PickPageEdit<"h1" | "title" | "description">;
}) {
  return (
    <Table striped>
      {(["title", "h1", "description"] as const).map(
        (metadata) =>
          pageEdit[metadata] && (
            <tr>
              <td>
                <b>{capitalize(metadata)}</b>
              </td>
              <td>{pageEdit[metadata]}</td>
            </tr>
          )
      )}
    </Table>
  );
}

export function Detail() {
  const { websiteId, pageEditId } = useParams<
    WebsiteIdParams & PageEditIdParams
  >() as WebsiteIdParams & PageEditIdParams;
  const { loading, error, data } = useQuery<
    PageEditQuery,
    PageEditQueryVariables
  >(GET_PAGE_EDIT, {
    variables: {
      websiteId: websiteId,
      id: pageEditId,
    },
  });

  const pageEdit = data?.pageEdit;

  if (error || loading || !pageEdit) {
    return (
      <DataHandler error={error} loading={loading} data={pageEdit} expectData />
    );
  }

  return (
    <>
      <Header.Title websiteId={websiteId} name="Page Edit" />
      <h3>
        {pageEdit.url} on {pageEdit.device}
      </h3>

      <p style={{ textAlign: "right" }}>
        <a
          target="_blank"
          href={`https://api.activation.botify.com/admin-46EQ/page_edit/pageedit/${pageEdit.id}/change/`}
          rel="noreferrer"
        >
          Edit URL on Django Admin
        </a>
      </p>

      {pageEdit.permanentRedirect || pageEdit.temporaryRedirect ? (
        <Redirect pageEdit={pageEdit} />
      ) : (
        <Metadata pageEdit={pageEdit} />
      )}

      {pageEdit.htmlBlocks.length > 0 && (
        <>
          <h3>HTML Blocks</h3>
          {pageEdit.htmlBlocks.map((block) => (
            <>
              <HtmlBlock block={block} />
              <hr />
            </>
          ))}
        </>
      )}
    </>
  );
}
