import type { IMonitoringOptions, PeriodKey } from "./types";
import { getFrozenDate } from "../constants";
import { dateToMidnight, dateToTimestamp } from "../common/time";

export function getOptions(): IMonitoringOptions {
  const now = new Date();
  const coef = 1000 * 60;

  // Round to last minute
  const frozen_end_date = getFrozenDate("all");
  const end =
    frozen_end_date !== null
      ? frozen_end_date
      : new Date(
          Math.round((now.getTime() + now.getTimezoneOffset()) / coef) * coef
        );
  const timestampEnd = dateToTimestamp(end);

  const timestampStartLast24h = dateToTimestamp(
    new Date(
      Math.round(end.getTime() + end.getTimezoneOffset() - coef * 60 * 24)
    )
  );
  const timestampStart1dMidnight = dateToTimestamp(
    dateToMidnight(
      new Date(
        Math.round(end.getTime() + end.getTimezoneOffset() - coef * 60 * 24)
      )
    )
  );
  const timestampStart7dMidnight = dateToTimestamp(
    dateToMidnight(
      new Date(
        Math.round(end.getTime() + end.getTimezoneOffset() - coef * 60 * 24 * 7)
      )
    )
  );
  const timestampStart30dMidnight = dateToTimestamp(
    dateToMidnight(new Date(Math.round(end.getTime() - coef * 60 * 24 * 30)))
  );

  return {
    timestampEnd: timestampEnd,
    timestampStartLast24h: timestampStartLast24h,
    timestampStart1dMidnight: timestampStart1dMidnight,
    timestampStart7dMidnight: timestampStart7dMidnight,
    timestampStart30dMidnight: timestampStart30dMidnight,
  };
}

interface IDateSettings {
  dateField: "date" | "dateHour";
  dateVerbose: string;
  timestampStart: number;
  timestampEnd: number;
}

export function getDateSettings(
  period: PeriodKey,
  options: IMonitoringOptions
): IDateSettings {
  const dateSettings: Record<PeriodKey, IDateSettings> = {
    "30d": {
      dateField: "date",
      dateVerbose: "Last 30 days",
      timestampStart: options.timestampStart30dMidnight,
      timestampEnd: options.timestampEnd,
    },
    "24h": {
      dateField: "dateHour",
      dateVerbose: "Last 24 Hours",
      timestampStart: options.timestampStartLast24h,
      timestampEnd: options.timestampEnd,
    },
  };
  return dateSettings[period];
}
