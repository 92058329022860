import { useQuery } from "@apollo/client";
import { useState } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";

import "react-datepicker/dist/react-datepicker.css";

import { Pie } from "../../Charts";
import {
  convertToNivoPie,
  reducebyDimensions,
  StackedBarChart,
} from "../../common/charts";
import { dateToTimestamp, getDates } from "../../common/time";
import { CACHE_HIT_COLORS, SEARCH_ENGINES_COLORS } from "../../constants";
import { QueryContentSubmitPagesResult } from "../../gql/types.generated";
import {
  BingContentSubmissionMonitoringQuery,
  BingContentSubmissionMonitoringQueryVariables,
  GET_BING_CONTENT_SUBMISSION_MONITORING,
} from "../../gql/websites/website";
import { DateComponent } from "../components/date";
import { Header } from "../components/header";
import { WebsiteIdParams } from "../types/routeParams";
import "../beam/Home.css";
import { DEFAULT_START_OFFSET } from "./Settings";
import { useParams } from "react-router-dom";
import { Alert } from "react-bootstrap";

type ResultWithService = QueryContentSubmitPagesResult & { service: string };

interface IChartsCardsProps {
  websiteId: string;
  startDate: Date;
  endDate: Date;
}

function ChartsCards({ websiteId, startDate, endDate }: IChartsCardsProps) {
  const tStart = dateToTimestamp(startDate);
  const tEnd = dateToTimestamp(endDate);
  const {
    loading,
    error,
    data: monitoringData,
  } = useQuery<
    BingContentSubmissionMonitoringQuery,
    BingContentSubmissionMonitoringQueryVariables
  >(GET_BING_CONTENT_SUBMISSION_MONITORING, {
    variables: { id: websiteId, timestampStart: tStart, timestampEnd: tEnd },
  });

  const [service, setService] = useState("");

  if (loading) return <div>Loading Charts</div>;
  if (error) return <>Error! {error.message}</>;

  const monitoringDataWithServices =
    monitoringData?.website?.queryContentSubmitPages.filter<ResultWithService>(
      // @ts-expect-error - nonsensical TS error
      // `Signature '({ service }: { ... }): boolean' must be a type predicate.`
      ({ service }) => !!service
    ) || [];
  const services = [
    ...new Set(monitoringDataWithServices.map(({ service }) => service)),
  ];
  const stats = monitoringDataWithServices.filter(
    (entry) => !service || entry.service === service
  );

  const getPercent = (val: number, total: number) =>
    ((val / total) * 100).toFixed(1);

  const getTotalSubmissions = (stats: ResultWithService[]) =>
    stats.reduce(
      (totalSubmissions, { countSubmissions }) =>
        totalSubmissions + (countSubmissions ?? 0),
      0
    );

  if (stats.length === 0) {
    return <div>No data</div>;
  }

  return (
    <>
      <div>
        <Form.Group>
          <Form.Control
            as="select"
            onChange={(e) => setService(e.target.value)}
          >
            <option value="">All Services</option>
            {services.map((service) => (
              <option value={service}>{service}</option>
            ))}
          </Form.Control>
        </Form.Group>

        <Card>
          <Card.Header>By Service</Card.Header>
          <Card.Body>
            <Row className="websiteHomeChart">
              <Col sm={3}>
                <Pie
                  data={convertToNivoPie(
                    reducebyDimensions(stats, ["service"], "countSubmissions"),
                    "service",
                    "countSubmissions",
                    SEARCH_ENGINES_COLORS
                  )}
                />
              </Col>
              <Col sm={9}>
                <StackedBarChart
                  data={stats}
                  dimension="date"
                  splitBy={["service"]}
                  metric="countSubmissions"
                  colors={SEARCH_ENGINES_COLORS}
                  xTitle="Date"
                  yTitle="Number of Submissions"
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card>
          <Card.Header>Submissions Status</Card.Header>
          <Card.Body>
            <div>
              <b>Delivery Success :</b>{" "}
              {getPercent(
                getTotalSubmissions(stats.filter(({ succeed }) => !!succeed)),
                getTotalSubmissions(stats)
              )}
              %
            </div>
            <Row className="websiteHomeChart">
              <Col sm={3}>
                <Pie
                  data={convertToNivoPie(
                    reducebyDimensions(stats, ["succeed"], "countSubmissions"),
                    "succeed",
                    "countSubmissions",
                    CACHE_HIT_COLORS
                  )}
                />
              </Col>
              <Col sm={9}>
                <StackedBarChart
                  data={stats}
                  dimension="date"
                  splitBy={["succeed"]}
                  metric="countSubmissions"
                  colors={CACHE_HIT_COLORS}
                  xTitle="Date"
                  yTitle="Number of Submissions"
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>

        <Card>
          <Card.Header>Submissions Errors</Card.Header>
          <Card.Body>
            <Row className="websiteHomeChart">
              <Col sm={3}>
                <Pie
                  data={convertToNivoPie(
                    reducebyDimensions(
                      stats.filter((e) => !e.succeed),
                      ["errorCode"],
                      "countSubmissions"
                    ),
                    "errorCode",
                    "countSubmissions"
                  )}
                />
              </Col>
              <Col sm={9}>
                <StackedBarChart
                  data={stats.filter((e) => !e.succeed)}
                  dimension="date"
                  splitBy={["errorCode"]}
                  metric="countSubmissions"
                  xTitle="Date"
                  yTitle="Number of Submissions"
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    </>
  );
}

export function BingContentSubmissionHome() {
  const { websiteId } = useParams<WebsiteIdParams>() as WebsiteIdParams;
  const { start, end } = getDates(websiteId, DEFAULT_START_OFFSET);

  const {
    startDate,
    endDate,
    component: dateComponent,
  } = DateComponent(websiteId, start, end);

  return (
    <div>
      <Header>
        <Header.Title
          websiteId={websiteId}
          name="Bing Content Submission Dashboard"
        />
        <Header.Selectors selectorComponents={[dateComponent]} />
      </Header>

      <Alert variant="warning">
        Bing Content Submission will only work once Bing approved this domain
        for a large submission volume. If you’d like to use Bing Content
        Submission for this client, reach out to the product team.
      </Alert>

      <ChartsCards
        websiteId={websiteId}
        startDate={startDate}
        endDate={endDate}
      />
    </div>
  );
}
