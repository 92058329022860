export const allMetricKeys = [
  "websiteId",
  "websiteName",
  "date",
  "dateHour",
  "count",
  "countHits",
  "countUrls",
  "countHitsDelivered",
  "countHitsNotDelivered",
] as const;

export type MetricKey = typeof allMetricKeys[number];

export type DimensionKey = "date" | "dateHour" | "websiteId" | "websiteName";

export const allDatabaseKeys = ["servedPages", "indexedPages"] as const;

export type DatabaseKey = typeof allDatabaseKeys[number];

export const allPeriodKeys = ["24h", "30d"];

export type PeriodKey = typeof allPeriodKeys[number];

export interface IMonitoringOptions {
  timestampEnd: number;
  timestampStartLast24h: number;
  timestampStart1dMidnight: number;
  timestampStart7dMidnight: number;
  timestampStart30dMidnight: number;
}
