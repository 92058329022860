import { WebsiteIdParams } from "./types/routeParams";
import { Header } from "./components/header";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import { ForceRefresh } from "./forceRefresh/forceRefresh";
import { useParams } from "react-router-dom";

export function WebsiteForceRefresh() {
  const { websiteId } = useParams<WebsiteIdParams>() as WebsiteIdParams;

  return (
    <div>
      <Header.Title websiteId={websiteId} name="Force refresh" />
      <Container style={{ margin: 0, padding: 0 }}>
        <Card>
          <Card.Body>
            <ForceRefresh websiteId={websiteId} />
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
}
