import { gql } from "@apollo/client";

export const LIST_SEARCH_ENGINES = gql`
  query SearchEngines {
    listSearchEngines {
      id
      name
      bots {
        id
        name
      }
    }
  }
`;
