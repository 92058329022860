import { FeatureFilter } from "./featureFilter";
import { Filters, WebsitesFilterHandler } from "./helpers";

export function WebsitesFilters({
  handleFilter,
  filters,
}: {
  handleFilter: WebsitesFilterHandler;
  filters: Filters;
}) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "10px",
      }}
    >
      <FeatureFilter
        onChange={handleFilter}
        label="Has ADN"
        name="hasAdn"
        value={filters["hasAdn"]}
      />
      <FeatureFilter
        onChange={handleFilter}
        label="Has Smart Cache Refresh"
        name="hasSmartCacheRefresh"
        value={filters["hasSmartCacheRefresh"]}
      />
      <FeatureFilter
        onChange={handleFilter}
        label="Has Rendering Farm"
        name="hasRenderingFarm"
        value={filters["hasRenderingFarm"]}
      />
      <FeatureFilter
        onChange={handleFilter}
        label="Has Links Discovery Inputs"
        name="hasLinksDiscoveryInputs"
        value={filters["hasLinksDiscoveryInputs"]}
      />
      <FeatureFilter
        onChange={handleFilter}
        label="Has Bots Discovery Inputs"
        name="hasBotsDiscoveryInputs"
        value={filters["hasBotsDiscoveryInputs"]}
      />
    </div>
  );
}
