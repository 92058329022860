import { gql } from "@apollo/client";

export const GET_WEBSITE_SEARCH_ENGINE_INDEX = gql`
  query WebsiteSearchEngineIndex(
    $id: String!
    $nbDays: Int!
    $searchEngine: Int!
    $bot: Int
  ) {
    website(id: $id) {
      id
      querySearchEngineIndex(
        searchEngineId: $searchEngine
        botId: $bot
        nbDays: $nbDays
      ) {
        results {
          sectionName
          date
          count
        }
        error
      }
    }
  }
`;
